import { stateOptions } from '../retirementPlan';
import { DATATYPES } from '../common';

export const dependentFields = [
  'annualRent',
  'annualRentGrowth',
  'priorDeprAccumulated',
];

export const investmentPropertiesConstants = [
  {
    label: 'Purchase Date',
    name: 'purchaseDate',
    colSize: 6,
    type: 'date',
    errorText: 'Invalid Date format',
  },
  {
    label: 'Purchase Price',
    name: 'purchasePrice',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Current value',
    name: 'value',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Appreciation rate',
    name: 'appreciationRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Maintenance expense',
    name: 'aggMaintenanceExp',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Expense growth',
    name: 'aggMaintenanceExpGrowth',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Orig/refi loan dt',
    name: 'origOrRefiLoanDate',
    colSize: 6,
    type: 'date',
    errorText: 'Invalid Date',
  },
  {
    label: 'Loan amount',
    name: 'origOrRefiLoanAmount',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Amount outstanding',
    name: 'loanAmountOutstanding',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Loan term yrs',
    name: 'loanTerm',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
  },
  {
    label: 'Fixed rate',
    name: 'fixedIntRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Domicile state',
    name: 'propDomicile',
    type: 'select',
    options: stateOptions,
    errorText: '% field should range from 0 to 100',
  },
  // {
  //   label: 'Property domicile state',
  //   name: 'propDomicile',
  //   colSize: 4,
  //   type: 'select',
  //   options: taxStateOptions,
  //   errorText: '',
  // },
  {
    label: 'Prop tax rate',
    name: 'taxRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Tax basis',
    name: 'taxBasis',
    colSize: 6,
    type: 'number',
    isDollarSignRequired: true,
    errorText: 'Value should be greater than 0',
  },
  {
    label: 'Basis growth',
    name: 'taxBasisGrowth',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Accumulated depr',
    name: 'priorDeprAccumulated',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Annual rent',
    name: 'annualRent',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Rent growth',
    name: 'annualRentGrowth',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
];
