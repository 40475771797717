import React, { useEffect, useState } from 'react';
import { renderInputRow, renderSelectOption } from '../../helpers/ClientHelper';

const ModelComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
    horizonMenuOptions,
    displayTermMenuOptions,
  } = props;

  const { moduleSettings: { model = {} } = {} } = localPlanDetailsByPlanId;
  const [modelDetails, setModelDetails] = useState(model);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  // const renderLabel = () => {
  //   const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
  //   const { basicsFirst = {}, basicsSecond = {} } = infoCore;
  //   const ageFirst = basicsFirst?.age;
  //   const ageSecond = basicsSecond?.age;
  //   const smallestAge =
  //     ageFirst !== undefined && ageSecond !== undefined
  //       ? Math.min(ageFirst, ageSecond)
  //       : ageFirst !== undefined
  //       ? ageFirst
  //       : ageSecond !== undefined
  //       ? ageSecond
  //       : null;
  //   const ageText =
  //     isNaN(smallestAge) || smallestAge === null ? 'youngest' : smallestAge;
  //   const result = 'Simulate until ' + ageText + ' age';
  //   return result;
  // };

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlYoungerAgeAtEstateTransfer',
          'youngerAgeAtEstateTransfer',
          //renderLabel(),
          `Simulate until younger person's age`,
          'number',
          modelDetails,
          'model',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setModelDetails
        )}
        {renderSelectOption(
          'text',
          'tlHorizon',
          'horizon',
          'Planning Horizion Years',
          horizonMenuOptions,
          modelDetails,
          'model',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setModelDetails
        )}
        {renderSelectOption(
          'text',
          'tlDisplayTerm',
          'displayTerm',
          'Display Periods',
          displayTermMenuOptions,
          modelDetails,
          'model',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setModelDetails
        )}
      </div>
    </>
  );
};

export default ModelComponent;
