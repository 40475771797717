import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DialogBox from './shared/DialogBox';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import Controls from './controls/Controls';
import { createUserInformation, sendEmail } from '../services/dashboardSvc';
import axios from 'axios';
import { getCurrentDateTime } from '../utils/apirequestutil';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignUpPopup = (props) => {
  const {
    formValues,
    formValues: {
      isfirstNameValid = true,
      islastNameValid = true,
      isemailIdValid = true,
      ismobileNumberValid = true,
      iscompanyValid = true,
    },
    setDialogState,
    setFormFieldValue,
    dialogOpen,
    setIsAPIFailed,
    isConfirmButtonDisabled,
    taxStateOptions,
    signupFields,
    dialogClass,
    accessLevels,
    privacyAcceptanceValue,
    termsAcceptanceValue,
  } = props;
  const tempSignUpFormFields = signupFields.map((field) => {
    if (field.key === 'location') {
      return {
        ...field,
        options: taxStateOptions || [],
        value: formValues[field.key],
      };
    }
    if (field.key === 'accessLevel') {
      return {
        ...field,
        options: accessLevels || [],
        value: formValues[field.key],
      };
    }
    return {
      ...field,
      value: formValues[field.key],
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setIsSnackbarOpen] = useState(false);
  const [signUpMessage, setSignUpMessage] = useState('');
  const [openFailedSnackbar, setIsFailedSnackbarOpen] = useState(false);
  // const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  // const locations = companiesInfo
  //   .filter((c) => c.company === companyVal)
  //   .map((a) => {
  //     return { label: a.location, value: a.shortLocation };
  //   });
  // React.useEffect(() => {
  //   let companies = companiesInfo.map((m) => {
  //     return {
  //       label: m.company,
  //       value: m.company,
  //     };
  //   });
  //   companies = companies.filter(
  //     (value, index, self) =>
  //       index === self.findIndex((t) => t.label === value.label)
  //   );
  //   const companiesWithDefault = [...companies];
  //   const addNewCompany = { label: 'Add New Company', value: 'newCompanyId' };
  //   if (
  //     Object.keys(addedCompany).length > 0 &&
  //     companies.filter((p) => p.value === addedCompany.value).length === 0
  //   ) {
  //     companiesWithDefault.unshift(addedCompany);
  //   }
  //   if (companies.filter((p) => p.value === addNewCompany.value).length === 0) {
  //     companiesWithDefault.unshift(addNewCompany);
  //   }
  //   setCompanies(companiesWithDefault);
  // }, [companiesInfo, addedCompany]);

  const getSignUpMailBody = (mailBodyObj) => {
    const { firstName, lastName, emailId, mobileNumber } = mailBodyObj;
    return (
      'Hi Team,' +
      '<br><br>' +
      'Please provide access to below User: <br><br>' +
      'First Name:' +
      '\t' +
      firstName +
      '<br>' +
      'Last Name:' +
      '\t' +
      lastName +
      '<br>' +
      'Email:' +
      '\t' +
      emailId +
      '<br>' +
      'Mobile:' +
      '\t' +
      mobileNumber +
      '<br><br>' +
      'Thank You,' +
      '<br>' +
      "Green'R"
    );
  };

  const triggerMailToAdmin = async ({
    emailId,
    mobileNumber,
    firstName,
    lastName,
  }) => {
    const reqPayload = {
      createdDate: getCurrentDateTime(),
      message: getSignUpMailBody({
        emailId,
        mobileNumber,
        firstName,
        lastName,
      }),
      notificationId: '',
      subject: 'New User Signed Up',
      toAddress: 'admin@greenrfinancial.com',
    };
    const { status } = await sendEmail(reqPayload);
    if (status === 200) {
      setDialogState(false);
      setIsSnackbarOpen(true);
    } else {
      setDialogState(true);
      setIsFailedSnackbarOpen(true);
      setIsAPIFailed(true);
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    const {
      firstName,
      lastName,
      emailId,
      mobileNumber,
      company,
      location,
      accessLevel,
      userType,
      password,
    } = formValues;
    const userName = `${firstName} ${lastName}`;
    const reqObj = {
      createdBy: userName,
      createdDate: getCurrentDateTime(),
      emailId: emailId,
      isActive: false,
      isAdmin: false,
      isApproved: false,
      mobileNumber: mobileNumber,
      companyName: company,
      shortLocation: location,
      accessLevel: accessLevel,
      modifiedBy: '',
      modifiedDate: '',
      name: userName,
      firstName: firstName,
      lastName: lastName,
      password: password,
      userType: userType,
      privacyAccepted: privacyAcceptanceValue,
      termsAccepted: termsAcceptanceValue,
    };
    try {
      const [firstResponse] = await Promise.all([
        createUserInformation(reqObj),
      ]);
      setIsLoading(false);
      setIsAPIFailed(false);

      if (
        firstResponse &&
        firstResponse.status === 200 &&
        !firstResponse.data.includes('already exists')
      ) {
        let statusMessage = firstResponse.data;
        triggerMailToAdmin(reqObj);
        setDialogState(false);
        setSignUpMessage(statusMessage);
      } else {
        let statusMessage = firstResponse?.data;
        setSignUpMessage(statusMessage);
        setIsFailedSnackbarOpen(true);
        setIsAPIFailed(true);
      }
    } catch (e) {
      setIsLoading(false);
      setIsAPIFailed(true);
    }
  };

  // const onVerifyNewPassword = () => {
  //   const { password, confirmPassword } = formValues;
  //   if (
  //     password !== '' &&
  //     confirmPassword !== '' &&
  //     password !== confirmPassword
  //   ) {
  //     setIsPasswordMatched(false);
  //   } else {
  //     setIsPasswordMatched(true);
  //   }
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
    setIsFailedSnackbarOpen(false);
  };

  const getIsValidFieldValue = (field) => {
    switch (field) {
      case 'firstName':
        return isfirstNameValid;
      case 'lastName':
        return islastNameValid;
      case 'emailId':
        return isemailIdValid;
      case 'mobileNumber':
        return ismobileNumberValid;
      case 'company':
        return iscompanyValid;
      default:
        return true;
    }
  };

  return (
    <>
      <DialogBox
        dialogOpen={dialogOpen}
        dialogClass={dialogClass}
        handleCancel={() => setDialogState(false)}
        handleConfirm={handleSignUp}
        // isConfirmButtonDisabled={isConfirmButtonDisabled || !isPasswordMatched}
        isConfirmButtonDisabled={isConfirmButtonDisabled}
        size="md"
        confirmLabel="Register"
      >
        <>
          <div className="signup-title-container">
            <span className="signup-title">Register</span>
            <CloseIcon
              className="signup-title-close-icon"
              onClick={() => setDialogState(false)}
            />
          </div>
          <div className="signup-loader">
            {isLoading && <CircularProgress />}
          </div>
          <div className="signup-body-container">
            <Grid container className="signup-form-grid" spacing={2}>
              {tempSignUpFormFields.map((field) => {
                switch (field.type) {
                  case 'text':
                    return (
                      <Grid item xs={5}>
                        <Controls.Input
                          name={field.key}
                          label={field.name}
                          value={field.value}
                          type="text"
                          onChange={(event, name) =>
                            setFormFieldValue(event, name)
                          }
                          placeholder={field?.placeholder}
                          // errorText={field.errorMessage}
                          errorText={
                            field.value
                              ? `Invalid ${field.name}`
                              : 'This is a mandatory field'
                          }
                          isValid={getIsValidFieldValue(field.key)}
                          required
                        />
                      </Grid>
                    );
                  // case 'textBlur':
                  //   return (
                  //     <Grid item xs={5}>
                  //       <Controls.InputWithBlur
                  //         name={field.key}
                  //         label={field.name}
                  //         value={field.value}
                  //         type="password"
                  //         onChange={(event, name) =>
                  //           setFormFieldValue(event, name)
                  //         }
                  //         onBlur={() => onVerifyNewPassword()}
                  //         minLength="6"
                  //         required
                  //         errorText={field.errorMessage}
                  //         isValid={
                  //           field.key === 'confirmPassword'
                  //             ? isconfirmPasswordValid && isPasswordMatched
                  //             : isPasswordValid
                  //         }
                  //       />
                  //     </Grid>
                  //   );
                  case 'select':
                    return (
                      <Grid item xs={5}>
                        <div className="select_outer">
                          <Controls.Select
                            name={field.key}
                            label={field.name}
                            value={field.value}
                            options={field.options}
                            onChange={(event) =>
                              setFormFieldValue(event, field.key)
                            }
                            required
                          />
                        </div>
                      </Grid>
                    );
                  // case 'checkbox':
                  //   return (
                  //     <Grid item xs={12} md={12} lg={12}>
                  //       <FormControlLabel
                  //         control={
                  //           <Checkbox
                  //             name={field.key}
                  //             checked={true}
                  //             disabled
                  //             value={privacyAcceptanceValue}
                  //           />
                  //         }
                  //         style={{ color: '#fff' }}
                  //         label={'Agreed to the Privacy Policy'}
                  //       />
                  //     </Grid>
                  //   );
                  default:
                    return null;
                }
              })}
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
                <span style={{ color: '#fff' }}>
                  *Asterisk denotes mandatory fields.
                </span>
              </Grid> */}
            <Grid container className="signup-form-grid" spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={'privacyAndTermsAccepted'}
                      checked={privacyAcceptanceValue && termsAcceptanceValue}
                      disabled
                      value={privacyAcceptanceValue && termsAcceptanceValue}
                    />
                  }
                  style={{ color: '#fff' }}
                  label={
                    <>
                      Agreed to the Privacy Policy and Terms
                      {/* <a
                                href="/privacypolicy"
                                target="_self"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a> */}
                    </>
                  }
                  // onChange={(event) =>
                  //   setFormFieldValue(event, field.key)
                  // }
                />
              </Grid>
            </Grid>
          </div>
        </>
      </DialogBox>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {signUpMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailedSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {signUpMessage || 'Something went wrong, please try again later'}
        </Alert>
      </Snackbar>
    </>
  );
};
export default SignUpPopup;
