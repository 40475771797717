import React, { useState, useEffect } from 'react';
import {
  renderInputRow,
  renderSelectOption,
  transformHeirsBackToUIType,
} from '../../helpers/BuildHelperComponentV3.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BuildEstatePlanningHeirsComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    taxStateOptions,
    setLastStepInParent,
  } = props;
  const { moduleEstate: { heirs = {} } = {} } = localPlanDetailsByPlanId;
  const [heirsDetails, setHeirsDetails] = useState(heirs);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  useEffect(() => {
    if (heirs && Object.keys(heirs).length > 0) {
      let tempHeirs = transformHeirsBackToUIType(heirs);
      setHeirsDetails(tempHeirs);
    }
  }, [localPlanDetailsByPlanId, heirs]);

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlAGIAtInheritance',
          'agiAtInheritance',
          "What is Heir's Annual Gross Income (AGI) at Inheritance?",
          'currency',
          heirsDetails,
          'heirs',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          false,
          setHeirsDetails
        )}
        {renderInputRow(
          'text',
          'tlAGIGrowth',
          'agiGrowth',
          "What is annual growth for Heir's AGI?",
          'percentage',
          heirsDetails,
          'heirs',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          false,
          setHeirsDetails
        )}
        {renderSelectOption(
          'text',
          'tlTaxState',
          'taxState',
          "What is Heir's residence state",
          taxStateOptions,
          heirsDetails,
          'heirs',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          false,
          setHeirsDetails
        )}
      </div>

      <div className='tab_main_nav'>
        <div className='tab_left_nav'>
          <button>
          <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className='tab_right_nav'>
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};
export default BuildEstatePlanningHeirsComponent;
