import { put, call, takeLatest } from 'redux-saga/effects';
import { getClientReportDataByReportId } from '../../services/dashboardSvc';
import {
  GET_REPORT_DATA_BY_REPORTID,
  getReportDataByReportIdFailureAction,
  getReportDataByReportIdSuccessAction,
  setReportDataLoadingAction,
} from '../actions/reportsAction';

export default function* triggerGetReportDataByReportId(action) {
  try {
    const {
      payload: { displayTerm, reportId },
    } = action;
    yield put(setReportDataLoadingAction(true));
    const { status, data: reportData } = yield call(
      getClientReportDataByReportId,
      displayTerm,
      reportId
    );
    if (status === 200) {
      yield put(setReportDataLoadingAction(false));
      yield put(getReportDataByReportIdSuccessAction(reportData));
    } else {
      yield put(setReportDataLoadingAction(false));
      yield put(getReportDataByReportIdFailureAction([]));
    }
  } catch (err) {
    yield put(setReportDataLoadingAction(false));
    yield put(getReportDataByReportIdFailureAction([]));
  }
}

export function* watchClientReportByReportId() {
  yield takeLatest(GET_REPORT_DATA_BY_REPORTID, triggerGetReportDataByReportId);
}
