import { DATATYPES } from '../common';
import { stateOptions } from '../retirementPlan';

const taxStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'MFS', value: 'mfs' },
  { label: 'MFJ', value: 'mfj' },
  { label: 'HOH', value: 'hoh' },
];
const lifeStyleOptions = [
  { label: 'Prudent', value: 'prudent' },
  { label: 'Generous', value: 'generous' },
  { label: 'Luxury', value: 'luxury' },
  { label: 'Saved', value: 'SAVED' },
];

export const dependentFields = [
  'taxFilingStatus',
  'residenceState',
  'lifestyle',
  'otherIncValue',
  'otherIncGrowth',
];
export const wagesAndExpenseProfileConstants = [
  {
    label: 'Tax Filing Status',
    name: 'taxFilingStatus',
    colSize: 3,
    type: 'select',
    errorText: '',
    options: taxStatusOptions,
    isHidden: false,
  },
  {
    label: 'Residence Tax State',
    name: 'residenceState',
    colSize: 4,
    type: 'select',
    errorText: '',
    options: [],
    isHidden: false,
  },
  {
    label: 'Lifestyle',
    name: 'lifestyle',
    colSize: 3,
    type: 'select',
    errorText: '',
    options: [],
    isHidden: true,
  },
  {},
  {},
  {},
  {
    label: 'Age end of last year',
    name: 'age',
    colSize: 4,
    type: 'number',
    errorText: 'Age can take values from 1 to 150',
    validations: [
      {
        methodArgs: ['elderAge'],
        methodKey: 'validateYoungerAgeAgainstElderAge',
        errorText: "Younger age can't be greater than elder age",
      },
    ],
    isHidden: true,
  },
  {
    label: 'Annual wages',
    name: 'wages',
    colSize: 4,
    type: 'number',
    errorText: 'Wages should be greater than 0',
    isHidden: false,
    isDollarSignRequired: true,
  },
  {
    label: 'Wage Growth %',
    name: 'wageGrowth',
    colSize: 4,
    type: 'number',
    errorText: '% field should range from 0 to 100',
    dataType: DATATYPES.PERCENTAGE,
    isHidden: true,
  },
  {
    label: 'Other (non-rental) Income',
    name: 'otherIncValue',
    colSize: 4,
    type: 'number',
    errorText: '% field should range from 0 to 100',
    isHidden: true,
    isDollarSignRequired: true,
  },
  {
    label: 'Other Income Growth %',
    name: 'otherIncGrowth',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
    isHidden: true,
  },
  {},
  {
    label: 'Work Tax State',
    name: 'workState',
    colSize: 4,
    type: 'select',
    errorText: '',
    options: [],
    isHidden: false,
  },
  {},
  {},
  {},
  {},
  {},
  {},
];
