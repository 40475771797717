import {
  lifeStyleMenuItems,
  lifeEventMenuItems,
  debtMenuItems,
  estateMenuItems,
  realEstateMenuItems,
  insuranceMenuItems,
  investmentMenuItems,
  retirementMenuItems,
  WAGES_EXPENSE_PROFILE,
  REVIEW_PREFERENCES,
  SAVINGS_DETAILS,
} from '../constants/retirementPlan';
import { savingsDetails } from '../constants/LifeStyle/savingsDetailConstants';
import { reviewPreferencesFields } from '../constants/LifeStyle/reviewPreferencesConstants';
import { wagesAndExpenseProfileConstants } from '../constants/LifeStyle/wagesAndExpenseProfileConstants';
import { DATATYPES, SET_PASSWORD_PATH } from '../constants/common';
let infoInvpropsOptions = [];
let primaryHomeOption = [];
let secondHomesOptions = [];
let otherOptions = [];

export const getRetirementPlanMenuFields = (menukey) => {
  switch (menukey) {
    case WAGES_EXPENSE_PROFILE:
    default:
      return wagesAndExpenseProfileConstants;
    case SAVINGS_DETAILS:
      return savingsDetails;
    case REVIEW_PREFERENCES:
      return reviewPreferencesFields;
  }
};

export const getRetirementPlanMenuItems = (menukey) => {
  switch (menukey) {
    case 'lifeStyle':
    default:
      return lifeStyleMenuItems;
    case 'lifeEvents':
      return lifeEventMenuItems;
    case 'debt':
      return debtMenuItems;
    case 'estate':
      return estateMenuItems;
    case 'realEstate':
      return realEstateMenuItems;
    case 'insurance':
      return insuranceMenuItems;
    case 'investments':
      return investmentMenuItems;
    case 'retirement':
      return retirementMenuItems;
  }
};

export const transformFieldValueByDataType = (fieldvalues, fieldsConstants) => {
  const newFieldValues = Object.keys(fieldvalues).reduce((acc, key) => {
    try {
      const formFields = fieldsConstants.filter((el) => Object.keys(el).length);

      const fieldConfig = formFields.find((field) => field.name === key);
      let dataType = DATATYPES.STRING;
      if (fieldConfig && fieldConfig.dataType) {
        dataType = fieldConfig.dataType;
      }

      let newFieldValue = fieldvalues[key];
      if (
        dataType === DATATYPES.PERCENTAGE &&
        fieldvalues[key] !== '' &&
        fieldvalues[key]
      ) {
        const percentFieldValue = parseFloat(
          parseFloat(fieldvalues[key]) * 100
        ).toFixed(2);
        newFieldValue = `${percentFieldValue}%`;
      }

      return {
        ...acc,
        [key]: newFieldValue,
      };
    } catch (e) {
      console.log('errr', e, key);
    }
    return {};
  }, {});
  return newFieldValues;
};

export const revertTransformedValueByDataType = (
  fieldvalues,
  fieldsConstants
) => {
  try {
    const newFieldValues = Object.keys(fieldvalues).reduce((acc, key) => {
      const formFields = fieldsConstants.filter((el) => Object.keys(el).length);
      const fieldConfig = formFields.find((field) => field.name === key);
      let dataType = DATATYPES.STRING;
      if (fieldConfig && fieldConfig.dataType) {
        dataType = fieldConfig.dataType;
      }
      let newFieldValue = fieldvalues[key];
      if (
        dataType === DATATYPES.PERCENTAGE &&
        fieldvalues[key] &&
        fieldvalues[key] !== ''
      ) {
        const percentFieldValue = fieldvalues[key].split('%')[0];
        newFieldValue = parseFloat(percentFieldValue) / 100;
      }
      return {
        ...acc,
        [key]: newFieldValue,
      };
    }, {});
    return newFieldValues;
  } catch (e) {
    console.log('e', e, fieldvalues);
  }
};

const getInsuranceInsuredOptions = (source) => {
  Object.entries(source).forEach(([key, value]) => {
    if (key.toLowerCase() === 'infoinvprops') {
      infoInvpropsOptions = [];
      if (value && value.invProp && value.invProp.length > 0) {
        infoInvpropsOptions = value.invProp.reduce(function (filtered, tl) {
          if (tl && typeof tl === 'object' && tl.hasOwnProperty('prop')) {
            var tlInfo = {
              label: tl.prop.name ? tl.prop.name : '',
              value: tl.prop.name ? tl.prop.name : '',
            };
            if (filtered.indexOf(tlInfo) === -1) {
              filtered.push(tlInfo);
            }
          }
          return filtered;
        }, []);
      }
    } else if (key.toLowerCase() === 'infoprimaryhome') {
      primaryHomeOption = [];
      if (
        value &&
        value.primaryHome &&
        Object.keys(value.primaryHome).length > 0
      ) {
        if (
          typeof value.primaryHome === 'object' &&
          value.primaryHome.hasOwnProperty('name')
        ) {
          var tlInfo = {
            label: value.primaryHome.name ? value.primaryHome.name : '',
            value: value.primaryHome.name ? value.primaryHome.name : '',
          };
          if (
            primaryHomeOption.indexOf(
              primaryHomeOption.filter(
                (f) => f.label === tlInfo.label && f.value === tlInfo.value
              )
            ) === -1
          ) {
            primaryHomeOption.push(tlInfo);
          }
        }
      }
    } else if (key.toLowerCase() === 'infosecondhomes') {
      secondHomesOptions = [];
      if (value && value.secondHomes && value.secondHomes.length > 0) {
        secondHomesOptions = value.secondHomes.reduce(function (filtered, tl) {
          if (tl && typeof tl === 'object' && tl.hasOwnProperty('name')) {
            var tlInfo = {
              label: tl.name,
              value: tl.name,
            };
            if (filtered.indexOf(tlInfo) === -1) {
              filtered.push(tlInfo);
            }
          }
          return filtered;
        }, []);
      }
    } else {
      otherOptions = [];
      otherOptions = Object.values(source).reduce(function (filtered, tl) {
        //reduce directly on source
        if (tl && typeof tl === 'object' && tl.hasOwnProperty('name')) {
          var tlInfo = {
            label: tl.name,
            value: tl.name,
          };
          if (filtered.indexOf(tlInfo) === -1) {
            filtered.push(tlInfo);
          }
        }
        return filtered;
      }, []);
    }
  });
};
export const getInsuredOptions = (source) => {
  if (Object.keys(source).length > 0) {
    getInsuranceInsuredOptions(source);
    return [
      otherOptions,
      [...secondHomesOptions, ...primaryHomeOption, ...infoInvpropsOptions],
    ];
  }
  return [];
};

export const isSetPwdScreen = () =>
  window.location.pathname === SET_PASSWORD_PATH;
