import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Controls from './controls/Controls';
import history from '../history';
import '../styles/resetPassword.scss';
import { changePassword } from '../services/dashboardSvc';
import { isSetPwdScreen } from '../utils/commonUtils';
import Picture1 from '../images/Picture1.png';
import Greenr_Logo from '../images/Greenr_Logo.svg';
import Stack from '@mui/material/Stack';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class ChangePassword extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    isnewPasswordValid: true,
    isconfirmPasswordValid: true,
    isAPILoading: false,
    isAPIFailed: false,
    openSnackbar: false,
  };

  launchHomePage = () => {
    history.push({ pathname: '/' });
  };

  validateFormFields = (name, value) => {
    let isFieldValid = true;
    switch (name) {
      case 'newPassword':
      case 'confirmPassword':
        isFieldValid = value.length >= 8;
        break;
      default:
        isFieldValid = true;
    }
    this.setState({
      [`is${name}Valid`]: isFieldValid,
    });
  };

  onInputChange = (event, name) => {
    const { value } = event.target;
    this.setIsAPIFailed(false);
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateFormFields(name, value);
      }
    );
  };

  setIsAPILoading = (isAPILoading) => {
    this.setState({
      isAPILoading,
    });
  };

  setIsSnackbarOpen = (openSnackbar) => {
    this.setState({
      openSnackbar,
    });
  };

  setIsAPIFailed = (isAPIFailed) => {
    this.setState({
      isAPIFailed,
    });
  };

  changePasswordContinue = async (e) => {
    e.preventDefault();
    let { location: { state: { userName = '' } = {} } = {} } = this.props;
    const { newPassword, emailId } = this.state;
    // For set pwd flow taking email ID from user input
    if (isSetPwdScreen()) {
      userName = emailId;
    }
    if (userName && newPassword) {
      this.setIsAPILoading(true);
      this.setIsAPIFailed(false);
      try {
        const { status, data } = await changePassword(userName, newPassword);
        this.setIsAPILoading(false);
        if (status === 200) {
          this.snackBarMessage =
            'New password has been set successfully. Please login with new password';
          this.setIsAPIFailed(false);
          this.setIsSnackbarOpen(true);
          setTimeout(() => {
            history.push('/');
          }, 3000);
        } else {
          this.setIsAPIFailed(false);
        }
      } catch (e) {
        this.setIsSnackbarOpen(false);
        this.setIsAPILoading(false);
        this.setIsAPIFailed(true);
      }
    }
  };

  isFormDisabled = () => {
    const { newPassword, confirmPassword, emailId } = this.state;
    let formInvalid = newPassword.length <= 0 || confirmPassword.length <= 0;
    const isSetPwd = isSetPwdScreen();
    if (isSetPwd) {
      formInvalid = formInvalid || !emailId;
    }
    return formInvalid;
  };

  render() {
    const {
      newPassword,
      confirmPassword,
      isconfirmPasswordValid,
      isnewPasswordValid,
      isAPIFailed,
      openSnackbar,
      isAPILoading,
      emailId,
    } = this.state;
    const isSetPwd = isSetPwdScreen();
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isAPILoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light greener_website_nav">
            <div className="container">
              <span className="navbar-brand">
                <img src={Greenr_Logo} alt="" title="" />
              </span>
            </div>
          </nav>
        </header>
        <main>
          <section className="Grenner_Financial_Roots_main_outer">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-6">
                  <div className="images">
                    <img src={Picture1} title="" alt="" />
                  </div>
                </div>

                <div className="col-xl-5 col-lg-6">
                  <div className="banner_main_title">
                    <div className="main_title">
                      <h3 className="text-white">Building</h3>
                      <h2 className="text-white">Financial Roots</h2>
                    </div>
                  </div>
                  <div className="tab-container-one">
                    <form onSubmit={this.changePasswordContinue}>
                      <div className="row">
                        <div style={{ padding: '10px', color: '#fff' }}>
                          <h4>
                            {isSetPwd ? 'Set Password' : 'Change Password'}
                          </h4>
                        </div>
                      </div>
                      {isSetPwd ? (
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <input
                              type="email"
                              className="form-control"
                              aria-describedby="emailHelp"
                              placeholder="User Name"
                              errorText="Invalid Email"
                              name="emailId"
                              value={emailId}
                              onChange={(e) => this.onInputChange(e, 'emailId')}
                              // isValid={isUserNameValid}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <input
                            placeholder="Enter new password"
                            className="form-control"
                            aria-describedby="emailHelp"
                            value={newPassword}
                            name="newPassword"
                            label="Enter your New Password"
                            type="password"
                            onChange={(e) =>
                              this.onInputChange(e, 'newPassword')
                            }
                            errorText="Password must be atleast of 8 characters"
                            isValid={isnewPasswordValid}
                          />
                        </div>

                        <div className="col-lg-6 mb-3">
                          <input
                            placeholder="Confirm Password"
                            className="form-control"
                            errorText="New password and confirm password do not match"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            type="password"
                            onChange={(e) =>
                              this.onInputChange(e, 'confirmPassword')
                            }
                            isValid={isconfirmPasswordValid}
                          />
                        </div>
                      </div>

                      {isAPIFailed && (
                        <Stack
                          sx={{
                            width: '100%',
                            padding: '5px',
                            margin: '10px',
                          }}
                          spacing={2}
                        >
                          <MuiAlert severity="error">
                            {'Something went wrong, please try again later.'}
                          </MuiAlert>
                        </Stack>
                      )}

                      <div className="button_group">
                        <button
                          type="submit"
                          className="common_button"
                          //onClick={this.changePasswordContinue}
                          //className="continue-btn"
                          disabled={this.isFormDisabled()}
                        >
                          Continue
                        </button>
                        <button
                          type="submit"
                          className="common_button"
                          onClick={this.launchHomePage}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={this.handleSnackBarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleSnackBarClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {this.snackBarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default ChangePassword;
