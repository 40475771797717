import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  RetirementConstants,
  commonFields,
} from '../../../constants/IndividualClient/RetirementConstants';
import PlannerTabsContent from '../PlannerTabsContent';
import { RETIREMENT_COMPONENT } from '../../../constants/IndividualClient';

class RetirementComponent extends Component {
  PlannerTabsRef = React.createRef();

  getRetirementCompFormState = () => {
    const { current: { getAllFormFieldValues } = {} } = this.PlannerTabsRef;
    return getAllFormFieldValues();
  };

  getInitialFieldsState() {
    const formFields = RetirementConstants.filter(
      (el) => Object.keys(el).length
    );
    return formFields.reduce((acc, curr) => {
      const { name } = curr;
      return { [name]: '', ...acc };
    }, {});
  }

  getFieldsConfig = () => {
    let fieldConfig = {
      fieldConstants: {},
      commonFields: {},
    };
    const { taxStateOptions } = this.props;
    const retFieldConstants = RetirementConstants.map((retField, index) => {
      const retFieldWithOptions = retField.fields.map((field) => {
        if (field.name === 'retirementState') {
          return {
            ...field,
            options: taxStateOptions,
          };
        }
        return { ...field };
      });

      return {
        ...retField,
        fields: [...retFieldWithOptions],
      };
    });

    const fieldConstants = retFieldConstants.map((fieldObj, index) => {
      return {
        ...fieldObj,
        fields: fieldObj.fields.filter(
          (field) => !commonFields.includes(field.name)
        ),
      };
    });
    fieldConfig = {
      fieldConstants: fieldConstants,
      commonFields: commonFields,
    };
    return fieldConfig;
  };

  render() {
    const { storeValues, taxStateOptions } = this.props;
    const retFieldConstants = RetirementConstants.map((retField, index) => {
      const retFieldWithOptions = retField.fields.map((field) => {
        if (field.name === 'retirementState') {
          return {
            ...field,
            options: taxStateOptions,
          };
        }
        return { ...field };
      });

      return {
        ...retField,
        fields: [...retFieldWithOptions],
      };
    });
    return (
      <>
        <PlannerTabsContent
          ref={this.PlannerTabsRef}
          fieldsConstants={[...retFieldConstants]}
          onSimulateSave={this.saveFormFieldsInStore}
          tabLabelPrefix="Member"
          storeValues={storeValues}
          componentName={RETIREMENT_COMPONENT}
          fieldsConfig={this.getFieldsConfig()}
        />
      </>
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(
  RetirementComponent
);
