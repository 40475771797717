import React from 'react';
import { generateTableHTML } from '../../utils/renderHtmlUtil';

export const CustomTable = (props) => {
  const { tables, renderWhatData, snap } = props;

  const renderCustomTable = () => {
    const { tabName, subtabName, table } = renderWhatData;
    const { isItFull, mainOrAux, notfullOffset, subTabType } = table;
    if (tables && tables.length > 0) {
      const selectedTablesData = tables.filter(
        (t) => t && tabName && t.tabName.toLowerCase() === tabName.toLowerCase()
      )[0];
      const selectedSubTablesData =
        (selectedTablesData &&
          selectedTablesData.subtabs &&
          selectedTablesData.subtabs.length > 0 &&
          selectedTablesData.subtabs.filter(
            (s) =>
              s &&
              subtabName &&
              s.subtabName.toLowerCase() === subtabName.toLowerCase()
          )[0]) ||
        {};
      if (
        selectedSubTablesData &&
        selectedSubTablesData[subTabType] &&
        Object.keys(selectedSubTablesData[subTabType]).length > 0
      ) {
        const { [subTabType]: { [mainOrAux]: renderData = [] } = {} } =
          selectedSubTablesData;
        if (renderData.filter((r) => r).length > 0) {
          if (isItFull) {
            return (
              <div className="client_chart_main_div Table">
                <div className="client_chart_inner">
                  <div className="Table_main_outer d-lg-flex">
                    {renderData.map((val, index) => {
                      return generateTableHTML(val, tabName, snap);
                    })}
                  </div>
                </div>
              </div>
            );
          } else {
            const tableData = renderData[notfullOffset];
            if (tableData && Object.keys(tableData).length > 0) {
              return (
                <div className="client_chart_main_div Table">
                  <div className="client_chart_inner">
                    <div className="Table_main_outer d-lg-flex">
                      <>{generateTableHTML(tableData, tabName, snap)}</>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }
      }
    }
  };

  return <>{renderCustomTable()}</>;
};
export default CustomTable;
