import { DATATYPES } from '../common';

export const commonFields = [];

export const ExpenseConstants = [
  {
    label: 'non-housing',
    fields: [
      {
        label: 'Total',
        name: 'aggNonHousing',
        colSize: 3,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Discretionary %',
        name: 'discPercentOfAgg',
        colSize: 4,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Adjust Horizon',
        name: 'discHorFactor',
        colSize: 3,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        isDisabled: false,
      },
      {
        label: 'Adjust Retirement',
        name: 'discRetFactor1',
        colSize: 3,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        isDisabled: false,
      },
    ],
  },
  {
    label: 'housing(if rented)',
    fields: [
      {
        label: 'Total',
        name: 'rental',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
    ],
  },
];
