export const SET_INITAL_REPORT_OPTIONS = 'reports:set:initial:values';
export const SET_SELECTED_REPORT_OPTIONS_ACTION =
  'reports:set:selected:report:options';
export const RESET_SIMULATE_BEFORE_AFTER_VALUES =
  'plans:reset:simulate:beforeafter:values';
export const GET_REPORT_DATA_BY_REPORTID = 'reports:get:initial:data';
export const GET_REPORT_DATA_BY_REPORTID_SUCCESS = 'reports:get:success';
export const GET_REPORT_DATA_BY_REPORTID_FAILURE = 'reports:get:failure';
export const SET_REPORT_DATA_LOADING_ACTION = 'report:data:loading';
export function setInitialReportOptions(payload) {
  return {
    type: SET_INITAL_REPORT_OPTIONS,
    payload: payload,
  };
}
export function setSelectedReportOptionsAction(payload) {
  return {
    type: SET_SELECTED_REPORT_OPTIONS_ACTION,
    payload,
  };
}

export function getSelectedReportDataByReportId(payload) {
  return {
    type: GET_REPORT_DATA_BY_REPORTID,
    payload,
  };
}

export function getReportDataByReportIdSuccessAction(payload) {
  return {
    type: GET_REPORT_DATA_BY_REPORTID_SUCCESS,
    payload,
  };
}

export function getReportDataByReportIdFailureAction() {
  return {
    type: GET_REPORT_DATA_BY_REPORTID_FAILURE,
  };
}

export function setReportDataLoadingAction(payload) {
  return {
    type: SET_REPORT_DATA_LOADING_ACTION,
    payload,
  };
}
