export const GET_PLANS_BY_CLIENT_ID = 'plans:clientId';
export const GET_PLANS_BY_CLIENT_ID_ERROR = 'plans:clientId:error';
export const GET_PLANS_BY_CLIENT_ID_SUCCESS = 'plans:clientId:success';

export const GET_MEETINGDETAILS_BY_MEETING_ID = 'meetingdetails:meetingId';
export const GET_MEETINGDETAILS_BY_MEETING_ID_ERROR = 'meetingdetails:meetingId:error';
export const GET_MEETINGDETAILS_BY_MEETING_SUCCESS = 'meetingdetails:meetingId:success';

export const SAVE_MEETINGDETAILS_BY_MEETINGID = 'meetings:details:save';
export const SAVE_MEETINGDETAILS_BY_MEETINGID_ERROR = 'meetings:savedetails:error';
export const SAVE_MEETINGDETAILS_BY_MEETINGID_SUCCESS = 'meetings:savedetails:success';

export const UPDATE_USER_MOBILE = 'user:updatemobile:save';
export const UPDATE_USER_MOBILE_ERROR = 'user:updatemobile:error';
export const UPDATE_USER_MOBILE_SUCCESS = 'user:updatemobile:success';

export const MEETING_DETAILS_LOADING = 'meetings:details:loading';

export const SET_SELECTED_PLANID_FOR_CLIENTID = 'plans:setselectedplanid';
export const SET_SELECTED_HORIZON_TERM_FOR_CLIENT =
  'plans:setselectedhorizontermforclient';
export const SET_SELECTED_DISPLAY_TERM_FOR_CLIENT =
  'plans:setselecteddisplaytermforclient';
export const SET_SELECTED_CHRONO_TAB_ITEM = 'plans:setselectedchronotabitem';
export const SET_SELECTED_CHART = 'plans:setselected:Chart';
export const SET_SELECTED_TABLE = 'plans:setselected:Table';
export const SET_SELECTED_DATA = 'plans:setselected:Data';
export const SET_SELECTED_NUMBEROF_RUNS = 'client:setselectednumberofruns';
export const SET_CLIENT_VIEW = 'client:selctedclientview';
export const CLEAR_SELECTED_CLIENT_PLAN_DETAILS = 'clear:client:plan:details';
export const PUSH_PIN_CHARTS = 'plans:pushpin:charts';
export const CLEAR_PUSH_PIN_CHARTS = 'plans:clear:pushpin:charts';

export const GET_USERDETAILS_BY_EMAIL_ID = 'userdetails:emailId';
export const GET_USERDETAILS_BY_EMAIL_ID_ERROR = 'userdetails:emailId:error';
export const GET_USERDETAILS_BY_EMAIL_ID_SUCCESS = 'userdetails:emailId:success';

export function getPlansByClientIdAction(payload) {
  return {
    type: GET_PLANS_BY_CLIENT_ID,
    payload: payload,
  };
}

export function getPlansByClientIdSuccessAction(payload) {
  return {
    type: GET_PLANS_BY_CLIENT_ID_SUCCESS,
    payload,
  };
}

export function getPlansByClientIdErrorAction(payload) {
  return {
    type: GET_PLANS_BY_CLIENT_ID_ERROR,
    payload,
  };
}

export function getMeetingDetailsByMeetingIdAction(payload) {
  return {
    type: GET_MEETINGDETAILS_BY_MEETING_ID,
    payload,
  };
}

export function getMeetingDetailsByMeetingIdSuccessAction(payload) {
  return {
    type: GET_MEETINGDETAILS_BY_MEETING_SUCCESS,
    payload,
  };
}

export function getMeetingDetailsByMeetingIdErrorAction(payload) {
  return {
    type: GET_MEETINGDETAILS_BY_MEETING_ID_ERROR,
    payload,
  };
}

export function setMeetingDetailsLoadingAction(payload) {
  return {
    type: MEETING_DETAILS_LOADING,
    payload,
  };
}

export function saveAndUpdateMeetingDetailsByMeetingIdAction(payload) {
  return {
    type: SAVE_MEETINGDETAILS_BY_MEETINGID,
    payload,
  };
}

export function saveAndUpdateMeetingDetailsByMeetingIdSuccessAction(payload) {
  return {
    type: SAVE_MEETINGDETAILS_BY_MEETINGID_SUCCESS,
    payload,
  };
}

export function saveAndUpdateMeetingDetailsByMeetingIdErrorAction(payload) {
  return {
    type: SAVE_MEETINGDETAILS_BY_MEETINGID_ERROR,
    payload,
  };
}

export function setSelectedPlanForClientId(payload) {
  return {
    type: SET_SELECTED_PLANID_FOR_CLIENTID,
    payload,
  };
}

export function setSelectedNumberOfRuns(payload) {
  return {
    type: SET_SELECTED_NUMBEROF_RUNS,
    payload,
  };
}

export function setSelectedHorizonTermAction(payload) {
  return {
    type: SET_SELECTED_HORIZON_TERM_FOR_CLIENT,
    payload,
  };
}

export function setSelectedDisplayTermAction(payload) {
  return {
    type: SET_SELECTED_DISPLAY_TERM_FOR_CLIENT,
    payload,
  };
}

export function setSelectedChronoTabItem(payload) {
  return {
    type: SET_SELECTED_CHRONO_TAB_ITEM,
    payload,
  };
}

export function setSelectedChartAction(payload) {
  return {
    type: SET_SELECTED_CHART,
    payload,
  };
}

export function setSelectedTableAction(payload) {
  return {
    type: SET_SELECTED_TABLE,
    payload,
  };
}

export function setSelectedDataAction(payload) {
  return {
    type: SET_SELECTED_DATA,
    payload,
  };
}

export function setClientViewSelected(payload) {
  return {
    type: SET_CLIENT_VIEW,
    payload,
  };
}

export function clearSelectedClientPlanDetails(payload) {
  return {
    type: CLEAR_SELECTED_CLIENT_PLAN_DETAILS,
  };
}

export function pushPinCharts(payload) {
  return {
    type: PUSH_PIN_CHARTS,
    payload: payload,
  };
}

export function clearPushPinCharts(payload) {
  return {
    type: CLEAR_PUSH_PIN_CHARTS,
  };
}

export function getUserInformationByEmailIdAction(payload) {
  return {
    type: GET_USERDETAILS_BY_EMAIL_ID,
    payload: payload,
  };
}

export function getUserInformationByEmailIdSuccessAction(payload) {
  return {
    type: GET_USERDETAILS_BY_EMAIL_ID_SUCCESS,
    payload,
  };
}

export function getUserInformationByEmailIdErrorAction(payload) {
  return {
    type: GET_USERDETAILS_BY_EMAIL_ID_ERROR,
    payload,
  };
}

export function updateUserMobileAction(payload) {
  return {
    type: UPDATE_USER_MOBILE,
    payload,
  };
}

export function updateUserMobileSuccessAction(payload) {
  return {
    type: UPDATE_USER_MOBILE_SUCCESS,
    payload,
  };
}

export function updateUserMobileErrorAction(payload) {
  return {
    type: UPDATE_USER_MOBILE_ERROR,
    payload,
  };
}
