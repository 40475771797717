import { DATATYPES } from '../common';
import { OUTPUT_FIELD_TYPE } from '../retirementPlan';

export const otherLoansConstants = [
  {
    label: 'Loan amount',
    name: 'loanAmount',
    colSize: 4,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Balance',
    name: 'balance',
    colSize: 4,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'APR',
    name: 'apr',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Loan term months',
    name: 'termMonths',
    colSize: 4,
    type: 'number',
    errorText: 'value should be greater than 0',
  },
  {
    label: 'Int Start Date',
    name: 'interestStartDate',
    colSize: 4,
    type: 'date',
    errorText: 'Invalid Field',
  },

  {
    label: 'Pay Start Date',
    name: 'paymentStartDate',
    colSize: 4,
    type: 'date',
    errorText: 'Invalid Field',
  },
  {
    label: 'Compounding',
    name: 'compounding',
    colSize: 4,
    type: 'select',
    options: [],
    errorText: 'Invalid Field',
  },
  {
    label: 'Monthly Payment',
    name: 'monthlyPayment',
    colSize: 4,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
  },
  {},
  {},
  {},
  {},
  {
    name: 'Remainingmonths',
    label: 'Remaining months',
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Remaining interest',
    name: 'Remaininginterest',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {
    label: 'Annual payment',
    name: 'Annualpayment',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {},
  {},
  {},
];
