export const commonFields = [];

export const InvestmentConstants = [
  {
    label: 'member-1',
    fields: [
      {
        label: '401(k) Trad',
        name: 'trad401k',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: '401(k) Roth',
        name: 'roth401k',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'IRA Trad',
        name: 'tradIra',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'IRA Roth',
        name: 'rothIra',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'Emergency cash',
        name: 'emCash',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'Brokerage',
        name: 'brokerage',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'Pension',
        name: 'pension',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'Def comp',
        name: 'deferredComp',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'RSU',
        name: 'rsu',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
    ],
  },
  {
    label: 'member-2',
    fields: [
      {
        label: '401(k) Trad',
        name: 'trad401k',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: '401(k) Roth',
        name: 'roth401k',
        colSize: 6,
        type: 'number',
        errorText: 'value should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'IRA Trad',
        name: 'tradIra',
        colSize: 6,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'IRA Roth',
        name: 'rothIra',
        colSize: 6,
        isDollarSignRequired: true,

        type: 'number',
        errorText: 'value should be greater than 0',
      },
      {
        label: 'Emergency cash',
        name: 'emCash',
        colSize: 6,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'Brokerage',
        name: 'brokerage',
        colSize: 6,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'Pension',
        name: 'pension',
        colSize: 6,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'Def comp',
        name: 'deferredComp',
        colSize: 6,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'RSU',
        name: 'rsu',
        colSize: 6,
        isDollarSignRequired: true,
        type: 'number',
        errorText: 'value should be greater than 0',
      },
    ],
  },
];
