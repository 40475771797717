export const getLoggedInUserDetails = (state) => {
  //remove this and use redux instead of browser session
  if (sessionStorage.getItem('userDetails')) {
    return JSON.parse(sessionStorage.getItem('userDetails'));
  } else {
    if (state && state.loggedInUser) {
      return state.loggedInUser.userDetails;
    }
  }
  return {};
};

export const getPrivacyAcceptanceValueSelector = (state) => {
  return state.loggedInUser.privacyAcceptanceValue;
};

export const getIsPrivacyCheckedSelector = (state) => {
  return state.loggedInUser.isPrivacyChecked;
};

export const getTermsAcceptanceValueSelector = (state) => {
  return state.loggedInUser.termsAcceptanceValue;
};

export const getIsTermsCheckedSelector = (state) => {
  return state.loggedInUser.isTermsChecked;
};

export const getPreviousPathValueSelector = (state) => {
  return state.loggedInUser.previousPathValue;
};
