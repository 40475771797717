export const PROFILE_COMPONENT = 'Profile';
export const INCOME_COMPONENT = 'Income';
export const EXPENSE_COMPONENT = 'Expenses';
export const SAVINGS_COMPONENT = 'Savings';
export const INVESTMENT_COMPONENT = 'Investments';
export const RETIREMENT_COMPONENT = 'Retirement';
export const INSURANCE_COMPONENT = 'Insurance';
export const OTHERGOALS_COMPONENT = 'Family Goals';
export const ASSETGOALS_COMPONENT = 'Asset Goals';
export const DEBT_COMPONENT = 'Debt';

export const plannerTabs = [
  {
    label: PROFILE_COMPONENT,
    value: '0',
  },
  {
    label: INCOME_COMPONENT,
    value: '1',
  },
  {
    label: EXPENSE_COMPONENT,
    value: '2',
  },
  {
    label: SAVINGS_COMPONENT,
    value: '3',
  },
  {
    label: INVESTMENT_COMPONENT,
    value: '4',
  },
  {
    label: DEBT_COMPONENT,
    value: '5',
  },
  {
    label: ASSETGOALS_COMPONENT,
    value: '6',
  },
  {
    label: OTHERGOALS_COMPONENT,
    value: '7',
  },
  {
    label: INSURANCE_COMPONENT,
    value: '8',
  },
  {
    label: RETIREMENT_COMPONENT,
    value: '9',
  },
];

export const MENU_ITEM_WITH_MULTIPLE_KIDS = [];

export const MENU_ITEM_WITH_CUSTOM_TABS = [EXPENSE_COMPONENT];

export const TABS_WITH_DIFF_BORDER = [
  EXPENSE_COMPONENT,
  OTHERGOALS_COMPONENT,
  DEBT_COMPONENT,
  ASSETGOALS_COMPONENT,
];
