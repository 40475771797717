import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import MuiAlert from '@mui/material/Alert';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { advisoryGridCol, pendingUsersCol } from '../constants/dashboard';
import { DEFAULT_DIRECT_PLAN_ID } from '../services/CONSTANTS';
import { getCurrentDateTime } from '../utils/apirequestutil';
import { getLoggedInUserDetails } from '../selectors/loginUserSelector';
import {
  getAllUserInformation,
  approveUserInfo,
  copyDefaultPlan,
  createNewClient,
  sendEmail,
} from '../services/dashboardSvc';
import { getPlansByClientIdAction } from '../redux/actions/plansAction';
import '../styles/adminDashboard.scss';
import DialogBox from './shared/DialogBox';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class AdminDashboard extends Component {
  state = {
    allUsersInfo: [],
    tabValue: 0,
    isLoading: false,
    isAPIFailed: false,
    openSnackbar: false,
  };

  snackBarMessage = '';

  componentDidMount() {
    this.fetchAllUserInfo();
  }

  fetchAllUserInfo = async () => {
    const { status, data } = await getAllUserInformation();
    this.setState({
      allUsersInfo: data.map((user, index) => ({ ...user, id: index + 1 })),
    });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  getAllUsers() {
    const { allUsersInfo } = this.state;
    return allUsersInfo;
    // .filter((user) => user.isApproved === '1');
  }

  getPendingApprovalUsers() {
    const { allUsersInfo } = this.state;
    return allUsersInfo.filter((user) => user.isApproved !== '1');
  }

  getPendingUsersCol() {
    return pendingUsersCol.map((column) => {
      let renderCellComp = null;
      if (column.field === 'approve') {
        renderCellComp = (params) => {
          return (
            <IconButton
              size="small"
              onClick={(e) => this.handleApproveUser(e, params)}
              className="action-btn approve-btn"
            >
              <DoneIcon fontSize="small" />
            </IconButton>
          );
        };
      }
      if (column.field === 'reject') {
        renderCellComp = (params) => {
          return (
            <IconButton
              size="small"
              onClick={(e) => this.handleRejectUser(e, params)}
              className="action-btn reject-btn"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          );
        };
      }
      return {
        ...column,
        renderCell: renderCellComp,
      };
    });
  }

  setIsLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  setIsAPIFailed = (isAPIFailed) => {
    this.setState({
      isAPIFailed,
    });
  };

  handleRejectUser = (e, params) => {
    e.stopPropagation();
    const userData = {};
    const { api } = params;
    api
      .getAllColumns()
      .filter((c) => c.field !== '__check__' && !!c)
      .forEach(
        (c) => (userData[c.field] = params.getValue(params.id, c.field))
      );
    this.callApproveUserInfo(userData, 0);
  };

  handleApproveUser = (e, params) => {
    e.stopPropagation();
    const userData = {};
    const { api, row } = params;
    const userType = row?.userType;
    api
      .getAllColumns()
      .filter((c) => c.field !== '__check__' && !!c)
      .forEach(
        (c) => (userData[c.field] = params.getValue(params.id, c.field))
      );
    this.callApproveUserInfo({ ...userData, userType }, 1);
  };

  triggerMailToUser = ({ name, emailId }, isApproved) => {
    let toAddr = emailId;
    if (toAddr.length === 0) {
      return;
    }
    name = name && name !== '' && name.split(' ');
    let data = {};
    data = {
      firstName: name[0],
      lastName: name[1],
      to: toAddr,
      from: `Green'r <Admin@greenr.com>`, // use corporate email id here
      subject: isApproved === 1 ? 'Sign Up Successful' : 'Sign Up Unsuccessful',
      template: isApproved === 1 ? 'approvedTemplate' : 'rejectedTemplate',
    };
    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'axios',
    };
    axios
      .post('http://localhost:3001/sendEmail', data, { headers: headers })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  copyDefaultPlan = async (planId, clientId, type) => {
    const { triggerGetPlansByClientId, loggedInUserDetails = {} } = this.props;
    const { emailId } = loggedInUserDetails;
    const reqObj = {
      clientId: clientId !== '' ? clientId.toLowerCase() : '',
      advisorId: emailId !== '' ? emailId.toLowerCase() : '',
      sourcePlanId: DEFAULT_DIRECT_PLAN_ID,
      targetPlanId: planId,
    };
    try {
      const [response] = await Promise.all([copyDefaultPlan(reqObj)]);
      if (response && response.status === 200) {
        this.setState(
          {
            isApiFailed: false,
            isLoading: false,
          },
          () => {
            const payload = {
              clientId: clientId !== '' ? clientId.toLowerCase() : '',
            };
            triggerGetPlansByClientId(payload);
          }
        );
      } else {
        this.setState({
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  handleAddNewClient = async (clientEmail) => {
    const newPlanName = 'defaultDirectPlan';
    const clientEmailId =
      clientEmail && clientEmail !== '' ? clientEmail.toLowerCase() : '';
    const { loggedInUserDetails = {} } = this.props;
    const { emailId, name } = loggedInUserDetails;
    this.setState({ isLoading: true });
    const reqObj = {
      createdBy: name,
      createdDate: getCurrentDateTime(),
      emailId: clientEmailId,
      mobileNumber: '',
      clientName: clientEmailId,
      planName: newPlanName,
      modifiedBy: name,
      modifiedDate: getCurrentDateTime(),
      advisorId: clientEmailId,
      planId: '',
    };
    try {
      const [firstResponse] = await Promise.all([createNewClient(reqObj)]);
      if (firstResponse && firstResponse.status === 200) {
        this.setState({ openSnackbar: true, isLoading: false }, () => {
          this.copyDefaultPlan(firstResponse.data, clientEmailId, 'client');
        });
      } else {
        this.setState({
          openSnackbar: true,
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        openSnackbar: true,
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  callApproveUserInfo = async (userData, isApproved) => {
    const { emailId, userType } = userData;
    this.setIsLoading(true);
    this.setIsAPIFailed(false);
    const reqPayload = {
      emailId,
      isApproved,
    };
    try {
      const { status } = await approveUserInfo(reqPayload);
      this.setIsLoading(false);
      if (status === 200 && isApproved) {
        if (userType === 'direct') {
          this.handleAddNewClient(emailId);
        }
        this.triggerMailToUser(userData, isApproved);
        this.setIsAPIFailed(false);
        this.setIsSnackbarOpen(true, isApproved);
        this.fetchAllUserInfo();
      } else {
        this.setIsAPIFailed(false);
      }
    } catch (e) {
      this.setIsSnackbarOpen(false);
      this.setIsLoading(false);
      this.setIsAPIFailed(true);
    }
  };

  setIsSnackbarOpen = (openSnackbar, isApproved = 0) => {
    this.setState({
      openSnackbar,
    });
    this.snackBarMessage = isApproved
      ? 'User successfully Approved'
      : 'User successfully rejected';
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setIsSnackbarOpen(false);
  };

  render() {
    const { tabValue, openSnackbar, isLoading, isAPIFailed } = this.state;
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="admin-container">
          <div className="tabs-container">
            <Tabs value={tabValue} onChange={this.handleTabChange}>
              <Tab disableRipple label="All Users" />
              <Tab disableRipple label="Pending Approvals" />
            </Tabs>
          </div>
          <div className="tab-view-container">
            {tabValue === 0 && (
              <DataGrid
                rows={this.getAllUsers()}
                columns={advisoryGridCol}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            )}
            {tabValue === 1 && (
              <DataGrid
                rows={this.getPendingApprovalUsers()}
                columns={this.getPendingUsersCol()}
                pageSize={10}
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
              />
            )}
          </div>
        </div>
        <DialogBox
          open={isAPIFailed}
          alertDialogType
          cancelLabel="OK"
          handleCancel={() => this.setIsAPIFailed(false)}
        >
          <div>There is some network error, Please try later.</div>
        </DialogBox>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={this.handleSnackBarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleSnackBarClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {this.snackBarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedInUserDetails: getLoggedInUserDetails(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
