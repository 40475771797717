import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import {
  renderFooterSubmitCancelButtons,
  renderSelectAccountDropdown,
  DeleteConfirmation,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook 
import { secondHomesFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import Controls from '../controls/Controls';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  validateDateFields,
  createLocalDateFromDateString,
  parseCurrency,
  getDateValue,
} from '../../helpers/SolutionsHelper';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import { useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { cloneDeep } from 'lodash';

const BuildPropertiesSecondHomes = forwardRef((props, ref) => {
  const {
    state,
    concatContent,
    taxStateOptions,
    setLastStepInParent,
    setFormDetails
  } = props;

  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const moduleObject = {
    moduleTab: 'moduleInvestments',
    mainTab: 'secondHomes',
    subTab: 'props',
    countVal: 'numSecondHomes',
  };

  const [selectedSecondHome, setSelectedSecondHome] = useState({});

  const [secondaryHomesFormFields, setSecondaryHomesFormFields] = useState(
    secondHomesFormFields
  );

  const isEditingRef = useRef(false);
  const { formDetails, isEditable, isAddNew, isCancelled } = state;
  const [isFormValid, setIsFormValid] = useState(true);

  const [modalType, setModalType] = useState(undefined);

  useEffect(() => {
    const type = isAddNew ? 'add' : isEditable ? 'edit' : undefined
    setModalType(type);
    setSelectedSecondHome({});
  }, [isAddNew, isEditable])

  useEffect(() => {
    if (isCancelled) {
      setSelectedSecondHome({});
    }
  }, [isCancelled])


  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  const conditionalFields = [
    'name',
    'origOrRefiLoanAmount',
    'purchaseDate',
    'origOrRefiLoanDate',
  ];

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedSecondHome;
    },
  }));

  useEffect(() => {
    let tempSecondHomesFormFields = [...secondHomesFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempSecondHomesFormFields = tempSecondHomesFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setSecondaryHomesFormFields(tempSecondHomesFormFields);
  }, [setSecondaryHomesFormFields, taxStateOptions]);


  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'props', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const { props = {} } = formDetails || {}
    const secondHome = props && Object.keys(props).length > 0 && props.name.toLowerCase() === defaultName.toLowerCase() ? props : undefined
    if (
      !secondHome &&
      modalType === 'add' &&
      Object.keys(selectedSecondHome).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['props']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['props'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );
      if (Object.keys(selectedSecondHome).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          secondHomesFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !==
          JSON.stringify(selectedSecondHome)
        ) {
          setSelectedSecondHome(formattedNewRecord);
        }
      }
    }

    if (secondHome) {
      const formattedHome = formatValuesForUI(
        secondHome,
        secondHomesFormFields
      );
      if (
        JSON.stringify(formattedHome) !== JSON.stringify(selectedSecondHome)
      ) {
        setSelectedSecondHome(formattedHome); // Only update if necessary
      }
    }
  }, [
    formDetails,
    defaultName,
    modalType,
    selectedSecondHome,
    generatePropsMemoized,
  ]);

  const validateSecondHomeNames = useCallback(
    (value) => {
      const {
        moduleInvestments: { secondHomes: { props = [] } = {} } = {},
      } = planDetailsByPlanId || {};
      const existingSecondHome = props.find(
        (secondHome) => secondHome?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingSecondHome) {
        return false;
      }
      return true;
    },
    [modalType, planDetailsByPlanId]
  );

  const validateLoanAmountField = useCallback(
    (value) => {
      const loanAmount = parseCurrency(value || 0);
      const purchasePrice = parseCurrency(
        selectedSecondHome?.purchasePrice || 0
      );

      if (isNaN(loanAmount) || isNaN(purchasePrice)) {
        return false;
      }
      // Ensure loan amount is not greater than the purchase price
      return loanAmount <= purchasePrice;
    },
    [selectedSecondHome]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedSecondHome[key];
      let isValid = true;
      switch (key) {
        case 'aggMaintenanceExp':
        case 'loanAmountOutstanding':
        case 'purchasePrice':
        case 'taxBasis':
        case 'value':
          isValid = value;
          break;
        case 'origOrRefiLoanAmount':
          isValid = value !== '' ? validateLoanAmountField(value) : false;
          break;
        case 'aggMaintenanceExpGrowth':
        case 'appreciationRate':
        case 'fixedIntRate':
        case 'taxBasisGrowth':
        case 'taxRate':
          isValid = value;
          break;
        case 'loanTerm':
          isValid = value !== '' && parseInt(value) >= 0;
          break;
        case 'purchaseDate':
          const date =
            value &&
            validateDateFields(value) &&
            createLocalDateFromDateString(value);
          isValid = validateDateFields(value)
            ? modalType === 'add'
              ? date &&
              new Date(date).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0)
              : true
            : false;
          break;
        case 'origOrRefiLoanDate':
          isValid = value ? validateDateFields(value) : false;
          break;
        case 'name':
          isValid = value ? validateSecondHomeNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [
      selectedSecondHome,
      validateSecondHomeNames,
      modalType,
      validateLoanAmountField,
    ]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = secondHomesFormFields.map((s) => s.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = secondaryHomesFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedSecondHome[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedSecondHome[field]);
    }
    if (field === 'purchaseDate') {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        origOrRefiLoanDate: formattedValue,
      });
    } else if (field === 'purchasePrice') {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        taxBasis: formattedValue,
        value: formattedValue,
      }); // Update the selected vehicle state
    } else if (
      field === 'origOrRefiLoanAmount' &&
      parseCurrency(formattedValue) === 0
    ) {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        loanAmountOutstanding: formattedValue,
        loanTerm: 0,
      }); // Update the selected vehicle state
    } else {
      setSelectedSecondHome({ ...selectedSecondHome, [field]: formattedValue }); // Update the selected secondHome state
    }
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = secondaryHomesFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const secondHome = { ...selectedSecondHome, [key]: formattedValue };
    // Update selected secondHome state to reflect changes in UI
    setSelectedSecondHome(secondHome);
  };

  useEffect(() => {
    if (selectedSecondHome && Object.keys(selectedSecondHome).length > 0) {
      checkFormValidity();
      const { props = {} } = formDetails || {};
      let tempFormDetails = cloneDeep(formDetails);
      const secondHome = props && Object.keys(props).length > 0 && props.name.toLowerCase() === defaultName.toLowerCase() ? props : undefined
      if (secondHome && modalType === 'edit') {
        const formattedHome = formatValuesForUI(
          secondHome,
          secondHomesFormFields
        );
        if (
          JSON.stringify(formattedHome) !== JSON.stringify(selectedSecondHome)
        ) {
          tempFormDetails['props'] = selectedSecondHome;
          setFormDetails(tempFormDetails);
        }
      }
      else if (modalType === 'add') {
        const formattedNewHome = formatValuesForUI(
          props,
          secondHomesFormFields
        );
        if (
          JSON.stringify(formattedNewHome) !== JSON.stringify(selectedSecondHome)
        ) {
          tempFormDetails['props'] = selectedSecondHome;
          setFormDetails(tempFormDetails);
        }
      }
    }
  }, [formDetails, checkFormValidity, selectedSecondHome, defaultName, setFormDetails, modalType]);


  // // Helper function to update form details and set the state
  // const updateFormDetails = (selectedSecondHome) => {
  //   const tempFormDetails = { ...formDetails };
  //   tempFormDetails.props = selectedSecondHome;
  //   setFormDetails(tempFormDetails);
  // };

  // // Helper function for shallow or deep comparison (use lodash for deep equality)
  // const isEqual = (a, b) => {
  //   // For deep equality, you can use lodash's isEqual or write your own
  //   return JSON.stringify(a) === JSON.stringify(b); // or lodash.isEqual(a, b);
  // };

  // useEffect(() => {
  //   if (!selectedSecondHome || Object.keys(selectedSecondHome).length === 0) return;
  //   checkFormValidity();
  //   const { props = {} } = formDetails || {};
  //   const existingHome = finalExistingItems?.length > 0 ? defaultName : "";
  //   const secondHome = props?.name?.toLowerCase() === existingHome.toLowerCase() ? props : undefined;
  //   if (secondHome) {
  //     const formattedHome = formatValuesForUI(secondHome, secondHomesFormFields);
  //     // Use a shallow comparison or a deep compare function for formattedHome vs selectedSecondHome
  //     if (!isEqual(formattedHome, selectedSecondHome)) {
  //       updateFormDetails(selectedSecondHome);
  //     }
  //   } else if (modalType === 'add') {
  //     updateFormDetails(selectedSecondHome);
  //   }
  // }, [formDetails, checkFormValidity, selectedSecondHome, defaultName, setFormDetails, finalExistingItems, modalType]);


  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = secondaryHomesFormFields.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        let secondHome = { ...selectedSecondHome };
        if (field.key === 'purchaseDate') {
          secondHome = {
            ...secondHome,
            [field.key]: formattedDate,
            origOrRefiLoanDate: formattedDate,
          };
        } else {
          secondHome = {
            ...secondHome,
            [field.key]: formattedDate,
          };
        }
        setSelectedSecondHome(secondHome);
      }
    }
  };

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedSecondHome).length > 0 &&
            secondaryHomesFormFields.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          disabled={!modalType || (modalType === 'edit' && field.isDisabled)}
                          value={selectedSecondHome[field.key]}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={() => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedSecondHome &&
                        Object.keys(selectedSecondHome).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {selectedSecondHome[field.key] !== '' &&
                              selectedSecondHome[field.key] !== undefined
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-8 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={selectedSecondHome[field.key]?.toLowerCase()}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          disabled={!modalType || (modalType === 'edit' && field.isDisabled)}
                          className={'custom-input'}
                          options={field.options.map((c) => {
                            return {
                              value: c.value?.toLowerCase(),
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(
                    selectedSecondHome[field.key],
                    field
                  );
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-8 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            onBlur={() => handleBlur(field.key)}
                            inputProps={{ style: { height: '39px' } }}
                            minDate={
                              (modalType === 'add' ||
                                (modalType === 'edit' &&
                                  new Date(dateValue).setHours(0, 0, 0, 0) >=
                                  new Date().setHours(0, 0, 0, 0))) &&
                                field.key === 'origOrRefiLoanDate'
                                ? getDateValue(
                                  selectedSecondHome['purchaseDate']
                                )
                                : new Date()
                            }
                            // disabled={!modalType ||
                            //   (modalType === 'edit' &&
                            //     new Date(dateValue).setHours(0, 0, 0, 0) <
                            //     new Date().setHours(0, 0, 0, 0))
                            // }
                            disabled={!modalType}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      </div>
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  )
})
export default BuildPropertiesSecondHomes;