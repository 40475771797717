import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DateRangeOutlined } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MAX_DATE,
  MAX_DATE_MSG,
  MIN_DATE,
  MIN_DATE_MSG,
  INVALID_DATE_MSG,
} from '../../constants/controls';

const CustomKeyboardDatePicker = (props) => {
  const {
    label,
    selectedDate,
    handleDateChange,
    dateFormat = 'yyyy-MM-dd',
    isRequired = false,
    maxDateMessage = MAX_DATE_MSG,
    minDate = MIN_DATE,
    maxDate = MAX_DATE,
    name,
    invalidDateMessage = INVALID_DATE_MSG,
    minDateMessage = MIN_DATE_MSG,
    ...rest
  } = props;
  const defaultProps = {
    variant: 'inline',
    margin: 'normal',
    inputVariant: 'outlined',
    size: 'small',
    keyboardIcon: <DateRangeOutlined fontSize="small" />,
    PopoverProps: {
      disablePortal: true,
      anchorE1: () => {
        if (datePickerRef.current) {
          return datePickerRef.current;
        }
        return document.body;
      },
    },
    InputAdornmentProps: { position: 'end' },
  };
  const datePickerRef = React.createRef();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="controls-datepicker" ref={datePickerRef}>
        <div className="datepicker-label">
          <label>{label}</label>
        </div>
        <DatePicker
          InputLabelProps={{ shrink: false }}
          format={dateFormat}
          value={selectedDate.replace(/-/g, '/')}
          autoOk
          maxDateMessage={maxDateMessage}
          minDateMessage={minDateMessage}
          invalidDateMessage={invalidDateMessage}
          onChange={handleDateChange}
          // format="yyyy/MM/dd"
          {...{ ...defaultProps, ...rest }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default CustomKeyboardDatePicker;
