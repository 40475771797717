import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import {
  BuildTabComponent,
  ExpenseInsuranceAccordionNames,
  ExpenseVehicleAccordionNames,
  ExpenseLoanAccordionNames,
} from '../constants/BuildTabComponentV3';
import { PropertyNamesMap } from '../constants/PropertyNames';
import history from '../history';
import { setSelectedClientIdAction } from '../redux/actions/clientsInfoAction';
import {
  getPlansByClientIdAction,
  setSelectedPlanForClientId,
  setSelectedChartAction,
  setSelectedTableAction,
  setSelectedDataAction,
} from '../redux/actions/plansAction';
import {
  saveAndUpdatePlanDetailsByPlanId,
  simulatePlanDetailsByPlanIdSuccessAction,
  simulatePlanDetailsByPlanId,
  getInvestmentProductsByClientIdAction,
} from '../redux/actions/plannerDetailsAction';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import { getLoggedInUserDetails } from '../selectors/loginUserSelector';
import {
  outputResponseLoadingSelector,
  outputResponseBuildByPlanIdSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
} from '../selectors/outputResponseDetailsSelector';
import {
  planDetailsByPlanIdSelector,
  plannerDetailsLoadingSelector,
  plannerDetailsSaveStatus,
  plannerDetailsSimulateStatus,
  selectedPlanIdForClientIdSelector,
  allTaxSlabsSelector,
  selectedChartSelector,
  allPlannerMenuItemsSelector,
  expenseFactorDetailsSelector,
  getInvestmentProductsByClientIdSelector,
  simulatePlanDetailsLoadingSelector,
} from '../selectors/plannerDetailsSelector';
import AlertsComponent from './Build/AlertsComponent';
import CashFlowsComponent from './Build/CashFlowsComponent';
import ModelComponent from './Build/ModelComponent';
import HeirsComponent from './Estate/HeirsComponent';
import RothComponent from './Estate/RothComponent';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  transformTaxStateOptions,
  getInfoExpenseStoreValuesV2,
} from '../redux/transformers/apiToClientResponseTransformer';
import TaxesComponent from './Build/TaxesComponent';
import EmergencyFundsComponent from './Build/EmergencyFundsComponent';
import CustomChart from '../components/shared/CustomChartComponent';
import CustomTable from '../components/shared/CustomTableComponent';
import CustomData from '../components/shared/CustomDataComponent';
import NewChartsContainer from '../components/NewChartsContainer';
import HorizonDetails from './shared/HorizonDetails';
import TabsComponent from './shared/TabsComponent';
import { isEqual } from 'lodash';
import BuildFamilyPersonalComponent from './Family/BuildFamilyPersonalComponentV3';
import BuildIncomeWagesComponent from './Family/BuildIncomeWagesComponentV3';
import BuildIncomeInvestmentComponent from './Family/BuildIncomeInvestmentComponentV3';
import BuildIncomeSocialSecurityComponent from './Family/BuildIncomeSocialSecurityComponentV3';
import BuildIncomePensionComponent from './Family/BuildIncomePensionComponentV3';
import BuildIncomeDeferredCompensationComponent from './Family/BuildIncomeDeferredCompensationComponentV3.js';
import BuildIncomeOtherIncomeComponent from './Family/BuildIncomeOtherIncomeComponentV3.js';
import BuildSavingsPensionComponent from './Savings/BuildSavingsPensionComponentV3.js';
import BuildSavings401kANDIRAComponents from './Savings/BuildSavings401k&IRAComponentsV3.js';
import BuildSavingsEmergencyFundsComponent from './Savings/BuildSavingsEmergencyFundsComponentV3.js';
import BuildSavingsDeferredCompensationComponent from './Savings/BuildSavingsDeferredCompensationComponentV3.js';
import BuildSavings529PlansComponent from './Savings/BuildSavings529PlansComponentV3.js';
import BuildSavingsOtherComponent from './Savings/BuildSavingsOtherComponentV3.js';
import BuildExpensesKidsComponent from './Expenses/BuildExpensesKidsComponentV3.js';
import BuildExpensesVehiclesComponent from './Expenses/BuildExpensesVehiclesComponentV3.js';
import BuildExpensesInsurancesComponent from './Expenses/BuildExpensesInsurancesComponentV3.js';
import BuildExpensesCreditCardsComponent from './Expenses/BuildExpensesCreditCardsComponentV3.js';
import BuildExpensesLoansComponent from './Expenses/BuildExpensesLoansComponentV3.js';
import BuildExpensesVacationsComponent from './Expenses/BuildExpensesVacationsComponentV3.js';
import BuildExpensesLivingComponent from './Expenses/BuildExpensesLivingComponentV3.js';
import BuildInvestmentsMarketsComponent from './Investments/BuildInvestmentsMarketsComponentV3.js';
import BuildInvestmentsOtherComponent from './Investments/BuildInvestmentsOtherComponentV3.js';
import BuildEstatePlanningRothComponent from './EstatePlanning/BuildEstatePlanningRothComponentV3.js';
import BuildEstatePlanningHeirsComponent from './EstatePlanning/BuildEstatePlanningHeirsComponentV3.js';
import BuildSettingsModelComponent from './Settings/BuildSettingsModelComponentV3.js';
import BuildSettingsTaxesComponent from './Settings/BuildSettingsTaxesComponentV3.js';
import BuildSettingsAlertsComponent from './Settings/BuildSettingsAlertsComponentV3.js';
import BuildPropertiesPrimaryHome from './Properties/BuildPropertiesPrimaryHomeV3.js';
import BuildPropertiesSecondHomes from './Properties/BuildPropertiesSecondHomesV3.js';
import BuildPropertiesInvestment from './Properties/BuildPropertiesInvestmentV3.js';
import BuildSavingsComponent from './Family/BuildSavingsComponent';
import { generateTableHTML } from '../utils/renderHtmlUtil';
import BuildFamilyKidsComponent from './Family/BuildFamilyKidsComponentV3';
import VacationContent from './Family/VacationContent';
import VehiclesContent from './Family/VehiclesContent';
import CreditCardsContent from './Debt/CreditCardsContent';
import StudentLoansContent from './Debt/StudentLoansContent';
import OtherLoansContent from './Debt/OtherLoansContent';
import TermLifeClientContent from './ClientInsurance/TermLifeClientContent';
import WholeLifeClientContent from './ClientInsurance/WholeLifeClientContent';
import LongTermClientContent from './ClientInsurance/LongTermClientContent';
import Journey from './Journey';
import PlaidUIContainer from './PlaidUINew';
import CloseIcon from '@mui/icons-material/Close';
import UmbrellaClientContent from './ClientInsurance/UmbrellaClientContent';
import PropertyClientContent from './ClientInsurance/PropertyClientContent';
import AutoClientContent from './ClientInsurance/AutoClientContent';
import HealthClientContent from './ClientInsurance/HealthClientContent';
import {
  setPlaidLinkSuccessAction,
  setPlaidLinkAccessToken,
} from '../redux/actions/plaidUIActions';
import PrimaryContent from './Investments/PrimaryContent';
import SecondHomesContent from './Investments/SecondHomesContent';
import InvPropsContent from './Investments/InvPropsContent';
import Simulate_icon_2 from '../images/Simulate_icon_2.svg';
import Simulate_icon_1 from '../images/Simulate_icon_1.svg';
import Save_icon from '../images/save.svg';
import {
  transformBackToAPIType,
  getFormattedUIValue,
  validateFormDetails,
} from '../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import BuildTermLifeInsuranceComponent from './Expenses/BuildTermLifeInsuranceComponentV3';
import BuildWholeLifeInsuranceComponent from './Expenses/BuildWholeLifeInsuranceComponentV3';
import BuildUmbrellaLifeInsuranceComponent from './Expenses/BuildUmbrellaLifeInsuranceComponentV3';
import BuildLongTermCareInsuranceComponentV3 from './Expenses/BuildLongTermCareInsuranceComponentV3';
import BuildHealthCareInsuranceComponent from './Expenses/BuildHealthCareComponentV3';
import BuildStudentLoanComponentV3 from './Expenses/BuildStudentLoanComponentV3';
import BuildOtherLoanComponentV3 from './Expenses/BuildOtherLoanComponentV3';
import BuildVehiclesComponentV3 from './Expenses/BuildVehiclesComonentV3';
import BuildInsuranceComponentV3 from './Expenses/BuildVehicleInsuranceComponentV3';
import BuildPropertyInsuranceComponent from './Expenses/BuildPropertyInsuranceComponentV3';

const DEFAULT_RENDER_MAIN_TYPE = 'Build';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const [VEHICLES, AUTOINS] = ExpenseVehicleAccordionNames;

const [
  TERMLIFEINS,
  WHOLELIFEINS,
  UMBRELLAINS,
  LONGTERMCARE,
  HEALTHCARE,
  PROPERTYINSURANCE,
] = ExpenseInsuranceAccordionNames;

const [STUDENTLOANS] = ExpenseLoanAccordionNames;

class BuildComponent extends Component {
  state = {
    showLeftNav: false,
    showRightNav: true,
    leftParentTabValue: 0,
    expandedPanel: '',
    leftSubTabValue: 0,
    rightParentTabValue: 0,
    rightSubTabValue: 0,
    tabInfo: [],
    rightTabInfo: [],
    renderMainType: 'Build',
    renderType: 'Custom',
    localPlanDetailsByPlanId: {},
    retiredState: false,
    isFirstPersonRetired: false,
    isSecondPersonRetired: false,
    memberOne: false,
    memberTwo: false,
    membersCount: 0,
    existingObjects: [],
    formDetails: {
      kids: {},
      vehicles: {},
      vacations: {},
      creditCards: {},
      studentLoans: {},
      otherLoans: {},
      termLifeIns: {},
      wholeLifeIns: {},
      ltcIns: {},
      umbrellaIns: {},
      propIns: {},
      autoIns: {},
      prop: {},
      props: {},
      invProps: {},
      healthIns: {},
    },
    isComputed: false,
    isEditable: false,
    isAddNew: false,
    selectedObjValue: '',
    memberOptionsForInvestments: [],
    memberOptions: [],
    isLastStep: false,
    isFormValid: true,
    formErrors: [],
    currentIndex: 0,
    selectedDDNameIndex: 0,
    isReduxUpdated: false,
    snackbarShown: false,
  };

  handleNext = () => {
    const { leftParentTabValue, leftSubTabValue } = this.state;
    const subTabCount =
      BuildTabComponent[0].data[leftParentTabValue].subtabs.length;

    if (leftSubTabValue < subTabCount - 1) {
      // If not the last sub-tab, go to the next sub-tab
      this.setState({ leftSubTabValue: leftSubTabValue + 1 });
    } else if (leftParentTabValue < BuildTabComponent[0].data.length - 1) {
      // If at the last sub-tab, move to the first sub-tab of the next main tab
      this.setState({
        leftParentTabValue: leftParentTabValue + 1,
        leftSubTabValue: 0,
      });
    }
  };

  handlePrevious = () => {
    const { leftParentTabValue, leftSubTabValue } = this.state;

    if (leftSubTabValue > 0) {
      // If not the first sub-tab, go to the previous sub-tab
      this.setState({ leftSubTabValue: leftSubTabValue - 1 });
    } else if (leftParentTabValue > 0) {
      // If at the first sub-tab, move to the last sub-tab of the previous main tab
      const prevSubTabCount =
        BuildTabComponent[0].data[leftParentTabValue - 1].subtabs.length;
      this.setState({
        leftParentTabValue: leftParentTabValue - 1,
        leftSubTabValue: prevSubTabCount - 1, // Go to the last sub-tab of the previous parent tab
      });
    }
  };

  // Mobile scroll
  carouselContainerRef = React.createRef();

  showNextItem = () => {
    this.setState({
      showLeftNav: true,
      showRightNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex < 1) {
      this.setState({ currentIndex: currentIndex + 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  showPreviousItem = () => {
    this.setState({
      showRightNav: true,
      showLeftNav: false,
    });

    const { currentIndex } = this.state;
    if (currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  scrollToIndex = (index) => {
    const carouselContainer = this.carouselContainerRef.current;
    if (carouselContainer) {
      carouselContainer.scrollTo({
        left: carouselContainer.offsetWidth * index,
        behavior: 'smooth',
      });
    }
  };

  componentDidMount() {
    const renderMainType = DEFAULT_RENDER_MAIN_TYPE;
    const {
      selectedClientId,
      selectedPlan,
      planDetailsByPlanId,
      isV2,
      allMenuItems,
    } = this.props;
    const isDirectUser = this.getIfUserTypeIsDirect();
    const isClientUser = this.getIfUserTypeIsClient();
    const { genderMenuItems = [] } = allMenuItems || {};
    if (!selectedClientId && !selectedPlan && !isDirectUser && !isClientUser) {
      history.push({ pathname: '/home' });
    } else if (
      isV2 &&
      selectedClientId &&
      selectedClientId !== '' &&
      selectedPlan &&
      selectedPlan !== ''
    ) {
      this.getClientTabData(renderMainType);
    }
    // this.getAllGenders();
    this.setState({
      renderMainType,
      leftParentTabValue: 0,
      leftSubTabValue: 0,
    });

    this.scrollToIndex(this.state.currentIndex);
  }

  componentWillUnmount() {
    const { triggerUpdatePlannerDetailsinRedux } = this.props;
    const { localPlanDetailsByPlanId, planDetailsByPlanId } = this.state;
    triggerUpdatePlannerDetailsinRedux({
      plannerData: localPlanDetailsByPlanId,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { renderMainType, tabInfo } = this.state;
    const {
      renderMainType: prevRenderMainType,
      leftParentTabValue: prevLeftParentTabValue,
      leftSubTabValue: prevLeftSubTabValue,
    } = prevState;
    const { planDetailsByPlanId, charts, tables, data, allMenuItems } =
      this.props;
    const {
      localPlanDetailsByPlanId,
      renderType,
      rightParentTabValue,
      leftParentTabValue,
      leftSubTabValue,
      formDetails,
      isAddNew,
      isEditable,
      snackbarShown,
    } = this.state;
    const {
      renderType: prevRenderType,
      localPlanDetailsByPlanId: prevLocalPlanDetailsByPlanId,
      rightParentTabValue: prevRightParentTabValue,
      formDetails: prevFormDetails,
    } = prevState;
    const {
      planDetailsByPlanId: prevPlanDetailsByPlanId,
      charts: prevCharts,
      tables: prevTables,
      data: prevData,
    } = prevProps;

    const { genderMenuItems = [] } = allMenuItems || {};
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      renderMainType !== prevRenderMainType
    ) {
      this.getClientTabData(renderMainType, prevState);
    }
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      (!isEqual(prevRenderType, renderType) ||
        (charts && charts.length > 0 && !isEqual(charts, prevCharts)) ||
        (data && data.length > 0 && !isEqual(data, prevData)) ||
        (tables && tables.length > 0 && !isEqual(tables, prevTables)))
    ) {
      this.loadResultsContainer();
    }
    if (rightParentTabValue !== prevRightParentTabValue) {
      this.updateReduxOnTabChange();
    }
    if (
      renderMainType !== prevRenderMainType ||
      leftParentTabValue !== prevLeftParentTabValue ||
      leftSubTabValue !== prevLeftSubTabValue
    ) {
      this.loadTabSpecificInfo();
    }
    if (prevState.currentIndex !== this.state.currentIndex) {
      this.scrollToIndex(this.state.currentIndex);
    }
    if (!isEqual(prevFormDetails, formDetails) && (isAddNew || isEditable)) {
      this.validateAllFormDetails();
    }
    if (
      !isEqual(planDetailsByPlanId, prevPlanDetailsByPlanId) &&
      !snackbarShown
    ) {
      this.showSnackbar();
    }
  }

  showSnackbar = () => {
    this.setState({ isReduxUpdated: true, snackbarShown: true });
    setTimeout(() => {
      this.setState({ isReduxUpdated: false, snackbarShown: false });
    }, 2000);
    this.handleDisplayToast('redux');
  };

  validateTabwiseFormDetails = (
    selectedFormDetails,
    validateFields,
    existingItems,
    subTab
  ) => {
    const { isAddNew, isEditable, selectedDDNameIndex } = this.state;
    let validationErrors = {};
    validationErrors = validateFormDetails(
      selectedFormDetails,
      validateFields,
      existingItems,
      isAddNew,
      isEditable,
      selectedDDNameIndex,
      subTab
    );
    if (validationErrors && validationErrors.length > 0) {
      this.setState({ formErrors: validationErrors, isFormValid: false });
    } else {
      this.setState({ formErrors: [], isFormValid: true });
    }
  };

  validateAllFormDetails = () => {
    const { formDetails, leftSubTabValue, leftParentTabValue, expandedPanel } =
      this.state;
    let mandatoryFields = [];
    let formFields = {};
    let existingItems = [];
    let moduleTab = '';
    let mainTab = '';
    let subTab = '';
    switch (leftParentTabValue) {
      case 0:
        moduleTab = 'moduleFamily';
        switch (leftSubTabValue) {
          case 1:
            mainTab = 'infoKids';
            subTab = 'kids';
            mandatoryFields = Object.entries(PropertyNamesMap['kids'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['kids'];
            break;

          default:
            break;
        }
        break;
      case 1:
        moduleTab = 'moduleInvestments';
        switch (leftSubTabValue) {
          case 1:
            mainTab = 'primaryHome';
            subTab = 'prop';
            mandatoryFields = Object.entries(PropertyNamesMap['prop'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['prop'];
            break;
          case 2:
            mainTab = 'secondHomes';
            subTab = 'props';
            mandatoryFields = Object.entries(PropertyNamesMap['props'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['props'];
            break;
          case 3:
            mainTab = 'invProps';
            subTab = 'props';
            mandatoryFields = Object.entries(
              PropertyNamesMap['invProps']['prop']
            )
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['invProps']['prop'];
            break;
          default:
            break;
        }
        break;
      case 2:
        switch (leftSubTabValue) {
          case 0:
            moduleTab = 'moduleDebt';
            mainTab = 'infoCreditCards';
            subTab = 'creditCards';
            mandatoryFields = Object.entries(PropertyNamesMap['creditCards'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['creditCards'];
            break;
          case 1:
            moduleTab = 'moduleDebt';
            mainTab = 'infoStudentLoans';
            subTab = 'studentLoans';
            mandatoryFields = Object.entries(PropertyNamesMap['studentLoans'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['studentLoans'];
            break;
          case 2:
            moduleTab = 'moduleDebt';
            mainTab = 'infoOtherLoans';
            subTab = 'otherLoans';
            mandatoryFields = Object.entries(PropertyNamesMap['otherLoans'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['otherLoans'];
            break;
          case 4:
            moduleTab = 'moduleFamily';
            mainTab = 'infoKids';
            subTab = 'kids';
            mandatoryFields = Object.entries(PropertyNamesMap['kids529Plan'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['kids'];
            break;
          default:
            break;
        }
        break;
      case 3:
        // moduleTab = 'moduleFamily';
        switch (leftSubTabValue) {
          case 0:
            moduleTab = 'moduleFamily';
            mainTab = 'infoKids';
            subTab = 'kids';
            mandatoryFields = Object.entries(PropertyNamesMap['ekids'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true && !value.isOptional
              )
              .map(([key, value]) => value);
            formFields = formDetails['kids'];
            break;
          case 1:
            if (expandedPanel === VEHICLES) {
              moduleTab = 'moduleFamily';
              mainTab = 'infoVehicles';
              subTab = 'vehicles';
              mandatoryFields = Object.entries(PropertyNamesMap['vehicles'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['vehicles'];
            }
            if (expandedPanel === AUTOINS) {
              moduleTab = 'moduleInsurance';
              mainTab = 'auto';
              subTab = 'autoIns';
              mandatoryFields = Object.entries(PropertyNamesMap['autoIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['autoIns'];
            }

            break;

          case 2:
            moduleTab = 'moduleInsurance';
            if (expandedPanel === LONGTERMCARE) {
              mainTab = 'longTermCare';
              subTab = 'ltcIns';
              mandatoryFields = Object.entries(PropertyNamesMap['ltcIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['ltcIns'];
            }
            if (expandedPanel === TERMLIFEINS) {
              mainTab = 'termLife';
              subTab = 'termLifeIns';
              mandatoryFields = Object.entries(PropertyNamesMap['termLifeIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['termLifeIns'];
            }
            if (expandedPanel === UMBRELLAINS) {
              mainTab = 'umbrella';
              subTab = 'umbrellaIns';
              mandatoryFields = Object.entries(PropertyNamesMap['umbrellaIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['umbrellaIns'];
            }
            if (expandedPanel === WHOLELIFEINS) {
              mainTab = 'wholeLife';
              subTab = 'wholeLifeIns';
              mandatoryFields = Object.entries(PropertyNamesMap['wholeLifeIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['wholeLifeIns'];
            }
            if (expandedPanel === HEALTHCARE) {
              mainTab = 'health';
              subTab = 'healthIns';
              mandatoryFields = Object.entries(PropertyNamesMap['healthIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['healthIns'];
            }
            if (expandedPanel === PROPERTYINSURANCE) {
              mainTab = 'property';
              subTab = 'propIns';
              mandatoryFields = Object.entries(PropertyNamesMap['propIns'])
                .filter(
                  ([key, value]) =>
                    value.isMandatory === true && !value.isOptional
                )
                .map(([key, value]) => value);
              formFields = formDetails['propIns'];
            }
            break;
          case 3:
            moduleTab = 'moduleDebt';
            mainTab = 'infoCreditCards';
            subTab = 'creditCards';
            mandatoryFields = Object.entries(PropertyNamesMap['creditCards'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['creditCards'];
            break;
          case 4:
            mainTab = 'property';
            subTab = 'propIns';
            mandatoryFields = Object.entries(PropertyNamesMap['propIns'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true && !value.isOptional
              )
              .map(([key, value]) => value);
            formFields = formDetails['propIns'];
            break;
          case 5:
            mainTab = 'auto';
            subTab = 'autoIns';
            mandatoryFields = Object.entries(PropertyNamesMap['autoIns'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true && !value.isOptional
              )
              .map(([key, value]) => value);
            formFields = formDetails['autoIns'];
            break;
          case 6:
            mainTab = 'health';
            subTab = 'healthIns';
            mandatoryFields = Object.entries(PropertyNamesMap['healthIns'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true && !value.isOptional
              )
              .map(([key, value]) => value);
            formFields = formDetails['healthIns'];
            break;
          default:
            break;
        }
        break;
      case 5:
        moduleTab = 'moduleInvestments';
        switch (leftSubTabValue) {
          case 1:
            mainTab = 'primaryHome';
            subTab = 'prop';
            mandatoryFields = Object.entries(PropertyNamesMap['prop'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true ||
                  value.name === 'origOrRefiLoanDate'
              )
              .map(([key, value]) => value);
            formFields = formDetails['prop'];
            break;
          case 2:
            mainTab = 'secondHomes';
            subTab = 'props';
            mandatoryFields = Object.entries(PropertyNamesMap['props'])
              .filter(
                ([key, value]) =>
                  value.isMandatory === true ||
                  value.name === 'origOrRefiLoanDate'
              )
              .map(([key, value]) => value);
            formFields = formDetails['props'];
            break;
          case 3:
            mainTab = 'invProps';
            subTab = 'props';
            mandatoryFields = Object.entries(
              PropertyNamesMap['invProps']['prop']
            )
              .filter(
                ([key, value]) =>
                  value.isMandatory === true ||
                  value.name === 'origOrRefiLoanDate'
              )
              .map(([key, value]) => value);
            formFields = formDetails['invProps']['prop'];
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    existingItems = this.concatContent(moduleTab, mainTab, subTab);
    this.validateTabwiseFormDetails(
      formFields,
      mandatoryFields,
      existingItems,
      subTab
    );
  };

  loadInvestmentsTabInfo = () => {
    const { localPlanDetailsByPlanId } = this.state;
    const { clientid } = localPlanDetailsByPlanId;
    const { triggerSelectInvestmentProductsByClientId, investmentProducts } =
      this.props;
    if (investmentProducts && investmentProducts.length === 0) {
      triggerSelectInvestmentProductsByClientId(clientid);
    }
  };

  loadTabSpecificInfo = () => {
    //refer newSolutions to implement this logic, tab specific info can be updated here
    //for now just setting renderType to 'Custom'
    const { leftParentTabValue } = this.state;
    switch (leftParentTabValue) {
      case 1:
        this.loadInvestmentsTabInfo();
        break;
      default:
        break;
    }
    this.setState({ renderType: 'Custom' });
  };

  getChartsMenu = () => {
    const { charts, triggerSetSelectedChart } = this.props;
    const { rightParentTabValue, rightSubTabValue } = this.state;
    let chartInfo = [];
    if (charts && charts.length > 0) {
      chartInfo = charts.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedChart =
        (chartInfo[rightParentTabValue] &&
          chartInfo[rightParentTabValue].subMenu &&
          chartInfo[rightParentTabValue].subMenu.length > 0 &&
          chartInfo[rightParentTabValue].subMenu[rightSubTabValue]
            .subtabName) ||
        '';
      triggerSetSelectedChart(selectedChart);
    }
    this.setState({
      rightTabInfo: chartInfo,
    });
  };

  getTableMenu = () => {
    const { tables, triggerSetSelectedTable } = this.props;
    const { rightParentTabValue, rightSubTabValue } = this.state;
    let tableInfo = [];
    if (tables && tables.length > 0) {
      tableInfo = tables.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedTable =
        (tableInfo[rightParentTabValue] &&
          tableInfo[rightParentTabValue].subMenu &&
          tableInfo[rightParentTabValue].subMenu.length > 0 &&
          tableInfo[rightParentTabValue].subMenu[rightSubTabValue]
            .subtabName) ||
        '';
      triggerSetSelectedTable(selectedTable);
    }
    this.setState({
      rightTabInfo: tableInfo,
    });
  };

  getDataMenu = () => {
    const { data, triggerSetSelectedData } = this.props;
    const { rightParentTabValue, rightSubTabValue } = this.state;
    let dataInfo = [];
    if (data && data.length > 0) {
      dataInfo = data.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedData =
        (dataInfo[rightParentTabValue] &&
          dataInfo[rightParentTabValue].subMenu &&
          dataInfo[rightParentTabValue].subMenu.length > 0 &&
          dataInfo[rightParentTabValue].subMenu[rightSubTabValue].subtabName) ||
        '';
      triggerSetSelectedData(selectedData);
    }
    this.setState({
      rightTabInfo: dataInfo,
    });
  };

  loadResultsContainer = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        this.getChartsMenu();
        break;
      case 'Table':
        this.getTableMenu();
        break;
      case 'Data':
        this.getDataMenu();
        break;
      default:
        break;
    }
  };

  onAccountChange = (event, accounts, mainTab, subTab, arrayObject) => {
    let isInvProps = subTab === 'invProps';
    const tab = isInvProps ? subTab : arrayObject;
    let { value: selectedAccount, index } = event.target;
    const { formDetails, existingObjects } = this.state;
    let tempFormDetails = { ...formDetails };
    const selectedObject = existingObjects.filter(
      (f) => (isInvProps ? f.prop.name : f.name) === selectedAccount
    )[0];
    let selectedObjValue = selectedAccount;
    if (selectedObject && Object.keys(selectedObject).length > 0) {
      const propertyInfoMap =
        (isInvProps
          ? PropertyNamesMap[subTab]
          : PropertyNamesMap[arrayObject]) || {};
      const updatedProperties = {};

      for (const key in propertyInfoMap) {
        if (propertyInfoMap.hasOwnProperty(key)) {
          const propertyInfo = propertyInfoMap[key];
          const propertyName = propertyInfo.name;
          if (isInvProps && key === 'prop') {
            updatedProperties[key] = {};
            for (const subKey in propertyInfo) {
              if (propertyInfo.hasOwnProperty(subKey)) {
                const propertySubInfo = propertyInfo[subKey];
                const propertySubName = propertySubInfo.name;
                updatedProperties[key][subKey] =
                  selectedObject[key][propertySubName] !== undefined
                    ? getFormattedUIValue(
                        selectedObject[key][propertySubName],
                        propertyInfo[propertySubName]
                      )
                    : '';
              }
            }
          } else {
            updatedProperties[key] =
              selectedObject[propertyName] !== undefined
                ? getFormattedUIValue(
                    selectedObject[propertyName],
                    propertyInfo
                  )
                : '';
          }
        }
      }

      tempFormDetails = {
        ...tempFormDetails,
        [tab]: {
          ...tempFormDetails[tab],
          ...updatedProperties,
        },
      };
    }
    this.setState({
      isEditable: false,
      formDetails: { ...tempFormDetails },
      selectedObjValue: selectedObjValue,
      selectedDDNameIndex: index,
    });
  };

  // updateReduxOnTabChange is used to set the name of the chart/table/data in redux to control rendering of charts/data/table based on chart name in NewChartContainer
  updateReduxOnTabChange = () => {
    const {
      triggerSetSelectedChart,
      triggerSetSelectedTable,
      triggerSetSelectedData,
    } = this.props;
    const { rightParentTabValue, renderType, rightTabInfo } = this.state;
    const selectedItem =
      (rightTabInfo[rightParentTabValue] &&
        rightTabInfo[rightParentTabValue].subMenu &&
        rightTabInfo[rightParentTabValue].subMenu.length > 0 &&
        rightTabInfo[rightParentTabValue].subMenu[0].subtabName) ||
      '';
    switch (renderType) {
      case 'Chart':
        triggerSetSelectedChart(selectedItem);
        break;
      case 'Table':
        triggerSetSelectedTable(selectedItem);
        break;
      case 'Data':
        triggerSetSelectedData(selectedItem);
        break;
      default:
        break;
    }
  };

  concatContent = (mainTab, subTab, objectArray) => {
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    // Dynamically fetch the moduleSettings object based on tab
    const tabModule = tempPlanDetailsByPlanId[mainTab] || {};
    const itemArray = tabModule[subTab]
      ? tabModule[subTab][objectArray] || []
      : [];
    let existingItemsOptions = [];
    if (Array.isArray(itemArray)) {
      existingItemsOptions = itemArray?.map((m) => {
        return {
          title: subTab === 'invProps' ? m.prop?.name : m.name,
          id: subTab === 'invProps' ? m.prop?.name : m.name,
        };
      });
    } else {
      if (Object.keys(itemArray).length > 0) {
        existingItemsOptions = [
          {
            title: itemArray.name,
            id: itemArray.name,
          },
        ];
      }
    }
    return existingItemsOptions.filter((f) => f.title && f.id); //removing nulls
  };

  getExistingObjects = (tab, subTab, objectArray) => {
    const { localPlanDetailsByPlanId } = this.state;
    const tabModule = localPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
    const subTabModule = tabModule[subTab] ? tabModule[subTab] : {};
    const objectValueArray = subTabModule[objectArray]
      ? subTabModule[objectArray]
      : [];
    return objectValueArray;
  };

  updateFormDetails = (tempFormDetails, mainTab, subTab, tab, isComputed) => {
    let existingObjectsArray = this.getExistingObjects(mainTab, subTab, tab);
    let existingObjects = existingObjectsArray;
    const isInvProps = subTab === 'invProps';
    const tempTab = isInvProps ? subTab : tab;
    let selectedObject;
    if (Array.isArray(existingObjects)) {
      selectedObject =
        this.state.selectedObjValue !== ''
          ? existingObjects.filter(
              (item) =>
                (isInvProps ? item.prop?.name : item.name) ===
                this.state.selectedObjValue
            )[0]
          : existingObjectsArray[0];
    } else {
      selectedObject = existingObjectsArray;
    }
    const propertyMap = PropertyNamesMap[tempTab];
    if (selectedObject && Object.keys(selectedObject).length > 0) {
      tempFormDetails = {
        ...tempFormDetails,
        [tempTab]: {
          ...tempFormDetails[tempTab],
          ...Object.keys(propertyMap).reduce(
            (acc, propName) => ({
              ...acc,
              [propName]:
                selectedObject !== undefined &&
                selectedObject.hasOwnProperty(propName) &&
                selectedObject[propName] !== undefined
                  ? getFormattedUIValue(
                      selectedObject[propName],
                      propertyMap[propName],
                      propName
                    )
                  : '',
            }),
            {}
          ),
        },
      };
    } else {
      tempFormDetails = {
        ...tempFormDetails,
        [tempTab]: {
          ...tempFormDetails[tempTab],
          ...Object.keys(propertyMap).reduce(
            (acc, propName) => ({
              ...acc,
              [propName]: '',
            }),
            {}
          ),
        },
      };
    }

    this.setState({
      existingObjects,
      selectedObjValue: isInvProps
        ? selectedObject?.prop?.name
        : selectedObject?.name,
    });
    return tempFormDetails;
  };

  handleEdit = (isEditable = true, isNewRec = false, name = '') => {
    const { selectedObjValue, memberOptions } = this.state;
    const updatedMemberOptions = memberOptions.map((option) => ({
      ...option,
      isSelected: false,
    }));
    const selectedValue = name || selectedObjValue; // Use the provided name if not empty, otherwise use the state's selectedObjValue
    this.setState({
      isEditable: isEditable,
      isAddNew: isNewRec,
      selectedObjValue: selectedValue || '',
      memberOptions: updatedMemberOptions,
    });
  };

  handleCancel = (moduleObject, from) => {
    const { isEditable, isAddNew, formDetails } = this.state;
    if (isEditable && from !== '') {
      if (isAddNew) {
        let tempFormDetails = { ...formDetails };
        tempFormDetails = this.updateFormDetails(
          tempFormDetails,
          moduleObject.moduleTab,
          moduleObject.mainTab,
          moduleObject.subTab
        );
        this.setState({
          isEditable: false,
          isAddNew: false,
          formDetails: tempFormDetails,
          formErrors: [],
          isFormValid: true,
        });
      } else {
        this.setState({
          isEditable: false,
          isAddNew: false,
          isComputed: false,
          formErrors: [],
          isFormValid: true,
        });
      }
    }
  };

  handleDelete = (tab, subTab, formField, countVal, selectedAccountName) => {
    const { existingObjects, formDetails, localPlanDetailsByPlanId } =
      this.state;
    let { triggerSimulatePlanDetailsByPlanId } = this.props;
    // Dynamically fetch the moduleTab object based on tab
    const tabModule = localPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
    const subTabModule = tabModule[subTab] ? tabModule[subTab] : {};
    const objectArray = subTabModule[formField];
    let tempCountVal = subTabModule[countVal];
    // Find the index of the insurance entry to be deleted based on selectedAccountName
    const indexToDelete = Array.isArray(objectArray)
      ? objectArray.findIndex(
          (ins) =>
            (subTab === 'invProps'
              ? ins.prop?.name.toLowerCase()
              : ins.name.toLowerCase()) === selectedAccountName.toLowerCase()
        )
      : 0;
    if (indexToDelete !== -1) {
      // Remove the insurance entry from the temporary array and log it
      const tempDeletedObject = Array.isArray(objectArray)
        ? objectArray.splice(indexToDelete, 1)
        : objectArray;
      // Update the moduleInsurance object with the modified insurances
      tabModule[subTab][formField] = Array.isArray(objectArray)
        ? objectArray
        : {};
      if (Array.isArray(objectArray)) {
        tabModule[subTab][countVal] = tempCountVal - 1;
      }
      // Filter the existingInsurances outside of setState
      const updatedExistingObjects = Array.isArray(existingObjects)
        ? existingObjects.filter(
            (ins) =>
              (subTab === 'invProps'
                ? ins.prop?.name.toLowerCase()
                : ins.name.toLowerCase()) !== selectedAccountName.toLowerCase()
          )
        : [];
      let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            //[countVal]: subTabModule[countVal] - 1,
            [formField]: Array.isArray(objectArray) ? [...objectArray] : {},
          },
        },
      };
      //Pefrom Cascade Delete
      if (subTab === 'infoVehicles' || tab === 'moduleInvestments') {
        let insTab = 'moduleInsurance',
          insSubTab,
          insuranceType,
          numInsName;
        if (subTab === 'infoVehicles') {
          insSubTab = 'auto';
          insuranceType = 'autoIns';
          numInsName = 'numAutoIns';
        } else {
          insSubTab = 'property';
          insuranceType = 'propIns';
          numInsName = 'numPropIns';
        }
        const tabInsModule = tempPlanDetailsByPlanId[insTab] || {}; // Tab will be module estate
        let insMainObj = tabInsModule[insSubTab]
          ? tabInsModule[insSubTab] || {}
          : {};
        const insObj = insMainObj[insuranceType] || {};
        let memberNameToDelete = tempDeletedObject?.[0]?.['name'];
        if (subTab === 'primaryHome') {
          memberNameToDelete = tempDeletedObject?.['name'];
        } else if (subTab === 'invProps') {
          memberNameToDelete = tempDeletedObject?.[0]?.['prop']?.['name'];
        }
        // Find all indexes of insurance objects with the specified name
        const indexesToDelete = insObj.reduce((acc, ins, index) => {
          if (ins.insured === memberNameToDelete) {
            acc.push(index);
          }
          return acc;
        }, []);

        // Reverse the array of indexes to avoid modification issues
        const reversedIndexes = indexesToDelete.reverse();

        // Remove the objects at the found indexes and reduce the count
        reversedIndexes.forEach((index) => {
          insObj.splice(index, 1);
          insMainObj[numInsName]--;
        });

        // Update the insurance module in tabInsModule
        insMainObj[insuranceType] = insObj;
        tempPlanDetailsByPlanId[insTab][insSubTab] = insMainObj;
      }

      // Update the state and trigger a simulation
      this.setState(
        (prevState) => ({
          ...prevState,
          localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
          existingObjects: updatedExistingObjects,
          deleteInsurance: true,
          selectedObjValue: '',
          isComputed: false,
        }),
        () => {
          tempPlanDetailsByPlanId = transformBackToAPIType(
            tempPlanDetailsByPlanId
          );
          // Trigger the simulation with the updated plan details
          triggerSimulatePlanDetailsByPlanId({
            displayTermChanged: false,
            chrono: {},
            plannerFormReq: {
              ...tempPlanDetailsByPlanId,
              [tab]: tabModule,
            },
            version: 'v2',
          });
        }
      );
    }
  };

  onClickDismiss = (key) => () => {
    const { closeSnackbar } = this.props;
    closeSnackbar(key);
  };

  handleDisplayToast = (from) => {
    const { enqueueSnackbar } = this.props;
    const toasterMsg = from
      ? 'Simulate to see the impact. Save to make changes permanent for your next login.'
      : 'Updated Plan details, click on Simulate';
    this.key = enqueueSnackbar(toasterMsg, {
      variant: 'success',
      autoHideDuration: 5000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => <CloseIcon onClick={this.onClickDismiss(key)} />,
    });
  };

  updateExpenseDetailsInRedux = (expenseDetails) => {
    const { triggerUpdatePlannerDetailsinRedux } = this.props;
    const { isLastStep, localPlanDetailsByPlanId } = this.state;
    const { moduleFamily = {} } = localPlanDetailsByPlanId;
    const { rentalPercent, ...restExpenseDetails } = expenseDetails;
    const newExpenseDetails = {
      ...restExpenseDetails,
      disc: Object.keys(expenseDetails.disc).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: false,
        };
      }, {}),
      retFactor: Object.keys(expenseDetails.retFactor).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: rentalPercent,
        };
      }, {}),
    };
    const newPlanDetails = {
      ...localPlanDetailsByPlanId,
      moduleFamily: {
        ...moduleFamily,
        infoExpenses: {
          ...newExpenseDetails,
        },
      },
    };
    this.setState({ localPlanDetailsByPlanId: newPlanDetails }); //keeping local state in sync when updating redux
    triggerUpdatePlannerDetailsinRedux({
      plannerData: newPlanDetails,
    });
  };

  getAutoOptions = (localPlanDetailsByPlanId) => {
    const { moduleFamily: { infoVehicles = {} } = {} } =
      localPlanDetailsByPlanId;
    const tempAutoRadioOptions = [];
    const addAutoRadioOptions = (vehicles) => {
      if (Array.isArray(vehicles) && vehicles.length > 0) {
        vehicles.forEach((vehicle, index) => {
          tempAutoRadioOptions.push({
            key: `vehicle_${index}`,
            label: vehicle.name, // Replace with the actual property that should be used as 'id'
            value: vehicle.name, // Replace with the actual property that should be used as 'title'
          });
        });
      }
    };
    infoVehicles && addAutoRadioOptions(infoVehicles.vehicles);
    return tempAutoRadioOptions;
  };

  getPropertyOptions = (localPlanDetailsByPlanId) => {
    const {
      moduleInvestments: {
        primaryHome = {},
        secondHomes = {},
        invProps = {},
      } = {},
    } = localPlanDetailsByPlanId;
    let tempPropertyRadioOptions = [];
    // Define a function to add options to tempPropertyRadioOptions
    const addOptions = (items, prefix) => {
      if (items && items.length > 0) {
        tempPropertyRadioOptions.push(
          ...items.map((item, index) => ({
            key: `${prefix}_${index}`,
            label: item.name,
            value: item.name,
          }))
        );
      }
    };
    // Calculate tempPropertyRadioOptions for primaryHome
    if (primaryHome && primaryHome.prop) {
      const { name } = primaryHome.prop;
      tempPropertyRadioOptions.push({
        key: 'primaryHome',
        label: name,
        value: name,
      });
    }
    // Calculate tempPropertyRadioOptions for secondaryHomes
    secondHomes && addOptions(secondHomes.props, 'secondaryHome'); // revert this after checking with Reva

    // Calculate tempPropertyRadioOptions for invProps
    if (invProps && invProps.props && Array.isArray(invProps.props)) {
      // Loop through the props array and access the name property in each prop object
      invProps.props.forEach((item, index) => {
        if (item && item.prop && item.prop.name) {
          tempPropertyRadioOptions.push({
            key: `invProps_${index}`,
            label: item.prop.name,
            value: item.prop.name,
          });
        }
      });
    }

    return tempPropertyRadioOptions;
  };

  getMemberOptions = (localPlanDetailsByPlanId) => {
    const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let tempMemberOptions = [];
    if (
      basicsFirst &&
      Object.keys(basicsFirst).length > 0 &&
      basicsFirst.age > 0 &&
      basicsFirst.name
    ) {
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'first')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'first',
          id: basicsFirst.name,
          title: basicsFirst.name,
          isSelected: false,
        });
      }
    }
    if (
      basicsSecond &&
      Object.keys(basicsSecond).length > 0 &&
      basicsSecond.age > 0 &&
      basicsSecond.name
    ) {
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'second')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'second',
          id: basicsSecond.name,
          title: basicsSecond.name,
          isSelected: false,
        });
      }
    }
    this.setState({ memberOptions: tempMemberOptions });
    // return tempMemberOptions;
  };

  updateAssetMixInRedux = (plaidReq) => {
    const { triggerUpdatePlannerDetailsinRedux, planDetailsByPlanId } =
      this.props;
    let tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
    };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      planDetailsByPlanId;
    const { invbalanceReq, invValueAsOf } = plaidReq;
    const { first = {}, second = {} } = invbalanceReq || {};
    let tempMarkets = {
      ...markets,
      first,
      second,
      invValueAsOf,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState({ localPlanDetailsByPlanId: tempPlanDetailsByPlanId }); //keeping local state in sync when updating redux
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
  };

  saveAssetMixToPlannerReq = (plaidReq) => {
    const { triggerSaveAndUpdatePlanDetailsbyPlanId, planDetailsByPlanId } =
      this.props;
    let tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
    };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      planDetailsByPlanId;
    const { invbalanceReq, invValueAsOf } = plaidReq;
    const { first = {}, second = {} } = invbalanceReq || {};
    let tempMarkets = {
      ...markets,
      first,
      second,
      invValueAsOf,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState({ localPlanDetailsByPlanId: tempPlanDetailsByPlanId }); //keeping local state in sync when updating redux
    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({
      version,
      tempPlanDetailsByPlanId,
    });
  };

  simulateAssetMixToPlannerReq = (plaidReq) => {
    const { triggerSimulatePlanDetailsByPlanId, planDetailsByPlanId } =
      this.props;
    let tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
    };
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = planDetailsByPlanId;
    const { invbalanceReq, invValueAsOf, isMC } = plaidReq;
    const { first = {}, second = {} } = invbalanceReq || {};
    let tempModel = {};
    const runCode = isMC ? 100 : 0;
    tempModel = { ...model, runCode: runCode };
    let tempMarkets = {
      ...markets,
      first,
      second,
      invValueAsOf,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    this.setState({ localPlanDetailsByPlanId: tempPlanDetailsByPlanId }); //keeping local state in sync when updating redux
    tempPlanDetailsByPlanId = transformBackToAPIType(tempPlanDetailsByPlanId);
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  updateInvValueAsOfInStore = (event, from) => {
    let newInvValueAsOf = from
      ? 'now'
      : event && event.target
      ? event.target.value
      : 'now';
    const { triggerUpdatePlannerDetailsinRedux, planDetailsByPlanId } =
      this.props;
    let tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
    };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      planDetailsByPlanId;
    let tempMarkets = {
      ...markets,
      invValueAsOf: newInvValueAsOf,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState({ localPlanDetailsByPlanId: tempPlanDetailsByPlanId }); //keeping local state in sync when updating redux
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
  };

  setIsLastStep = (val) => {
    this.setState({ isLastStep: val });
  };

  // setNameValidationErrorFn = (val) => {
  //   this.setState({ nameValidationError: val });
  // };

  setValidationError = (isValid) => {
    this.setState({ isFormValid: isValid });
  };

  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({
      expandedPanel: isExpanded ? panel : false,
      isEditable: false,
      isComputed: false,
      selectedObjValue: '',
    });
  };

  renderContainer = () => {
    const {
      renderMainType,
      leftParentTabValue,
      leftSubTabValue,
      prevLeftParentTabValue,
      prevLeftSubTabValue,
      tabInfo,
      localPlanDetailsByPlanId,
      isComputed,
      formDetails,
      membersCount,
      memberOptionsForInvestments,
      memberOptions,
      isLastStep,
      isAddNew,
      isEditable,
      formErrors,
      isFormValid,
      expandedPanel,
    } = this.state;
    const {
      alltaxSlabs,
      allMenuItems,
      planDetailsByPlanId,
      expenseFactorDetails,
      setPlaidLinkSuccessAction,
      plaidLinkSuccess,
      setPlaidLinkAccessToken,
      plaidLinkAccessToken,
      investmentProducts,
      userDetails,
    } = this.props;
    const {
      moduleFamily: { infoExpenses = {} } = {},
      moduleInvestments: { markets = {} } = {},
    } = planDetailsByPlanId;
    const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId; // reading infoCore from localPlanDetails cos local state gets updated when changed age in core tab
    const { first = {}, second = {}, invValueAsOf } = markets;
    const {
      genderMenuItems = [],
      compoundingMenuItems = [],
      horizonMenuOptions = [],
      displayTermMenuOptions = [],
      taxFilingStatusMenuItems = [],
    } = allMenuItems;
    // const memberOptions = this.getMemberOptions(localPlanDetailsByPlanId);
    const autoOptions = this.getAutoOptions(localPlanDetailsByPlanId);
    const taxStateVals = transformTaxStateOptions(alltaxSlabs);
    switch (renderMainType.toUpperCase()) {
      case 'BUILD':
        switch (leftParentTabValue) {
          case 0:
            let taxStatusOptions = taxFilingStatusMenuItems || [];
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildFamilyPersonalComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    taxStatusOptions={taxStatusOptions}
                    genderMenuItems={genderMenuItems}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );

              // return (
              //   <Journey
              //     updateExpenseDetailsInRedux={
              //       this.updateExpenseDetailsInRedux
              //     }
              //     infoCore={infoCore}
              //     storeValues={getInfoExpenseStoreValuesV2(
              //       infoExpenses,
              //       expenseFactorDetails
              //     )}
              //     isV2={true}
              //     setLastStepInParent={this.setIsLastStep}
              //   />
              // );
              case 1:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleFamily',
                          'infoKids',
                          'kids'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildFamilyKidsComponent
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    setValidationError={this.setValidationError}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );

              default:
                return;
            }

          case 1:
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildInvestmentsMarketsComponent
                    userDetails={userDetails}
                    plaidLinkSuccess={plaidLinkSuccess}
                    plaidLinkAccessToken={plaidLinkAccessToken}
                    setPlaidLinkAccessToken={setPlaidLinkAccessToken}
                    setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
                    updateAssetMixInRedux={this.updateAssetMixInRedux}
                    simulateAssetMixToPlannerReq={
                      this.simulateAssetMixToPlannerReq
                    }
                    storeValues={{ first, second }}
                    invValueAsOf={invValueAsOf}
                    members={memberOptionsForInvestments}
                    investmentProducts={investmentProducts}
                    updateInvValueAsOfInStore={this.updateInvValueAsOfInStore}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );

              case 1:
                return (
                  <BuildInvestmentsOtherComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    taxStateOptions={taxStateVals}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              default:
                return;
            }
          case 2:
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildIncomeWagesComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 1:
                return (
                  <BuildIncomeInvestmentComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 2:
                return (
                  <BuildIncomeSocialSecurityComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 3:
                return (
                  <BuildIncomePensionComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 4:
                return (
                  <BuildIncomeDeferredCompensationComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 5:
                return (
                  <BuildIncomeOtherIncomeComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    taxStateOptions={taxStateVals}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              default:
                break;
            }
            break;
          case 3:
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildSavingsPensionComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );

              case 1:
                return (
                  <BuildSavings401kANDIRAComponents
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 2:
                return (
                  <BuildSavingsEmergencyFundsComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 3:
                return (
                  <BuildSavingsDeferredCompensationComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setValidationError={this.setValidationError}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 4:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleFamily',
                          'infoKids',
                          'kids'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildSavings529PlansComponent
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    setValidationError={this.setValidationError}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 5:
                return (
                  <BuildSavingsOtherComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              default:
                return;
            }
          case 4:
            switch (leftSubTabValue) {
              case 0:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleFamily',
                          'infoKids',
                          'kids'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildExpensesKidsComponent
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    setValidationError={this.setValidationError}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 1:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const vehiclesFormDetails = this.updateFormDetails(
                    formDetails,
                    'moduleFamily',
                    'infoVehicles',
                    'vehicles'
                  );
                  const autoInsFormDetails = this.updateFormDetails(
                    vehiclesFormDetails,
                    'moduleInsurance',
                    'auto',
                    'autoIns'
                  );
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : { ...vehiclesFormDetails, ...autoInsFormDetails };

                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildExpensesVehiclesComponent
                    renderBuildVehiclesComponent={
                      this.renderBuildVehiclesComponent
                    }
                    renderBuildVehicleInsuranceComponent={
                      this.renderBuildVehicleInsuranceComponent
                    }
                    handleAccordionChange={this.handleAccordionChange}
                    expandedPanel={expandedPanel}
                    state={this.state}
                    allMenuItems={this.props.allMenuItems}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 2:
                return (
                  <BuildExpensesInsurancesComponent
                    renderPropertyInsuranceComponent={
                      this.renderPropertyInsuranceComponent
                    }
                    renderHealthCareInsuranceComponent={
                      this.renderHealthCareInsuranceComponent
                    }
                    renderTermLifeInsuranceComponent={
                      this.renderTermLifeInsuranceComponent
                    }
                    renderWholeLifeInsuranceComponent={
                      this.renderWholeLifeInsuranceComponent
                    }
                    renderUmbrellaLifeInsuranceComponent={
                      this.renderUmbrellaLifeInsuranceComponent
                    }
                    renderLongTermCareInsuranceComponent={
                      this.renderLongTermCareInsuranceComponent
                    }
                    handleAccordionChange={this.handleAccordionChange}
                    expandedPanel={expandedPanel}
                    state={this.state}
                    allMenuItems={this.props.allMenuItems}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    memberOptions={memberOptions}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              // case 3:
              //   if (
              //     localPlanDetailsByPlanId &&
              //     Object.keys(localPlanDetailsByPlanId).length > 0 &&
              //     !isComputed
              //   ) {
              //     const tempFormDetails =
              //       isAddNew || isEditable
              //         ? formDetails
              //         : this.updateFormDetails(
              //             formDetails,
              //             'moduleDebt',
              //             'infoCreditCards',
              //             'creditCards'
              //           );
              //     this.setState({
              //       deleteInsurance: false,
              //       formDetails: tempFormDetails,
              //       isComputed: true,
              //     });
              //   }
              //   return (
              //     <BuildExpensesCreditCardsComponent
              //       state={this.state}
              //       allMenuItems={this.props.allMenuItems}
              //       concatContent={this.concatContent}
              //       onAccountChange={this.onAccountChange}
              //       handleEdit={this.handleEdit}
              //       handleDelete={this.handleDelete}
              //       handleCancel={this.handleCancel}
              //       localPlanDetailsByPlanId={localPlanDetailsByPlanId}
              //       setEditStateValues={this.setEditStateValues}
              //       setLocalPlanDetailsByPlanId={
              //         this.setLocalPlanDetailsByPlanId
              //       }
              //       setFormDetails={this.setFormDetails}
              //       triggerSimulatePlanDetailsByPlanId={
              //         this.props.triggerSimulatePlanDetailsByPlanId
              //       }
              //       triggerUpdatePlannerDetailsinRedux={
              //         this.props.triggerUpdatePlannerDetailsinRedux
              //       }
              //       setLastStepInParent={this.setIsLastStep}
              //       formErrors={formErrors}
              //       isFormValid={isFormValid}
              //     />
              //   );
              case 3:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleDebt',
                          'infoStudentLoans',
                          'studentLoans'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildExpensesLoansComponent
                    handleAccordionChange={this.handleAccordionChange}
                    expandedPanel={expandedPanel}
                    renderStudentLoansComponent={
                      this.renderStudentLoansComponent
                    }
                    renderCreditCardsComponent={this.renderCreditCardsComponent}
                    renderOtherLoansComponent={this.renderOtherLoansComponent}
                    state={this.state}
                    allMenuItems={this.props.allMenuItems}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 4:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleFamily',
                          'infoVacations',
                          'vacations'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildExpensesVacationsComponent
                    state={this.state}
                    allMenuItems={this.props.allMenuItems}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 5:
                return (
                  <BuildExpensesLivingComponent
                    updateExpenseDetailsInRedux={
                      this.updateExpenseDetailsInRedux
                    }
                    infoCore={infoCore}
                    storeValues={getInfoExpenseStoreValuesV2(
                      infoExpenses,
                      expenseFactorDetails
                    )}
                    isV2={true}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );

              default:
                return;
            }

          case 5:
            switch (leftSubTabValue) {
              case 0:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleInvestments',
                          'primaryHome',
                          'prop'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildPropertiesPrimaryHome
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    taxStateOptions={taxStateVals}
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 1:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleInvestments',
                          'secondHomes',
                          'props'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildPropertiesSecondHomes
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    taxStateOptions={taxStateVals}
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              case 2:
                if (
                  localPlanDetailsByPlanId &&
                  Object.keys(localPlanDetailsByPlanId).length > 0 &&
                  !isComputed
                ) {
                  const tempFormDetails =
                    isAddNew || isEditable
                      ? formDetails
                      : this.updateFormDetails(
                          formDetails,
                          'moduleInvestments',
                          'invProps',
                          'props'
                        );
                  this.setState({
                    deleteInsurance: false,
                    formDetails: tempFormDetails,
                    isComputed: true,
                  });
                }
                return (
                  <BuildPropertiesInvestment
                    state={this.state}
                    concatContent={this.concatContent}
                    onAccountChange={this.onAccountChange}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleCancel={this.handleCancel}
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    taxStateOptions={taxStateVals}
                    setFormDetails={this.setFormDetails}
                    triggerSimulatePlanDetailsByPlanId={
                      this.props.triggerSimulatePlanDetailsByPlanId
                    }
                    triggerUpdatePlannerDetailsinRedux={
                      this.props.triggerUpdatePlannerDetailsinRedux
                    }
                    setLastStepInParent={this.setIsLastStep}
                    formErrors={formErrors}
                    isFormValid={isFormValid}
                  />
                );
              default:
                return;
            }

          case 6:
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildEstatePlanningRothComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setEditStateValues={this.setEditStateValues}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 1:
                return (
                  <BuildEstatePlanningHeirsComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    taxStateOptions={taxStateVals}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              default:
                return;
            }

          case 7:
            switch (leftSubTabValue) {
              case 0:
                return (
                  <BuildSettingsModelComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    displayTermMenuOptions={displayTermMenuOptions}
                    horizonMenuOptions={horizonMenuOptions}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 1:
                return (
                  <BuildSettingsTaxesComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              case 2:
                return (
                  <BuildSettingsAlertsComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
              default:
                return (
                  <BuildSettingsModelComponent
                    localPlanDetailsByPlanId={localPlanDetailsByPlanId}
                    setLocalPlanDetailsByPlanId={
                      this.setLocalPlanDetailsByPlanId
                    }
                    displayTermMenuOptions={displayTermMenuOptions}
                    horizonMenuOptions={horizonMenuOptions}
                    state={this.state}
                    setLastStepInParent={this.setIsLastStep}
                  />
                );
            }
          default:
            return null;
        }
        break;
      default:
        return null;
    }
  };

  setEditStateValues = (editMember, memberName) => {
    if (memberName === 'basicsFirst') {
      this.setState({
        memberOne: editMember,
      });
    }

    if (memberName === 'basicsSecond') {
      this.setState({
        memberTwo: editMember,
      });
    }
  };

  setLocalPlanDetailsByPlanId = (
    newPlanDetails,
    ageModifiedOrMemberDeleted,
    newFormDetails,
    formField
  ) => {
    let tempPlanDetailsByPlanId = { ...newPlanDetails };
    if (ageModifiedOrMemberDeleted === true) {
      tempPlanDetailsByPlanId = this.membersCount(tempPlanDetailsByPlanId);
      this.membersRetired(tempPlanDetailsByPlanId);
    }
    if (newFormDetails && Object.keys(newFormDetails).length > 0) {
      const firstObject = newFormDetails[formField];
      let name =
        firstObject && Object.keys(firstObject).length > 0
          ? firstObject.name
          : this.state.selectedObjValue;
      if (
        formField === 'props' &&
        firstObject &&
        Object.keys(firstObject).length &&
        firstObject['prop'] !== undefined
      ) {
        name = firstObject['prop']?.name
          ? firstObject['prop']?.name
          : this.state.selectedObjValue;
      }
      this.setState({
        formDetails: newFormDetails,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        isComputed: false,
        selectedObjValue: name,
      });
    } else {
      this.setState({
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        isComputed: false,
      });
    }
  };

  setMemberOptions = (memberOptions) => {
    this.setState({ memberOptions });
  };

  setFormDetails = (newFormDetails) => {
    this.setState({
      formDetails: newFormDetails,
      isComputed: false,
    });
  };

  membersRetired = (localPlanDetailsByPlanId) => {
    const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let retired = false;
    let isFirstPersonRetired = false;
    let isSecondPersonRetired = false;
    if (
      Object.keys(basicsFirst).length > 0 &&
      Object.keys(basicsSecond).length > 0
    ) {
      if (
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] >= basicsFirst['retirementAge'] &&
        basicsSecond['age'] &&
        basicsSecond['retirementAge'] &&
        basicsSecond['age'] >= basicsSecond['retirementAge']
      ) {
        retired = true;
        isFirstPersonRetired = true;
        isSecondPersonRetired = true;
      } else if (
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = false;
        isFirstPersonRetired = true;
      } else if (
        basicsSecond['age'] &&
        basicsSecond['retirementAge'] &&
        basicsSecond['age'] >= basicsSecond['retirementAge']
      ) {
        retired = false;
        isSecondPersonRetired = true;
      }
    } else if (
      Object.keys(basicsFirst).length === 0 ||
      Object.keys(basicsSecond).length === 0
    ) {
      if (
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] > 0 &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = true;
        isFirstPersonRetired = true;
      } else if (
        basicsSecond['age'] &&
        basicsSecond['retirementAge'] &&
        basicsSecond['age'] > 0 &&
        basicsSecond['age'] >= basicsSecond['retirementAge']
      ) {
        retired = true;
        isSecondPersonRetired = true;
      }
    }
    this.setState({
      retiredState: retired,
      isFirstPersonRetired,
      isSecondPersonRetired,
    });
  };

  membersCount = (localPlanDetailsByPlanId) => {
    const { moduleFamily: { infoCore = {} } = {}, moduleFamily = {} } =
      localPlanDetailsByPlanId;
    const {
      basicsFirst = {},
      basicsSecond = {},
      taxFilingStatus = '',
    } = infoCore;
    let membersCount = 0;
    if (
      basicsFirst &&
      Object.keys(basicsFirst).length > 0 &&
      basicsFirst.age > 0 &&
      basicsFirst.name
    ) {
      membersCount += 1;
      if (
        (basicsSecond &&
          Object.keys(basicsSecond).length > 0 &&
          basicsSecond.age > 0 &&
          basicsSecond.name) ||
        taxFilingStatus.toLowerCase() === 'mfj'
      ) {
        membersCount += 1;
      }
    }
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    if (membersCount === 1) {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: { ...infoCore, taxFilingStatus: 'Single' },
        },
      };
    } else if (
      membersCount === 2 &&
      (taxFilingStatus.toLowerCase() === 'single' || taxFilingStatus === '')
    ) {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: { ...infoCore, taxFilingStatus: 'MFJ' },
        },
      };
    }
    this.setState({
      membersCount: membersCount,
    });
    return tempPlanDetailsByPlanId;
  };

  loadSettingModelTabData = () => {};

  getIfUserTypeIsDirect = () => {
    const { location: { state = {} } = {} } = this.props;
    let isDirectUser = false;
    if (state) {
      ({ isDirectUser } = state);
    }
    return isDirectUser;
  };

  getIfUserTypeIsClient = () => {
    const { location: { state = {} } = {} } = this.props;
    let isClientUser = false;
    if (state) {
      ({ isClientUser } = state);
    }
    return isClientUser;
  };

  getMemberOptionsForInvestments = (tempPlanDetailsByPlanId) => {
    const { moduleFamily: { infoCore = {} } = {} } = tempPlanDetailsByPlanId;
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let tempMemberOptions = [];
    if (
      basicsFirst &&
      Object.keys(basicsFirst).length > 0 &&
      basicsFirst.age > 0 &&
      basicsFirst.name
    ) {
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'first')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'first',
          id: 'first',
          title: basicsFirst.name,
        });
      }
    }
    if (
      basicsSecond &&
      Object.keys(basicsSecond).length > 0 &&
      basicsSecond.age > 0 &&
      basicsSecond.name
    ) {
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'second')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'second',
          id: 'second',
          title: basicsSecond.name,
        });
      }
    }
    this.setState({
      memberOptionsForInvestments: tempMemberOptions,
    });
  };

  getClientTabData = (mainTab) => {
    const { localPlanDetailsByPlanId, formDetails } = this.state;
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
      clientid,
    } = planDetailsByPlanId;
    let tempModel = { ...model, runCode: 0 };
    let tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    tempPlanDetailsByPlanId = this.membersCount(tempPlanDetailsByPlanId);
    this.membersRetired(tempPlanDetailsByPlanId);
    this.getMemberOptionsForInvestments(tempPlanDetailsByPlanId);
    this.getMemberOptions(tempPlanDetailsByPlanId);
    let filteredTabs = [];
    let tabDetails = [];
    filteredTabs = BuildTabComponent.filter(
      (tab) => tab.mainTab.toLowerCase() === mainTab.toLowerCase()
    );
    if (filteredTabs.length > 0) {
      tabDetails = filteredTabs
        ? filteredTabs[0].data?.map((c) => {
            return {
              tabName: c.tabName,
              subMenu: c.subtabs.map((subtab) => ({ subtabName: subtab })),
            };
          })
        : [];
    }
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length <= 0
    ) {
      if (!isEqual(tempPlanDetailsByPlanId, planDetailsByPlanId)) {
        triggerSimulatePlanDetailsByPlanId({
          displayTermChanged: false,
          chrono: {},
          plannerFormReq: tempPlanDetailsByPlanId,
          version: 'v2',
        });
      }
    }
    this.setState({
      tabInfo: tabDetails,
      localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
    });
  };

  handleRightSubTabChange = (event, value) => {
    const {
      triggerSetSelectedChart,
      triggerSetSelectedTable,
      triggerSetSelectedData,
    } = this.props;
    const { rightParentTabValue, rightTabInfo, renderType } = this.state;
    const selectedItem =
      (rightTabInfo[rightParentTabValue] &&
        rightTabInfo[rightParentTabValue].subMenu &&
        rightTabInfo[rightParentTabValue].subMenu.length > 0 &&
        rightTabInfo[rightParentTabValue].subMenu[value].subtabName) ||
      '';
    switch (renderType) {
      case 'Chart':
        triggerSetSelectedChart(selectedItem);
        break;
      case 'Table':
        triggerSetSelectedTable(selectedItem);
        break;
      case 'Data':
        triggerSetSelectedData(selectedItem);
        break;
      default:
        break;
    }
    this.setState({
      rightSubTabValue: value,
      isEditable: false,
    });
  };

  handleLeftSubTabChange = (event, value) => {
    this.setState({
      leftSubTabValue: value,
      isComputed: false,
      isEditable: false,
      isAddNew: false,
      selectedObjValue: '',
      selectedDDNameIndex: 0,
      isFormValid: true,
      formErrors: [],
      expandedPanel: false,
      isExpanded: false,
    });
  };

  handleLeftParentTabChange = (event, value) => {
    this.setState({
      leftParentTabValue: value,
      leftSubTabValue: 0,
      isComputed: false,
      isEditable: false,
      isAddNew: false,
      selectedObjValue: '',
      selectedDDNameIndex: 0,
      isFormValid: true,
      formErrors: [],
      expandedPanel: false,
      isExpanded: false,
    });
  };

  renderLeftSubMenuContainer = () => {
    const { leftSubTabValue, leftParentTabValue, tabInfo } = this.state;
    const subMenus = tabInfo[leftParentTabValue]
      ? tabInfo[leftParentTabValue].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={leftSubTabValue}
          onChange={this.handleLeftSubTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.subtabName}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
                key={index}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  updateRenderMainType = (e, renderMainType) => {
    this.setState({
      renderMainType,
      leftParentTabValue: 0,
      leftSubTabValue: 0,
      tabInfo: [],
    });
  };

  renderChartContainer = () => {
    const { charts, selectedChart, triggerPushPinCharts } = this.props;
    const {
      pushPinLabel,
      rightParentTabValue,
      rightSubTabValue,
      rightTabInfo,
    } = this.state;
    if (charts && charts.length > 0) {
      const chartsData =
        (rightTabInfo[rightParentTabValue] &&
          rightTabInfo[rightParentTabValue].subMenu &&
          rightTabInfo[rightParentTabValue].subMenu.length > 0 &&
          rightTabInfo[rightParentTabValue].subMenu[rightSubTabValue]) ||
        {};
      return (
        <div className="client_chart_main_div Chart">
          <div className="client_chart_inner">
            <NewChartsContainer
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
          <div className="client_chart_inner">
            <NewChartsContainer
              isAux={true}
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
        </div>
      );
    } else {
      //return <div className="client_chart_main_div">No data to display</div>;
      return <div className="client_chart_main_div"></div>;
    }
  };

  renderCustomTable = (renderWhatData) => {
    const { tables } = this.props;
    return <CustomTable tables={tables} renderWhatData={renderWhatData} />;
  };

  renderCustomData = (renderWhatData) => {
    const { data } = this.props;
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  deriveTableAuxAndMain = (tablesData) => {
    const { subtabType } = tablesData || {};
    const { [subtabType]: { aux = [], main = [] } = {} } = tablesData || {};
    const auxAndMain = { aux, main };
    return auxAndMain;
  };

  renderTableContainer = () => {
    const { tables, selectedTable } = this.props;
    const { rightParentTabValue, rightSubTabValue, rightTabInfo } = this.state;
    if (tables && tables.length > 0) {
      const parentTabName =
        (rightTabInfo[rightParentTabValue] &&
          rightTabInfo[rightParentTabValue].tabName) ||
        '';
      const tablesData =
        (rightTabInfo[rightParentTabValue] &&
          rightTabInfo[rightParentTabValue].subMenu &&
          rightTabInfo[rightParentTabValue].subMenu.length > 0 &&
          rightTabInfo[rightParentTabValue].subMenu[rightSubTabValue]) ||
        {};
      const auxAndMain = this.deriveTableAuxAndMain(tablesData);
      const { aux = [], main = [] } = auxAndMain;
      if ((main && main.length > 0) || (aux && aux.length > 0)) {
        return (
          <div className="client_chart_main_div Table">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {main.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {aux.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
          </div>
        );
      } else {
        //return <div className="client_chart_main_div">No data to display</div>;
        return <div className="client_chart_main_div"></div>;
      }
    }
  };

  renderDataContainer = () => {
    const { data, selectedData } = this.props;
    const { rightParentTabValue, rightSubTabValue, rightTabInfo } = this.state;
    if (data && data.length > 0) {
      const dataInfo =
        (rightTabInfo[rightParentTabValue] &&
          rightTabInfo[rightParentTabValue].subMenu &&
          rightTabInfo[rightParentTabValue].subMenu.length > 0 &&
          rightTabInfo[rightParentTabValue].subMenu[rightSubTabValue]) ||
        {};
      const { subtabName, subtabType, build, input } = dataInfo || {};
      let mainData = [];
      let auxData = [];
      if (subtabType === 'input' && input) {
        const {
          main: { heading, rows = [] },
          aux,
        } = input;
        if (aux && aux.rows && aux.rows.length > 0) {
          auxData = [
            {
              name: aux.heading,
              elements: aux.rows.filter((a) => a && a.label && a.value),
            },
          ];
        }
        mainData =
          rows && rows.length > 0
            ? [
                {
                  name: heading,
                  elements: rows.filter((r) => r && r.label && r.value),
                },
              ]
            : [];
      } else if (subtabType === 'build' && build) {
        const { main = [], aux = [] } = build;
        const tempMainData =
          main &&
          main.filter(
            (m) => m.elements && m.elements.find((v) => v.label && v.value)
          );
        mainData =
          tempMainData.length > 0
            ? tempMainData.map((m, i) => {
                return {
                  name: m.name || 'Heading',
                  elements: m.elements.filter((v) => v.label && v.value),
                };
              })
            : [];
        const tempAuxData =
          (aux &&
            aux.filter(
              (m) =>
                m && m.elements && m.elements.find((v) => v.label && v.value)
            )) ||
          [];
        auxData =
          tempAuxData.length > 0
            ? aux.map((m, i) => {
                return {
                  name: m.name || 'Heading',
                  elements: m.elements,
                };
              })
            : [];
      }
      if (mainData.length > 0 || auxData.length > 0) {
        return (
          <div className="client_chart_main_div">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {mainData.map((val, index) => {
                  return (
                    <table
                      className={
                        mainData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {auxData.map((val, index) => {
                  return (
                    <table
                      className={
                        auxData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else {
        //return <div className="client_chart_main_div">No data to display</div>;
        return <div className="client_chart_main_div"></div>;
      }
    }
  };

  renderCustomChart = (renderWhatData) => {
    const { charts } = this.props;
    return <CustomChart charts={charts} renderWhatData={renderWhatData} />;
  };

  renderCustomContentContainer = ({ renderTabType, data }) => {
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data);
      case 'table':
        return this.renderCustomTable(data);
      case 'data':
        return this.renderCustomData(data);
      default:
        break;
    }
  };

  deriveAuxMainAndProfile = () => {
    const { build } = this.props;
    const { leftParentTabValue, leftSubTabValue, renderMainType } = this.state;
    let aux = {};
    let main = {};
    let profile = {};
    switch (renderMainType.toLowerCase()) {
      case 'build':
        switch (leftParentTabValue) {
          case 0:
            const {
              family: {
                core = {},
                expenses = {},
                kids = {},
                vacations = {},
                vehicles = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 0:
              case 1:
              case 2:
                aux = core.aux || {};
                main = core.main || {};
                profile = core.profile || {};
                break;
              case 3:
                aux = expenses.aux || {};
                main = expenses.main || {};
                profile = expenses.profile || {};
                break;
              case 4:
                aux = kids.aux || {};
                main = kids.main || {};
                profile = kids.profile || {};
                break;
              case 5:
                aux = vehicles.aux || {};
                main = vehicles.main || {};
                profile = vehicles.profile || {};
                break;
              case 6:
                aux = vacations.aux || {};
                main = vacations.main || {};
                profile = vacations.profile || {};
                break;
              default:
                break;
            }
            break;
          case 1:
            const {
              investments: {
                markets = {},
                primaryHome = {},
                secondHomes = {},
                investmentProps = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 0:
                aux = markets.aux || {};
                main = markets.main || {};
                profile = markets.profile || {};
                break;
              case 1:
                aux = primaryHome.aux || {};
                main = primaryHome.main || {};
                profile = primaryHome.profile || {};
                break;
              case 2:
                aux = secondHomes.aux || {};
                main = secondHomes.main || {};
                profile = secondHomes.profile || {};
                break;
              case 3:
                aux = investmentProps.aux || {};
                main = investmentProps.main || {};
                profile = investmentProps.profile || {};
                break;
              default:
                break;
            }
            break;
          case 2:
            const {
              debt: {
                creditCards = {},
                studentLoans = {},
                otherLoans = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 0:
                aux = creditCards.aux || {};
                main = creditCards.main || {};
                profile = creditCards.profile || {};
                break;
              case 1:
                aux = studentLoans.aux || {};
                main = studentLoans.main || {};
                profile = studentLoans.profile || {};
                break;
              case 2:
                aux = otherLoans.aux || {};
                main = otherLoans.main || {};
                profile = otherLoans.profile || {};
                break;
              default:
                break;
            }
            break;
          case 3:
            const {
              insurance: {
                termLife = {},
                wholeLife = {},
                longTermCare = {},
                umbrella = {},
                property = {},
                auto = {},
                health = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 0:
                aux = termLife.aux || {};
                main = termLife.main || {};
                profile = termLife.profile || {};
                break;
              case 1:
                aux = wholeLife.aux || {};
                main = wholeLife.main || {};
                profile = wholeLife.profile || {};
                break;
              case 2:
                aux = longTermCare.aux || {};
                main = longTermCare.main || {};
                profile = longTermCare.profile || {};
                break;
              case 3:
                aux = umbrella.aux || {};
                main = umbrella.main || {};
                profile = umbrella.profile || {};
                break;
              case 4:
                aux = property.aux || {};
                main = property.main || {};
                profile = property.profile || {};
                break;
              case 5:
                aux = auto.aux || {};
                main = auto.main || {};
                profile = auto.profile || {};
                break;
              case 6:
                aux = health.aux || {};
                main = health.main || {};
                profile = health.profile || {};
                break;
              default:
                break;
            }
            break;
          case 4:
            const { estate: { heirs = {}, roth = {} } = {} } = build;
            switch (leftSubTabValue) {
              case 0:
                aux = roth.aux || {};
                main = roth.main || {};
                profile = roth.profile || {};
                break;
              case 1:
                aux = heirs.aux || {};
                main = heirs.main || {};
                profile = heirs.profile || {};
                break;
              default:
                break;
            }
            break;
          case 5:
            const {
              settings: {
                alerts = {},
                cashFlows = {},
                emergencyFunds = {},
                model = {},
                taxes = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 0:
                aux = model.aux || {};
                main = model.main || {};
                profile = model.profile || {};
                break;
              case 1:
                aux = cashFlows.aux || {};
                main = cashFlows.main || {};
                profile = cashFlows.profile || {};
                break;
              case 2:
                aux = emergencyFunds.aux || {};
                main = emergencyFunds.main || {};
                profile = emergencyFunds.profile || {};
                break;
              case 3:
                aux = taxes.aux || {};
                main = taxes.main || {};
                profile = taxes.profile || {};
                break;
              case 4:
                aux = alerts.aux || {};
                main = alerts.main || {};
                profile = alerts.profile || {};
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        break;
      case 'insights':
        break;
      case 'goals':
        break;
      case 'compare':
        break;
      default:
        break;
    }
    const auxMainAndProfile = { aux, main, profile };
    return auxMainAndProfile;
  };

  renderCustomContainer = () => {
    const auxMainAndProfile = this.deriveAuxMainAndProfile();
    const { aux = {}, main = {}, profile = {} } = auxMainAndProfile;
    const { tabType: auxTabType = '' } = aux;
    const { tabType: mainTabType = '' } = main;
    const { tabType: profileTabType = '' } = profile;
    return (
      <div className="">
        {Object.keys(aux).length > 0 ||
        Object.keys(main).length > 0 ||
        Object.keys(profile).length > 0 ? (
          <div className="client_chart_main_div">
            {['profile', 'main', 'aux'].map((p) => {
              let obj = {};
              if (p === 'main') {
                obj = { renderTabType: mainTabType, data: main };
              } else if (p === 'aux') {
                obj = { renderTabType: auxTabType, data: aux };
              } else if (p === 'profile') {
                obj = { renderTabType: profileTabType, data: profile };
              }
              return (
                <div className="client_chart_inner">
                  {this.renderCustomContentContainer(obj)}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="client_chart_main_div"></div>
        )}
      </div>
    );
  };

  renderContentContainer = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        return this.renderChartContainer();
      case 'Table':
        return this.renderTableContainer();
      case 'Data':
        return this.renderDataContainer();
      case 'Custom':
        return this.renderCustomContainer();
      default:
        break;
    }
  };

  updateRenderType = (e, renderType) => {
    this.setState({
      renderType,
      rightParentTabValue: 0,
      rightSubTabValue: 0,
      rightTabInfo: [],
    });
  };

  handleSimulate = (isMC) => {
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId =
      Object.keys(localPlanDetailsByPlanId).length > 0
        ? { ...localPlanDetailsByPlanId }
        : { ...planDetailsByPlanId };
    const {
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
      moduleInvestments: { markets = {} } = {},
      moduleFamily = {},
    } = tempPlanDetailsByPlanId;
    tempPlanDetailsByPlanId = transformBackToAPIType(tempPlanDetailsByPlanId);
    let tempModel = {};
    const runCode = isMC ? 100 : 0;
    tempModel = { ...model, runCode: runCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  handleSave = () => {
    const { localPlanDetailsByPlanId } = this.state;
    let { planDetailsByPlanId, triggerSaveAndUpdatePlanDetailsbyPlanId } =
      this.props;
    let tempPlanDetailsByPlanId =
      Object.keys(localPlanDetailsByPlanId).length > 0
        ? { ...localPlanDetailsByPlanId }
        : { ...planDetailsByPlanId };
    tempPlanDetailsByPlanId = transformBackToAPIType(tempPlanDetailsByPlanId);
    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({
      version,
      planDetailsByPlanId: tempPlanDetailsByPlanId,
    });
  };

  handleRightParentTabChange = (event, value) => {
    this.setState({
      rightParentTabValue: value,
      rightSubTabValue: 0,
    });
  };

  renderRightSubMenuContainer = () => {
    const { rightSubTabValue, rightParentTabValue, rightTabInfo } = this.state;
    const subMenus = rightTabInfo[rightParentTabValue]
      ? rightTabInfo[rightParentTabValue].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={rightSubTabValue}
          onChange={this.handleRightSubTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.subtabName}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  renderUmbrellaLifeInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const umbrellaLifeInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'umbrella',
        'umbrellaIns'
      );

      const tempFormDetails =
        isAddNew || isEditable ? formDetails : umbrellaLifeInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildUmbrellaLifeInsuranceComponent
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderPropertyInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const propertyInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'property',
        'propIns'
      );

      const tempFormDetails =
        isAddNew || isEditable ? formDetails : propertyInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    const propertyOptions = this.getPropertyOptions(localPlanDetailsByPlanId);

    return (
      <BuildPropertyInsuranceComponent
        propertyOptions={propertyOptions}
        setMemberOptions={this.setMemberOptions}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderHealthCareInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const healthCareLifeInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'health',
        'healthIns'
      );

      const tempFormDetails =
        isAddNew || isEditable ? formDetails : healthCareLifeInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildHealthCareInsuranceComponent
        setMemberOptions={this.setMemberOptions}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderLongTermCareInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const umbrellaLifeInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'longTermCare',
        'ltcIns'
      );

      const tempFormDetails =
        isAddNew || isEditable ? formDetails : umbrellaLifeInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildLongTermCareInsuranceComponentV3
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderWholeLifeInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const wholeLifeInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'wholeLife',
        'wholeLifeIns'
      );

      const tempFormDetails =
        isAddNew || isEditable ? formDetails : wholeLifeInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildWholeLifeInsuranceComponent
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderCreditCardsComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const creditCardsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleDebt',
        'infoCreditCards',
        'creditCards'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : creditCardsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildExpensesCreditCardsComponent
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderOtherLoansComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const otherLoansFormDetails = this.updateFormDetails(
        formDetails,
        'moduleDebt',
        'infoOtherLoans',
        'otherLoans'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : otherLoansFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildOtherLoanComponentV3
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderStudentLoansComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const studentLoansFormDetails = this.updateFormDetails(
        formDetails,
        'moduleDebt',
        'infoStudentLoans',
        'studentLoans'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : studentLoansFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildStudentLoanComponentV3
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderBuildVehicleInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;
    const autoOptions = this.getAutoOptions(localPlanDetailsByPlanId);

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const vehiclesFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'auto',
        'autoIns'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : vehiclesFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildInsuranceComponentV3
        autoOptions={autoOptions}
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderBuildVehiclesComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const vehiclesFormDetails = this.updateFormDetails(
        formDetails,
        'moduleFamily',
        'infoVehicles',
        'vehicles'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : vehiclesFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildVehiclesComponentV3
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderTermLifeInsuranceComponent = () => {
    const {
      isAddNew,
      isEditable,
      formDetails,
      localPlanDetailsByPlanId,
      isComputed,
      expandedPanel,
      memberOptions,
      formErrors,
      isFormValid,
    } = this.state;

    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      !isComputed
    ) {
      const termLifeInsFormDetails = this.updateFormDetails(
        formDetails,
        'moduleInsurance',
        'termLife',
        'termLifeIns'
      );
      const tempFormDetails =
        isAddNew || isEditable ? formDetails : termLifeInsFormDetails;
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        isComputed: true,
      });
    }
    return (
      <BuildTermLifeInsuranceComponent
        expandedPanel={expandedPanel}
        state={this.state}
        allMenuItems={this.props.allMenuItems}
        concatContent={this.concatContent}
        onAccountChange={this.onAccountChange}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleCancel={this.handleCancel}
        localPlanDetailsByPlanId={localPlanDetailsByPlanId}
        memberOptions={memberOptions}
        setEditStateValues={this.setEditStateValues}
        setLocalPlanDetailsByPlanId={this.setLocalPlanDetailsByPlanId}
        setFormDetails={this.setFormDetails}
        triggerSimulatePlanDetailsByPlanId={
          this.props.triggerSimulatePlanDetailsByPlanId
        }
        triggerUpdatePlannerDetailsinRedux={
          this.props.triggerUpdatePlannerDetailsinRedux
        }
        setLastStepInParent={this.setIsLastStep}
        formErrors={formErrors}
        isFormValid={isFormValid}
      />
    );
  };

  renderClientContainer = () => {
    const {
      leftParentTabValue,
      renderMainType,
      tabInfo,
      rightParentTabValue,
      renderType,
      rightTabInfo,
      isLastStep,
      leftSubTabValue,
      isFormValid,
      isEditable,
      isAddNew,
      // nameValidationError,
    } = this.state;
    const { isV2, planner, allocation, simulatePlanDetailsLoading } =
      this.props;
    const { main = [] } = planner || {};
    const elements = main.length > 0 && main[0].elements;

    const { showLeftNav, showRightNav } = this.state;

    return (
      <div className="client_main product_main outer">
        <div className="carousel-container" ref={this.carouselContainerRef}>
          <div key="left" className="carousel-itemm">
            <div className="inner">
              {/* <div className="client_left product_left solution_tab_content "> */}
              <div
                className={
                  isLastStep
                    ? 'client_left product_left solution_tab_content'
                    : 'client_left product_left solution_tab_content build_action_height_full'
                }
              >
                <div className="chrono-tabs-wrapper">
                  <div className="chrono-parent-tab">
                    <Tabs
                      value={leftParentTabValue}
                      onChange={this.handleLeftParentTabChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      TabIndicatorProps={{
                        style: {
                          display: 'none',
                        },
                      }}
                    >
                      {tabInfo &&
                        tabInfo.length > 0 &&
                        tabInfo.map((item, index) => (
                          <Tab
                            iconPosition="start"
                            label={item.tabName}
                            {...a11yProps(index)}
                            sx={{ padding: '10px !important' }}
                            key={index}
                          />
                        ))}
                    </Tabs>
                    {tabInfo &&
                      tabInfo.length > 0 &&
                      this.renderLeftSubMenuContainer()}
                  </div>
                </div>

                <div className="product_left_content">
                  {this.renderContainer()}

                  {isEditable || isAddNew ? (
                    <></>
                  ) : (
                    <div className="tab_main_nav block">
                      <div className="tab_left_nav">
                        <Button
                          variant="contained"
                          onClick={this.handlePrevious}
                          disabled={
                            this.state.leftParentTabValue === 0 &&
                            this.state.leftSubTabValue === 0
                          }
                        >
                          <ArrowBackIcon /> Previous
                        </Button>
                      </div>
                      <div className="tab_right_nav">
                        <Button
                          variant="contained"
                          onClick={this.handleNext}
                          disabled={
                            this.state.leftParentTabValue ===
                              BuildTabComponent[0].data.length - 1 &&
                            this.state.leftSubTabValue ===
                              BuildTabComponent[0].data[
                                this.state.leftParentTabValue
                              ].subtabs.length -
                                1
                          }
                        >
                          Next <ArrowForwardIcon />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {isLastStep && (
                  <div className="Save_Simulate_button_outer">
                    <div className="plan_results_main_outer_right">
                      <div className="Simulate_button_group">
                        <button
                          className={
                            !isFormValid
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={!isFormValid}
                          onClick={() => this.handleSimulate(false)}
                        >
                          <img src={Simulate_icon_1} alt="" title="" /> Simulate
                        </button>
                        {/* <button
                          className={
                            !isFormValid
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={!isFormValid}
                          onClick={() => this.handleSimulate(true)}
                        >
                          <img src={Simulate_icon_2} alt="" title="" />{' '}
                          Simulate/MC
                        </button> */}
                        <button
                          className={
                            !isFormValid
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={!isFormValid}
                          onClick={this.handleSave}
                        >
                          <img src={Save_icon} alt="" title="" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="divider_line"></div>

          <div key="right" className="carousel-itemm">
            <div className="inner">
              <div className="client_right solution_tab_content">
                <div className="client_main_right">
                  <div className="client_main_right_left">
                    <TabsComponent
                      renderType={renderType}
                      updateRenderType={this.updateRenderType}
                    />

                    <div className="chrono-tabs-wrapper">
                      {renderType !== 'Custom' ? (
                        <div className="chrono-parent-tab">
                          <Tabs
                            value={rightParentTabValue}
                            onChange={this.handleRightParentTabChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                display: 'none',
                              },
                            }}
                          >
                            {rightTabInfo &&
                              rightTabInfo.length > 0 &&
                              rightTabInfo.map((item, index) => (
                                <Tab
                                  iconPosition="start"
                                  label={item.tabName}
                                  {...a11yProps(index)}
                                  sx={{ padding: '10px !important' }}
                                />
                              ))}
                          </Tabs>
                          {rightTabInfo &&
                            rightTabInfo.length > 0 &&
                            this.renderRightSubMenuContainer()}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className={
                        renderType !== 'Custom'
                          ? 'result_scroll_div'
                          : 'result_scroll_div result_scroll_div_custom'
                      }
                    >
                      <HorizonDetails
                        isV1={false}
                        isV2={true}
                        renderMainType={renderMainType}
                        leftParentTabValue={leftParentTabValue}
                        leftSubTabValue={leftSubTabValue}
                        renderType={renderType}
                      />
                      {this.renderContentContainer()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Left and Right Arrows */}
        {showLeftNav && (
          <div
            className="arrow left"
            id="prevButton"
            onClick={this.showPreviousItem}
          >
            <DescriptionIcon />
          </div>
        )}
        {showRightNav && (
          <div
            className="arrow right"
            id="nextButton"
            onClick={this.showNextItem}
          >
            <BarChartIcon />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { simulatePlanDetailsLoading } = this.props;
    return (
      <>
        <div className="main-content-wrapper">
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={simulatePlanDetailsLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="container-fluid main-content-wrapper-inner">
            <div className="row">{this.renderClientContainer()}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: getLoggedInUserDetails(state),
    clients: state.clientInfoReducer.clients || [],
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    isPlanDetailsSaved: plannerDetailsSaveStatus(state),
    planDetailsSimulated: plannerDetailsSimulateStatus(state),
    selectedClientId: selectedClientIdSelector(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    alltaxSlabs: allTaxSlabsSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || [],
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    build: outputResponseBuildByPlanIdSelector(state) || {},
    selectedChart: selectedChartSelector(state) || '',
    allMenuItems: allPlannerMenuItemsSelector(state),
    expenseFactorDetails: expenseFactorDetailsSelector(state),
    plaidLinkSuccess: state.PlaidUIReducer.plaidLinkSuccess,
    plaidLinkAccessToken: state.PlaidUIReducer.plaidLinkAccessToken,
    investmentProducts: getInvestmentProductsByClientIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerSetSelectedClientId: (payload) =>
      dispatch(setSelectedClientIdAction(payload)),
    triggersetSelectedPlanIdForClientId: (payload) =>
      dispatch(setSelectedPlanForClientId(payload)),
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
    triggerSetSelectedChart: (payload) =>
      dispatch(setSelectedChartAction(payload)),
    triggerSetSelectedTable: (payload) =>
      dispatch(setSelectedTableAction(payload)),
    triggerSetSelectedData: (payload) =>
      dispatch(setSelectedDataAction(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerSaveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    setPlaidLinkSuccessAction: (payload) =>
      dispatch(setPlaidLinkSuccessAction(payload)),
    setPlaidLinkAccessToken: (payload) =>
      dispatch(setPlaidLinkAccessToken(payload)),
    triggerSelectInvestmentProductsByClientId: (payload) =>
      dispatch(getInvestmentProductsByClientIdAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(BuildComponent));
