import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
  validateFormDetails,
} from '../../helpers/ClientHelper';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

export const KidsContent = (props) => {
  const {
    state,
    concatContent,
    handleDelete,
    setLastStepInParent,
    setValidationError,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoKids',
    subTab: 'kids',
    countVal: 'numKids',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  const userSavingOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  // const validateFields = [
  //   {
  //     apiFieldName: 'name',
  //     errorMessage: 'Invalid Name',
  //   },
  // ];

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Kid Name',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {renderFormDateField(
            'yearBorn',
            'Year born or projected to be born',
            'date',
            moduleObject,
            state,
            props,
            'year'
          )}
          {renderFormInputRow(
            'text',
            'tlAnnualExpense',
            'annualExpense',
            'Additional annual expenses due to kid including child care etc.',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlCollegeAge',
            'collegeAge',
            'Age at which the kid will start college',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlCollegeYears',
            'collegeYears',
            'Plan for number of years in college',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlannualCollegeExpenseNow',
            'annualCollegeExpenseNow',
            'Annual college expense now where kid is projected to attend',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlannualCollegeExpenseGrowth',
            'collegeExpenseGrowth',
            'Annual growth of college expense between now and when projected to attend',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlvalue529Plan',
            'value529Plan',
            '529 savings plan balance (will be taken out of your future funds if kid is not born yet)',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlPeakReturn529Plan',
            'peakReturn529Plan',
            'Projected peak investment return of 529 plan (will be treated like the asset mix of a target fund)',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {isAddNew || isEditable ? (
            <div className="insured_main_outer">
              {renderFormSelectOption(
                'text',
                'tluseSavingsToBuild529',
                'useSavingsToBuild529',
                'Do you want to use non-retirment funds to contribute to 529 plan if income is not sufficient ?',
                userSavingOptions,
                moduleObject,
                state,
                props
              )}
              {renderFormSelectOption(
                'text',
                'tluseSavingsFor529Deficit',
                'useSavingsFor529Deficit',
                'Do you want to use non-retirment funds at the time of attending college if 529 plan savings are not sufficient ?',
                userSavingOptions,
                moduleObject,
                state,
                props
              )}
            </div>
          ) : (
            <div className="insured_main_outer">
              {renderFormInputRow(
                'text',
                'tluseSavingsToBuild529',
                'useSavingsToBuild529',
                'Do you want to use non-retirment funds to contribute to 529 plan if income is not sufficient ?',
                'dropdown',
                moduleObject,
                state,
                props
              )}
              {renderFormInputRow(
                'text',
                'tluseSavingsFor529Deficit',
                'useSavingsFor529Deficit',
                'Do you want to use non-retirment funds at the time of attending college if 529 plan savings are not sufficient ?',
                'dropdown',
                moduleObject,
                state,
                props
              )}
            </div>
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}
      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default KidsContent;
