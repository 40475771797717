import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderRadioGroup,
  renderFormDateField,
  renderSelectAccountDropdown,
  DeleteConfirmation,
} from '../../helpers/ClientHelper';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

export const WholeLifeClientContent = (props) => {
  const {
    state,
    concatContent,
    memberOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'wholeLife',
    subTab: 'wholeLifeIns',
    countVal: 'numWholeLifeIns',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of insurance',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {isAddNew ? (
            <>
              {renderRadioGroup(
                state,
                props,
                memberOptions,
                moduleObject,
                'insured',
                'Who is covered?',
                true, //isMandatory
                formErrors
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlInsured',
                'insured',
                'Who is covered?',
                'textRadioButton',
                moduleObject,
                state,
                props,
                undefined,
                true, //isDisabled,
                true // isMandatory
              )}
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlCoverage',
            'coverage',
            'Insurance Coverage Amount',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormDateField(
            'coverageStartYr',
            'Year when coverage started or expected to start',
            'number',
            moduleObject,
            state,
            props,
            'year',
            true
          )}
          {renderFormInputRow(
            'text',
            'tlCoverageYrs',
            'coverageYrs',
            'Number of years of coverage',
            'number',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlPremium',
            'premium',
            'Annual Premium',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlCashValueAnnual',
            'cashValueAnnual',
            'Cash value to be received per year',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlCashValueDistributionYrs',
            'cashValueDistributionYrs',
            'Number of years that the cash value may be paid',
            'number',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default WholeLifeClientContent;
