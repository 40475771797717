import { DATATYPES } from '../common';

export const commonFields = [];

export const SavingsConstants = [
  {
    label: 'member-1',
    fields: [
      {
        label: '401k self (% of wages)',
        name: 'selfContrib401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Employer (% of Self)',
        name: 'employerContrib401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Roth (% of 401k)',
        name: 'percentRoth401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'IRA (% of Wages)',
        name: 'iraContrib',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Roth (% of IRA)',
        name: 'percentRothIra',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Pension (% of wages)',
        name: 'percentPensionGrant',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'DefComp (% of wages)',
        name: 'percentDefferedComp',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
    ],
  },
  {
    label: 'member-2',
    fields: [
      {
        label: '401k self (% of wages)',
        name: 'selfContrib401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Employer (% of Self)',
        name: 'employerContrib401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Roth (% of 401k)',
        name: 'percentRoth401k',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'IRA (% of Wages)',
        name: 'iraContrib',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Roth (% of IRA)',
        name: 'percentRothIra',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'Pension (% of wages)',
        name: 'percentPensionGrant',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'DefComp (% of wages)',
        name: 'percentDefferedComp',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: '% field should range from 0 to 100',
      },
    ],
  },
];
