import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { kidsFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  getDateValue,
} from '../../helpers/SolutionsHelper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const KidsContentGoals = forwardRef((props, ref) => {
  const { commonProps = {} } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const conditionalFields = ['collegeAge', 'collegeYears', 'name'];
  const [selectedKid, setSelectedKid] = useState({});

  const isEditingRef = useRef(false);
  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedKid;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'kids', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const kid = formDetails?.kids.find((k) => k.name === goal.name);
    if (!kid && modalType === 'add' && Object.keys(selectedKid).length === 0) {
      const newRecord = Object.keys(PropertyNamesMap['kids']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['kids'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedKid).length === 0) {
        const formattedNewRecord = formatValuesForUI(newRecord, kidsFormFields);
        if (
          JSON.stringify(formattedNewRecord) !== JSON.stringify(selectedKid)
        ) {
          setSelectedKid(formattedNewRecord);
        }
      }
    }

    if (kid && Object.keys(selectedKid).length === 0) {
      const formattedKid = formatValuesForUI(kid, kidsFormFields);
      if (JSON.stringify(formattedKid) !== JSON.stringify(selectedKid)) {
        setSelectedKid(formattedKid); // Only update if necessary
      }
    }
  }, [
    formDetails.kids,
    goal.name,
    modalType,
    selectedKid,
    generatePropsMemoized,
  ]);

  const validateKidsNames = useCallback(
    (value) => {
      const existingKid = formDetails?.kids.find(
        (kid) => kid?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingKid) {
        return false;
      }
      return true;
    },
    [modalType, formDetails?.kids]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedKid[key];
      let isValid = true;
      switch (key) {
        case 'annualExpense':
        case 'annualCollegeExpenseNow':
        case 'value529Plan':
          isValid = value;
          break;
        case 'collegeExpenseGrowth':
        case 'peakReturn529Plan':
          isValid = value;
          break;
        case 'collegeYears':
          isValid = value !== '' && parseInt(value) > 0;
          break;
        case 'collegeAge':
          isValid = value !== '' && parseInt(value) >= 18;
          break;
        case 'yearBorn':
          isValid =
            modalType === 'add'
              ? value && parseInt(value) >= new Date().getFullYear()
              : true;
          break;
        case 'name':
          isValid = value !== '' ? validateKidsNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedKid, validateKidsNames, modalType]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = kidsFormFields.map((m) => m.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = kidsFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedKid[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedKid[field]);
    }
    // Update the selected kid with the formatted value
    setSelectedKid({ ...selectedKid, [field]: formattedValue }); // Update the selected kid state
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = kidsFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const kid = { ...selectedKid, [key]: formattedValue };
    // Update selected kid state to reflect changes in UI
    setSelectedKid(kid);
  };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = kidsFormFields.find((ft) => ft.key === field.key);
      if (fieldType) {
        const kid = { ...selectedKid, [field.key]: formattedDate };
        // Update selected kid state to reflect changes in UI
        setSelectedKid(kid);
      }
    }
  };

  useEffect(() => {
    if (selectedKid && Object.keys(selectedKid).length > 0) {
      checkFormValidity();
    }
  }, [formDetails, checkFormValidity, selectedKid]);

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Kid' : 'Update Kid'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedKid).length > 0 &&
            kidsFormFields.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          disabled={modalType === 'edit' && field.isDisabled}
                          value={selectedKid[field.key]}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={() => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedKid &&
                        Object.keys(selectedKid).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {selectedKid[field.key] !== '' &&
                            selectedKid[field.key] !== undefined // dont check for zero
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-8 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={selectedKid[field.key]}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          className={'custom-input'}
                          // disabled={!state.isEditable}
                          options={field.options.map((c) => {
                            return {
                              value: c.value,
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(selectedKid[field.key], field);
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-8 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            inputProps={{
                              style: { height: '39px' },
                            }}
                            minDate={
                              modalType === 'add' ||
                              (modalType === 'edit' &&
                                selectedKid[field.key] >=
                                  new Date().getFullYear())
                                ? new Date()
                                : undefined
                            }
                            disabled={
                              modalType === 'edit' &&
                              selectedKid[field.key] < new Date().getFullYear()
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    </>
  );
});
export default KidsContentGoals;
