import { DATATYPES } from '../common';
import { OUTPUT_FIELD_TYPE } from '../retirementPlan';

export const planForVehicleConstants = [
  {
    name: 'loanAmount',
    label: 'Loan amount',
    colSize: 4,
    type: 'number',
    errorText: '% field should range from 0 to 100',
    isDollarSignRequired: true,
  },
  {
    name: 'balance',
    label: 'Balance',
    colSize: 4,
    type: 'number',
    errorText: 'This field is required',
    isDollarSignRequired: true,
  },
  {
    name: 'apr',
    label: 'APR',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    name: 'termMonths',
    label: 'Loan term months',
    colSize: 4,
    type: 'number',
    errorText: 'Investments should be greater than 0',
  },
  {
    name: 'interestStartDate',
    label: 'Intrst Start Date',
    colSize: 4,
    type: 'date',
    errorText: '% field should range from 0 to 100',
  },
  {
    name: 'paymentStartDate',
    label: 'Pay Start Date',
    colSize: 4,
    type: 'date',
    errorText: 'Investments should be greater than 0',
  },
  {
    name: 'compounding',
    label: 'Compounding',
    colSize: 4,
    type: 'select',
    options: [],
    errorText: 'Investments should be greater than 0',
  },

  {
    name: 'monthlyPayment',
    label: 'Monthly Payment',
    colSize: 4,
    type: 'number',
    errorText: 'Investments should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    name: 'value',
    label: 'Value',
    colSize: 4,
    type: 'number',
    errorText: 'Years should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    name: 'deprYrs',
    label: 'Depriciation Yrs',
    colSize: 4,
    type: 'number',
    errorText: 'Years should be greater than 0',
  },
  // {
  //   name: 'purchasePrice', //not available in excel
  //   label: 'Purchase price',
  //   colSize: 4,
  //   type: 'number',
  //   errorText: 'Tax should be greater than 0',
  //   isDisabled: true,
  // },
  {
    name: 'purchasePrice', //not available in excel
    label: 'Purchase price',
    colSize: 4,
    type: 'number',
    errorText: 'Tax should be greater than 0',
    isDollarSignRequired: true,
    isDisabled: true,
  },
  {},
  {
    label: 'Remaining months',
    name: 'Remainingmonths',
    fieldType: OUTPUT_FIELD_TYPE,
  },
  // {
  //   name: 'remainingNoOfMonths',
  //   label: 'Remaining # of Months',
  //   colSize: 4,
  //   type: 'number',
  //   errorText: 'Tax should be greater than 0',
  //   isDisabled: true,
  // },
  {
    label: 'Remaining interest',
    name: 'Remaininginterest',
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Annual payment',
    name: 'Annualpayment',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {},
  {},
  {},
];
