// DeleteConfirmationUtils.js

import { useState } from 'react';

export const useDeleteConfirmation = () => {
  const [nameValidationError, setNameValidationError] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');

  const openDeleteConfirmation = (name) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
  };

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = (handleDelete, moduleObject) => {
    closeDeleteConfirmation();
    // Perform the delete action here
    handleDelete(
      moduleObject.moduleTab,
      moduleObject.mainTab,
      moduleObject.subTab,
      moduleObject.countVal,
      nameToDelete
    );
  };

  return {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
    setNameToDelete,
    nameValidationError,
    setNameValidationError,
  };
};
