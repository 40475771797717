import {
  GET_OUTPUT_RESPONSE_BY_PLAN_ID,
  GET_OUTPUT_RESPONSE_BY_PLAN_ID_SUCCESS,
  GET_OUTPUT_RESPONSE_BY_PLAN_ID_ERROR,
  OUTPUT_RESPONSE_LOADING,
  CLEAR_OUTPUT_RESPONSE,
} from '../actions/outputResponseDetailsAction';

const initialState = {
  isOutputResponseLoading: false,
  outputResponse: {},
  simulateBeforeData: {},
};

export default function outputResponseDetails(state = initialState, action) {
  switch (action.type) {
    case OUTPUT_RESPONSE_LOADING:
      return {
        ...state,
        isOutputResponseLoading: action.payload,
      };
    case GET_OUTPUT_RESPONSE_BY_PLAN_ID_SUCCESS:
      return {
        ...state,
        simulateBeforeData: action.payload.simulateBeforeData,
        outputResponse: action.payload.simulateOutputResponse,
      };
    case GET_OUTPUT_RESPONSE_BY_PLAN_ID_ERROR:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_OUTPUT_RESPONSE:
      return {
        ...state,
        simulateBeforeData: {},
        outputResponse: {},
      };
    default:
      return state;
  }
}
