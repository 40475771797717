import {
  SET_PLAID_LINK_SUCCESS,
  SET_PLAID_LINK_ACCESS_TOKEN,
} from '../actions/plaidUIActions';

const initialState = {
  plaidLinkSuccess: false,
  plaidLinkAccessToken: '',
};

export default function clientsInfo(state = initialState, action) {
  switch (action.type) {
    case SET_PLAID_LINK_SUCCESS:
      return {
        ...state,
        plaidLinkSuccess: action.payload,
      };
    case SET_PLAID_LINK_ACCESS_TOKEN:
      return {
        ...state,
        plaidLinkAccessToken: action.payload,
      };
    default:
      return state;
  }
}
