/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Button } from '@mui/material';
import { Grid } from '@material-ui/core';

const SolutionsJourneyButtons = (props) => {
  const { currentStep, handlePrevious, handleNext, isLastStep, isFormValid } =
    props;
  return (
    <Grid container spacing={1} className="">
      <Grid item xs={12}>
        <div className="simulate-buttons next_previous_btn_group">
          {currentStep >= 1 && (
            <Button
              variant="contained"
              size="Medium"
              onClick={handlePrevious}
              disabled={!isFormValid}
              className="previous_button"
              style={{
                padding: '7.5px 15px',
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: isFormValid ? '#009fe3' : 'gray',
              }}
            >
              Previous
            </Button>
          )}
          {!isLastStep && (
            <Button
              variant="contained"
              size="Medium"
              onClick={handleNext}
              disabled={!isFormValid}
              className="next_button"
              style={{
                padding: '7.5px 15px',
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                backgroundColor: isFormValid ? '#009fe3' : 'gray',
              }}
            >
              Next
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default SolutionsJourneyButtons;
