import React from 'react';
import { FormHelperText } from '@material-ui/core';
import Select from '@mui/material/Select';
import Autocomplete from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export default function SelectComp(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options = [],
    disabled = false,
    isAutoComplete = false,
    maxMenuHeight = 200,
    placeholder = 'Select',
    required = false,
    noOptionsMessage,
  } = props;

  const handleChange = (event) => {
    const { value } = event;
    const index = options.findIndex((option) => option.value === value);
    onChange({ target: { value, index, name } });
  };

  const selectedValue = options.find((option) => option.value === value);
  return (
    <div className="controls-select">
      <FormControl fullWidth size="small">
        <label className={required ? 'required' : ''}>{label}</label>
        <Autocomplete
          options={options}
          sx={{ width: 300 }}
          value={selectedValue || null}
          onChange={handleChange}
          classNamePrefix="select-search"
          placeholder={placeholder}
          noOptionsMessage={
            noOptionsMessage ? noOptionsMessage : () => 'No Options'
          }
          // isClearable={true}
          maxMenuHeight={maxMenuHeight}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </FormControl>
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
}
