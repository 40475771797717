export const defaultProfileConstants = {
  memberValues: [
    {
      'member-1': {
        age: '',
        residenceState: '',
        taxFilingStatus: '',
        workState: '',
        name: 'member-1',
      },
    },
    {
      'member-2': {
        age: '',
        residenceState: '',
        taxFilingStatus: '',
        workState: '',
        name: 'member-2',
      },
    },
  ],
};

export const defaultIncomeConstants = {
  memberValues: [
    {
      'member-1': {
        wages: '',
        percentRsuGrant: '',
        wageGrowth: '',
        rsuVestingYrs: '',
        name: 'member-1',
      },
    },
    {
      'member-2': {
        wages: '',
        percentRsuGrant: '',
        wageGrowth: '',
        rsuVestingYrs: '',
        name: 'member-1',
      },
    },
  ],
};

export const defaultExpenseConstants = {
  memberValues: [
    {
      'non-housing': {
        aggNonHousing: '',
        discPercentOfAgg: '',
        discHorFactor: '',
        discRetFactor1: '',
        name: 'non-housing',
        label: 'non-housing',
      },
    },
    {
      'housing(if rented)': {
        rental: '',
        name: 'housing(if rented)',
        label: 'housing(if rented)',
      },
    },
  ],
};

export const defaultSavingsConstants = {
  memberValues: [
    {
      'member-1': {
        selfContrib401k: '',
        employerContrib401k: '',
        percentRoth401k: '',
        iraContrib: '',
        percentRothIra: '',
        percentPensionGrant: '',
        percentDefferedComp: '',
        name: 'member-1',
      },
    },
    {
      'member-2': {
        selfContrib401k: '',
        employerContrib401k: '',
        percentRoth401k: '',
        iraContrib: '',
        percentRothIra: '',
        percentPensionGrant: '',
        percentDefferedComp: '',
        name: 'member-1',
      },
    },
  ],
};

export const defaultInvestmentConstants = {
  memberValues: [
    {
      'member-1': {
        trad401k: '',
        roth401k: '',
        tradIra: '',
        rothIra: '',
        emCash: '',
        brokerage: '',
        pension: '',
        deferredComp: '',
        name: 'member-1',
      },
    },
    {
      'member-2': {
        trad401k: '',
        roth401k: '',
        tradIra: '',
        rothIra: '',
        emCash: '',
        brokerage: '',
        pension: '',
        deferredComp: '',
        name: 'member-2',
      },
    },
  ],
};

export const defaultRetirementConstants = {
  memberValues: [
    {
      'member-1': {
        retAge: '',
        socialSecAge: '',
        socialSecAmount: '',
        retirementState: '',
        name: 'member-1',
      },
    },
    {
      'member-2': {
        retAge: '',
        socialSecAge: '',
        socialSecAmount: '',
        retirementState: '',
        name: 'member-2',
      },
    },
  ],
};

export const defaultInsuranceConstants = {
  memberValues: [
    {
      'term life': {
        coverageStartYr: '',
        coverage: '',
        coverageYrs: '',
        premium: '',
        name: 'term life',
      },
    },
    {
      'whole life': {
        coverageStartYr: '',
        coverage: '',
        coverageYrs: '',
        premium: '',
        cashValueAnnual: '',
        cashValueDistributionYrs: '',
        name: 'whole life',
      },
    },
  ],
};

export const defaultOtherGoalsConstants = {
  memberValues: [
    {
      vacation: {
        vacationYear: '',
        vacationExpense: '',
        name: 'vacation',
      },
    },
    {
      kid: {
        yearBorn: '',
        annualExpense: '',
        annualCollegeExpenseNow: '',
        value529Plan: '',
        name: 'kid',
      },
    },
  ],
};

export const defaultAssetGoalsConstants = {
  memberValues: [
    {
      home: {
        purchaseDate: '',
        purchasePrice: '',
        origOrRefiLoanAmount: '',
        loanTerm: '',
        fixedIntRate: '',
        appreciationRate: '',
        aggMaintenanceExp: '',
        name: 'home',
      },
    },
    {
      vehicles: {
        paymentStartDate: '',
        purchasePrice: '',
        termMonths: '',
        loanTerm: '',
        apr: '',
        name: 'vehicles',
      },
    },
  ],
};

export const defaultDebtConstants = {
  memberValues: [
    {
      'credit cards': {
        balance: '',
        apr: '',
        monthlyPayment: '',
        monthlySpend: '',
        name: 'credit cards',
      },
    },
    {
      'student loans': {
        balance: '',
        apr: '',
        monthlyPayment: '',
        termMonths: '',
        loanAmount: '',
        paymentStartDate: '',
        name: 'student loans',
      },
    },
    {
      'other loans': {
        balance: '',
        apr: '',
        monthlyPayment: '',
        termMonths: '',
        loanAmount: '',
        paymentStartDate: '',
        name: 'other loans',
      },
    },
  ],
};
