export const SET_PLAID_LINK_SUCCESS = 'set:plaid:link:success';
export const SET_PLAID_LINK_ACCESS_TOKEN = 'set:plaid:link:accesstoken';

export function setPlaidLinkSuccessAction(payload) {
  return {
    type: SET_PLAID_LINK_SUCCESS,
    payload: payload,
  };
}

export function setPlaidLinkAccessToken(payload) {
  return {
    type: SET_PLAID_LINK_ACCESS_TOKEN,
    payload: payload,
  };
}
