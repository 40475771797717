export const commonFields = ['retirementState'];

export const RetirementConstants = [
  {
    label: 'member-1',
    fields: [
      {
        label: 'Retirement Age',
        name: 'retAge',
        colSize: 4,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Soc Sec age',
        name: 'socialSecAge',
        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Soc Sec Amt',
        name: 'socialSecAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Retirement State',
        name: 'retirementState',
        colSize: 3,
        type: 'select',
        options: [],
      },
    ],
  },
  {
    label: 'member-2',
    fields: [
      {
        label: 'Retirement Age',
        name: 'retAge',
        colSize: 4,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Soc Sec age',
        name: 'socialSecAge',
        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Soc Sec Amt',
        name: 'socialSecAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Retirement State',
        name: 'retirementState',
        colSize: 3,
        type: 'select',
        options: [],
      },
    ],
  },
];
