import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { primaryHomeFormFields } from '../../constants/Client';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  createLocalDateFromDateString,
  getDateValue,
  parseCurrency,
  validateDateFields,
} from '../../helpers/SolutionsHelper';
import { PropertyNamesMap } from '../../constants/PropertyNames';

const PrimaryHomeContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, taxStateOptions = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;

  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const conditionalFields = [
    'name',
    'origOrRefiLoanAmount',
    'purchaseDate',
    'origOrRefiLoanDate',
  ];
  const [selectedPrimaryHome, setSelectedPrimaryHome] = useState({});
  const [primaryHomeFormFieldObjects, setPrimaryHomeFormFieldObjects] =
    useState(primaryHomeFormFields);

  useEffect(() => {
    let tempPrimaryHomeFormFields = [...primaryHomeFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempPrimaryHomeFormFields = tempPrimaryHomeFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setPrimaryHomeFormFieldObjects(tempPrimaryHomeFormFields);
  }, [setPrimaryHomeFormFieldObjects, taxStateOptions]);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedPrimaryHome;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'prop', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const primaryHome = formDetails?.prop || {};
    if (
      primaryHome &&
      Object.keys(primaryHome).length === 0 &&
      modalType === 'add' &&
      Object.keys(selectedPrimaryHome).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['prop']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['prop'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedPrimaryHome).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          primaryHomeFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !==
          JSON.stringify(selectedPrimaryHome)
        ) {
          setSelectedPrimaryHome(formattedNewRecord);
        }
      }
    }

    if (primaryHome && Object.keys(selectedPrimaryHome).length === 0) {
      const formattedHome = formatValuesForUI(
        primaryHome,
        primaryHomeFormFields
      );
      if (
        JSON.stringify(formattedHome) !== JSON.stringify(selectedPrimaryHome)
      ) {
        setSelectedPrimaryHome(formattedHome); // Only update if necessary
      }
    }
  }, [
    formDetails.prop,
    goal.name,
    modalType,
    selectedPrimaryHome,
    generatePropsMemoized,
  ]);

  const validateLoanAmountField = useCallback(
    (value) => {
      const loanAmount = parseCurrency(value || 0);
      const purchasePrice = parseCurrency(
        selectedPrimaryHome?.purchasePrice || 0
      );

      if (isNaN(loanAmount) || isNaN(purchasePrice)) {
        return false;
      }
      // Ensure loan amount is not greater than the purchase price
      return loanAmount <= purchasePrice;
    },
    [selectedPrimaryHome]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedPrimaryHome[key];
      let isValid = true;
      switch (key) {
        case 'aggMaintenanceExp':
        case 'loanAmountOutstanding':
        case 'purchasePrice':
        case 'taxBasis':
        case 'value':
          isValid = value;
          break;
        case 'origOrRefiLoanAmount':
          isValid = value !== '' ? validateLoanAmountField(value) : false;
          break;
        case 'aggMaintenanceExpGrowth':
        case 'appreciationRate':
        case 'fixedIntRate':
        case 'taxBasisGrowth':
        case 'taxRate':
          isValid = value;
          break;
        case 'loanTerm':
          isValid = value !== '' && parseInt(value) >= 0;
          break;
        case 'purchaseDate':
          const date =
            value &&
            validateDateFields(value) &&
            createLocalDateFromDateString(value);
          isValid = validateDateFields(value)
            ? modalType === 'add'
              ? date &&
                new Date(date).setHours(0, 0, 0, 0) >=
                  new Date().setHours(0, 0, 0, 0)
              : true
            : false;
          break;
        case 'origOrRefiLoanDate':
          isValid = value ? validateDateFields(value) : false;
          break;
        case 'name':
          isValid = value ? true : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedPrimaryHome, modalType, validateLoanAmountField]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = primaryHomeFormFields.map((s) => s.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = primaryHomeFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedPrimaryHome[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedPrimaryHome[field]);
    }
    if (field === 'purchaseDate') {
      setSelectedPrimaryHome({
        ...selectedPrimaryHome,
        [field]: formattedValue,
        origOrRefiLoanDate: formattedValue,
      });
    } else if (field === 'purchasePrice') {
      setSelectedPrimaryHome({
        ...selectedPrimaryHome,
        [field]: formattedValue,
        taxBasis: formattedValue,
        value: formattedValue,
      }); // Update the selected vehicle state
    } else if (
      field === 'origOrRefiLoanAmount' &&
      parseCurrency(formattedValue) === 0
    ) {
      setSelectedPrimaryHome({
        ...selectedPrimaryHome,
        [field]: formattedValue,
        loanAmountOutstanding: formattedValue,
        loanTerm: 0,
      }); // Update the selected vehicle state
    } else
      setSelectedPrimaryHome({
        ...selectedPrimaryHome,
        [field]: formattedValue,
      }); // Update the selected secondHome state
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = primaryHomeFormFieldObjects.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const primaryHome = { ...selectedPrimaryHome, [key]: formattedValue };
    // Update selected secondHome state to reflect changes in UI
    setSelectedPrimaryHome(primaryHome);
  };

  useEffect(() => {
    if (selectedPrimaryHome && Object.keys(selectedPrimaryHome).length > 0) {
      checkFormValidity();
    }
  }, [selectedPrimaryHome, formDetails, checkFormValidity]);

  // const isDisabledFn = ({ isDisabled = false, key = '' }) => {
  //   if (isDisabled) return true;
  //   if (key === 'purchaseDate' || key === 'purchasePrice') {
  //     const { purchaseDate = '' } = selectedPrimaryHome || {};
  //     return purchaseDate && new Date(purchaseDate) < new Date();
  //   }
  //   return false;
  // };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = primaryHomeFormFieldObjects.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        let primaryHome = { ...selectedPrimaryHome };
        if (field.key === 'purchaseDate') {
          primaryHome = {
            ...primaryHome,
            [field.key]: formattedDate,
            origOrRefiLoanDate: formattedDate,
          };
        } else {
          primaryHome = {
            ...primaryHome,
            [field.key]: formattedDate,
          };
        }
        setSelectedPrimaryHome(primaryHome);
      }
    }
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add Primary Home' : 'Update Primary Home'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {primaryHomeFormFieldObjects.map((field) => {
            switch (field.type) {
              case 'text':
                return (
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                    >
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <input
                        type={field.type}
                        id={field.key}
                        name={field.key}
                        disabled={modalType === 'edit' && field.isDisabled}
                        // disabled={modalType === 'edit' && isDisabledFn(field)}
                        value={selectedPrimaryHome[field.key]}
                        className={'custom-input'} // Apply the appropriate class
                        onChange={(event) => handleOnChange(event, field.key)}
                        onBlur={() => handleBlur(field.key)}
                        placeholder=""
                      />
                    </div>
                    {conditionalFields.includes(field.key) &&
                      selectedPrimaryHome &&
                      Object.keys(selectedPrimaryHome).length > 0 &&
                      !getIsValidFieldValue(field.key) && (
                        <p className="error-text">
                          {selectedPrimaryHome[field.key] !== '' &&
                          selectedPrimaryHome[field.key] !== undefined
                            ? field.errorMessage
                            : ''}
                        </p>
                      )}
                  </div>
                );
              case 'select':
                return (
                  <div className="row align-items-center">
                    <div className={`col-lg-8 insured_content_outer`}>
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className={`col-lg-3 insured_radio_group`}>
                      <Controls.Select
                        name={field.key}
                        id={field.key}
                        value={selectedPrimaryHome[field.key]}
                        onChange={(event, index) =>
                          handleOnChange(event, field.key)
                        }
                        className={'custom-input'}
                        options={field.options.map((c) => {
                          return {
                            value: c.value,
                            label: c.label,
                          };
                        })}
                      />
                    </div>
                  </div>
                );
              case 'date':
                const dateValue = getDateValue(
                  selectedPrimaryHome[field.key],
                  field
                );
                return (
                  <div className="row align-items-center">
                    <div className="col-lg-8 insured_content_outer">
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          views={[field.dataType]}
                          className="planner-input long-text-input"
                          format={
                            field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                          }
                          autoOk
                          variant="inline"
                          InputAdornmentProps={{ position: 'start' }}
                          value={dateValue}
                          onChange={(date) => handleDateChange(date, field)}
                          inputProps={{ style: { height: '39px' } }}
                          minDate={
                            (modalType === 'add' ||
                              (modalType === 'edit' &&
                                new Date(dateValue).setHours(0) >=
                                  new Date().setHours(0))) &&
                            field.key === 'origOrRefiLoanDate'
                              ? getDateValue(
                                  selectedPrimaryHome['purchaseDate']
                                )
                              : new Date()
                          }
                          disabled={
                            modalType === 'edit' &&
                            new Date(dateValue).setHours(0, 0, 0, 0) <
                              new Date().setHours(0, 0, 0, 0)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
});
export default PrimaryHomeContentGoals;
