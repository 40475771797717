import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getOutputResponseByPlanIdSuccessAction,
  getOutputResponseByPlanIdErrorAction,
  GET_OUTPUT_RESPONSE_BY_PLAN_ID,
  setOutputResponseLoadingAction,
} from '../actions/outputResponseDetailsAction';
import { getOutputResponseByPlanId } from '../../services/dashboardSvc';

function* triggerGetOutputResponseByPlanId(action) {
  try {
    yield put(setOutputResponseLoadingAction(true));
    const { payload } = action;
    const { status, data } = yield call(getOutputResponseByPlanId, payload);
    if (status === 200) {
      yield put(setOutputResponseLoadingAction(false));
      yield put(
        getOutputResponseByPlanIdSuccessAction({
          simulateBeforeData: data.chrono,
          simulateOutputResponse: data,
        })
      );
    } else {
      yield put(setOutputResponseLoadingAction(false));
      yield put(getOutputResponseByPlanIdErrorAction({}));
    }
  } catch (err) {
    yield put(setOutputResponseLoadingAction(false));
    yield put(getOutputResponseByPlanIdErrorAction({}));
  }
}

export function* watchOutputResponseByPlanId() {
  yield takeLatest(
    GET_OUTPUT_RESPONSE_BY_PLAN_ID,
    triggerGetOutputResponseByPlanId
  );
}
