import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import { plannerTabs } from '../../constants/IndividualClient';
import ProfileComponent from './PlannerFormComp/ProfileComponent';
import IncomeComponent from './PlannerFormComp/IncomeComponent';
import ExpenseComponent from './PlannerFormComp/ExpenseComponent';
import SavingsComponent from './PlannerFormComp/SavingsComponent';
import InvestmentComponent from './PlannerFormComp/InvestmentComponent';
import RetirementComponent from './PlannerFormComp/RetirementComponent';
import InsuranceComponent from './PlannerFormComp/InsuranceComponent';
import OtherGoalsComponent from './PlannerFormComp/OtherGoalsComponent';
import AssetGoalsComponent from './PlannerFormComp/AssetGoalsComponent';
import DebtComponent from './PlannerFormComp/DebtComponent';
import {
  getProfileStoreValues,
  transformTaxStateOptions,
  getIncomeStoreValues,
  geExpenseStoreValues,
  getSavingsStoreValues,
  getInvestmentStoreValues,
  getRetirementChoiceStoreValues,
  getInsuranceStoreValues,
  getOtherGoalsStoreValues,
  getAssetGoalsStoreValues,
  getDebtStoreValues,
} from '../../redux/transformers/individualClient/apiToClientResponseTransformer';
import {
  transformWagesAndExpenseProfileReq,
  transformSavingsDetailsReq,
  getInfoTaxDetailsReq,
  getInfoPreferencesReq,
  getInfoExpenseDetailsReq,
  getKidsDetailsFormReq,
  getVacationFormState,
  getVehiclesFormState,
  getCreditCardsFormState,
  getStudentLoansFormState,
  getOtherLoansFormState,
  getInvestmentBalancesFormState,
  getInvestmentReturnsFormState,
  getInvestmentVolatilityFormState,
  getInsuranceTermLifeFormState,
  getInsuranceWholeLifeFormState,
  getLiabilityOrUmbrellaFormState,
  getPropertyCovergeFormState,
  getPrimaryHomeFormState,
  getSecondaryHomeFormState,
  getInvestmentPropertiesFormState,
  getRetirementChoicesFormState,
  getRothVsTraditionalFormState,
  getEstateTransferFormState,
  infoModulesSubscribed,
  infoInputsCompleted,
  getAuditlogs,
} from '../../redux/transformers/individualClient/clientToApiRequestTransformer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class PlannerTabs extends Component {
  profileComponentRef = React.createRef();
  incomeComponentRef = React.createRef();
  investmentComponentRef = React.createRef();
  insuranceComponentRef = React.createRef();
  expenseComponentRef = React.createRef();
  savingsComponentRef = React.createRef();
  debtComponentRef = React.createRef();
  assetGoalsComponentRef = React.createRef();
  otherGoalsComponentRef = React.createRef();
  retirementComponentRef = React.createRef();

  state = {
    value: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    const { planDetails } = this.props;
    if (planDetails && Object.keys(planDetails).length > 0) {
      const plannerFormReq = this.getPlannerFormReq();
      const { updatePlannerDataInReduxCb } = this.props;
      updatePlannerDataInReduxCb(plannerFormReq);
    }
  }

  handleChange = (event, value) => {
    event.stopPropagation();
    this.setState({
      value,
    });
  };

  getPlannerFormReq = (action = '') => {
    const {
      planDetails,
      selectedPlan,
      userDetails,
      selectedClientId,
      selectedHorizonTerm,
      selectedDisplayTerm,
      selectedNumberOfRuns,
    } = this.props;
    const {
      moduleLifeStyle: {
        infoPreferences = {},
        infoWagesAndExpenseProfile = {},
      } = {},
      moduleLifeEvents: { infoVehicles = {} } = {},
      moduleDebt: { infoStudentLoans = {}, infoOtherLoans = {} } = {},
      moduleRealEstate: { infoPrimaryHome = {} } = {},
    } = planDetails;
    const { current: profileComponent } = this.profileComponentRef;
    const { current: incomeComponent } = this.incomeComponentRef;
    const { current: savingsComponent } = this.savingsComponentRef;
    const { current: expenseComponent } = this.expenseComponentRef;
    const { current: otherGoalsComponent } = this.otherGoalsComponentRef;
    const { current: assetGoalsComponent } = this.assetGoalsComponentRef;
    const { current: debtComponent } = this.debtComponentRef;
    const { current: investmentComponent } = this.investmentComponentRef;
    const { current: insuranceComponent } = this.insuranceComponentRef;
    const { current: retirementComponent } = this.retirementComponentRef;

    const profileCompReq = profileComponent.getProfileCompFormState();
    const incomeCompReq = incomeComponent.getIncomeCompFormState();
    const savingsCompReq = savingsComponent.getSavingsDetailsCompFormState();
    const expenseCompReq = expenseComponent.getExpenseCompFormState();
    const otherGoalsCompReq = otherGoalsComponent.getOtherGoalsFormState();
    const assetGoalsCompReq = assetGoalsComponent.getAssetGoalsCompFormState();
    const debtCompReq = debtComponent.getDebtCompFormState();
    const investmentCompReq =
      investmentComponent.getInvestmentComponentFormState();
    const insuranceCompReq = insuranceComponent.getInsuranceCompFormState();
    const retirementCompReq = retirementComponent.getRetirementCompFormState();

    const lifestyleReq = {
      infoWagesAndExpenseProfile: transformWagesAndExpenseProfileReq({
        profileCompReq,
        incomeCompReq,
        infoPreferences,
      }),
      infoSavingsDetails: transformSavingsDetailsReq({
        savingsCompReq,
        incomeCompReq,
        infoWagesAndExpenseProfile,
      }),
      infoTaxDetails: getInfoTaxDetailsReq(),
      infoExpenseDetails: getInfoExpenseDetailsReq({
        expenseCompReq,
        infoPreferences,
      }),
      infoPreferences: getInfoPreferencesReq({
        selectedHorizonTerm,
        selectedDisplayTerm,
        selectedNumberOfRuns,
        action,
      }),
    };

    const lifeEventsReq = {
      infoKids: getKidsDetailsFormReq({ otherGoalsCompReq, infoPreferences }),
      infoVacations: getVacationFormState({ otherGoalsCompReq }),
      infoVehicles: getVehiclesFormState({ assetGoalsCompReq, infoVehicles }),
    };

    const debtReq = {
      infoCreditCards: getCreditCardsFormState({ debtCompReq }),
      infoStudentLoans: getStudentLoansFormState({
        debtCompReq,
        infoStudentLoans,
      }),
      infoOtherLoans: getOtherLoansFormState({ debtCompReq, infoOtherLoans }),
    };

    const investmentsReq = {
      infoInvBalances: getInvestmentBalancesFormState({ investmentCompReq }),
      infoInvReturns: getInvestmentReturnsFormState(infoPreferences),
      infoInvVolatility: getInvestmentVolatilityFormState(infoPreferences),
    };
    const insuranceReq = {
      infoInsuranceTermLife: getInsuranceTermLifeFormState({
        insuranceCompReq,
      }),
      infoInsuranceWholeLife: getInsuranceWholeLifeFormState({
        insuranceCompReq,
      }),
      infoInsuranceLiability: getLiabilityOrUmbrellaFormState(),
      infoInsuranceProperty: getPropertyCovergeFormState(),
    };

    const realEstateReq = {
      infoPrimaryHome: getPrimaryHomeFormState({
        assetGoalsCompReq,
        infoPreferences,
        infoWagesAndExpenseProfile,
        infoPrimaryHome,
      }),
      infoSecondHomes: getSecondaryHomeFormState(),
      infoInvProps: getInvestmentPropertiesFormState(),
    };

    const retirementReq = {
      infoRetirementChoices: getRetirementChoicesFormState({
        retirementCompReq,
        infoPreferences,
      }),
      infoRothVsTraditional: getRothVsTraditionalFormState(),
    };

    const estateReq = {
      infoHeir: getEstateTransferFormState({
        infoPreferences,
        infoWagesAndExpenseProfile,
      }),
    };

    const savePlanByPlanIdReq = {
      moduleLifeStyle: lifestyleReq,
      moduleLifeEvents: lifeEventsReq,
      moduleDebt: debtReq,
      moduleInvestments: investmentsReq,
      moduleInsurance: insuranceReq,
      moduleRealEstate: realEstateReq,
      moduleRetirement: retirementReq,
      moduleEstateTransfer: estateReq,
    };
    const auditLogs = getAuditlogs(userDetails);

    const finalPayloadReq = {
      ...auditLogs,
      ...savePlanByPlanIdReq,
      planid: selectedPlan,
      infoModulesSubscribed,
      infoInputsCompleted: { ...infoInputsCompleted(savePlanByPlanIdReq) },
      clientid: selectedClientId,
    };

    return finalPayloadReq;
  };

  renderPlannerMenu = () => {
    const { value } = this.state;
    const {
      planDetails,
      allMenuItems,
      alltaxSlabs,
      updatePlannerDataInReduxCb,
    } = this.props;
    const {
      moduleLifeStyle: {
        infoWagesAndExpenseProfile = {},
        infoSavingsDetails = {},
        infoExpenseDetails = {},
      } = {},
      moduleRetirement: { infoRetirementChoices = {} } = {},
      moduleInvestments: { infoInvBalances = {} } = {},
      moduleInsurance: {
        infoInsuranceTermLife = {},
        infoInsuranceWholeLife = {},
      } = {},
      moduleLifeEvents: {
        infoKids = {},
        infoVacations = {},
        infoVehicles = {},
      } = {},
      moduleDebt: {
        infoCreditCards = {},
        infoOtherLoans = {},
        infoStudentLoans = {},
      } = {},
      moduleRealEstate: { infoPrimaryHome = {} } = {},
    } = planDetails;
    return (
      <>
        {/* <TabPanel value={value} index={0} className="planner-tab-panel"> */}
        <div
          className={`planner-tab-panel ${value === 0 ? 'd-block' : 'd-none'}`}
        >
          <ProfileComponent
            storeValues={getProfileStoreValues(infoWagesAndExpenseProfile)}
            ref={this.profileComponentRef}
            lifestyleMenuOptions={allMenuItems.lifeStyleMenuOptions}
            taxFilingStatusMenuItems={allMenuItems.taxFilingStatusMenuItems}
            taxStateOptions={transformTaxStateOptions(alltaxSlabs)}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 1 ? 'd-block' : 'd-none'}`}
        >
          <IncomeComponent
            storeValues={getIncomeStoreValues(
              infoWagesAndExpenseProfile,
              infoSavingsDetails
            )}
            ref={this.incomeComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 2 ? 'd-block' : 'd-none'}`}
        >
          <ExpenseComponent
            storeValues={geExpenseStoreValues(infoExpenseDetails)}
            ref={this.expenseComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 3 ? 'd-block' : 'd-none'}`}
        >
          <SavingsComponent
            storeValues={getSavingsStoreValues(infoSavingsDetails)}
            ref={this.savingsComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 4 ? 'd-block' : 'd-none'}`}
        >
          <InvestmentComponent
            storeValues={getInvestmentStoreValues(infoInvBalances)}
            ref={this.investmentComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 5 ? 'd-block' : 'd-none'}`}
        >
          <DebtComponent
            storeValues={getDebtStoreValues({
              ...infoCreditCards,
              ...infoOtherLoans,
              ...infoStudentLoans,
            })}
            ref={this.debtComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 6 ? 'd-block' : 'd-none'}`}
        >
          <AssetGoalsComponent
            storeValues={getAssetGoalsStoreValues({
              ...infoPrimaryHome,
              ...infoVehicles,
            })}
            ref={this.assetGoalsComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 7 ? 'd-block' : 'd-none'}`}
        >
          <OtherGoalsComponent
            storeValues={getOtherGoalsStoreValues({
              ...infoVacations,
              ...infoKids,
            })}
            ref={this.otherGoalsComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 8 ? 'd-block' : 'd-none'}`}
        >
          <InsuranceComponent
            storeValues={getInsuranceStoreValues({
              ...infoInsuranceTermLife,
              ...infoInsuranceWholeLife,
            })}
            ref={this.insuranceComponentRef}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
        <div
          className={`planner-tab-panel ${value === 9 ? 'd-block' : 'd-none'}`}
        >
          <RetirementComponent
            storeValues={getRetirementChoiceStoreValues(infoRetirementChoices)}
            ref={this.retirementComponentRef}
            taxStateOptions={transformTaxStateOptions(alltaxSlabs)}
            updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
          />
        </div>
      </>
    );
  };

  render() {
    const { value } = this.state;
    return (
      <div className="planner-tabs-wrapper">
        <div className="tabs-container">
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {plannerTabs.map((tab, index) => (
              <Tab label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </div>
        {this.renderPlannerMenu()}
      </div>
    );
  }
}

export default PlannerTabs;
