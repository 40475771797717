/* eslint-disable default-case */
import { API_CONFIG_ENV } from './CONSTANTS';

export const API_HOST_URL = (isPublic = false) => {
  switch (API_CONFIG_ENV) {
    case 'dev':
    default:
      return 'https://api.greenrfinancial.com';
    case 'uacc':
      return 'https://greenrfinancial.net:9080';
  }
};
