import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderInputRow,
  transformCashFlowsBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BuildSavingsOtherComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const { moduleSettings: { cashFlows = {} } = {} } = localPlanDetailsByPlanId;
  const [cashFlowsDetails, setCashFlowsDetails] = useState(cashFlows);

  useEffect(() => {
    if (cashFlows && Object.keys(cashFlows).length > 0) {
      let tempCashFlows = transformCashFlowsBackToUIType(cashFlows);
      setCashFlowsDetails(tempCashFlows);
    }
  }, [localPlanDetailsByPlanId]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tldefaultCashPercent',
          'defaultCashPercentInSavings',
          'When you have excess funds to go into your non-retirement investment account, what % of it would you like to keep it as cash',
          'percentage',
          cashFlowsDetails,
          'cashFlows',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setCashFlowsDetails
        )}
      </div>

      <hr />

      <div className="insured_main_outer">
        <Alert severity="info">Savings into non-retirement investment account will depend upon budget surplus.</Alert>
      </div>
      
      <div className='tab_main_nav'>
        <div className='tab_left_nav'>
          <button>
          <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className='tab_right_nav'>
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};
export default BuildSavingsOtherComponent;
