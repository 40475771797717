export const createLocalDateFromDateString = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  // Use the Date constructor with year, month (0-indexed), and day
  return new Date(year, month - 1, day);
};

export const createLocalDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 0-padded
  const day = String(date.getDate()).padStart(2, '0');
  // Directly return the formatted string
  return `${year}-${month}-${day}`;
};

// Function to parse a string or float with or without a dollar sign
export const parseCurrency = (val) => {
  if (typeof val === 'string') {
    // Remove any non-numeric characters (like "$" or ",") and convert to float
    return parseFloat(val.replace(/[^0-9.-]+/g, ''));
  }
  return parseFloat(val);
};

export const validateDateFields = (value) => {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return false;
  }
  return true;
};

export const getDateValue = (date, field) => {
  return (() => {
    if (field?.dataType === 'year') {
      const year = parseInt(date, 10);
      if (year) {
        // Ensure the next day is based on the local timezone
        const today = new Date();
        const nextDay = new Date(year, today.getMonth(), today.getDate() + 1);
        return nextDay;
      }
      return null;
    } else {
      return validateDateFields(date)
        ? createLocalDateFromDateString(date)
        : null;
    }
  })();
};

export const generateProps = (keyVal, key, subTab, planDetailsByPlanId) => {
  let newRecordKey;
  if (keyVal['type'] === 'number') {
    if (keyVal['defaultValue']) {
      newRecordKey = keyVal['defaultValue'];
    } else newRecordKey = 0;
  } else if (keyVal['type'] === 'percentage') {
    newRecordKey = '0%';
  } else if (keyVal['type'] === 'currency') {
    newRecordKey = '$0';
  } else if (keyVal['type'] === 'year') {
    newRecordKey = new Date().getFullYear();
  } else if (keyVal['type'] === 'date') {
    const date = new Date();
    newRecordKey = createLocalDate(date);
    // const userLocale = navigator?.language || 'en-US'; // Fallback to 'en-GB' if the user's language isn't available
    // const localDate = now.toLocaleDateString(userLocale);
    // const [month, day, year] = localDate.includes('/')
    //   ? localDate.split('/')
    //   : localDate.split('-'); // Handle both `MM/DD/YYYY` or `YYYY-MM-DD` formats
    // newRecordKey = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    // const now = new Date();
    // const year = now.getFullYear();
    // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    // const day = String(now.getDate()).padStart(2, '0');
    // newRecordKey = `${year}-${month}-${day}`;
  } else if (keyVal['type'] === 'select') {
    if ((subTab === 'prop' || subTab === 'props') && key === 'propDomicile') {
      let residenceState =
        planDetailsByPlanId?.moduleFamily?.infoCore?.residenceState;
      newRecordKey = residenceState;
    } else {
      newRecordKey =
        keyVal['option'] === 'BooleanOptions' || 'Compounding'
          ? keyVal['defaultValue']
          : 'Daily';
    }
  } else {
    newRecordKey = '';
  }
  return newRecordKey;
};

export const currencyFormatToUI = (value) => {
  const numFormat = Intl.NumberFormat('en-US');
  if (value !== null && value !== undefined && value !== '') {
    let currencyFieldValue = value.toString().replace(/\D/g, '');
    if (!isNaN(currencyFieldValue)) {
      return `$${numFormat.format(currencyFieldValue)}`;
    }
  }
  return '$0';
};

// Optimized percentage formatting function
export const percentageConversionToUI = (val) => {
  if (
    val === null ||
    val === undefined ||
    val === '' ||
    isNaN(parseFloat(val))
  ) {
    return '0.00%';
  }
  let percentValue = parseFloat(val.toString().replace('%', '').trim()); // Remove '%' and trim spaces
  if (isNaN(percentValue)) {
    return '0.00%'; // Fallback in case of unexpected input
  }

  if (percentValue < 0) {
    return `0.00%`;
  }
  if (percentValue < 1) {
    return `${(percentValue * 100).toFixed(2)}%`; // Convert decimal to percentage
  }

  // If it's a whole number or already a percentage
  return `${percentValue.toFixed(2)}%`;
};

export const formatValueByType = (value, dataType) => {
  switch (dataType) {
    case 'currency':
      return currencyFormatToUI(value);
    case 'percent':
      return percentageConversionToUI(value);
    case 'number':
      return value >= 0 ? value : 0;
    default:
      return value; // Return as is for other types
  }
};

export const formatValuesForUI = (obj, formFieldsConstants) => {
  const formattedObj = { ...obj };
  formFieldsConstants.forEach((field) => {
    if (obj.hasOwnProperty(field.key)) {
      formattedObj[field.key] = formatValueByType(
        obj[field.key],
        field.dataType
      );
    }
  });
  return formattedObj;
};

export const formatValuesForInvPropsUI = (
  invProp,
  formFieldsConstants,
  outerObjectKeys
) => {
  const formattedInvProp = { ...invProp };
  formFieldsConstants.forEach((field) => {
    if (
      outerObjectKeys.includes(field.key) &&
      invProp.hasOwnProperty(field.key)
    ) {
      formattedInvProp[field.key] = formatValueByType(
        invProp[field.key],
        field.dataType
      );
    } else if (invProp.prop.hasOwnProperty(field.key)) {
      formattedInvProp.prop[field.key] = formatValueByType(
        invProp.prop[field.key],
        field.dataType
      );
    }
  });
  return formattedInvProp;
};
