import { DATATYPES } from '../common';

export const commonFields = [];

export const DebtConstants = [
  {
    label: 'credit cards',
    fields: [
      {
        label: 'Balance',
        name: 'balance',
        colSize: 3,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'APR',
        name: 'apr',
        colSize: 4,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Monthly Payment',
        name: 'monthlyPayment',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Monthly Spend',
        name: 'monthlySpend',
        colSize: 4,
        isDollarSignRequired: true,

        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
    ],
  },
  {
    label: 'student loans',
    fields: [
      {
        label: 'Balance',
        name: 'balance',
        isDollarSignRequired: true,

        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'APR',
        name: 'apr',
        colSize: 4,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Monthly Payment',
        name: 'monthlyPayment',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Term Months',
        name: 'termMonths',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
      {
        label: 'Loan amount',
        name: 'loanAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'Pay Start Date',
        name: 'paymentStartDate',
        colSize: 4,
        type: 'date',
        errorText: 'Invalid Field',
      },
    ],
  },
  {
    label: 'other loans',
    fields: [
      {
        label: 'Balance',
        name: 'balance',
        colSize: 3,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'APR',
        name: 'apr',
        colSize: 4,
        type: 'number',

        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Monthly Payment',
        name: 'monthlyPayment',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Term Months',
        name: 'termMonths',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
      {
        label: 'Loan amount',
        name: 'loanAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'value should be greater than 0',
      },
      {
        label: 'Pay Start Date',
        name: 'paymentStartDate',
        colSize: 4,
        type: 'date',
        errorText: 'Invalid Field',
      },
    ],
  },
];
