import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getCompaniesInfoSuccessAction,
  getCompaniesInfoErrorAction,
  GET_COMPANIES_INFO,
} from '../actions/companiesInfoAction';
import { getCompaniesInfo } from '../../services/dashboardSvc';

export default function* triggerGetCompanies() {
  try {
    const { status, data } = yield call(getCompaniesInfo);
    if (status === 200) {
      const companiesInfo = data.map((d) => {
        return {
          company: d.name,
          location: d.location,
          shortLocation: d.shortLocation,
        };
      });
      yield put(getCompaniesInfoSuccessAction(companiesInfo));
    } else {
      yield put(getCompaniesInfoErrorAction([]));
    }
  } catch (err) {
    yield put(getCompaniesInfoErrorAction([]));
  }
}

export function* watchCompaniesInfo() {
  yield takeLatest(GET_COMPANIES_INFO, triggerGetCompanies);
}
