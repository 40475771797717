import {
  GET_COMPANIES_INFO,
  GET_COMPANIES_INFO_SUCCESS,
  GET_COMPANIES_INFO_ERROR,
} from '../actions/companiesInfoAction';

const initialState = {
  companiesInfo: [],
};

export default function companiesInfo(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_INFO:
      return {
        ...state,
        companiesInfo: [],
      };
    case GET_COMPANIES_INFO_SUCCESS:
      return {
        ...state,
        companiesInfo: action.payload,
      };
    case GET_COMPANIES_INFO_ERROR:
      return {
        ...state,
        companiesInfo: action.payload,
      };
    default:
      return state;
  }
}
