import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  InvestmentConstants,
  commonFields,
} from '../../../constants/IndividualClient/InvestmentConstants';
import PlannerTabsContent from '../PlannerTabsContent';
import { INVESTMENT_COMPONENT } from '../../../constants/IndividualClient';

class InvestmentComponent extends Component {
  PlannerTabsRef = React.createRef();

  getInvestmentComponentFormState = () => {
    const { current: { getAllFormFieldValues } = {} } = this.PlannerTabsRef;
    return getAllFormFieldValues();
  };

  getInitialFieldsState() {
    const formFields = InvestmentConstants.filter(
      (el) => Object.keys(el).length
    );
    return formFields.reduce((acc, curr) => {
      const { name } = curr;
      return { [name]: '', ...acc };
    }, {});
  }

  getFieldsConfig = () => {
    let fieldConfig = {
      fieldConstants: {},
      commonFields: {},
    };
    const fieldConstants = InvestmentConstants.map((fieldObj, index) => {
      return {
        ...fieldObj,
        fields: fieldObj.fields.filter(
          (field) => !commonFields.includes(field.name)
        ),
      };
    });
    fieldConfig = {
      fieldConstants: fieldConstants,
      commonFields: commonFields,
    };
    return fieldConfig;
  };

  render() {
    const { storeValues } = this.props;
    return (
      <>
        <PlannerTabsContent
          ref={this.PlannerTabsRef}
          fieldsConstants={[...InvestmentConstants]}
          onSimulateSave={this.saveFormFieldsInStore}
          tabLabelPrefix="Member"
          storeValues={storeValues}
          componentName={INVESTMENT_COMPONENT}
          fieldsConfig={this.getFieldsConfig()}
        />
      </>
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(
  InvestmentComponent
);
