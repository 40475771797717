import { DATATYPES } from '../common';
import { OUTPUT_FIELD_TYPE } from '../retirementPlan';

export const dependentFields = ['getMarketData', 'invValueAsOf'];
export const InvestmentBalancesConstants = [
  {
    label: 'Traditional 401k',
    name: 'Traditional401k',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Roth 401k',
    name: 'Roth401k',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Traditional IRA',
    name: 'TraditionalIRA',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Roth IRA',
    name: 'RothIRA',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Other Investments',
    name: 'OtherInvestments',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Disposable cash',
    name: 'DisposableCash',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Dividend Portfolio',
    name: 'DividendPortfolio',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },

  {
    label: 'Emergency cash',
    name: 'EmergencyCash',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Deferred Compensation',
    name: 'DeferredCompensation',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'Pension',
    name: 'Pension',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {
    label: 'RSU/PSU',
    name: 'RSU/PSU',
    colSize: 6,
    type: 'number',
    errorText: 'value should be greater than 0',
    isDollarSignRequired: true,
    fieldType: OUTPUT_FIELD_TYPE,
  },
  {},
  {},

  {
    label: 'Value as of',
    name: 'invValueAsOf',
    colSize: 6,
    type: 'select',
    options: [],
    isDisabled: true,
  },
  // {
  //   label: 'Get market data',
  //   name: 'getMarketData',
  //   colSize: 6,
  //   type: 'select',
  //   options: [
  //     {
  //       label: 'Yes',
  //       value: true,
  //     },
  //     {
  //       label: 'No',
  //       value: false,
  //     },
  //   ],
  // },
  {},
  {},
  {},
  {},
];
