import React, { Component } from 'react';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import {
  setMainChartOptions,
  setSubChartOptions,
  setPieChartsData,
  setMainChartOptionsArearange,
  setMainChartOptionsLine,
} from '../components/shared/ChartData';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import ChartContainer from './ChartContainer';
import Button from '@mui/material/Button';
import PushPinIcon from '@mui/icons-material/PushPin';
import { green } from '@mui/material/colors';
// import accessibility from 'highcharts/modules/accessibility.js';
// accessibility(Highcharts);
highchartsMore(Highcharts);
drilldown(Highcharts);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'grey',
    boxSizing: 'border-box',
  },
}));
export class MainChartsContainer extends Component {
  constructor(props) {
    super(props);
    // Create a ref to store the chart instance
    this.chartRef = React.createRef();
  }
  getChart = (chart) => {
    this.chartRef.current = chart;
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.chartRef.current) {
      this.chartRef.current.reflow();
    }
  }

  render() {
    const { options, selectedChart, index, colSize, chartType } = this.props;
    const selectedChartId = index ? selectedChart + '_' + index : selectedChart;
    return (
      <HighchartsReact
        id={selectedChartId}
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
        callback={this.getChart}
      />
    );
  }
}

class ChronoChartsContainer extends Component {
  state = {
    mainChartData: {},
    nowChartsData: {},
    nearTermData: {},
    mediumTermData: {},
    longTermData: {},
    isPieAtVariousAges: false,
    isPieAtDrillDownLevel: false,
    isPieChartsAvailable: false,
    isDrillDown: false,
    canDataRender: false,
    drillDownPieChartsData: {},
    isShowRightAxis: false,
    isRightAxisAvailable: false,
  };

  drillupCallback = () => {
    this.setState({
      isDrillDown: false,
      canDataRender: false,
      drillDownPieChartsData: {},
    });
  };

  drilldownCallback = (e) => {
    const { selectedChart, chartsData, series } = this.props;
    const totalOrSurplusData =
      chartsData &&
      chartsData[series] &&
      chartsData[series].yaxis &&
      chartsData[series].yaxis.length > 0 &&
      chartsData[series].yaxis.filter((f) => f.rightAxis);
    let totalOrSurplusDataSeriesName = '';
    if (totalOrSurplusData.length > 0) {
      totalOrSurplusDataSeriesName = totalOrSurplusData[0].seriesName;
    }
    if (e && e.seriesOptions && chartsData && chartsData[series]) {
      const yAxisLeftLabel = chartsData[series].yaxisLeftLabel
        ? chartsData[series].yaxisLeftLabel
        : '';
      const chartName = chartsData[series].chartName
        ? chartsData[series].chartName
        : '';
      const drillDownPieChartsData = setPieChartsData(
        e,
        chartName,
        totalOrSurplusDataSeriesName,
        yAxisLeftLabel
      );
      this.setState({
        isDrillDown: true,
        isPieAtVariousAges: false,
        drillDownPieChartsData,
      });
    }
  };

  generateSubChartsData = () => {
    const { chartsData, series } = this.props;
    if (
      chartsData &&
      chartsData[series] &&
      chartsData.sub &&
      chartsData.sub.length > 0
    ) {
      const yAxisLeftLabel =
        (chartsData[series].yaxisLeftLabel &&
          chartsData[series].yaxisLeftLabel !== '' &&
          chartsData[series].yaxisLeftLabel) ||
        '';
      const nowChartsData = setSubChartOptions(
        chartsData.sub[0],
        'LTS', // to distinguish cta pie chart and lifetime simulation pies
        yAxisLeftLabel
      );
      const nearTermData = setSubChartOptions(
        chartsData.sub[1],
        'LTS',
        yAxisLeftLabel
      );
      const mediumTermData = setSubChartOptions(
        chartsData.sub[2],
        'LTS',
        yAxisLeftLabel
      );
      const longTermData = setSubChartOptions(
        chartsData.sub[3],
        'LTS',
        yAxisLeftLabel
      );
      this.setState({
        nowChartsData,
        nearTermData,
        mediumTermData,
        longTermData,
      });
    }
  };

  isPieChartsAvailableFn = () => {
    const { chartsData, selectedChart } = this.props;
    if (
      chartsData &&
      chartsData.sub &&
      chartsData.sub.length > 0 &&
      chartsData.numSubCharts > 0
    ) {
      this.setState({ isPieChartsAvailable: true });
    } else {
      this.setState({ isPieChartsAvailable: false });
    }
  };

  isRightAxisAvailableFn = () => {
    const { chartsData, selectedChart, series } = this.props;
    const rightAxisData =
      chartsData &&
      chartsData[series] &&
      chartsData[series].yaxis &&
      chartsData[series].yaxis.length > 0 &&
      chartsData[series].yaxis.filter((f) => f.rightAxis)[0];
    if (rightAxisData && Object.keys(rightAxisData).length > 0) {
      this.setState({ isRightAxisAvailable: true });
    } else {
      this.setState({ isRightAxisAvailable: false });
    }
  };

  setMainChartOptionsFn = (isShowRightAxis) => {
    const { chartsData, series } = this.props;
    const chartType =
      (chartsData &&
        chartsData &&
        chartsData[series] &&
        chartsData[series].leftChartStyle) ||
      '';
    let mainChartData = {};
    if (chartType) {
      if (chartType.toLowerCase() === 'stacked') {
        mainChartData = setMainChartOptions(
          chartsData[series],
          this.drillupCallback,
          this.drilldownCallback,
          isShowRightAxis
        );
      } else if (chartType.toLowerCase() === 'line') {
        mainChartData = setMainChartOptionsLine(chartsData[series]);
      } else {
        mainChartData = setMainChartOptionsArearange(chartsData[series]);
      }
    }
    this.setState(
      {
        mainChartData: mainChartData,
      },
      () => {
        this.isPieChartsAvailableFn();
        this.isRightAxisAvailableFn();
      }
    );
  };

  generateChartData = () => {
    const { chartsData, series } = this.props;
    const { isShowRightAxis } = this.state;
    if (
      chartsData &&
      Object.keys(chartsData).length > 0 &&
      chartsData.hasOwnProperty(series)
    ) {
      this.setMainChartOptionsFn(isShowRightAxis);
    } else {
      this.setState(
        {
          mainChartData: {},
        },
        () => {
          this.isPieChartsAvailableFn();
          this.isRightAxisAvailableFn();
        }
      );
    }
  };

  componentDidMount() {
    const { chartsData } = this.props;
    if (chartsData && Object.keys(chartsData).length > 0) {
      this.generateChartData();
      this.generateSubChartsData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedChart, chartsData } = this.props;
    const {
      isPieAtDrillDownLevel,
      isPieAtVariousAges,
      isDrillDown,
      canDataRender,
      isShowRightAxis,
    } = this.state;
    const { isShowRightAxis: prevIsShowRightAxis } = prevState;
    const { selectedChart: prevSelectedChart, chartsData: prevChartsData } =
      prevProps;
    if (
      chartsData &&
      Object.keys(chartsData).length > 0 &&
      selectedChart !== '' &&
      (!isEqual(selectedChart, prevSelectedChart) ||
        !isEqual(chartsData, prevChartsData))
    ) {
      this.setState(
        {
          isDrillDown: false,
          isPieAtDrillDownLevel: false,
          isPieAtVariousAges: false,
          mainChartData: {},
        },
        () => {
          this.generateChartData();
          this.generateSubChartsData();
        }
      );
    }
    if (
      isDrillDown &&
      isPieAtDrillDownLevel &&
      isPieAtVariousAges &&
      !canDataRender
    ) {
      this.setState({ canDataRender: true });
    }
  }

  switchToPieAtVariousAges = (event) => {
    const { isDrillDown } = this.state;
    const isPieAtVariousAges = event.target.checked ? true : false;
    if (isDrillDown && isPieAtVariousAges) {
      this.setState({ isPieAtVariousAges });
    } else {
      this.setState({ isPieAtVariousAges, isDrillDown: false });
    }
  };

  switchToPieAtDrillDownLevel = (event) => {
    const { isDrillDown } = this.state;
    const isPieAtDrillDownLevel = event.target.checked ? true : false;
    if (isDrillDown && isPieAtDrillDownLevel) {
      this.setState({ isPieAtDrillDownLevel, isPieAtVariousAges: true });
    } else {
      this.setState({
        isPieAtDrillDownLevel,
        isDrillDown: false,
        isPieAtVariousAges: false,
      });
    }
  };

  pushPinCharts = () => {
    const { triggerPushPinCharts, chronoTabItem, selectedChart } = this.props;
    const pushPinName = `${chronoTabItem} - ${selectedChart}`;
    const pushPinReq = {
      pushPinName: pushPinName,
    };
    triggerPushPinCharts({ pushPinReq, pushPinName });
  };

  showRightAxis = (event) => {
    const isShowRightAxis = event.target.checked ? true : false;
    this.setState({ isShowRightAxis });
  };

  render() {
    const {
      selectedChart,
      chartsData,
      isDashboard,
      isFaq,
      pushPinLabel,
      isSwitch = true,
      itemsLength,
      series,
      from,
    } = this.props;
    const {
      mainChartData,
      nowChartsData,
      nearTermData,
      mediumTermData,
      longTermData,
      isPieAtDrillDownLevel,
      isPieAtVariousAges,
      isPieChartsAvailable,
      isDrillDown,
      drillDownPieChartsData,
      isShowRightAxis,
      isRightAxisAvailable,
    } = this.state;
    const chartInfo =
      (chartsData &&
        Object.keys(chartsData).length > 0 &&
        chartsData &&
        chartsData[series] &&
        chartsData[series].yaxis &&
        chartsData[series].yaxis.length > 0 &&
        chartsData[series].yaxis.filter((f) => f.rightAxis)[0]) ||
      {};
    const size = itemsLength >= 3 ? 12 : 6;
    return (
      <div
        className="chrono-charts-container"
        style={isFaq && { margin: '0px' }}
      >
        <div
          className="ant-switch"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!from && isPieChartsAvailable && isDrillDown && isSwitch && (
            <FormControlLabel
              control={
                <AntSwitch
                  sx={{ m: 1 }}
                  checked={isPieAtDrillDownLevel}
                  onChange={this.switchToPieAtDrillDownLevel}
                />
              }
              label={'Pie at specific age'}
            />
          )}
          {!from && isPieChartsAvailable && !isDrillDown && isSwitch && (
            <FormControlLabel
              control={
                <AntSwitch
                  sx={{ m: 1 }}
                  checked={isPieAtVariousAges}
                  onChange={this.switchToPieAtVariousAges}
                />
              }
              label={'Pie (At Various Ages)'}
            />
          )}
          {!from &&
          isDashboard &&
          !isPieChartsAvailable &&
          !isFaq &&
          isSwitch ? (
            <FormControlLabel
              control={
                <AntSwitch
                  disabled={true}
                  sx={{ m: 1 }}
                  checked={isPieAtVariousAges}
                  onChange={this.switchToPieAtVariousAges}
                />
              }
              label={'Pie (At Various Ages)'}
            />
          ) : (
            <></>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!from && isRightAxisAvailable && isSwitch && (
              <FormControlLabel
                control={
                  <AntSwitch
                    sx={{ m: 1 }}
                    checked={isShowRightAxis}
                    onChange={this.showRightAxis}
                  />
                }
                label={
                  (chartInfo &&
                    Object.keys(chartInfo).length > 0 &&
                    chartInfo.seriesName) ||
                  'Right axis'
                }
              />
            )}
            {!from && isDashboard && (
              <Button
                variant="contained"
                startIcon={<PushPinIcon />}
                style={{
                  backgroundColor:
                    pushPinLabel === 'Pinned' ? green[500] : '#225ea8',
                }}
                onClick={this.pushPinCharts}
              >
                {pushPinLabel}
              </Button>
            )}
          </div>
        </div>
        {isDashboard ? (
          <Grid container spacing={2} className="chrono-list-wrapper">
            {!isPieAtVariousAges ? (
              <Grid item xs={12} md={12} lg={12}>
                {Object.keys(mainChartData).length > 0 && (
                  <MainChartsContainer
                    options={mainChartData}
                    selectedChart={selectedChart}
                    chartType="main"
                  />
                )}
              </Grid>
            ) : isPieAtDrillDownLevel && isDrillDown ? (
              <Grid item xs={12} md={12} lg={12}>
                {Object.keys(drillDownPieChartsData).length > 0 && (
                  <ChartContainer
                    options={drillDownPieChartsData}
                    selectedChart={selectedChart}
                    chartType="main"
                  />
                )}
              </Grid>
            ) : (
              <>
                {/* <Grid item xs={12} md={12} lg={12}>
                  {Object.keys(mainChartData).length > 0 && (
                    <MainChartsContainer
                      options={mainChartData}
                      selectedChart={selectedChart}
                      chartType="main"
                    />
                  )}
                </Grid> */}
                <Grid item xs={3} md={3} lg={3}>
                  {Object.keys(nowChartsData).length > 0 && (
                    <ChartContainer
                      chartType="now"
                      selectedChart={selectedChart}
                      options={nowChartsData}
                    />
                  )}
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  {Object.keys(nearTermData).length > 0 && (
                    <ChartContainer
                      chartType="near"
                      options={nearTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  {Object.keys(mediumTermData).length > 0 && (
                    <ChartContainer
                      chartType="medium"
                      options={mediumTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  {Object.keys(longTermData).length > 0 && (
                    <ChartContainer
                      chartType="long"
                      options={longTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2} className="chrono-list-wrapper">
            {!isPieAtVariousAges ? (
              <Grid item xs={12} md={12} lg={12}>
                {Object.keys(mainChartData).length > 0 && (
                  <MainChartsContainer
                    options={mainChartData}
                    selectedChart={selectedChart}
                    chartType="main"
                  />
                )}
              </Grid>
            ) : isPieAtDrillDownLevel && isDrillDown ? (
              <Grid item xs={12} md={12} lg={12}>
                {Object.keys(drillDownPieChartsData).length > 0 && (
                  <ChartContainer
                    options={drillDownPieChartsData}
                    selectedChart={selectedChart}
                    chartType="main"
                  />
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={size} lg={size}>
                  {Object.keys(nowChartsData).length > 0 && (
                    <ChartContainer
                      chartType="now"
                      selectedChart={selectedChart}
                      options={nowChartsData}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={size} lg={size}>
                  {Object.keys(nearTermData).length > 0 && (
                    <ChartContainer
                      chartType="near"
                      options={nearTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={size} lg={size}>
                  {Object.keys(mediumTermData).length > 0 && (
                    <ChartContainer
                      chartType="medium"
                      options={mediumTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={size} lg={size}>
                  {Object.keys(longTermData).length > 0 && (
                    <ChartContainer
                      chartType="long"
                      options={longTermData}
                      selectedChart={selectedChart}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        )}
      </div>
    );
  }
}

export default ChronoChartsContainer;
