export const GET_COMPANIES_INFO = 'companies';
export const GET_COMPANIES_INFO_ERROR = 'companies:error';
export const GET_COMPANIES_INFO_SUCCESS = 'companies:success';

export function getCompaniesInfoAction(payload) {
  return {
    type: GET_COMPANIES_INFO,
    payload: payload,
  };
}

export function getCompaniesInfoSuccessAction(payload) {
  return {
    type: GET_COMPANIES_INFO_SUCCESS,
    payload,
  };
}

export function getCompaniesInfoErrorAction(payload) {
  return {
    type: GET_COMPANIES_INFO_ERROR,
    payload,
  };
}
