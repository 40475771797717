import { pick } from 'lodash';
import {
  ProfileConstants,
  commonFields,
} from '../../../constants/IndividualClient/ProfileConstants';
import {
  defaultProfileConstants,
  defaultIncomeConstants,
  defaultExpenseConstants,
  defaultSavingsConstants,
  defaultInvestmentConstants,
  defaultRetirementConstants,
  defaultInsuranceConstants,
  defaultOtherGoalsConstants,
  defaultAssetGoalsConstants,
  defaultDebtConstants,
} from '../../../constants/IndividualClient/defaultStoreState/defaultProfileConstants';
import { IncomeConstants } from '../../../constants/IndividualClient/IncomeConstants';
import { SavingsConstants } from '../../../constants/IndividualClient/SavingsConstants';
import { InvestmentConstants } from '../../../constants/IndividualClient/InvestmentConstants';
import {
  RetirementConstants,
  commonFields as RetCommonFields,
} from '../../../constants/IndividualClient/RetirementConstants';
import { ExpenseConstants } from '../../../constants/IndividualClient/ExpenseConstants';
import { InsuranceConstants } from '../../../constants/IndividualClient/InsuranceConstants';
import { OtherGoalsConstants } from '../../../constants/IndividualClient/OtherGoalsConstants';
import { AssetGoalsConstants } from '../../../constants/IndividualClient/AssetGoalsConstants';
import { DebtConstants } from '../../../constants/IndividualClient/DebtConstants';

let memberName = {
  elder: '',
  younger: '',
};

const transformMemberNametoCustom = (storeValues, constantObj) => {
  return storeValues.map((value, index) => {
    const nonCommonFields = constantObj[index].fields
      .filter((field) => !commonFields.includes(field.name))
      .map((field) => field.name);
    return {
      [constantObj[index].label]: {
        ...pick(Object.values(value)[0], nonCommonFields),
      },
    };
  });
};

export const getProfileStoreValues = (wageAndExpenseData) => {
  const wagesAndExpenseProfile = Object.keys(wageAndExpenseData)
    .sort()
    .reduce((obj, key) => {
      obj[key] = wageAndExpenseData[key];
      return obj;
    }, {});
  if (Object.keys(wagesAndExpenseProfile).length > 0) {
    const commonAttr = commonFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: wagesAndExpenseProfile[curr],
      };
    }, {});
    const otherValues = Object.keys(wagesAndExpenseProfile).reduce(
      (acc, curr) => {
        if (
          wagesAndExpenseProfile[curr] &&
          typeof wagesAndExpenseProfile[curr] === 'object' &&
          Object.keys(wagesAndExpenseProfile[curr]).length > 0
        ) {
          memberName = {
            ...memberName,
            [curr]: wagesAndExpenseProfile[curr].name,
          };
          const memberValues = wagesAndExpenseProfile[curr];
          acc.push({
            [memberName[curr]]: {
              ...memberValues,
              name: curr,
              label: memberName[curr],
              // ...commonAttr,
            },
          });
        }
        return acc;
      },
      []
    );

    const newOtherValues = {
      ...commonAttr,
      memberValues: transformMemberNametoCustom(otherValues, ProfileConstants),
    };
    return newOtherValues;
  }
  return defaultProfileConstants;
};

export const getIncomeStoreValues = (wagesDetails, savingsDetails) => {
  if (
    Object.keys(wagesDetails).length > 0 &&
    Object.keys(savingsDetails).length > 0
  ) {
    const otherValues = Object.keys(wagesDetails).reduce((acc, curr) => {
      if (
        wagesDetails[curr] &&
        typeof wagesDetails[curr] === 'object' &&
        Object.keys(wagesDetails[curr]).length > 0
      ) {
        const wagesValue = wagesDetails[curr];
        const savingsValue = savingsDetails[curr];
        acc.push({
          [memberName[curr]]: {
            ...wagesValue,
            ...savingsValue,
            name: curr,
            label: memberName[curr],
            // ...commonAttr,
          },
        });
      }
      return acc;
    }, []);

    const newOtherValues = {
      memberValues: transformMemberNametoCustom(otherValues, IncomeConstants),
    };
    return newOtherValues;
  }

  return defaultIncomeConstants;
};

export const geExpenseStoreValues = (expensedetails) => {
  if (Object.keys(expensedetails).length > 0) {
    const {
      expenses: { aggNonHousing, rental },
      useAggNonHousingExpense,
      discPercentOfAgg,
      discHorFactor,
      discRetFactor1,
    } = expensedetails;
    const expenseValues = {
      useAggNonHousingExpense,
      discPercentOfAgg,
      discHorFactor,
      discRetFactor1,
      aggNonHousing,
      rental,
    };

    const otherValues = ExpenseConstants.map((field, index) => {
      return {
        [field.label]: field.fields.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.name]: expenseValues[curr.name],
          };
        }, {}),
      };
    });

    const newOtherValues = {
      memberValues: otherValues,
    };
    return newOtherValues;
  }

  return defaultExpenseConstants;
};

export const getInsuranceStoreValues = (insuranceDetails) => {
  if (Object.keys(insuranceDetails).length > 0) {
    const { termLifeIns, wholeLifeIns } = insuranceDetails;
    if (termLifeIns.length > 0 && wholeLifeIns.length > 0) {
      const otherValues = InsuranceConstants.map((field, index) => {
        if (field.label === 'term life') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: termLifeIns[0][curr.name],
              };
            }, {}),
          };
        }

        if (field.label === 'whole life') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: wholeLifeIns[0][curr.name],
              };
            }, {}),
          };
        }
        return { ...field };
      });
      const newOtherValues = {
        memberValues: otherValues,
      };
      return newOtherValues;
    }
  }

  return defaultInsuranceConstants;
};

export const getOtherGoalsStoreValues = (otherGoalsValues) => {
  if (Object.keys(otherGoalsValues).length > 0) {
    const { vacations, kids } = otherGoalsValues;
    if (kids.length > 0 && vacations.length > 0) {
      const otherValues = OtherGoalsConstants.map((field, index) => {
        if (field.label === 'vacation') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: vacations[0][curr.name],
              };
            }, {}),
          };
        }

        if (field.label === 'kid') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: kids[0][curr.name],
              };
            }, {}),
          };
        }
        return { ...field };
      });
      const newOtherValues = {
        memberValues: otherValues,
      };
      return newOtherValues;
    }
  }
  return defaultOtherGoalsConstants;
};

export const getDebtStoreValues = (debtValues) => {
  if (Object.keys(debtValues).length > 0) {
    const { creditCards, otherLoans, studentLoans } = debtValues;
    if (
      creditCards.length > 0 &&
      otherLoans.length > 0 &&
      studentLoans.length > 0
    ) {
      const otherValues = DebtConstants.map((field, index) => {
        if (field.label === 'credit cards') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: creditCards[0][curr.name],
              };
            }, {}),
          };
        }

        if (field.label === 'student loans') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: studentLoans[0][curr.name],
              };
            }, {}),
          };
        }
        if (field.label === 'other loans') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: otherLoans[0][curr.name],
              };
            }, {}),
          };
        }
        return { ...field };
      });
      const newOtherValues = {
        memberValues: otherValues,
      };
      return newOtherValues;
    }
  }
  return defaultDebtConstants;
};
export const getAssetGoalsStoreValues = (assetGoalsValues) => {
  if (Object.keys(assetGoalsValues).length > 0) {
    const { vehicles, primaryHome } = assetGoalsValues;
    if (Object.keys(primaryHome).length > 0 && vehicles.length > 0) {
      const otherValues = AssetGoalsConstants.map((field, index) => {
        if (field.label === 'home') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: primaryHome[curr.name],
              };
            }, {}),
          };
        }
        if (field.label === 'vehicles') {
          return {
            [field.label]: field.fields.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: vehicles[0][curr.name],
              };
            }, {}),
          };
        }

        return { ...field };
      });
      const newOtherValues = {
        memberValues: otherValues,
      };
      return newOtherValues;
    }
  }
  return defaultAssetGoalsConstants;
};

export const getSavingsStoreValues = (savingsDetails) => {
  if (Object.keys(savingsDetails).length > 0) {
    const otherFieldNames = SavingsConstants.map((profile) => profile.name);

    const otherValues = Object.keys(savingsDetails).reduce((acc, curr) => {
      if (
        savingsDetails[curr] &&
        typeof savingsDetails[curr] === 'object' &&
        Object.keys(savingsDetails[curr]).length > 0
      ) {
        const savingsValue = savingsDetails[curr];
        acc.push({
          [memberName[curr]]: {
            ...savingsValue,
            name: curr,
            label: memberName[curr],
            // ...commonAttr,
          },
        });
      }
      return acc;
    }, []);

    const newOtherValues = {
      memberValues: transformMemberNametoCustom(otherValues, SavingsConstants),
    };
    return newOtherValues;
  }
  return defaultSavingsConstants;
};

export const getInvestmentStoreValues = (infoInvBalances) => {
  if (Object.keys(infoInvBalances).length > 0) {
    const otherFieldNames = InvestmentConstants.map((profile) => profile.name);
    const firstInvMember = infoInvBalances.first;
    const secondInvMember = infoInvBalances.second;
    if (firstInvMember && secondInvMember) {
      const memberValues = [
        {
          'member-1': {
            trad401k: firstInvMember.trad401k,
            roth401k: firstInvMember.roth401k,
            tradIra: firstInvMember.tradIra,
            rothIra: firstInvMember.rothIra,
            emCash: firstInvMember.emCash,
            brokerage: firstInvMember.brokerage,
            pension: firstInvMember.pension,
            deferredComp: firstInvMember.deferredComp,
            name: 'member-2',
            rsu: firstInvMember.rsu,
          },
        },
        {
          'member-2': {
            trad401k: secondInvMember.trad401k,
            roth401k: secondInvMember.roth401k,
            tradIra: secondInvMember.tradIra,
            rothIra: secondInvMember.rothIra,
            emCash: secondInvMember.emCash,
            brokerage: secondInvMember.brokerage,
            pension: secondInvMember.pension,
            deferredComp: secondInvMember.deferredComp,
            name: 'member-2',
            rsu: secondInvMember.rsu,
          },
        },
      ];
      const newOtherValues = {
        memberValues,
      };
      return newOtherValues;
    }
    return defaultInvestmentConstants;
  }
  return defaultInvestmentConstants;
};

export const getRetirementChoiceStoreValues = (infoRetirementChoices) => {
  if (Object.keys(infoRetirementChoices).length > 0) {
    const commonAttr = RetCommonFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: infoRetirementChoices[curr],
      };
    }, {});
    const otherValues = Object.keys(infoRetirementChoices).reduce(
      (acc, curr) => {
        if (
          infoRetirementChoices[curr] &&
          typeof infoRetirementChoices[curr] === 'object' &&
          Object.keys(infoRetirementChoices[curr]).length > 0
        ) {
          const savingsValue = infoRetirementChoices[curr];
          acc.push({
            [memberName[curr]]: {
              ...savingsValue,
              name: curr,
              label: memberName[curr],
              // ...commonAttr,
            },
          });
        }
        return acc;
      },
      []
    );

    const newOtherValues = {
      ...commonAttr,
      memberValues: transformMemberNametoCustom(
        otherValues,
        RetirementConstants
      ),
    };
    return newOtherValues;
  }
  return defaultRetirementConstants;
};

export const transformTaxStateOptions = (taxSlabs) => {
  const nonFederalTaxBody =
    taxSlabs &&
    taxSlabs.filter(
      (slab) =>
        slab.taxbody !== 'federal' &&
        slab.taxbody !== 'stddeduction' &&
        slab.taxbody !== 'longtermcapitalgains' &&
        slab.taxbody !== 'qualifieddividends'
    );
  const set = new Set();
  const uniqueTaxBodySlabs = nonFederalTaxBody.filter(
    (taxSlab) => !set.has(taxSlab.taxbody) && set.add(taxSlab.taxbody)
  );
  const taxStateOptions = uniqueTaxBodySlabs.map((taxSlab) => ({
    label: taxSlab.taxbody.toUpperCase(),
    value: taxSlab.taxbody.toUpperCase(),
    key: taxSlab.taxbody.toUpperCase(),
  }));
  return taxStateOptions.sort((option1, option2) =>
    option1.value.localeCompare(option2.value)
  );
};
