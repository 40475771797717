import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Controls from './controls/Controls';
import '../styles/resetPassword.scss';
import history from '../history';
import { sendPassCode, verifyPassCode } from '../services/dashboardSvc';
import Picture1 from '../images/Picture1.png';
import Greenr_Logo from '../images/Greenr_Logo.svg';
import Stack from '@mui/material/Stack';
import { validateEmailId, validateInputFieId } from '../utils/validators';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let snackBarMessage = '';

const ResetPassword = (props) => {
  const location = useLocation();
  const { isForceChangePassword = false, loginMsg = '' } = location.state || {}; // Access the state data from location
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [isAPIFailed, setIsAPIFailed] = useState(false);
  const [openSnackbar, setIsSnackbarOpen] = useState(false);
  const [passCodeSent, setPassCodeSent] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [forceChangePasswordAlert, setForceChangePasswordAlert] =
    useState(false);
  const [formDetails, setFormDetails] = useState({
    userName: '',
    newPassCode: '',
  });

  useEffect(() => {
    if (isForceChangePassword) {
      setForceChangePasswordAlert(true);
    }
  }, [isForceChangePassword]);

  const launchHomePage = () => {
    history.push({ pathname: '/' });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const verifyPassCodeSent = async () => {
    setIsAPILoading(true);
    setIsAPIFailed(false);
    const { userName, newPassCode } = formDetails;
    try {
      const { status, data } = await verifyPassCode(userName, newPassCode);
      setIsAPILoading(false);
      if (status === 200) {
        snackBarMessage = 'Please set your new password to login.';
        setIsAPIFailed(false);
        setIsSnackbarOpen(true);
        setTimeout(() => {
          history.push({
            pathname: '/changePassword',
            state: { userName },
          });
        }, 3000);
      } else {
        setIsAPIFailed(false);
      }
    } catch (error) {
      if (error.response) {
        setFailedMessage(
          error.response
            ? error.response.data
            : 'Something went wrong, please try again'
        );
      } else {
        setFailedMessage('Something went wrong, please try again');
      }
      setIsSnackbarOpen(false);
      setIsAPILoading(false);
      setIsAPIFailed(true);
    }
  };

  const resetPasswordContinue = (e) => {
    e.preventDefault();
    if (!passCodeSent) sendPassCodeEmail();
    else verifyPassCodeSent();
  };

  const sendPassCodeEmail = async () => {
    setIsAPILoading(true);
    setIsAPIFailed(false);
    setPassCodeSent(false);
    const { userName } = formDetails;
    try {
      const { status, data } = await sendPassCode(userName);
      if (status === 200) {
        snackBarMessage =
          'A Passcode has been sent to your Email Id, please enter the passcode to set your new password.';
        setIsAPIFailed(false);
        setIsSnackbarOpen(true);
        setPassCodeSent(true);
        setIsAPILoading(false);
        setFailedMessage('');
      } else {
        setIsAPIFailed(true);
        setFailedMessage('Something went wrong, please try again');
        setPassCodeSent(false);
        setIsAPILoading(false);
      }
    } catch (error) {
      if (error.response) {
        setFailedMessage(
          error.response
            ? error.response.data
            : 'Something went wrong, please try again'
        );
      } else {
        setFailedMessage('Something went wrong, please try again');
      }
      setIsSnackbarOpen(false);
      setIsAPILoading(false);
      setIsAPIFailed(true);
      setPassCodeSent(false);
    }
  };

  const onInputChange = (event, name) => {
    const { value } = (event && event.target) || '';
    let isValid = true;
    switch (name && name.toLowerCase()) {
      case 'username':
        isValid = validateEmailId(value);
        break;
      case 'newpasscode':
        isValid = validateInputFieId(value);
        break;
      default:
        break;
    }
    setFormDetails((old) => ({
      ...old,
      [name]: value,
      [`is${name}Valid`]: isValid,
    }));
    setFailedMessage('');
    setForceChangePasswordAlert(false);
    setIsAPIFailed(false);
  };

  useEffect(() => {
    const disableContinue = () => {
      return (
        (formDetails && !formDetails.isuserNameValid && !passCodeSent) ||
        (formDetails &&
          formDetails.isuserNameValid &&
          passCodeSent &&
          !formDetails.isnewPassCodeValid) ||
        failedMessage
      );
    };
    const isDisabled = disableContinue();
    setIsDisabled(isDisabled);
  }, [formDetails, passCodeSent, failedMessage]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAPILoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light greener_website_nav">
          <div className="container">
            <span className="navbar-brand">
              <img src={Greenr_Logo} alt="" title="" />
            </span>
          </div>
        </nav>
      </header>
      <main>
        <section className="Grenner_Financial_Roots_main_outer">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-6">
                <div className="images">
                  <img src={Picture1} title="" alt="" />
                </div>
              </div>

              <div className="col-xl-5 col-lg-6">
                <div className="banner_main_title">
                  <div className="main_title">
                    <h3 className="text-white">Building</h3>
                    <h2 className="text-white">Financial Roots</h2>
                  </div>
                </div>
                <div className="tab-container-one">
                  <form onSubmit={resetPasswordContinue}>
                    <div className="row">
                      <div style={{ color: '#fff' }}>
                        <h5>Forgot Password</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <Controls.Input
                          name="userName"
                          label="Username"
                          className="text-white"
                          value={formDetails?.userName || ''}
                          type="email"
                          onChange={(e) => onInputChange(e, 'userName')}
                          errorText={
                            formDetails?.userName
                              ? 'Invalid Email ID'
                              : 'This is a mandatory field'
                          }
                          isValid={formDetails?.isuserNameValid}
                          placeholder={'  Email'}
                          disabled={passCodeSent}
                          required
                        />
                      </div>
                    </div>
                    {passCodeSent && (
                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <Controls.Input
                            label="Passcode"
                            type="text"
                            className="text-white"
                            placeholder="  Enter the passcode sent"
                            value={formDetails.newPassCode}
                            onChange={(e) => onInputChange(e, 'newPassCode')}
                            errorText={
                              formDetails.newPassCode
                                ? 'Invalid passcode'
                                : 'This is a mandatory field'
                            }
                            isValid={formDetails?.isnewPassCodeValid}
                            required
                          />
                        </div>
                      </div>
                    )}
                    {forceChangePasswordAlert && (
                      <Stack
                        sx={{
                          width: '100%',
                          // padding: '5px',
                          margin: '10px 0px',
                        }}
                        spacing={2}
                      >
                        <MuiAlert severity="error">{loginMsg}</MuiAlert>
                      </Stack>
                    )}
                    {isAPIFailed && failedMessage && (
                      <Stack
                        sx={{
                          width: '100%',
                          // padding: '5px',
                          margin: '10px 0px',
                        }}
                        spacing={2}
                      >
                        <MuiAlert severity="error">{failedMessage}</MuiAlert>
                      </Stack>
                    )}
                    <div className="button_group">
                      <button
                        type="button"
                        className="common_button"
                        onClick={launchHomePage}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={
                          isDisabled
                            ? 'common_secondary_button'
                            : 'common_button'
                        }
                        disabled={isDisabled}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
