import { stateOptions } from '../retirementPlan';
import { DATATYPES } from '../common';

export const dependentFields = [
  'divRate',
  'emCashPercentForDiv',
  'withdrawRothFirst',
  'youngerAgeAtDivWD',
  'retirementState',
];

export const retirementChoicesConstants = [
  {
    label: 'Add to Divfolio @ age',
    name: 'youngerAgeAtDivWD',
    colSize: 3,
    type: 'number',
    errorText: 'Age should be greater than 0',
  },
  {
    label: 'Dividend rate',
    name: 'divRate',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Conv Emcash to div',
    name: 'emCashPercentForDiv',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {}, //Amount converted
  {},
  {},
  {
    label: 'Withdraw Roth first',
    name: 'withdrawRothFirst',
    colSize: 4,
    type: 'select',
    options: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
    defaultValue: true,
  },
  {
    label: 'Retirement State',
    name: 'retirementState',
    colSize: 3,
    type: 'select',
    options: [],
  },
  {},
  {},
  {},
  {},
  {
    label: 'Retirement Age',
    name: 'retAge',
    colSize: 4,
    type: 'number',
    errorText: 'Expenses should be greater than 0',
  },
  {
    label: 'Social security age',
    name: 'socialSecAge',
    colSize: 3,
    type: 'number',
    errorText: 'Expenses should be greater than 0',
  },

  {
    label: 'Social security Amt',
    name: 'socialSecAmount',
    colSize: 4,
    type: 'number',
    isDollarSignRequired: true,

    errorText: 'Expenses should be greater than 0',
  },
  {
    label: 'Pension W/D Yrs',
    name: 'pensionWDYrs',
    colSize: 4,
    type: 'number',
    errorText: 'Expenses should be greater than 0',
  },
  {
    label: 'DefComp W/D Yrs',
    name: 'defCompWDYrs',
    colSize: 4,
    type: 'number',
    errorText: 'Expenses should be greater than 0',
  },
  {},
];
