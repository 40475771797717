import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LegendToggleOutlinedIcon from '@mui/icons-material/LegendToggleOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
//import InsightsIcon from '@mui/icons-material/Insights';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import Venkat_Mandava_logo1 from '../images/Venkat_Mandava_logo1.png';
import Venkat_Mandava_logo2 from '../images/Venkat_Mandava_logo2.png';
import Venkat_Mandava_logo3 from '../images/Venkat_Mandava_logo3.png';
import Venkat_Mandava_logo4 from '../images/Venkat_Mandava_logo4.png';
import Venkat_Mandava_logo5 from '../images/Venkat_Mandava_logo5.png';
import Venkat_Mandava_logo6 from '../images/Venkat_Mandava_logo6.png';
import Jonathan_Garrett_logo_1 from '../images/Jonathan_Garrett_logo_1.png';
import Jonathan_Garrett_logo_2 from '../images/Jonathan_Garrett_logo_2.png';
import Jonathan_Garrett_logo_3 from '../images/Jonathan_Garrett_logo_3.png';
import Saleem_Khan_logo_1 from '../images/Saleem_Khan_logo_1.png';
import Saleem_Khan_logo_2 from '../images/Saleem_Khan_logo_2.png';
import Saleem_Khan_logo_3 from '../images/Saleem_Khan_logo_3.png';
import Saleem_Khan_logo_4 from '../images/Saleem_Khan_logo_4.png';
import Suneel_saigal__logo_1 from '../images/Suneel_saigal__logo_1.png';
import Suneel_saigal__logo_2 from '../images/Suneel_saigal__logo_2.png';
import Suneel_saigal__logo_3 from '../images/Suneel_saigal__logo_3.png';
import Saleem_Khan from '../images/Saleem_Khan.png';
import Suneel_saigal from '../images/Suneel_saigal.png';
import Jonathan_Garrett from '../images/Jonathan_Garrett.png';
import Venkat_Mandava from '../images/Venkat_Mandava.png';
import SmartToyIcon from '@mui/icons-material/SmartToy';

export const renderTypes = ['Chart', 'Table', 'Data', 'Custom'];
export const ExpenseTypes = ['Lifestyle', 'Aggregate', 'Itemized'];
export const lifestyleTypes = [
  { label: 'Prudent', value: 'prudent' },
  { label: 'Generous', value: 'generous' },
  { label: 'Luxury', value: 'luxury' },
  // { label: 'Custom', value: 'custom' },
];
export const advisoryGridCol = [
  { headerName: 'User email', field: 'emailId', width: 200 },
  { headerName: 'User eame', field: 'name', width: 150 },
  { headerName: 'User phone', field: 'mobileNumber', width: 120 },
  { headerName: 'Is admin', field: 'isAdmin', width: 100 },
  { headerName: 'is active', field: 'isActive', width: 100 },
  { headerName: 'Created date', field: 'createdDate', width: 120 },
  { headerName: 'Created by', field: 'createdBy', width: 120 },
  {
    headerName: 'Approved date',
    field: 'modifiedDate',
    width: 140,
  },
  {
    headerName: 'Approved by',
    field: 'modifiedBy',
    width: 120,
  },
];

export const pendingUsersCol = [
  ...advisoryGridCol,
  {
    headerName: 'Approve',
    field: 'approve',
    width: 150,
  },
  {
    headerName: 'Reject',
    field: 'reject',
    width: 150,
  },
];

export const userTypeOptions = [
  {
    label: 'Direct',
    value: 'direct',
  },
  {
    label: 'Advisor',
    value: 'advisor',
  },
];

export const loginFormFields = {
  loginUserName: 'loginUserName',
  loginPassword: 'loginPassword',
};

export const resetPasswordFormFields = {
  resetUserName: 'resetUserName',
  resetPassword: 'resetPassword',
};

export const signupFormFields = [
  {
    key: 'firstName',
    name: 'First Name',
    isRequired: true,
    type: 'text',
    placeholder: 'First Name',
    errorMessage: 'Invalid First Name',
  },
  {
    key: 'lastName',
    name: 'Last Name',
    isRequired: true,
    type: 'text',
    placeholder: 'Last Name',
    errorMessage: 'Invalid Last Name',
  },
  {
    key: 'emailId',
    name: 'Email Id',
    isRequired: true,
    type: 'text',
    placeholder: 'Enter Valid Email',
    errorMessage: 'Invalid Email Id',
  },
  {
    key: 'mobileNumber',
    name: 'Mobile Number',
    isRequired: true,
    type: 'text',
    errorMessage: 'Invalid Mobile Number',
    placeholder: '(123) 456-7890',
  },
  // {
  //   key: 'password',
  //   name: 'Password *',
  //   isRequired: true,
  //   type: 'textBlur',
  //   errorMessage: 'Invalid Password',
  // },
  // {
  //   key: 'confirmPassword',
  //   name: 'Confirm Password *',
  //   isRequired: false,
  //   type: 'textBlur',
  //   errorMessage: 'Passwords do not match',
  // },
  {
    key: 'userType',
    name: 'User Type',
    isRequired: true,
    options: userTypeOptions,
    type: 'select',
    errorMessage: 'Please select User Type',
  },
  {
    key: 'location',
    name: 'Location',
    isRequired: true,
    options: [],
    type: 'select',
    errorMessage: 'Please select your location',
  },
  {
    key: 'company',
    name: 'Company',
    isRequired: true,
    type: 'text',
    placeholder: 'Company',
    errorMessage: 'Invalid Company',
  },
  {
    key: 'accessLevel',
    name: 'Access Level',
    isRequired: true,
    options: [],
    type: 'select',
    errorMessage: 'Please Select Access Level',
  },
  {
    key: 'privacyAccepted',
    name: 'I agree to the Privacy Policy',
    isRequired: false,
    type: 'checkbox',
    errorMessage: 'Please Select the privacy policy',
  },
];

export const contactUsFormFields = [
  {
    key: 'firstName',
    name: 'First Name',
    isRequired: true,
    placeholder: 'First Name',
    errorMessage: 'Invalid First Name',
  },
  {
    key: 'lastName',
    name: 'Last Name',
    isRequired: true,
    placeholder: 'Last Name',
    errorMessage: 'Invalid Last Name',
  },
  {
    key: 'emailId',
    name: 'Email Id',
    isRequired: true,
    placeholder: 'Enter valid Email',
    errorMessage: 'Invalid Email Id',
  },
  {
    key: 'mobileNumber',
    name: 'Mobile Number',
    isRequired: true,
    placeholder: '(123) 456-7890',
    errorMessage: 'Invalid Mobile Number',
  },
];

export const adminMenuOptions = ['User Management'];

export const newBaseSideMenuItems = [
  {
    label: 'Home',
    value: 'home',
    icon: HomeOutlinedIcon,
    isDisabled: false,
    link: '/home',
    isAdvisor: true,
  },
];

export const newSolutionsList = [
  {
    name: 'Investments',
    key: 'investments',
    subMenu: [],
    link: '/solutions?tab=Investments',
  },
  {
    name: 'Expenses',
    key: 'expenses',
    subMenu: [],
    link: '/solutions?tab=Expenses',
  },
  {
    name: 'Goals',
    key: 'goals',
    subMenu: [],
    link: '/solutions?tab=Goals',
  },
  {
    name: 'Insurance',
    key: 'insurance',
    subMenu: [
      { name: 'Term Life', key: 'termLife' },
      { name: 'Whole Life', key: 'wholeLife' },
      { name: 'Long Term Care', key: 'longTermCare' },
      { name: 'Umbrella', key: 'umbrella' },
      { name: 'Property', key: 'property' },
      { name: 'Auto', key: 'auto' },
      { name: 'Health', key: 'health' },
    ],
    link: '/solutions?tab=Insurance',
  },
  {
    name: 'Annuities',
    key: 'annuities',
    subMenu: [
      { name: 'Fixed', key: 'fixed' },
      { name: 'Index', key: 'index' },
      { name: 'Variable', key: 'variable' },
      { name: 'Immediate', key: 'immediate' },
      { name: 'Longevity', key: 'longevity' },
    ],
    link: '/solutions?tab=Annuities',
  },
  { name: 'Debt', key: 'debt', subMenu: [], link: '/solutions?tab=Debt' },
  // {
  //   name: 'Goals1',
  //   key: 'goals1',
  //   subMenu: [],
  // },
];

export const newSideMenuItems = [
  {
    label: 'Home',
    value: 'home',
    icon: HomeOutlinedIcon,
    isDisabled: false,
    link: '/home',
    isAdvisor: true,
  },
  {
    label: 'Build',
    value: 'build',
    isDisabled: false,
    icon: ManageAccountsIcon,
    link: '/build',
  },
  // {
  //   label: 'Mi boT',
  //   value: 'mibot',
  //   isDisabled: false,
  //   icon: SmartToyIcon,
  //   link: '/miboT',
  // },
  {
    label: 'Insights',
    value: 'insights',
    isDisabled: false,
    icon: ModelTrainingOutlinedIcon,
    link: '/insights',
  },
  {
    label: 'Solutions',
    value: 'solutions',
    isDisabled: false,
    icon: SavingsOutlinedIcon,
    link: '/solutions',
    subMenu: newSolutionsList,
  },
  {
    label: 'Reports',
    value: 'reports',
    isDisabled: false,
    icon: LegendToggleOutlinedIcon,
    link: '/reports',
  },
  // {
  //   label: 'CRM',
  //   value: 'crm',
  //   isDisabled: false,
  //   icon: NoteAltOutlinedIcon,
  //   link: '/crm',
  // },
];

export const sideMenuItems = [
  {
    label: 'Home',
    value: 'home',
    icon: HomeOutlinedIcon,
    link: '/home',
    isAdvisor: true,
  },
  {
    label: 'Client',
    value: 'client',
    isDisabled: false,
    icon: ManageAccountsIcon,
    link: '/build',
    isClient: true,
  },
  {
    label: 'Solutions',
    value: 'solutions',
    icon: SavingsOutlinedIcon,
    link: '/solutions',
  },
  {
    label: 'Reports',
    value: 'reports',
    icon: LegendToggleOutlinedIcon,
    link: '/reports',
  },
];

export const HorizonTerm = [
  { label: 'Near term', value: 'nearTerm' },
  { label: 'Medium term', value: 'mediumTerm' },
  { label: 'Long term', value: 'longTerm' },
  { label: 'Lifetime', value: 'lifetime' },
];

export const DisplayTerm = [
  { label: 'Periodic', value: 'periodic' },
  { label: 'Milestone', value: 'milestones' },
];

const planHealthItems = [
  {
    key: 'investments',
    name: 'Investments',
    value: 'red',
    toolTipItems: [
      { key: 'brokerage', name: 'Brokerage', value: 'green' },
      { key: 'tradSavings', name: 'Trad savings', value: 'yellow' },
      { key: 'rothSavings', name: 'Roth savings', value: 'red' },
      { key: '529 Savings', name: '529 savings', value: 'green' },
    ],
  },
  {
    key: 'realEstate',
    name: 'Real estate',
    value: 'red',
    toolTipItems: [
      { key: 'primaryHome', name: 'Primary home', value: 'Green' },
      { key: 'secondHome', name: 'Second home', value: 'Yellow' },
      { key: 'investmentProps', name: 'Investment props', value: 'red' },
      { key: '', name: '', value: 'Green' },
    ],
  },
  {
    key: 'emergencyCash',
    name: 'Emergency cash',
    value: 'red',
    toolTipItems: [
      { key: 'bankAccounts', name: 'Bank accounts', value: 'Green' },
      { key: 'fixedDeposits', name: 'Fixed deposits', value: 'Green' },
      { key: 'otherFunds', name: 'Other funds', value: 'red' },
      { key: '', name: '', value: 'Green' },
    ],
  },
  {
    key: 'overallPlan',
    name: 'Overall plan',
    value: 'red',
  },
];
const budgetItems = [
  {
    key: 'Income',
    name: 'Income',
    toolTipItems: [
      { key: 'wages', name: 'Wages' },
      { key: 'rentalIncome', name: 'Rental income' },
      { key: 'socialSecurity', name: 'social security' },
      { key: 'otherIncome', name: 'Other income' },
    ],
  },
  {
    key: 'expenses',
    name: 'Expenses',
    toolTipItems: [
      { key: 'housing', name: 'Housing' },
      { key: 'discretionary', name: 'Discretionary' },
      { key: 'nonDiscretionary', name: 'Non-Discretionary' },
      { key: 'taxes', name: 'Taxes' },
    ],
  },

  {
    key: 'savings',
    name: 'Savings',
    toolTipItems: [
      { key: 'retirement', name: 'Retirement' },
      { key: '529Plans', name: '529 Plans' },
      { key: 'brokerage', name: 'Brokerage' },
      { key: 'cash', name: 'Cash' },
    ],
  },
  { key: 'total', name: 'Total' },
];
const netWorthItems = [
  {
    key: 'investments',
    name: 'Investments',
    toolTipItems: [
      { key: 'brokerage', name: 'Brokerage' },
      { key: 'retirementSavings', name: 'Retirement savings' },
      { key: '529Savings', name: '529Savings' },
      { key: 'cashSavings', name: 'Cash savings' },
    ],
  },
  {
    key: 'realEstate',
    name: 'Real estate',
    toolTipItems: [
      { key: 'primaryHome', name: 'Primary Home' },
      { key: 'secondHome', name: 'Second Home' },
      { key: 'investmentProps', name: 'Investment Props' },
    ],
  },
  {
    key: 'otherFunds',
    name: 'Other Funds',
    toolTipItems: [
      { key: 'emergencyCash', name: 'Emergency cash' },
      { key: 'deferredComp', name: 'Deferred comp' },
      { key: 'pension', name: 'Pension' },
      { key: 'unvestedStock', name: 'Unvested stock' },
    ],
  },
  { key: 'total', name: 'Total' },
];
const assetsItems = [
  {
    key: 'investments',
    name: 'Investments',
    toolTipItems: [
      { key: 'brokerage', name: 'Brokerage' },
      { key: 'retirementSavings', name: 'Retirement savings' },
      { key: '529Savings', name: '529Savings' },
      { key: 'cashSavings', name: 'Cash savings' },
    ],
  },
  {
    key: 'realEstate',
    name: 'Real estate',
    toolTipItems: [
      { key: 'primaryHome', name: 'Primary home' },
      { key: 'secondHome', name: 'Second home' },
      { key: 'investmentProps', name: 'Investment props' },
    ],
  },
  {
    key: 'otherFunds',
    name: 'Other Funds',
    toolTipItems: [
      { key: 'emergencyCash', name: 'Emergency cash' },
      { key: 'deferredComp', name: 'Deferred comp' },
      { key: 'pension', name: 'Pension' },
      { key: 'unvestedStock', name: 'Unvested stock' },
    ],
  },
  { key: 'total', name: 'Total' },
];
const debtItems = [
  {
    key: 'mortgages',
    name: 'Mortgages',
    toolTipItems: [
      { key: 'balance', name: 'Balance' },
      { key: 'interest', name: 'Interest' },
      { key: 'minPayMonths', name: 'Min pay months' },
      { key: 'maxPayMonths', name: 'Max pay months' },
    ],
  },
  {
    key: 'studentLoans',
    name: 'Student loans',
    toolTipItems: [
      { key: 'balance', name: 'Balance' },
      { key: 'interest', name: 'Interest' },
    ],
  },
  {
    key: 'creditCards',
    name: 'Credit cards',
    toolTipItems: [
      { key: 'Balance', name: 'Balance' },
      { key: 'interest', name: 'Interest' },
      { key: 'minPayMonths', name: 'Min pay months' },
      { key: 'maxPayMonths', name: 'Max pay months' },
    ],
  },
  { key: 'total', name: 'Total' },
];
const insuranceItems = [
  {
    key: 'lifeCover',
    name: 'Life Cover',
    toolTipItems: [
      { key: 'termCoverage', name: 'Term coverage' },
      { key: 'wholeCoverage', name: 'Whole coverage' },
      { key: 'wholeCashValue', name: 'Whole cash value' },
      { key: 'totalPremium', name: 'Total premium' },
    ],
  },
  {
    key: 'liabilityCover',
    name: 'Liability Cover',
    toolTipItems: [
      { key: 'cars', name: 'Cars' },
      { key: 'property', name: 'Property' },
      { key: 'umbrella', name: 'Umbrella' },
      { key: 'totalPremium', name: 'Total premium' },
    ],
  },

  {
    key: 'propertyCover',
    name: 'Property Cover',
    toolTipItems: [
      { key: 'primaryHome', name: 'Primary home' },
      { key: 'secondHome', name: 'Second home' },
      { key: 'investmentProps', name: 'Investment props' },
      { key: 'totalPremium', name: 'Total premium' },
    ],
  },
  { key: 'totalCover', name: 'Total cover' },
];
export const chronoTabs = [
  {
    name: 'Plan health',
    key: 'planHealth',
    isHighlighted: true,
    isDisabled: false,
    icon: DashboardCustomizeOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now (0Y)',
        icon: DashboardCustomizeOutlinedIcon,
        items: planHealthItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: DashboardCustomizeOutlinedIcon,
        items: planHealthItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: DashboardCustomizeOutlinedIcon,
        items: planHealthItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: DashboardCustomizeOutlinedIcon,
        items: planHealthItems,
      },
    ],
  },
  {
    name: 'Budget',
    key: 'budget',
    isHighlighted: false,
    isDisabled: false,
    icon: NoteAltOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now',
        icon: NoteAltOutlinedIcon,
        items: budgetItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: NoteAltOutlinedIcon,
        items: budgetItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: NoteAltOutlinedIcon,
        items: budgetItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: NoteAltOutlinedIcon,
        items: budgetItems,
      },
    ],
  },
  {
    name: 'Net worth',
    key: 'netWorth',
    isHighlighted: false,
    isDisabled: false,
    icon: SavingsOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now',
        icon: SavingsOutlinedIcon,
        items: netWorthItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: SavingsOutlinedIcon,
        items: netWorthItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: SavingsOutlinedIcon,
        items: netWorthItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: SavingsOutlinedIcon,
        items: netWorthItems,
      },
    ],
  },
  {
    name: 'Assets',
    key: 'assets',
    isHighlighted: false,
    isDisabled: false,
    icon: DonutSmallOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now',
        icon: DonutSmallOutlinedIcon,
        items: assetsItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: DonutSmallOutlinedIcon,
        items: assetsItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: DonutSmallOutlinedIcon,
        items: assetsItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: DonutSmallOutlinedIcon,
        items: assetsItems,
      },
    ],
  },
  {
    name: 'Debt',
    key: 'debt',
    isHighlighted: false,
    isDisabled: false,
    icon: LegendToggleOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now',
        icon: LegendToggleOutlinedIcon,
        items: debtItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: LegendToggleOutlinedIcon,
        items: debtItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: LegendToggleOutlinedIcon,
        items: debtItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: LegendToggleOutlinedIcon,
        items: debtItems,
      },
    ],
  },
  {
    name: 'Insurance',
    key: 'insurance',
    isHighlighted: false,
    isDisabled: false,
    icon: LegendToggleOutlinedIcon,
    subTabNames: [
      {
        key: 'now',
        name: 'Now',
        icon: LegendToggleOutlinedIcon,
        items: insuranceItems,
      },
      {
        key: 'horizon',
        name: 'Horizon',
        icon: LegendToggleOutlinedIcon,
        items: insuranceItems,
      },
      {
        key: 'retirement',
        name: 'Retirement',
        icon: LegendToggleOutlinedIcon,
        items: insuranceItems,
      },
      {
        key: 'estateTransfer',
        name: 'Estate transfer',
        icon: LegendToggleOutlinedIcon,
        items: insuranceItems,
      },
    ],
  },
];

const familyProfileItems = [
  {
    key: 'adultAge',
    name: 'Adult Age(s)',
    value: ' 31 and 33',
  },
  {
    key: 'taxFilingStatus',
    name: 'Tax filing status',
    value: 'MFS',
  },
  {
    key: 'noOfKids',
    name: '# of Kids',
    value: '2',
  },
  {
    key: 'kidsAgeRange',
    name: 'Kids age range',
    value: '-3 to 0',
  },
];

const earningsProfileItems = [
  {
    key: 'youngerWages',
    name: 'Younger wages',
    value: '170K',
  },
  {
    key: 'elderWages',
    name: 'Elder wages',
    value: '230K',
  },
  {
    key: 'retirementAge',
    name: 'Retirement age(s)',
    value: '67 and 65',
  },
  {
    key: 'socialSecAge',
    name: 'Social sec age(s)',
    value: '67 and 67',
  },
];

const debtProfileItems = [
  {
    key: 'creditCardRates',
    name: 'Credit card rates',
    value: '18.0% to 22.0%',
  },
  {
    key: 'studentLoanRates',
    name: 'Student loan rates',
    value: '3.0% to 4.0%',
  },
  {
    key: 'mortgageRates',
    name: 'Mortgage rates',
    value: '2.75% to 5.25%',
  },
  {
    key: 'otherLoanRates',
    name: 'Other loan rates',
    value: '3.5% to 6.5%',
  },
];

const savingsProfileItems = [
  {
    key: 'percentageSavingsForRet',
    name: '% Savings for ret',
    value: '17.9% and 12.8%',
  },
  {
    key: 'rothPercentageOfRetSaves',
    name: 'Roth % of ret Saves',
    value: '3.0% and 5.0%',
  },
  {
    key: 'percentageSavingsForDC',
    name: '% Savings for DC',
    value: '44.2%',
  },
  {
    key: 'percentageSavingsFor529',
    name: '% Savings for 529',
    value: '5.5%',
  },
];

const investmentsProfileItems = [
  {
    key: '401kPortfolio',
    name: '401k Portfolio',
    value: '300K',
  },
  {
    key: 'iraPortfolio',
    name: 'IRA portfolio',
    value: '31K',
  },
  {
    key: 'brokeragePortfolio',
    name: 'Brokerage portfolio',
    value: '200K',
  },
  {
    key: 'cashPortfolio',
    name: 'Cash portfolio',
    value: '500K',
  },
];

const realEstateProfileItems = [
  {
    key: 'ownsPrimaryHome',
    name: 'Owns primary home',
    value: 'True',
  },
  {
    key: 'numberOfSecondHomes',
    name: '# of second homes',
    value: '2',
  },
  {
    key: 'numberOfInvProps',
    name: '# of inv props',
    value: '2',
  },
  {
    key: 'invPropDomicile',
    name: 'Inv prop domicile',
    value: 'NULL',
  },
];

const insuranceProfileItems = [
  {
    key: 'deathBenefit',
    name: 'Death benefit',
    value: '3000K',
  },
  {
    key: 'liabilityCoverage',
    name: 'Liability coverage',
    value: '5200K',
  },
  {
    key: 'wholeLifeCashValue',
    name: 'Whole life cash value',
    value: '500K',
  },
  {
    key: 'propertyCoverage',
    name: 'Property coverage',
    value: '14K',
  },
];

const taxProfileItems = [
  {
    key: 'residenceState',
    name: 'Residence state',
    value: 'NJ',
  },
  {
    key: 'retirementState',
    name: 'Retirement state',
    value: 'NJ',
  },
  {
    key: 'heirEstateXferState',
    name: 'Heir estate xfer state',
    value: 'NY',
  },
  {
    key: 'heirAGIAtEstateXfer',
    name: 'Heir AGI @ estate xfer',
    value: '700K',
  },
];

export const dashboardSummaryTabs = [
  [
    {
      name: 'Family profile',
      key: 'familyProfile',
      items: familyProfileItems,
    },
    {
      name: 'Earnings profile',
      key: 'earningsProfile',
      items: earningsProfileItems,
    },
    {
      name: 'Debt profile',
      key: 'debtProfile',
      items: debtProfileItems,
    },
    {
      name: 'Savings profile',
      key: 'savingsProfile',
      items: savingsProfileItems,
    },
  ],
  [
    {
      name: 'Investments profile',
      key: 'investmentsProfile',
      items: investmentsProfileItems,
    },
    {
      name: 'Real estate profile',
      key: 'realEstateProfile',
      items: realEstateProfileItems,
    },
    {
      name: 'Insurance profile',
      key: 'insuranceProfile',
      items: insuranceProfileItems,
    },
    {
      name: 'Tax profile',
      key: 'taxProfile',
      items: taxProfileItems,
    },
  ],
];

export const plannerModules = [
  { name: 'Life Style', key: 'lifeStyle' },
  { name: 'Life Events', key: 'lifeEvents' },
  {
    name: 'Debt',
    key: 'debt',
  },
  { name: 'Investments', key: 'investments' },
  { name: 'Insurance', key: 'insurance' },
  { name: 'Real Estate', key: 'realEstate' },
  { name: 'Retirement', key: 'retirement' },
  { name: 'Estate', key: 'estate' },
];

export const chartData = [
  {
    planType: 'LIFE EVENTS',
    key: 'lifeEvents',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: '#FFFFFF',
    },
  },
  {
    planType: 'DEBT',
    key: 'debt',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: '#FFFFFF',
    },
  },
  {
    planType: 'INVESTMENTS',
    key: 'investments',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: 'black',
    },
  },
  {
    planType: 'INSURANCE',
    key: 'insurance',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: 'black',
    },
  },
  {
    planType: 'REAL ESTATE',
    key: 'realEstate',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: 'black',
    },
  },
  {
    planType: 'RETIREMENT',
    key: 'retirement',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: 'black',
    },
  },
  {
    planType: 'ESTATE',
    key: 'estate',
    percent: 20,
    isSubscribed: false,
    config: {
      fill: 'black',
    },
  },
];
export const chartColors = [
  {
    color: '#007080',
    color1: '#c4c4c4',
  },
  { color: '#016630', color1: '#c4c4c4' },
  { color: '#009FE3', color1: '#c4c4c4' },
  { color: '#028ef9', color1: '#c4c4c4' },
  { color: '#03B454', color1: '#c4c4c4' },
  { color: '#03A2B8', color1: '#c4c4c4' },
  { color: '#028ef9', color1: '#c4c4c4' },
];
export const listOfCharts = [
  { name: 'Networth', key: 'networth' },
  { name: 'Assets', key: 'assets' },
  { name: 'Debt', key: 'debt' },
  { name: 'Budget', key: 'budget' },
];
export const solutionsList = [
  {
    name: 'Insurance',
    key: 'insurance',
    subMenu: [
      { name: 'Buy Term Life', key: 'buyTermLife' },
      { name: 'Buy Whole Life', key: 'buyWholeLife' },
      { name: 'Buy Long Term Care', key: 'buyLongTermCare' },
      { name: 'Buy Umbrella', key: 'buyUmbrella' },
    ],
  },
  {
    name: 'Investments',
    key: 'investments',
    subMenu: [
      { name: 'Growth', key: 'growth' },
      { name: 'Dividend', key: 'dividend' },
      { name: 'Savings', key: 'savings' },
    ],
  },
  { name: 'Debt', key: 'debt', subMenu: [] },
  { name: 'Expenses', key: 'expenses', subMenu: [] },
];

export const categoryOrder = [
  { label: 'Retirement', value: 'retirement' },
  { label: 'Kids', value: 'kids' },
  { label: 'Primary Home', value: 'primaryHome' },
  { label: 'Second Homes', value: 'secondHomes' },
  { label: 'Investment Properties', value: 'invProps' },
  { label: 'Vehicles', value: 'vehicles' },
  { label: 'Vacations', value: 'vacations' },
];

export const assetMixListValues = [
  { key: 'stock', label: 'Stock', current: '0.0%', target: '0.0%' },
  { key: 'bond', label: 'Bond', current: '0.0%', target: '0.0%' },
  { key: 'shortTerm', label: 'Short Term', current: '0.0%', target: '0.0%' },
  { key: 'cash', label: 'Cash', current: '0.0%', target: '0.0%' },
  { key: 'intlStock', label: 'Intl Stock', current: '0.0%', target: '0.0%' },
  { key: 'intlBond', label: 'Intl Bond', current: '0.0%', target: '0.0%' },
  { key: 'emktStock', label: 'Emkt Stock', current: '0.0%', target: '0.0%' },
  { key: 'emktBond', label: 'Emkt Bond', current: '0.0%', target: '0.0%' },
  { key: 'commodities', label: 'Commodities', current: '0.0%', target: '0.0%' },
  { key: 'reits', label: 'Reits', current: '0.0%', target: '0.0%' },
  {
    key: 'alternatives',
    label: 'Alternatives',
    current: '0.0%',
    target: '0.0%',
  },
  { key: 'crypto', label: 'Crypto', current: '0.0%', target: '0.0%' },
];

export const assetMixValuesTemplate = {
  Stock: '0.00%',
  Bond: '0.0%',
  'Short Term': '0.0%',
  Cash: '0.0%',
  'Intl Stock': '0.0%',
  'Intl Bond': '0.0%',
  'Emkt Stock': '0.0%',
  'Emkt Bond': '0.0%',
  Commodities: '0.0%',
  REITs: '0.0%',
  Alternatives: '0.0%',
  Crypto: '0.0%',
};

export const termLifeInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const wholeLifeInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Cash Value', field: 'cashValueAnnual', width: 150 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const umbrellaLifeInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const propertyLifeInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const autoLifeInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const healthInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 200 },
  { headerName: 'Premium', field: 'premium', width: 150 },
];
export const ltcInsuranceCols = [
  { headerName: 'Insurer', field: 'insurer', width: 150 },
  { headerName: 'Annual Benefit', field: 'cashValueAnnual', width: 150 },
  {
    headerName: 'Years Covered',
    field: 'cashValueDistributionYrs',
    width: 150,
  },
  { headerName: 'Premium', field: 'premium', width: 100 },
];

export const investmentFields = [
  {
    title: 'Traditional 401k',
    id: 'trad401k',
    selected: false,
    value: '',
  },
  {
    title: 'Roth 401k',
    id: 'roth401k',
    selected: false,
    value: '',
  },
  {
    title: 'Traditional IRA',
    id: 'tradIra',
    selected: false,
    value: '',
  },
  {
    title: 'Roth IRA',
    id: 'rothIra',
    selected: false,
    value: '',
  },
  {
    title: 'Dividend Portfolio',
    id: 'divFolio',
    selected: false,
    value: '',
  },
  {
    title: 'Other Investments',
    id: 'brokerage',
    selected: false,
    value: '',
  },
  {
    title: 'Disposable Cash',
    id: 'disposableCash',
    selected: false,
    value: '',
  },
  {
    title: 'Emergency cash',
    id: 'emCash',
    selected: false,
    value: '',
  },
  {
    title: 'Pension',
    id: 'pension',
    selected: false,
    value: '',
  },
  {
    title: 'RSU / PSU',
    id: 'rsu',
    selected: false,
    value: '',
  },
  {
    title: 'Deferred Compensation',
    id: 'deferredComp',
    selected: false,
    value: '',
  },
];

export const whoAreWe = [
  {
    key: 'person1',
    name: 'Venkat Mandava',
    degree: 'PH.D.',
    designation: 'Founder/CTO',
    experience: '20+',
    images: [
      { key: 'image0', name: Venkat_Mandava },
      { key: 'image1', name: Venkat_Mandava_logo1 },
      { key: 'image2', name: Venkat_Mandava_logo2 },
      { key: 'image3', name: Venkat_Mandava_logo3 },
      { key: 'image4', name: Venkat_Mandava_logo4 },
      { key: 'image5', name: Venkat_Mandava_logo5 },
      { key: 'image6', name: Venkat_Mandava_logo6 },
    ],
  },
  {
    key: 'person2',
    name: 'Jonathan Garrett',
    degree: '',
    designation: 'COO',
    experience: '13+',
    images: [
      { key: 'image0', name: Jonathan_Garrett },
      { key: 'image1', name: Jonathan_Garrett_logo_1 },
      { key: 'image2', name: Jonathan_Garrett_logo_2 },
      { key: 'image3', name: Jonathan_Garrett_logo_3 },
    ],
  },
  {
    key: 'person3',
    name: 'Saleem Khan',
    degree: '',
    designation: 'Senior Advisor',
    experience: '17+',
    images: [
      { key: 'image0', name: Saleem_Khan },
      { key: 'image1', name: Saleem_Khan_logo_1 },
      { key: 'image2', name: Saleem_Khan_logo_2 },
      { key: 'image3', name: Saleem_Khan_logo_3 },
      { key: 'image4', name: Saleem_Khan_logo_4 },
    ],
  },
  {
    key: 'person4',
    name: 'Suneel Saigal',
    degree: '',
    designation: 'Senior Advisor',
    experience: '20+',
    images: [
      { key: 'image0', name: Suneel_saigal },
      { key: 'image1', name: Suneel_saigal__logo_1 },
      { key: 'image2', name: Suneel_saigal__logo_2 },
      { key: 'image3', name: Suneel_saigal__logo_3 },
    ],
  },
];

export const investmentCustomFields = [
  {
    label: 'Custom Return(%)',
    value: '',
    name: 'customReturn',
    type: 'percent',
  },
  {
    label: 'Custom Volatality(%)',
    value: '',
    name: 'customVol',
    type: 'percent',
  },
  {
    label: 'Custom Dividend(%)',
    value: '',
    name: 'customDivRate',
    type: 'percent',
  },
];

export const assetMixList = [
  {
    label: 'Stocks (%)',
    value: '',
    name: 'stockPercent',
    type: 'percent',
  },
  {
    label: 'Intl Stock (%)',
    value: '',
    name: 'intlStockPercent',
    type: 'percent',
  },
  {
    label: 'Emerging Stock (%)',
    value: '',
    name: 'emktStockPercent',
    type: 'percent',
  },

  {
    label: 'Cash (%)',
    value: '',
    name: 'cashPercent',
    type: 'percent',
  },
  {
    label: 'Commodities (%)',
    value: '',
    name: 'commoditiesPercent',
    type: 'percent',
  },

  {
    label: 'Bonds (%)',
    value: '',
    name: 'bondPercent',
    type: 'percent',
  },
  {
    label: 'Intl Bond (%)',
    value: '',
    name: 'intlBondPercent',
    type: 'percent',
  },
  {
    label: 'Emerging Bond (%)',
    value: '',
    name: 'emktBondPercent',
    type: 'percent',
  },
  {
    label: 'REITs (%)',
    value: '',
    name: 'reitPercent',
    type: 'percent',
  },
  {
    label: 'Crypto (%)',
    value: '',
    name: 'cryptoPercent',
    type: 'percent',
  },
  {
    label: 'Short-term (%)',
    value: '',
    name: 'shortTermPercent',
    type: 'percent',
  },
  {
    label: 'Alternatives (%)',
    value: '',
    name: 'altPercent',
    type: 'percent',
  },
];

export const completeInvestmentFields = [
  ...investmentCustomFields,
  ...assetMixList,
  { label: 'Inv Balance', value: '', name: 'invBalance', type: 'currency' },
];

export const banksListObj = [
  {
    title: 'Wells Fargo',
    id: 'ins_127991',
  },
  {
    title: 'Citi',
    id: 'ins_5',
  },
  {
    title: 'Bank of America',
    id: 'ins_127989',
  },
  {
    title: 'Chase',
    id: 'ins_56',
  },
  {
    title: 'Vangaurd',
    id: 'ins_115616',
  },
  {
    title: 'Fidelity',
    id: 'ins_12',
  },
  {
    title: 'Schwab',
    id: 'ins_11',
  },
  {
    title: 'American Express',
    id: 'ins_10',
  },
];

export const newAccountDetailsToBeAdded = {
  nickName: '',
  invBalance: 0,
  stockPercent: 0,
  bondPercent: 0,
  intlStockPercent: 0,
  intlBondPercent: 0,
  emktStockPercent: 0,
  emktBondPercent: 0,
  shortTermPercent: 0,
  cashPercent: 0,
  reitPercent: 0,
  commoditiesPercent: 0,
  altPercent: 0,
  cryptoPercent: 0,
  assetProfile: 'generic',
  customReturn: 0,
  customVol: 0,
  customDivRate: 0,
  products: [],
};
