import React, { Component } from 'react';
import MuiAlert from '@mui/material/Alert';
import { Grid } from '@material-ui/core';
import CssBaseline from '@mui/material/CssBaseline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import Snackbar from '@mui/material/Snackbar';
import { withSnackbar } from 'notistack';
import PlannerTabs from './PlannerTabs';
import '../../styles/individualClient.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class IndividualClient extends Component {
  PlannerTabsRef = React.createRef();

  state = {
    // chartsMenu: [],
    // feedbackInput: '',
    // openFeedbackDialog: false,
    // isLoading: false,
    // openSnackbar: false,
    // openFailedSnackbar: false,
  };

  getPlannerFormReq = (displayTermChanged = false) => {
    const { current: PlannerTabsComp } = this.PlannerTabsRef;
    const plannerFormReq = PlannerTabsComp.getPlannerFormReq();
    // triggerSimulatePlanDetailsByPlanId({
    //   isIndvClient: true,
    //   displayTermChanged,
    //   chrono,
    //   plannerFormReq,
    // });
    return plannerFormReq;
  };

  // handlePlannerSave = () => {
  //   const { saveAndUpdatePlanDetailsbyPlanId } = this.props;
  //   const { current: PlannerTabsComp } = this.PlannerTabsRef;
  //   const plannerFormReq = PlannerTabsComp.getPlannerFormReq('save');
  //   saveAndUpdatePlanDetailsbyPlanId(plannerFormReq);
  // };

  updatePlannerDataInRedux = (plannerFormReq) => {
    // console.log('call to update data in redux', plannerFormReq);
  };

  render() {
    const {
      planDetailsByPlanId,
      allMenuItems,
      alltaxSlabs,
      selectedPlan,
      selectedClientId,
      userDetails,
      // planDetailsSimulated,
      selectedHorizonTerm,
      selectedDisplayTerm,
      selectedNumberOfRuns,
    } = this.props;
    // const { openSnackbar, openFailedSnackbar } = this.state;
    return (
      <div className="individual-client-container">
        {/* <FeedbackDialog
          confirmLabel="Send"
          dialogOpen={openFeedbackDialog}
          handleCancel={() => this.setFeedbackDialog(false)}
          handleConfirm={this.sendFeedback}
        >
          <p>Enter your feedback here</p>
          <TextareaAutosize
            minRows={4}
            value={feedbackInput}
            onChange={(event) => this.setFeedbackInputValue(event.target.value)}
          />
        </FeedbackDialog> */}
        <Grid container spacing={0} className="indiv-wrapper">
          <PlannerTabs
            updatePlannerDataInReduxCb={this.updatePlannerDataInRedux}
            selectedPlan={selectedPlan}
            ref={this.PlannerTabsRef}
            planDetails={planDetailsByPlanId}
            allMenuItems={allMenuItems}
            alltaxSlabs={alltaxSlabs}
            selectedClientId={selectedClientId}
            userDetails={userDetails}
            selectedHorizonTerm={selectedHorizonTerm}
            selectedDisplayTerm={selectedDisplayTerm}
            selectedNumberOfRuns={selectedNumberOfRuns}
          />
        </Grid>
        {/* <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            Thank you for showing interest, our team will surely get in touch
            with you.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailedSnackbar}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            Something went wrong, please try again later!
          </Alert>
        </Snackbar> */}
      </div>
    );
  }
}

export default withSnackbar(IndividualClient);
