import React from 'react';
export default function Footer() {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div>© Green'r 2024</div>
        </div>
      </div>
    </div>
  );
}
