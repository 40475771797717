export const getReportsDataReducer = (state) => state.reportsReducer;

export const getReportsInitialDataSelector = (state) =>
  getReportsDataReducer(state).reportData;

export const getSelectedReportOptionsSelector = (state) =>
  getReportsDataReducer(state).reportData;

export const getReportDataLoadingSelector = (state) =>
  getReportsDataReducer(state).isReportDataLoading;
