export const correlationOptions = [
  { id: '1', title: 'Stock' },
  { id: '2', title: 'Bond' },
  { id: '3', title: 'Short Term' },
  { id: '4', title: 'Cash' },
  { id: '5', title: 'Intl Stock' },
  { id: '6', title: 'Intl Bond' },
  { id: '7', title: 'Emkt Stock' },
  { id: '8', title: 'Emkt Bond' },
  { id: '9', title: 'Commodities' },
  { id: '10', title: 'REITs' },
  { id: '11', title: 'Alternatives' },
  { id: '12', title: 'Crypto' },
];

export const percentageFields = [
  'stockPercent',
  'bondPercent',
  'shortTermPercent',
  'cashPercent',
  'reitPercent',
  'altPercent',
  'commoditiesPercent',
  'cryptoPercent',
  'invReturn',
  'invVolatility',
];

export const tenures = [
  //   { label: '1 year', value: 365 },
  //   { label: '9 Months', value: 270 },
  //   { label: '6 Months', value: 180 },
  { label: 'All', value: 0 },
];

export const clientTenures = [
  { label: 'All', value: 1000 },
  { label: '1 week', value: 7 },
  { label: '3 week', value: 21 },
  { label: 'Today', value: 0 },
];

export const NewAdvisorFields = [
  {
    label: 'Name',
    fieldKey: 'advisorName',
    type: 'text',
    placeHolder: 'Advisor Name',
    validator: 'validateInputFieId',
    errorText: 'This is a mandatory field',
  },
  {
    label: 'Email Id',
    fieldKey: 'advisorId',
    type: 'text',
    placeHolder: 'Advisor Id',
    validator: 'validateEmailId',
    formatter: '',
    errorText: 'This is a mandatory field',
  },
  {
    label: 'Mobile Number',
    fieldKey: 'mobileNumber',
    type: 'text',
    placeHolder: '(123) 456-7890',
    validator: 'validatePhoneNumber',
    formatter: 'formatPhoneNumber',
    errorText: 'This is a mandatory field',
  },
  {
    label: 'Advisor State',
    fieldKey: 'stateCode',
    type: 'select',
    errorText: 'This is a mandatory field',
    formatter: '',
    validator: '',
  },
  {
    label: 'Advisor Type',
    fieldKey: 'advisorType',
    type: 'select',
    errorText: 'This is a mandatory field',
    formatter: '',
    validator: '',
    options: [
      {
        label: 'Direct',
        value: 'direct',
      },
      {
        label: 'Third Party',
        value: 'thirdParty',
      },
    ],
  },
];
