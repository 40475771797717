import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
  validateFormDetails,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildSavings529PlansComponent = (props) => {
  const {
    state,
    concatContent,
    handleDelete,
    setLastStepInParent,
    setValidationError,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoKids',
    subTab: 'kids',
    countVal: 'numKids',
  };

  const { formDetails, isEditable, isAddNew } = state;
  const { name: kidName } = formDetails.kids;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  const userSavingOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  // const validateFields = [
  //   {
  //     apiFieldName: 'name',
  //     errorMessage: 'Invalid Name',
  //   },
  // ];

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject,
          false,
          false
        )}
      </div>

      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          <div className="row align-items-center">
            <div
              className={`col-lg-9 col-sm-9 col-8 insured_content_outer labelStyle 
          }`}
            >
              <p>
                <strong>Name of the Kid</strong>
              </p>
            </div>
            <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
              <input
                type="text"
                required
                disabled
                value={kidName}
                className={'custom-input'} // Apply the appropriate class
                placeholder=""
              />
            </div>
          </div>
          {renderFormInputRow(
            'text',
            'tlCollegeAge',
            'collegeAge',
            'Age at which the kid will start college',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlCollegeYears',
            'collegeYears',
            'Plan for number of years in college',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlannualCollegeExpenseNow',
            'annualCollegeExpenseNow',
            'Annual college expense now where kid is projected to attend',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlannualCollegeExpenseGrowth',
            'collegeExpenseGrowth',
            'Annual growth of college expense between now and when projected to attend',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlvalue529Plan',
            'value529Plan',
            '529 savings plan balance (will be taken out of your future funds if kid is not born yet)',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlPeakReturn529Plan',
            'peakReturn529Plan',
            'Projected peak investment return of 529 plan (will be treated like the asset mix of a target fund)',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {isAddNew || isEditable ? (
            <div className="insured_main_outer">
              {renderFormSelectOption(
                'text',
                'tluseSavingsToBuild529',
                'useSavingsToBuild529',
                'Do you want to use non-retirement savings to contribute towards 529 savings if income were not enough?',
                userSavingOptions,
                moduleObject,
                state,
                props
              )}
            </div>
          ) : (
            <div className="insured_main_outer">
              {renderFormInputRow(
                'text',
                'tluseSavingsToBuild529',
                'useSavingsToBuild529',
                'Do you want to use non-retirement savings to contribute towards 529 savings if income were not enough?',
                'dropdown',
                moduleObject,
                state,
                props
              )}
            </div>
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default BuildSavings529PlansComponent;
