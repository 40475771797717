import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DialogBox from './shared/DialogBox';
import { Grid } from '@material-ui/core';
import Controls from './controls/Controls';
import { contactUsFormFields } from '../constants/dashboard';
import axios from 'axios';
import { sendEmail } from '../services/dashboardSvc';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactUsPopup = (props) => {
  const {
    formValues,
    formValues: {
      isfirstNameValid = true,
      islastNameValid = true,
      isemailIdValid = true,
      ismobileNumberValid = true,
      iscompanyValid = true,
    },
    setDialogState,
    setFormFieldValue,
    dialogOpen,
    setIsAPIFailed,
    isConfirmButtonDisabled,
    dialogClass,
  } = props;

  const tempContactUsFormFields = contactUsFormFields.map((field) => {
    return {
      ...field,
      value: formValues[field.key],
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setIsSnackbarOpen] = useState(false);
  const [openFailedSnackbar, setIsFailedSnackbarOpen] = useState(false);

  const getContactUsMailBody = (mailBodyObj) => {
    const { firstName, lastName, emailId, mobileNumber } = mailBodyObj;
    return (
      'Please get in touch with me, below are my details: <br><br>' +
      'First Name:' +
      '\t' +
      firstName +
      '<br>' +
      'Last Name:' +
      '\t' +
      lastName +
      '<br>' +
      'Email:' +
      '\t' +
      emailId +
      '<br>' +
      'Mobile:' +
      '\t' +
      mobileNumber +
      '<br><br>' +
      'Thank You,' +
      '<br>' +
      "Green'R"
    );
  };

  const handleContactUs = async () => {
    setIsLoading(true);
    const { firstName, lastName, emailId, mobileNumber } = formValues;
    const userName = `${firstName}, ${lastName}`;
    const reqObj = {
      emailId: emailId,
      mobileNumber: mobileNumber,
      name: userName,
      firstName: firstName,
      lastName: lastName,
    };
    try {
      setIsLoading(true);
      setIsAPIFailed(false);
      const reqPayload = {
        createdDate: '2022-12-21T09:44:53.809Z',
        message: getContactUsMailBody(reqObj),
        notificationId: '',
        subject: 'Contact Us',
        toAddress: 'info@greenrfinancial.com,marketing@greenrfinancial.com',
      };
      const { status } = await sendEmail(reqPayload);
      if (status === 200) {
        setIsLoading(false);
        setDialogState(false);
        setIsSnackbarOpen(true);
      } else {
        setDialogState(true);
        setIsFailedSnackbarOpen(true);
        setIsLoading(false);
        setIsAPIFailed(true);
      }
    } catch (e) {
      setIsLoading(false);
      setIsAPIFailed(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
    setIsFailedSnackbarOpen(false);
  };

  const getIsValidFieldValue = (field) => {
    switch (field) {
      case 'firstName':
        return isfirstNameValid;
      case 'lastName':
        return islastNameValid;
      case 'emailId':
        return isemailIdValid;
      case 'mobileNumber':
        return ismobileNumberValid;
      case 'company':
        return iscompanyValid;
      default:
        return true;
    }
  };

  return (
    <>
      <DialogBox
        dialogOpen={dialogOpen}
        dialogClass={dialogClass}
        handleCancel={() => setDialogState(false)}
        handleConfirm={handleContactUs}
        isConfirmButtonDisabled={isConfirmButtonDisabled}
        size="md"
        confirmLabel="Submit"
      >
        <>
          <div className="signup-title-container">
            <span className="signup-title">Contact Us</span>
            <CloseIcon
              className="signup-title-close-icon"
              onClick={() => setDialogState(false)}
            />
          </div>
          <div className="signup-loader">
            {isLoading && <CircularProgress />}
          </div>
          <div className="signup-body-container">
            <Grid container className="signup-form-grid" spacing={2}>
              {tempContactUsFormFields.map((field) => {
                return (
                  <Grid item xs={5}>
                    <Controls.Input
                      name={field.key}
                      label={field.name}
                      value={field.value}
                      type="text"
                      onChange={(event, name) => setFormFieldValue(event, name)}
                      // errorText={field.errorMessage}
                      errorText={
                        field.value
                          ? `Invalid ${field.name}`
                          : 'This is a mandatory field'
                      }
                      isValid={getIsValidFieldValue(field.key)}
                      required
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </DialogBox>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Thank you for your interest. We will get back to you shortly through
          email.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailedSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Something went wrong, please try again later!
        </Alert>
      </Snackbar>
    </>
  );
};
export default ContactUsPopup;
