import React, { useState, useEffect } from 'react';
import {
  renderMembersInputRow,
  renderLabelRow,
  renderMembersSelectOption,
  renderInputRow,
  renderRadioGroupDual,
  renderCustomMembersInputRow,
  transformInfoCoreBackToUIType,
  validateForm,
  renderMemberLabelRowWithoutEdits,
  renderInfoSection,
  getAndUpdatePensionContribYrs,
} from '../../helpers/ClientHelper';
import { infoCoreValidatableFields } from '../../constants/Client';

export const BuildIncomeComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    taxStateOptions,
    setLastStepInParent,
    setValidationError,
  } = props;
  const { retiredState, membersCount } = state;
  const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
  const [formErrors, setFormErrors] = useState([]);
  const [infoCoreDetails, setinfoCoreDetails] = useState(infoCore);

  useEffect(() => {
    if (infoCore && Object.keys(infoCore).length > 0) {
      //let tempInfoCore = getAndUpdatePensionContribYrs(infoCore, membersCount);
      let tempInfoCore = transformInfoCoreBackToUIType(infoCore);
      let validationErrors = {};
      validationErrors = validateForm(
        tempInfoCore,
        membersCount,
        infoCoreValidatableFields
      );
      if (Object.values(validationErrors).length > 0) {
        setFormErrors(validationErrors);
      }
      const { validationObj1 = [], validationObj2 = [] } = validationErrors;
      if (
        Object.values(validationObj1).length > 0 ||
        Object.values(validationObj2).length > 0
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
      setinfoCoreDetails(tempInfoCore);
    }
  }, [localPlanDetailsByPlanId, infoCore, membersCount, setValidationError]);

  const radioOptions = [
    {
      key: 'yes-radio-button',
      id: true,
      title: 'Yes',
    },
    {
      key: 'no-radio-button',
      id: false,
      title: 'No',
    },
  ];

  const [alertMessageOnAgeChange, setAlertMessageOnAgeChange] = useState('');

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  useEffect(() => {
    if (alertMessageOnAgeChange) {
      const timer = setTimeout(() => {
        setAlertMessageOnAgeChange('');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [alertMessageOnAgeChange]);

  return (
    <>
      <div className="insured_main_outer">
        {renderMemberLabelRowWithoutEdits(
          infoCoreDetails,
          'name',
          'basicsFirst',
          'basicsSecond',
          state
        )}
      </div>
      {retiredState ? (
        <></>
      ) : (
        <div>
          <div className="insured_main_outer">
            {renderLabelRow('WAGES', 'Wages')}
          </div>
          <div className="insured_main_outer">
            {renderMembersSelectOption(
              'text',
              'ddlMemOneTaxState',
              'workState',
              'Work State',
              taxStateOptions,
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'incSavFirst',
              'incSavSecond',
              'ddlMemTwoTaxState',
              state
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOneWages',
              'wages',
              'tlMemberSecWages',
              'Annual wages (base and bonus)',
              'currency',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'incSavFirst',
              'incSavSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOneWageGrowth',
              'wageGrowth',
              'tlMemberSecWageGrowth',
              'Annual Wage Growth (%)',
              'percentage',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'incSavFirst',
              'incSavSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
          </div>
          <div className="insured_main_outer">
            {renderLabelRow(
              'EQUITY COMPENSATION (RSU/PSU/OPTION)',
              'equitycompensation',
              2,
              8
            )}
          </div>
          <div className="insured_main_outer">
            {renderMembersInputRow(
              'text',
              'tlMemberOnePercentRsuGrant',
              'percentRsuGrant',
              'tlMemberSecPercentRsuGrant',
              'Equity Compensation as % of wages',
              'percentage',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'eqCompFirst',
              'eqCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOneRsuVestingYrs',
              'rsuVestingYrs',
              'tlMemberSecRsuVestingYrs',
              'Equity Compensation vesting years',
              'number',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'eqCompFirst',
              'eqCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOneAgeWhenRsuVestingStarts',
              'ageWhenRsuVestingStarts',
              'tlMemberSecAgeWhenRsuVestingStarts',
              'Age when vesting started or projected to start',
              'number',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'eqCompFirst',
              'eqCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
          </div>
        </div>
      )}
      <div className="insured_main_outer">
        {renderLabelRow('RETIREMENT INCOME', 'retirementIncome')}
      </div>
      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlMemberOneSocialSecAge',
          'socialSecAge',
          'tlMemberSecSocialSecAge',
          'Age when social security withdrawal begins',
          'number',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'retChoicesFirst',
          'retChoicesSecond',
          state,
          setinfoCoreDetails,
          formErrors
        )}
        {renderMembersInputRow(
          'text',
          'tlMemberOneSocialSecAmount',
          'socialSecAmount',
          'tlMemberSecSocialSecAmount',
          'Social Security Amount Projected',
          'currency',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'retChoicesFirst',
          'retChoicesSecond',
          state,
          setinfoCoreDetails
        )}
        {renderRadioGroupDual(
          'tlMemberOneLifetimePension',
          'lifetimePension',
          'tlMemberSecLifetimePension',
          'Will you get Lifetime Pension?',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'retChoicesFirst',
          'retChoicesSecond',
          state,
          radioOptions //radio options
        )}
        {(infoCore &&
          infoCore?.retChoicesFirst &&
          infoCore?.retChoicesFirst?.lifetimePension) ||
        (infoCore &&
          infoCore?.retChoicesSecond &&
          infoCore?.retChoicesSecond?.lifetimePension) ? (
          <>
            {renderCustomMembersInputRow(
              'text',
              'tlMemberOneLifetimePensionAmount',
              'lifetimePensionAmount',
              'tlMemberSecLifetimePensionAmount',
              'Annual Pension Amount',
              'currency',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'retChoicesFirst',
              'retChoicesSecond',
              state,
              infoCore?.retChoicesFirst?.lifetimePension,
              infoCore?.retChoicesSecond?.lifetimePension,
              setinfoCoreDetails,
              formErrors
            )}
            {renderCustomMembersInputRow(
              'text',
              'tlMemberOneLifetimePensionGrowth',
              'lifetimePensionGrowth',
              'tlMemberSecLifetimePensionAmount',
              'Annual Pension Growth',
              'percentage',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'retChoicesFirst',
              'retChoicesSecond',
              state,
              infoCore?.retChoicesFirst?.lifetimePension,
              infoCore?.retChoicesSecond?.lifetimePension,
              setinfoCoreDetails
            )}
          </>
        ) : null}
        {(infoCore &&
          infoCore?.retChoicesFirst &&
          !infoCore?.retChoicesFirst?.lifetimePension) ||
        (infoCore &&
          infoCore?.retChoicesSecond &&
          !infoCore?.retChoicesSecond?.lifetimePension) ? (
          <>
            {renderCustomMembersInputRow(
              'text',
              'tlMemberOnePensionWDYrs',
              'pensionWDYrs',
              'tlMemberSecPensionWDYrs',
              'If you have accumulated pension (you may enter in investments section), Over how many years you may want to spread the pension withdrawal?',
              'number',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'retChoicesFirst',
              'retChoicesSecond',
              state,
              !infoCore?.retChoicesFirst?.lifetimePension,
              !infoCore?.retChoicesSecond?.lifetimePension,
              setinfoCoreDetails
            )}
          </>
        ) : null}
        {renderMembersInputRow(
          'text',
          'tlMemberOneDefCompWDYrs',
          'defCompWDYrs',
          'tlMemberSecDefCompWDYrs',
          'If you have accumulated deferred compensation (you may enter in assets section), Over how many years you may want to spread the deferred compensation withdrawal?',
          'number',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'retChoicesFirst',
          'retChoicesSecond',
          state,
          setinfoCoreDetails
        )}
      </div>
      <div className="insured_main_outer">
        {renderLabelRow('OTHER INCOME', 'otherincome')}
      </div>
      <div className="insured_main_outer">
        {renderInfoSection(
          'info',
          'Rental Income',
          'Rental Income will be captured in Investment Properties',
          true
        )}
        {renderInfoSection(
          'info',
          'Dividend Income',
          'Dividend Income will be captured in Investments',
          true
        )}
        {renderInputRow(
          'text',
          'tlMemberOtherIncValue',
          'otherIncValue',
          'Other Annual Income (excluding rental and dividend income)',
          'currency',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          true,
          setinfoCoreDetails
        )}
        {renderInputRow(
          'text',
          'tlMemberOtherIncGrowth',
          'otherIncGrowth',
          'Other Income Growth (%)',
          'percentage',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          true,
          setinfoCoreDetails
        )}
      </div>
    </>
  );
};

export default BuildIncomeComponent;
