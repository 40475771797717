import { DATATYPES } from '../common';

export const commonFields = [];

export const OtherGoalsConstants = [
  {
    label: 'vacation',
    fields: [
      {
        label: 'Year',
        name: 'vacationYear',
        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Expense',
        name: 'vacationExpense',
        colSize: 4,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
        isDollarSignRequired: true,
      },
    ],
  },
  {
    label: 'kid',
    fields: [
      {
        label: 'Year',
        name: 'yearBorn',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
      {
        label: 'Expense',
        name: 'annualExpense',
        colSize: 3,
        type: 'number',
        isDisabled: false,
        isDollarSignRequired: true,
      },
      {
        label: 'College ExpNow',
        name: 'annualCollegeExpenseNow',
        colSize: 3,
        type: 'number',
        isDisabled: false,
        isDollarSignRequired: true,
      },
      {
        label: '529 Savings Now',
        name: 'value529Plan',
        colSize: 3,
        type: 'number',
        isDisabled: false,
        isDollarSignRequired: true,
      },
    ],
  },
];
