import { DATATYPES } from '../common';

export const commonFields = [];

export const InsuranceConstants = [
  {
    label: 'term life',
    fields: [
      {
        label: 'Start Year',
        name: 'coverageStartYr',
        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Coverage Amount',
        name: 'coverage',
        colSize: 4,
        isDollarSignRequired: true,

        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Coverage Years',
        name: 'coverageYrs',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
      {
        label: 'Premium',
        name: 'premium',
        colSize: 3,
        type: 'number',
        isDollarSignRequired: true,

        isDisabled: false,
      },
    ],
  },
  {
    label: 'whole life',
    fields: [
      {
        label: 'Start Year',
        name: 'coverageStartYr',
        colSize: 3,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Coverage Amount',
        name: 'coverage',
        colSize: 4,
        isDollarSignRequired: true,

        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Coverage Years',
        name: 'coverageYrs',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
      {
        label: 'Premium',
        name: 'premium',
        colSize: 3,
        type: 'number',
        isDisabled: false,
        isDollarSignRequired: true,
      },
      {
        label: 'Cash Value Annual',
        name: 'cashValueAnnual',
        colSize: 3,
        type: 'number',
        isDisabled: false,
        isDollarSignRequired: true,
      },
      {
        label: 'Cash Value Distribution Years',
        name: 'cashValueDistributionYrs',
        colSize: 3,
        type: 'number',
        isDisabled: false,
      },
    ],
  },
];
