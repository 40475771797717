import React, { useEffect, useState } from 'react';
import {
  renderInputRow,
  transformTaxesBackToUIType,
} from '../../helpers/ClientHelper';

const TaxesComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const { moduleSettings: { taxes = {} } = {} } = localPlanDetailsByPlanId;
  const [taxesDetails, setTaxesDetails] = useState(taxes);

  useEffect(() => {
    if (taxes && Object.keys(taxes).length > 0) {
      let tempTaxes = transformTaxesBackToUIType(taxes);
      setTaxesDetails(tempTaxes);
    }
  }, [taxes]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlDeductions',
          'itemizedDeduction',
          'Itemized deductions (standard deduction will be used if this is zero)',
          'currency',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlGrowthDed',
          'itemizedDedGrowth',
          'Annual growth % of itemized deductions',
          'percentage',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlBzDeductions',
          'qualifiedBusinessIncDeduction',
          'Qualified Business Income deduction',
          'currency',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlDedGrowth',
          'qualifiedBusinessIncDedGrowth',
          'Annual growth % of qualified business income deduction',
          'percentage',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
      </div>
    </>
  );
};

export default TaxesComponent;
