export const defaultReviewWagesAndExpenseProfileInitialState = {
  memberValues: [
    {
      'member-1': {
        age: '',
        lifestyle: '',
        name: '',
        otherIncGrowth: '',
        otherIncValue: '',
        residenceState: '',
        taxFilingStatus: '',
        wageGrowth: '',
        wages: '',
        workState: '',
      },
    },
  ],
};

export const defaultSavingsDetailsInitialState = {
  memberValues: [
    {
      'member-1': {
        ageWhenRsuVestingStarts: 35,
        employerContrib401k: 0.2,
        iraContrib: 0.05,
        percentDefferedComp: 0.05,
        percentPensionGrant: 0,
        percentRoth401k: 0.2,
        percentRothIra: 0.3,
        percentRsuGrant: 0,
        rsuVestingYrs: 3,
        selfContrib401k: 0.1,
        useSavingsForRetSavings: true,
      },
    },
  ],
};

export const defaultTaxDetailsInitialState = {
  itemizedDedGrowth: 0,
  itemizedDeduction: 0,
  qualifiedBusinessIncDedGrowth: 0,
  qualifiedBusinessIncDeduction: 0,
};

export const defaultExpenseDetailsInitialStateV2 = {
  useAggNonHousingExpense: false,
  discRetFactor1: '',
  discRetFactor2: '',
  discRetFactor3: '',
  discPercentOfAgg: '',
  amounts: {
    aggNonHousing: '',
    rental: '',
    food: '',
    restaurants: '',
    utils: '',
    phonesAndEnt: '',
    insurancesNonProp: '',
    cars: '',
    travel: '',
    shoppingAndMisc: '',
    healthAndFitness: '',
  },
  disc: {
    // discaggNonHousing: false,
    useDiscretionaryFlag: false,
    discrental: false,
    discfood: true,
    discrestaurants: false,
    discutils: true,
    discphonesAndEnt: false,
    discinsurancesNonProp: false,
    disccars: true,
    disctravel: true,
    dischealthAndFitness: false,
    discshoppingAndMisc: false,
  },
  horFactor: {
    // horizonaggNonHousing: '',
    horizonrental: '',
    horizonfood: '',
    horizonrestaurants: '',
    horizonutils: 1,
    horizonphonesAndEnt: '',
    horizoninsurancesNonProp: '',
    horizoncars: '',
    horizontravel: '',
    horizonshoppingAndMisc: '',
    horizonhealthAndFitness: '',
  },
  retFactor: {
    // retirementaggNonHousing: '',
    retirementrental: '',
    retirementfood: '',
    retirementrestaurants: '',
    retirementutils: '',
    retirementphonesAndEnt: '',
    retirementinsurancesNonProp: '',
    retirementcars: '',
    retirementtravel: '',
    retirementshoppingAndMisc: '',
    retirementhealthAndFitness: '',
  },
};

export const defaultExpenseDetailsInitialState = {
  useAggNonHousingExpense: false,
  discRetFactor1: '',
  discRetFactor2: '',
  discRetFactor3: '',
  discPercentOfAgg: '',
  expenses: {
    aggNonHousing: '',
    rental: '',
    food: '',
    restaurants: '',
    utils: '',
    phonesAndEnt: '',
    insurancesNonProp: '',
    cars: '',
    travel: '',
    shoppingAndMisc: '',
    healthAndFitness: '',
  },
  disc: {
    // discaggNonHousing: false,
    useDiscretionaryFlag: false,
    discrental: false,
    discfood: true,
    discrestaurants: false,
    discutils: true,
    discphonesAndEnt: false,
    discinsurancesNonProp: false,
    disccars: true,
    disctravel: true,
    dischealthAndFitness: false,
    discshoppingAndMisc: false,
  },
  horFactor: {
    // horizonaggNonHousing: '',
    horizonrental: '',
    horizonfood: '',
    horizonrestaurants: '',
    horizonutils: 1,
    horizonphonesAndEnt: '',
    horizoninsurancesNonProp: '',
    horizoncars: '',
    horizontravel: '',
    horizonshoppingAndMisc: '',
    horizonhealthAndFitness: '',
  },
  retFactor: {
    // retirementaggNonHousing: '',
    retirementrental: '',
    retirementfood: '',
    retirementrestaurants: '',
    retirementutils: '',
    retirementphonesAndEnt: '',
    retirementinsurancesNonProp: '',
    retirementcars: '',
    retirementtravel: '',
    retirementshoppingAndMisc: '',
    retirementhealthAndFitness: '',
  },
};

export const defaultReviewPreferencesInitialState = {
  horizon: '',
  emCashReqYrs: '',
  inflationRate: '',
  youngerAgeAtEstateTransfer: '',
  // defaultMarketReturn: '',
  // defaultCashReturn: '',
  defaultCashPercentInSavings: '',
  displayTerm: '',
  inflationVol: '',
  // defaultMarketVol: '',
  // defaultCashVol: '',
  debtRateRedThreshold: '',
  debtRateYellowThreshold: '',
  insuranceRedThreshold: '',
  insuranceYellowThreshold: '',
  invReturnRedHigh: '',
  invReturnRedLow: '',
  invReturnYellowHigh: '',
  invReturnYellowLow: '',
};

export const defaultReviewExpenseDetailsInitialState = {
  reduceDiscretionaryExpenses: null,
  inRetirementReduceDiscretionaryExpenses: null,
  rentalHousingAnnualCost: null,
  healthAndFitnessAnnualCost: null,
  insurancesAnnualCost: null,
  foodAnnualCost: null,
  restaurantsAnnualCost: null,
  utilitiesAnnualCost: null,
  shoppingAndMiscellaneousAnnualCost: null,
  phonesAndEntertainmentAnnualCost: null,
  travelAnnualCost: null,
  carsOrTransportationAnnualCost: null,
};

export const defaultReviewInvestmentsInitialState = {
  elderTraditional401kValue: null,
  youngerTraditional401kValue: null,
  youngerEmergencyCash: null,
  youngerBrokerageValue: null,
  youngerROTHIRAValue: null,
  youngerTraditionalIRAValue: null,
  elderEmergencyCash: null,
  elderBrokerageValue: null,
  elderROTHIRAValue: null,
  elderTraditionalIRAValue: null,
  youngerROTH401kValue: null,
  elderROTH401kValue: null,
};
export const defaultReviewInvestmentReturnsInitialState = {
  elderTraditional401kAnnualReturn: null,
  youngerTraditional401kAnnualReturn: null,
  elderROTH401kAnnualReturn: null,
  youngerROTH401kAnnualReturn: null,
  elderTraditionalIRAAnnualReturn: null,
  elderROTHIRAAnnualReturn: null,
  elderBrokerageAnnualReturn: null,
  elderEmergencyCashAnnualReturn: null,
  youngerTraditionalIRAAnnualReturn: null,
  youngerROTHIRAAnnualReturn: null,
  youngerBrokerageAnnualReturn: null,
  youngerEmergencyCashAnnualReturn: null,
};
export const defaultReviewMiscIncomeAndDeductionsInitialState = {
  otherIncomeExclRentalsAndSocialSec: null,
  otherIncomeGrowthRate: null,
  itemizedDedInTaxReturn: null,
  itemizedDedGrowthRate: null,
  qualifiedBusinessIncomeDed: null,
  qualifiedBusinessIncomeDedGrowthrate: null,
};
