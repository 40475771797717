import { put, call, all, takeLatest } from 'redux-saga/effects';
import {
  getClientsByAdvisorIdSuccessAction,
  getClientsByAdvisorIdErrorAction,
  GET_CLIENTS_BY_ADVISOR_ID,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM,
  GET_ADVISOR_STATS_BY_CLIENT_TYPE,
  setSelectedClientIdAction,
  getAdvisorAssetsUnderManagementSuccessAction,
  getAdvisorAssetsUnderManagementErrorAction,
  getAdvisorAssetsUnderManagementCompanyAUMSuccessAction,
  getAdvisorAssetsUnderManagementCompanyAUMErrorAction,
  getAdvisorStatsByClientTypeErrorAction,
  getAdvisorStatsByClientTypeSuccessAction,
  setAdvisorStatsLoadingAction,
  setAdvisorAssetsLoadingAction,
  getClientsNotUpdatedForNumDaysLoadingAction,
  getClientsMeetingsForNumDaysLoadingAction,
  getAssetAllocListByClientIdSuccessAction,
  getAssetAllocListByClientIdErrorAction,
  getForecastInvMktExpListByAdvisorIdSuccessAction,
  getForecastInvMktExpListByAdvisorIdErrorAction,
  // setInvMarketExpectationsByAdvisorIdLoadingAction,
  GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR,
  getInvMarketExpectationsByAdvisorIdSuccessAction,
  getInvMarketExpectationsByAdvisorIdErrorAction,
  getClientsNotUpdatedForNumDaysSuccessAction,
  getClientsNotUpdatedForNumDaysErrorAction,
  getClientsMeetingsForNumDaysSuccessAction,
  getClientsMeetingsForNumDaysErrorAction,
  GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS,
  GET_CLIENTS_MEETINGS_FOR_NUM_DAYS,
  GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID,
  GET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
  setAssetAllocListByClientIdLoadingAction,
} from '../actions/clientsInfoAction';
import {
  getClientsByAdvisorId,
  getAdvisorAssetsUnderManagement,
  getAdvisorStatsByClientType,
  getInvMarketExpectationsByAdvisorId,
  getClientsNotUpdatedForNumDays,
  getClientsMeetingsForNumDays,
  getCompanyAumByAdvisorId,
  getAssetAllocListByClientId,
  getForecastInvMktExpListByAdvisorId,
} from '../../services/dashboardSvc';
import { getPlansByClientIdAction } from '../actions/plansAction';

function* triggerGetClientsByAdvisorId(payload) {
  try {
    const { status, data: clientsData } = yield call(
      getClientsByAdvisorId,
      payload
    );
    if (status === 200) {
      const clients = clientsData.map((d) => {
        return {
          label: d.clientName,
          value: d.clientId,
        };
      });
      yield put(getClientsByAdvisorIdSuccessAction(clients));
      if (clientsData.length === 1) {
        yield put(setSelectedClientIdAction(clientsData[0].clientId));
      }
    } else {
      yield put(getClientsByAdvisorIdErrorAction([]));
      yield put(setSelectedClientIdAction(''));
    }
  } catch (err) {
    yield put(getClientsByAdvisorIdErrorAction([]));
    yield put(setSelectedClientIdAction(''));
  }
}

function* triggerGetAdvisorAssetsUnderManagementCompanyAUM(request) {
  try {
    yield put(setAdvisorAssetsLoadingAction(true));
    const { status: companyAumStatus, data: companyAum } = yield call(
      getCompanyAumByAdvisorId,
      request.payload
    );
    if (companyAumStatus === 200) {
      yield put(setAdvisorAssetsLoadingAction(false));
      yield put(
        getAdvisorAssetsUnderManagementCompanyAUMSuccessAction(companyAum)
      );
    } else {
      yield put(setAdvisorAssetsLoadingAction(false));
      yield put(getAdvisorAssetsUnderManagementCompanyAUMErrorAction({}));
    }
  } catch (err) {
    yield put(setAdvisorAssetsLoadingAction(false));
    yield put(getAdvisorAssetsUnderManagementCompanyAUMErrorAction({}));
  }
}

function* triggerGetAdvisorAssetsUnderManagement(request) {
  try {
    yield put(setAdvisorAssetsLoadingAction(true));
    const { status: advisorStatus, data: advisorAssetsAum } = yield call(
      getAdvisorAssetsUnderManagement,
      request.payload
    );
    if (advisorStatus === 200) {
      const advisorAssetsUnderManagement = {
        ...advisorAssetsAum,
      };
      yield put(setAdvisorAssetsLoadingAction(false));
      yield put(
        getAdvisorAssetsUnderManagementSuccessAction(
          advisorAssetsUnderManagement
        )
      );
    } else {
      yield put(setAdvisorAssetsLoadingAction(false));
      yield put(getAdvisorAssetsUnderManagementErrorAction({}));
    }
  } catch (err) {
    yield put(setAdvisorAssetsLoadingAction(false));
    yield put(getAdvisorAssetsUnderManagementErrorAction({}));
  }
}

function* triggerGetAdvisorStatsByClientType(request) {
  try {
    yield put(setAdvisorStatsLoadingAction(true));
    const { status, data: advisorStatsByClientType } = yield call(
      getAdvisorStatsByClientType,
      request.payload
    );
    if (status === 200) {
      yield put(setAdvisorStatsLoadingAction(false));
      yield put(
        getAdvisorStatsByClientTypeSuccessAction(advisorStatsByClientType)
      );
    } else {
      yield put(setAdvisorStatsLoadingAction(false));
      yield put(getAdvisorStatsByClientTypeErrorAction([]));
    }
  } catch (err) {
    yield put(setAdvisorStatsLoadingAction(false));
    yield put(getAdvisorStatsByClientTypeErrorAction([]));
  }
}

function* triggerGetInvMarketExpectationsByAdvisorId(request) {
  try {
    // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(true));
    const { status, data: invMarketExpectationsByAdvisorId } = yield call(
      getInvMarketExpectationsByAdvisorId,
      request.payload
    );
    if (status === 200) {
      // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
      yield put(
        getInvMarketExpectationsByAdvisorIdSuccessAction(
          invMarketExpectationsByAdvisorId
        )
      );
    } else {
      // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
      yield put(getInvMarketExpectationsByAdvisorIdErrorAction({}));
    }
  } catch (err) {
    // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
    yield put(getInvMarketExpectationsByAdvisorIdErrorAction({}));
  }
}

function* triggerGetClientsNotUpdatedForNumDays(request) {
  try {
    yield put(getClientsNotUpdatedForNumDaysLoadingAction(true));
    const { status, data: clientsNotUpdated } = yield call(
      getClientsNotUpdatedForNumDays,
      request.payload
    );
    if (status === 200) {
      yield put(getClientsNotUpdatedForNumDaysLoadingAction(false));
      yield put(getClientsNotUpdatedForNumDaysSuccessAction(clientsNotUpdated));
    } else {
      yield put(getClientsNotUpdatedForNumDaysLoadingAction(false));
      yield put(getClientsNotUpdatedForNumDaysErrorAction([]));
    }
  } catch (err) {
    yield put(getClientsNotUpdatedForNumDaysLoadingAction(false));
    yield put(getClientsNotUpdatedForNumDaysErrorAction([]));
  }
}
function* triggerGetClientsMeetingsForNumDays(request) {
  try {
    yield put(getClientsMeetingsForNumDaysLoadingAction(true));
    const { status, data: clientMeetings } = yield call(
      getClientsMeetingsForNumDays,
      request.payload
    );
    if (status === 200) {
      yield put(getClientsMeetingsForNumDaysLoadingAction(false));
      yield put(getClientsMeetingsForNumDaysSuccessAction(clientMeetings));
    } else {
      yield put(getClientsMeetingsForNumDaysLoadingAction(false));
      yield put(getClientsMeetingsForNumDaysErrorAction([]));
    }
  } catch (err) {
    yield put(getClientsMeetingsForNumDaysLoadingAction(false));
    yield put(getClientsMeetingsForNumDaysErrorAction([]));
  }
}

function* triggerGetAssetAllocListByClientId(request) {
  const { payload } = request;
  try {
    yield put(setAssetAllocListByClientIdLoadingAction(true));
    const { status, data: allocationList } = yield call(
      getAssetAllocListByClientId,
      payload
    );
    if (status === 200) {
      const allocation = allocationList
        .filter((a) => a.riskLevelId)
        .map((d) => {
          return {
            id: `${d.riskLevelLabel}-${d.riskLevelId}`,
            label: d.riskLevelLabel,
            value: d.riskLevelId,
            riskLevel: d.riskLevel,
          };
        });
      yield put(setAssetAllocListByClientIdLoadingAction(false));
      yield put(getAssetAllocListByClientIdSuccessAction(allocation));
    } else {
      yield put(setAssetAllocListByClientIdLoadingAction(false));
      yield put(getAssetAllocListByClientIdErrorAction([]));
    }
  } catch (err) {
    yield put(setAssetAllocListByClientIdLoadingAction(false));
    yield put(getAssetAllocListByClientIdErrorAction([]));
  }
}

function* triggerGetForecastInvMktExpListByAdvisorId(request) {
  try {
    const { status, data: forecastList } = yield call(
      getForecastInvMktExpListByAdvisorId,
      request.payload
    );
    if (status === 200) {
      const forecast = forecastList
        .filter((f) => f.benchmarkId)
        .map((d) => {
          return {
            label: d.benchmarkLabel,
            value: d.benchmarkId,
          };
        });
      yield put(getForecastInvMktExpListByAdvisorIdSuccessAction(forecast));
    } else {
      yield put(getForecastInvMktExpListByAdvisorIdErrorAction([]));
    }
  } catch (err) {
    yield put(getForecastInvMktExpListByAdvisorIdErrorAction([]));
  }
}

export function* watchClientsByAdvisor() {
  yield takeLatest(GET_CLIENTS_BY_ADVISOR_ID, triggerGetClientsByAdvisorId);
  yield takeLatest(
    GET_ADVISOR_ASSETS_UNDER_MANAGEMENT,
    triggerGetAdvisorAssetsUnderManagement
  );
  yield takeLatest(
    GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM,
    triggerGetAdvisorAssetsUnderManagementCompanyAUM
  );

  yield takeLatest(
    GET_ADVISOR_STATS_BY_CLIENT_TYPE,
    triggerGetAdvisorStatsByClientType
  );
  yield takeLatest(
    GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR,
    triggerGetInvMarketExpectationsByAdvisorId
  );
  yield takeLatest(
    GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS,
    triggerGetClientsNotUpdatedForNumDays
  );
  yield takeLatest(
    GET_CLIENTS_MEETINGS_FOR_NUM_DAYS,
    triggerGetClientsMeetingsForNumDays
  );
  yield takeLatest(
    GET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
    triggerGetAssetAllocListByClientId
  );
  yield takeLatest(
    GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID,
    triggerGetForecastInvMktExpListByAdvisorId
  );
}
