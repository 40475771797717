import React, { useEffect, useState } from 'react';
import {
  renderMembersInputRow,
  renderSelectOption,
  renderMemberLabel,
  transformRothChoicesBackToUIType,
  renderFormSelectOption,
} from '../../helpers/ClientHelper';
export const RothComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const {
    moduleEstate: { rothChoices = {} } = {},
    moduleFamily: { infoCore = {} } = {},
  } = localPlanDetailsByPlanId;

  const [rothChoicesDetails, setRothChoicesDetails] = useState(rothChoices);

  const withDrawRothFirst = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (rothChoices && Object.keys(rothChoices).length > 0) {
      let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);
      setRothChoicesDetails(tempRothChoices);
    }
  }, [rothChoices]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        {renderMemberLabel(
          infoCore,
          'name',
          'basicsFirst',
          'basicsSecond',
          state
        )}
      </div>
      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlOneTradIraToRothPercent',
          'tradIraToRothPercent',
          'tlTwoTradIraToRothPercent',
          'What % of Traditional IRA would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentNow',
          'trad401kToRothPercentNow',
          'tlTwoTrad401kToRothPercentNow',
          'What % of Traditional 401k would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentAtRet',
          'trad401kToRothPercentAtRet',
          'tlTwoTrad401kToRothPercentAtRet',
          'What % of Traditional IRA would you like to consider converting to ROTH at retirement',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderSelectOption(
          'text',
          'ddlMemOneWithdrawRothFirst',
          'withdrawRothFirst',
          'When withdrawing funds from Retirement accounts, would you like to withdraw first from ROTH?',
          withDrawRothFirst,
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          state.membersCount === 1 ? false : true,
          setRothChoicesDetails
        )}
      </div>
    </>
  );
};

export default RothComponent;
