import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import CustomDatePicker from '../controls/DatePicker';
import '../../styles/planner.scss';
import { DATATYPES } from '../../constants/common';
import { OUTPUT_FIELD_TYPE } from '../../constants/retirementPlan';
import Controls from '../controls/Controls';
class PlannerInputField extends Component {
  state = {
    isInputHidden: true,
    isFieldValid: true,
  };

  slidingInput = React.createRef();

  toggleInputField = (event) => {
    const { isDisabled = false, fieldType = '' } = this.props;
    const { isInputHidden, isFieldValid } = this.state;
    if (!isDisabled && fieldType !== OUTPUT_FIELD_TYPE && isFieldValid) {
      this.setState({
        isInputHidden: !isInputHidden,
      });
    }
  };

  maxLengthCheck = (value, maxLength) => {
    if (maxLength) {
      if (value.length > maxLength) {
        value = value.slice(0, maxLength);
      }
    }
  };

  getFormFieldType = (field) => {
    const { isInputHidden, isFieldValid } = this.state;
    const {
      type,
      name,
      errorText = '',
      isValid = true,
      label,
      options,
      onInputChange,
      onSelectChange,
      onDateChange,
      dataType = DATATYPES.STRING,
    } = this.props;
    const { [name]: fieldValue, isDisabled } = this.props;
    switch (type) {
      case 'select':
        return (
          <Controls.Select
            className="js-example-basic-single"
            name={name}
            label={label}
            onChange={onSelectChange}
            onBlur={this.toggleInputField}
            value={fieldValue}
            disabled={isDisabled}
            options={options.map((c) => {
              return {
                value: c.value,
                label: c.label,
              };
            })}
          />
        );
      case 'date':
        return (
          <CustomDatePicker
            selectedDate={fieldValue}
            onChange={onDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      default:
        return (
          <input
            disabled={isDisabled}
            ref={this.slidingInput}
            name={name}
            className={`planner-input ${!isFieldValid ? 'error-field' : ''}`}
            value={fieldValue}
            type={dataType !== DATATYPES.PERCENTAGE ? type : 'text'}
            onChange={this.handleInputChange}
            onBlur={this.toggleInputField}
          />
        );
    }
  };

  getFieldValue = () => {
    const { name, isDollarSignRequired = false } = this.props;
    const { type, options = [] } = this.props;
    let { [name]: fieldValue } = this.props;
    fieldValue =
      fieldValue === 'true'
        ? true
        : fieldValue === 'false'
        ? false
        : fieldValue;
    if (type === 'select' && fieldValue !== undefined && fieldValue !== '') {
      const optValue = options.find((option) => option.value === fieldValue);
      return optValue ? optValue.label : '';
    }
    return isDollarSignRequired
      ? this.getFomrattedFieldValue(fieldValue)
      : fieldValue;
  };

  getFomrattedFieldValue = (fieldValue) => {
    const fomrmattedNumber = parseFloat(fieldValue).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
    return `${fomrmattedNumber}`;
  };

  handleInputChange = (event) => {
    const { maxLength, onInputChange, name } = this.props;
    const { value } = event.target;
    const validationMethodName = `Is${name}Valid`;
    const validationMethod = this.props[validationMethodName];
    const isFieldValid =
      typeof validationMethod === 'function' ? validationMethod(value) : true;
    if (maxLength) {
      if (value.length <= maxLength) {
        this.setState({
          isFieldValid,
        });
        onInputChange(value);
      }
    } else {
      this.setState({
        isFieldValid,
      });
      onInputChange(value);
    }
  };

  renderInputField = () => {
    const { isInputHidden, isFieldValid } = this.state;
    const {
      label,
      name,
      onInputChange,
      fieldType = '',
      errorText = 'Invalid field',
      isLabelrequired = true,
    } = this.props;
    const { [name]: fieldValue, type, options, isDisabled } = this.props;
    return (
      <>
        <Grid
          item
          xs={isInputHidden ? 12 : 12}
          className="planner-label-grid"
          onClick={this.toggleInputField} //removed this fn on span and label, applied it to grid item
        >
          {isLabelrequired && (
            <div
              className={`planner-label ${
                isInputHidden ? 'hide-transition' : 'show-transition'
              }`}
            >
              {label}
            </div>
          )}
          {isInputHidden && fieldValue !== '' && (
            <>
              <div
                className={`input-value ${
                  fieldType === OUTPUT_FIELD_TYPE ? 'output-field-value' : ''
                }`}
              >
                {this.getFieldValue()}
              </div>
            </>
          )}
        </Grid>
        {!isInputHidden && (
          <Grid
            item
            xs={12}
            className={
              isInputHidden ? 'planner-input-hide' : 'planner-input-show'
            }
          >
            {this.getFormFieldType()}
            {!isFieldValid && <p className="error-text">{errorText}</p>}
          </Grid>
        )}
      </>
    );
  };
  render() {
    const { isDisabled, fieldType = '' } = this.props;
    return (
      <div
        container
        className={
          isDisabled || fieldType === OUTPUT_FIELD_TYPE
            ? 'planner-label-disabled'
            : ''
        }
      >
        {this.renderInputField()}
      </div>
    );
  }
}

export default PlannerInputField;
