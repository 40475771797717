import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer.js';
import rootSaga from './sagas/index';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
function restoreStoreState() {
  //remove this and use redux to store data instead of browser session
  const user = sessionStorage.getItem('userDetails');
  return { loggedInUser: { userDetails: JSON.parse(user) || {} } };
  //return { loggedInUser: { userDetails: {}, isAuthenticated: false } };
}
const initialState = restoreStoreState();
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);
export default store;
