import React from 'react';

export const CustomData = (props) => {
  const { data, renderWhatData } = props;

  const renderCustomData = () => {
    const { tabName, subtabName, data: dataRendered } = renderWhatData;
    const { isItFull, mainOrAux, notfullOffset, subTabType } = dataRendered;
    if (data && data.length > 0) {
      const selectedData = data.filter(
        (t) => t && tabName && t.tabName.toLowerCase() === tabName.toLowerCase()
      )[0];
      const selectedSubData =
        (selectedData &&
          selectedData.subtabs &&
          selectedData.subtabs.length > 0 &&
          selectedData.subtabs.filter(
            (s) =>
              s &&
              subtabName &&
              s.subtabName.toLowerCase() === subtabName.toLowerCase()
          )[0]) ||
        {};
      let inputData = [];
      let buildData = [];
      if (
        subTabType === 'input' &&
        selectedSubData &&
        selectedSubData.input &&
        Object.keys(selectedSubData.input).length > 0
      ) {
        const { input: { [mainOrAux]: renderData = [] } = {} } =
          selectedSubData;
        const { heading = '', rows = [] } = renderData || {};
        inputData =
          rows && rows.length > 0
            ? [
                {
                  name: heading,
                  elements: rows.filter((r) => r && r.label && r.value),
                },
              ]
            : [];
      } else if (
        subTabType === 'build' &&
        selectedSubData &&
        selectedSubData.build &&
        Object.keys(selectedSubData.build).length > 0
      ) {
        const { build: { [mainOrAux]: renderData = [] } = {} } =
          selectedSubData;
        const tempBuildData =
          renderData &&
          renderData.filter(
            (m) => m && m.elements && m.elements.find((v) => v.label && v.value)
          );
        buildData =
          tempBuildData.length > 0
            ? tempBuildData.map((m, i) => {
                return {
                  name: m.name || 'Heading',
                  elements: m.elements.filter((v) => v && v.label && v.value),
                };
              })
            : [];
      }
      if (inputData.length > 0 || buildData.length > 0) {
        return (
          <div className="client_chart_main_div">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {inputData.map((val, index) => {
                  return (
                    <table
                      className={
                        inputData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {buildData.map((val, index) => {
                  return (
                    <table
                      className={
                        buildData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <>{renderCustomData()}</>;
};
export default CustomData;
