export const ClientTabs = [
  {
    mainTab: 'Build',
    data: [
      {
        tabIndex: 0,
        tabName: 'FAMILY',
        subtabs: [
          'PROFILE',
          'INCOME',
          'SAVINGS',
          'EXPENSES',
          'KIDS',
          'VEHICLES',
          'VACATIONS',
        ],
      },
      {
        tabName: 'ASSETS',
        subtabs: [
          'MARKETS',
          'PRIMARY HOME',
          'SECONDARY HOMES',
          'INVESTMENT PROPERTIES',
        ],
      },
      {
        tabName: 'DEBT',
        subtabs: ['CREDIT CARDS', 'STUDENT LOANS', 'OTHER LOANS'],
      },
      {
        tabName: 'INSURANCE',
        subtabs: [
          'TERM LIFE',
          'WHOLE LIFE',
          'LONG TERM CARE',
          'UMBRELLA',
          'PROPERTY',
          'AUTO',
          'HEALTH',
        ],
      },
      {
        tabName: 'ESTATE',
        subtabs: ['ROTH', 'HEIRS'],
      },
      {
        tabName: 'SETTINGS',
        subtabs: ['MODEL', 'CASH FLOWS', 'EMERGENCY FUNDS', 'TAXES', 'ALERTS'],
      },
      // Add more objects as needed
    ],
  },
  // Add more mainTabs as needed
];

export const infoCoreValidatableFields = [
  {
    apiFieldName: 'pensionContribYrs',
    errorMessage: 'Cannot be greater than retirement Age - Current Age',
  },
  {
    apiFieldName: 'socialSecAge',
    errorMessage: 'Social Security Age should be minimum 62',
  },
];

const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const kidsFormFields = [
  {
    key: 'name',
    name: 'Kid Name',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Kid Name',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },
  {
    key: 'yearBorn',
    name: 'Year born or projected to be born',
    isRequired: true,
    isDisabled: true,
    type: 'date',
    placeholder: 'YYYY',
    dataType: 'year',
    errorMessage: 'This field is mandatory',
  },
  {
    key: 'annualExpense',
    name: 'Additional annual expenses due to kid including child care etc.',
    isRequired: false,
    type: 'text',
    placeholder: 'Expense',
    dataType: 'currency',
    errorMessage: '',
  },
  {
    key: 'collegeAge',
    name: 'Age at which the kid will start college',
    isRequired: true,
    type: 'text',
    errorMessage: 'Age should be above 18 years',
    dataType: 'number',
    placeholder: '',
  },
  {
    key: 'collegeYears',
    name: 'Plan for number of years in college',
    isRequired: true,
    type: 'text',
    errorMessage: 'Number of Years should be greater than 0',
    dataType: 'number',
    placeholder: '',
  },
  {
    key: 'annualCollegeExpenseNow',
    name: 'Annual college expense now where kid is projected to attend',
    isRequired: false,
    type: 'text',
    errorMessage: '',
    dataType: 'currency',
    placeholder: '',
  },
  {
    key: 'collegeExpenseGrowth',
    name: 'Annual growth of college expense between now and when projected to attend',
    isRequired: false,
    type: 'text',
    placeholder: '',
    dataType: 'percent',
    errorMessage: '',
  },
  {
    key: 'value529Plan',
    name: '529 savings plan balance (will be taken out of your future funds if kid is not born yet)',
    isRequired: false,
    type: 'text',
    errorMessage: '',
    dataType: 'currency',
    placeholder: '',
  },
  {
    key: 'peakReturn529Plan',
    name: 'Projected peak investment return of 529 plan (will be treated like the asset mix of a target fund)',
    isRequired: false,
    type: 'text',
    errorMessage: '',
    dataType: 'percent',
    placeholder: '',
  },
  {
    key: 'useSavingsToBuild529',
    name: 'Do you want to use non-retirment funds to contribute to 529 plan if income is not sufficient ?',
    isRequired: true,
    type: 'select',
    options: booleanOptions,
    dataType: 'boolean',
    errorMessage: '',
    placeholder: '',
  },
  {
    key: 'useSavingsFor529Deficit',
    name: 'Do you want to use non-retirment funds at the time of attending college if 529 plan savings are not sufficient ?',
    isRequired: true,
    type: 'select',
    options: booleanOptions,
    dataType: 'boolean',
    errorMessage: '',
    placeholder: '',
  },
];

export const vehiclesFormFields = [
  {
    key: 'name',
    name: 'Vehicle Name',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Vehicle Name',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },
  {
    key: 'purchasePrice',
    name: 'Purchase price',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: 'Purchase price',
    dataType: 'currency',
    errorMessage: '',
  },
  {
    key: 'purchaseDate',
    name: 'Purchase Date',
    isRequired: true,
    isDisabled: false,
    type: 'date',
    placeholder: 'yyyy-MM-dd',
    dataType: 'date',
    errorMessage: 'This field is mandatory',
  },
  {
    key: 'loanAmount',
    name: 'Loan amount',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: 'Loan amount',
    dataType: 'currency',
    errorMessage: 'Loan amount cannot be greater than Purchase price',
  },
  {
    key: 'balance',
    name: 'Current balance',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: 'Current balance',
    dataType: 'currency',
    errorMessage: 'This field is mandatory',
  },
  {
    key: 'apr',
    name: 'Annual percentage rate(APR)',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    dataType: 'percent',
    placeholder: 'Annual percentage rate(APR)',
    errorMessage: 'This field is mandatory',
  },
  {
    key: 'termMonths',
    name: 'Loan term months',
    isRequired: false,
    type: 'text',
    errorMessage: '',
    dataType: 'number',
    placeholder: 'Loan term months',
  },
  {
    key: 'interestStartDate',
    name: 'Date when interest starts accruing',
    isRequired: true,
    isDisabled: false,
    type: 'date',
    placeholder: 'yyyy-MM-dd',
    dataType: 'date',
    errorMessage: 'cannot go lesser than purchase date',
  },
  {
    key: 'paymentStartDate',
    name: 'Date when first payment is due',
    isRequired: true,
    isDisabled: false,
    type: 'date',
    placeholder: 'yyyy-MM-dd',
    dataType: 'date',
    errorMessage: 'cannot go lesser than purchase date',
  },
  {
    key: 'compounding',
    name: 'Interest will compound',
    isRequired: false,
    type: 'select',
    options: [],
    dataType: 'boolean',
    errorMessage: '',
    placeholder: '',
  },
  {
    key: 'monthlyPayment',
    name: 'Monthly payment (will be computed if left as zero)',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: 'Current balance',
    dataType: 'currency',
    errorMessage: '',
  },
  {
    key: 'value',
    name: 'Current value of vehicle',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: 'Current balance',
    dataType: 'currency',
    errorMessage: '',
  },
  {
    key: 'deprYrs',
    name: 'Number of years you want the value to be deprecated to zero',
    isRequired: false,
    type: 'text',
    errorMessage: '',
    dataType: 'number',
    placeholder: '',
  },
  {
    key: 'maintenanceExp',
    name: 'Annual maintenance expense \n (do not include insurance)',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: '',
    dataType: 'currency',
    errorMessage: '',
  },
];

export const vacationsFormFields = [
  {
    key: 'name',
    name: 'Vacation Name',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Vacation Name',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },

  {
    key: 'vacationYear',
    name: 'Year in which you plan to take',
    isRequired: true,
    isDisabled: false,
    type: 'date',
    placeholder: 'YYYY',
    dataType: 'year',
    errorMessage: 'Only future year is allowed',
  },

  {
    key: 'vacationExpense',
    name: 'How much would it cost?',
    isRequired: false,
    isDisabled: false,
    type: 'text',
    placeholder: '',
    dataType: 'currency',
    errorMessage: '',
  },
];

export const secondHomesFormFields = [
  {
    key: 'name',
    name: 'Short name of home',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Short name of home',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },
  {
    name: 'Purchase Date',
    key: 'purchaseDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date format',
  },
  {
    name: 'Purchase Price',
    key: 'purchasePrice',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Current value',
    key: 'value',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Appreciation rate',
    key: 'appreciationRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Maintenance expense',
    key: 'aggMaintenanceExp',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Expense growth',
    key: 'aggMaintenanceExpGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
  },
  {
    name: 'Orig/refi loan dt',
    key: 'origOrRefiLoanDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date',
  },
  {
    name: 'Loan amount',
    key: 'origOrRefiLoanAmount',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Loan Amount should be less than Purchase Price',
  },
  {
    name: 'Amount outstanding',
    key: 'loanAmountOutstanding',
    isRequired: false,
    type: 'text',
    errorMessage: 'Value should be greater than 0',
    dataType: 'currency',
  },
  {
    name: 'Loan term yrs',
    key: 'loanTerm',
    isRequired: false,
    type: 'text',
    dataType: 'number',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Fixed rate',
    key: 'fixedIntRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Domicile state',
    key: 'propDomicile',
    type: 'select',
    options: [],
    isRequired: true,
    errorMessage: 'This field is mandatory',
  },
  {
    name: 'Prop tax rate',
    key: 'taxRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Tax basis',
    key: 'taxBasis',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Basis growth',
    key: 'taxBasisGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
];

export const invPropsFormFields = [
  {
    key: 'name',
    name: 'Short name of home',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Short name of home',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },
  {
    name: 'Purchase Date',
    key: 'purchaseDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date format',
  },
  {
    name: 'Purchase Price',
    key: 'purchasePrice',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Current value',
    key: 'value',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Appreciation rate',
    key: 'appreciationRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Maintenance expense',
    key: 'aggMaintenanceExp',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Expense growth',
    key: 'aggMaintenanceExpGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
  },
  {
    name: 'Orig/refi loan dt',
    key: 'origOrRefiLoanDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date',
  },
  {
    name: 'Loan amount',
    key: 'origOrRefiLoanAmount',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Loan Amount should be less than Purchase Price',
  },
  {
    name: 'Amount outstanding',
    key: 'loanAmountOutstanding',
    isRequired: false,
    type: 'text',
    errorMessage: 'Value should be greater than 0',
    dataType: 'currency',
  },
  {
    name: 'Loan term yrs',
    key: 'loanTerm',
    isRequired: false,
    type: 'text',
    dataType: 'number',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Fixed rate',
    key: 'fixedIntRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Domicile state',
    key: 'propDomicile',
    type: 'select',
    options: [],
    isRequired: true,
    errorMessage: 'This field is mandatory',
  },
  {
    name: 'Prop tax rate',
    key: 'taxRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Tax basis',
    key: 'taxBasis',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Basis growth',
    key: 'taxBasisGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Annual Rent',
    key: 'annualRent',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Annual Rent Growth',
    key: 'annualRentGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Accumulated Depreciation',
    key: 'priorDeprAccumulated',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
];

export const primaryHomeFormFields = [
  {
    key: 'name',
    name: 'Short name of home',
    isRequired: true,
    isDisabled: true,
    type: 'text',
    placeholder: 'Short name of home',
    dataType: 'string',
    errorMessage: 'Name already exists',
  },
  {
    name: 'Purchase Date',
    key: 'purchaseDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date format',
  },
  {
    name: 'Purchase Price',
    key: 'purchasePrice',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Current value',
    key: 'value',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Appreciation rate',
    key: 'appreciationRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Maintenance expense',
    key: 'aggMaintenanceExp',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Expense growth',
    key: 'aggMaintenanceExpGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
  },
  {
    name: 'Orig/refi loan dt',
    key: 'origOrRefiLoanDate',
    isRequired: true,
    type: 'date',
    dataType: 'date',
    errorMessage: 'Invalid Date',
  },
  {
    name: 'Loan amount',
    key: 'origOrRefiLoanAmount',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Loan Amount should be less than Purchase Price',
  },
  {
    name: 'Amount outstanding',
    key: 'loanAmountOutstanding',
    isRequired: false,
    type: 'text',
    errorMessage: 'Value should be greater than 0',
    dataType: 'currency',
  },
  {
    name: 'Loan term yrs',
    key: 'loanTerm',
    isRequired: false,
    type: 'text',
    dataType: 'number',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Fixed rate',
    key: 'fixedIntRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Domicile state',
    key: 'propDomicile',
    type: 'select',
    options: [],
    isRequired: true,
    errorMessage: 'This field is mandatory',
  },
  {
    name: 'Prop tax rate',
    key: 'taxRate',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
  {
    name: 'Tax basis',
    key: 'taxBasis',
    isRequired: false,
    type: 'text',
    dataType: 'currency',
    errorMessage: 'Value should be greater than 0',
  },
  {
    name: 'Basis growth',
    key: 'taxBasisGrowth',
    isRequired: false,
    type: 'text',
    dataType: 'percent',
    errorMessage: '% field should range from 0 to 100',
  },
];
