export const BuildTabComponent = [
  {
    mainTab: 'Build',
    data: [
      {
        tabIndex: 0,
        tabName: 'FAMILY',
        subtabs: ['PERSONAL', 'KIDS'],
      },
      {
        tabName: 'Assets',
        subtabs: ['MARKETS', 'OTHER'],
      },
      {
        tabName: 'INCOME',
        subtabs: [
          'WAGES',
          'INVESTMENT INCOME',
          'SOCIAL SECURITY',
          'PENSION',
          'DEFERRED COMPENSATION',
          'OTHER INCOME',
        ],
      },
      {
        tabName: 'SAVINGS',
        subtabs: [
          'PENSION',
          '401k & IRA',
          'EMERGENCY FUNDS',
          'DEFERRED COMPENSATION',
          '529 PLANS',
          'OTHER',
        ],
      },
      {
        tabName: 'EXPENSES',
        subtabs: [
          'KIDS',
          'VEHICLES',
          'INSURANCE',
          'DEBT',
          'VACATIONS',
          'LIVING',
        ],
      },
      {
        tabName: 'PROPERTIES',
        subtabs: ['PRIMARY HOME', 'SECOND HOMES', 'Rentals'],
      },
      {
        tabName: 'ESTATE PLANNING',
        subtabs: ['ROTH', 'HEIRS'],
      },
      {
        tabName: 'SETTINGS',
        subtabs: ['MODEL', 'TAXES', 'ALERTS'],
      },
      // Add more objects as needed
    ],
  },
  // Add more mainTabs as needed
];

export const infoCoreValidatableFields = [
  {
    apiFieldName: 'pensionContribYrs',
    errorMessage: 'Cannot be greater than retirement Age - Current Age',
  },
  {
    apiFieldName: 'socialSecAge',
    errorMessage: 'Social Security Age should be minimum 62',
  },
];

export const ExpenseVehicleAccordionNames = ['vehicles', 'autoIns'];

export const ExpenseInsuranceAccordionNames = [
  'termlife',
  'wholelife',
  'umbrella',
  'longtermcare',
  'healthCare',
  'propertyInsurance',
];

export const ExpenseLoanAccordionNames = [
  'creditCards',
  'studentLoans',
  'otherLoans',
];
