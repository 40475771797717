export const SET_SIMULATE_BEFORE_AFTER_VALUES =
  'plans:set:simulate:beforeafter:values';
export const RESET_SIMULATE_BEFORE_AFTER_VALUES =
  'plans:reset:simulate:beforeafter:values';
export const TILE_BEFORE_AFTER_DATA =
  'plans:tileit:simulate:beforeafter:values';

export function setSimulateBeforeAfterValuesAction(payload) {
  return {
    type: SET_SIMULATE_BEFORE_AFTER_VALUES,
    payload: payload,
  };
}

export function resetSimulateBeforeAfterValues(payload) {
  return {
    type: RESET_SIMULATE_BEFORE_AFTER_VALUES,
    payload: payload,
  };
}

export function tileBeforeAfterValues(payload) {
  return {
    type: TILE_BEFORE_AFTER_DATA,
    payload: payload,
  };
}
