import { DATATYPES } from '../common';
export const HorizonTermOptions = [
  { label: 'Near term', value: 'NearTerm' },
  { label: 'Medium term', value: 'MediumTerm' },
  { label: 'Long term', value: 'LongTerm' },
  { label: 'Lifetime', value: 'Lifetime' },
];

export const DisplayTermOptions = [
  { label: 'Periodic', value: 'Periodic' },
  { label: 'Milestone', value: 'Milestone' },
];

export const reviewPreferencesFields = [
  {
    label: 'Simulate until age',
    name: 'youngerAgeAtEstateTransfer',
    colSize: 5,
    type: 'number',
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Emcash req (# yrs)',
    name: 'emCashReqYrs',
    colSize: 5,
    type: 'number',
    errorText: 'Expenses should be greater than 0',
  },
  {
    label: 'Cash % in savings',
    name: 'defaultCashPercentInSavings',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  // {
  //   label: 'Inflation rate',
  //   name: 'inflationRate',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  // {
  //   label: 'Market return (def)',
  //   name: 'defaultMarketReturn',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  // {
  //   label: 'Cash return (def)',
  //   name: 'defaultCashReturn',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  // {
  //   label: 'Inflation Vol',
  //   name: 'inflationVol',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  // {
  //   label: 'Market Vol',
  //   name: 'defaultMarketVol',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  // {
  //   label: 'Cash Vol',
  //   name: 'defaultCashVol',
  //   colSize: 5,
  //   type: 'number',
  //   dataType: DATATYPES.PERCENTAGE,
  //   errorText: '% field should range from 0 to 100',
  // },
  {
    label: 'Debt Rate Red Threshold',
    name: 'debtRateRedThreshold',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Debt Rate Yellow Threshold',
    name: 'debtRateYellowThreshold',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Insurance Red Threshold',
    name: 'insuranceRedThreshold',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Insurance Yellow Threshold',
    name: 'insuranceYellowThreshold',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Inv Return Red High',
    name: 'invReturnRedHigh',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Inv Return Red Low',
    name: 'invReturnRedLow',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Inv Return Yellow High',
    name: 'invReturnYellowHigh',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Inv Return Yellow Low',
    name: 'invReturnYellowLow',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
];
