import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { isEqual } from 'lodash';
import {
  revertTransformedValueByDataType,
  transformFieldValueByDataType,
} from '../../utils/commonUtils';
import {
  MENU_ITEM_WITH_MULTIPLE_KIDS,
  MENU_ITEM_WITH_CUSTOM_TABS,
  ASSETGOALS_COMPONENT,
  OTHERGOALS_COMPONENT,
  TABS_WITH_DIFF_BORDER,
  EXPENSE_COMPONENT,
  DEBT_COMPONENT,
} from '../../constants/IndividualClient';
import {
  COLUMN_CONFIG,
  OUTPUT_FIELD_TYPE,
} from '../../constants/retirementPlan';
import PlannerInputField from './PlannerInputField';

class PlannerTabsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialFieldsState(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      storeValues,
      shouldUpdateCompState = false,
      newStateToUpdate,
      updatePlannerDataInReduxCb,
    } = this.props;
    const {
      storeValues: prevStoreValues,
      shouldUpdateCompState: prevShouldUpdateCompState = false,
    } = prevProps;
    const { newMembers = [], commonFields } = this.state;
    const { newMembers: prevNewMembers = [], commonFields: prevCommonFields } =
      this.state;

    const newState =
      typeof newStateToUpdate === 'function' ? newStateToUpdate() : null;
    if (!isEqual(prevStoreValues, storeValues)) {
      this.setState((currentState) => {
        return {
          ...currentState,
          ...this.getInitialFieldsState(),
        };
      });
    }
    if (
      !isEqual(newMembers, prevNewMembers) ||
      (!isEqual(prevCommonFields, commonFields) &&
        newMembers.length > 0 &&
        Object.keys(commonFields).length > 0)
    ) {
      if (typeof updatePlannerDataInReduxCb === 'function') {
        updatePlannerDataInReduxCb();
      }
    }
  }

  getAllFormFieldValues = () => {
    const {
      fieldsConstants,
      fieldsConfig: { commonFields },
      componentName,
    } = this.props;
    const { newMembers, commonFields: commonFieldValues } = this.state;
    const commonFieldsObj = fieldsConstants[0].fields.filter((field) =>
      commonFields.includes(field.name)
    );
    const transformedCommonFields = revertTransformedValueByDataType(
      commonFieldValues,
      commonFieldsObj
    );
    const transformedState = newMembers.map((member) => {
      const { fieldValues } = member;
      return {
        ...member,
        fieldValues: {
          ...revertTransformedValueByDataType(
            fieldValues,
            fieldsConstants.find((field) => field.label === member.name).fields
          ),
        },
      };
    });
    return { members: transformedState, commonFields: transformedCommonFields };
  };

  getInitialFieldsState() {
    const {
      fieldsConstants,
      tabLabelPrefix,
      storeValues = [],
      componentName,
      fieldsConfig,
    } = this.props;
    const { commonFields } = fieldsConfig;
    try {
      const newMembers = fieldsConstants.reduce((acc, curr, index) => {
        const fieldNames = curr.fields.map((field) => field.name);
        const storeValueObj = storeValues.memberValues.find(
          (member) => Object.keys(member).toString() === curr.label
        );
        const nonCommonFields = curr.fields.filter(
          (field) => !commonFields.includes(field.name)
        );
        const fieldValues = nonCommonFields.reduce((acc1, curr1) => {
          return {
            ...acc1,
            [curr1.name]: Object.values(storeValueObj)[0][curr1.name],
          };
        }, {});
        const transformedCommonFieldValues = transformFieldValueByDataType(
          fieldValues,
          nonCommonFields
        );
        return acc.concat({
          name: curr.label,
          label: curr.label,
          key: parseInt(index + 1, 10),
          fieldValues: {
            ...transformedCommonFieldValues,
          },
        });
      }, []);
      const commonFieldsState = commonFields.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: storeValues[curr],
        };
      }, {});

      return {
        newMembers,
        commonFields: commonFieldsState,
      };
    } catch (e) {
      console.log('storevalues', storeValues, componentName, e);
    }
  }

  getSelectedMemberFormFields = (name) => {
    const { dependentFields, componentName } = this.props;
    const { newMembers, selectedMember, commonFields } = this.state;
    const fieldValuesObj = newMembers.find((member) => member.name === name);
    if (fieldValuesObj) {
      const { fieldValues } = fieldValuesObj;
      // if (MENU_ITEM_WITH_MULTIPLE_KIDS.indexOf(componentName) === -1) {
      //   const newFieldValues = { ...fieldValues, ...commonFields };
      //   return newFieldValues;
      // }
      return fieldValues;
    }
    return {};
  };

  getSelectedDate = (datevalue) => {
    const today = new Date(datevalue);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
  };

  handleInputChange = (value, field, memberName) => {
    const { name, type } = field;
    let updatedValue = value;
    // if (type === 'number' && dataType !== DATATYPES.PERCENTAGE) {
    //   updatedValue = parseFloat(value);
    // }
    this.setFormFieldValue(name, updatedValue, memberName);
  };

  handleDateChange = (value, field, index) => {
    const { name } = field;
    const selectedDate = this.getSelectedDate(value);
    this.setFormFieldValue(name, selectedDate, index);
  };

  handleSelectChange = (event, field, index) => {
    const { onSelectChangeCallback } = this.props;
    const { value } = event.target;
    const { name } = field;
    this.setFormFieldValue(name, value, index);
    if (
      onSelectChangeCallback &&
      typeof onSelectChangeCallback === 'function'
    ) {
      onSelectChangeCallback(name, value);
    }
  };

  getSelectedMemberFieldValues = (memberName) => {
    const { dependentFields, componentName } = this.props;
    const { newMembers, selectedMember } = this.state;
    const fieldValuesObj = newMembers.find(
      (member, memindex) => member.name === memberName
    );
    if (fieldValuesObj) {
      const { fieldValues } = fieldValuesObj;
      // if (MENU_ITEM_WITH_MULTIPLE_KIDS.indexOf(componentName) === -1) {
      //   const newFieldValues = { ...fieldValues };
      //   return newFieldValues;
      // }
      return fieldValues;
    }
    return {};
  };

  setFormFieldValue(fieldkey, value, memberName) {
    const { fieldsConfig } = this.props;
    const { commonFields: commonFieldConfig, fieldConstants } = fieldsConfig;

    const { commonFields } = this.state;
    if (commonFieldConfig.indexOf(fieldkey) !== -1) {
      this.setState({
        commonFields: { ...commonFields, [fieldkey]: value },
      });
    } else {
      const fieldValues = this.getSelectedMemberFieldValues(memberName);
      let newFielValues;
      newFielValues = { ...fieldValues, [fieldkey]: value };
      const { newMembers } = this.state;
      const selectedMemberState = newMembers.find(
        (member, memIndex) => member.name === memberName
      );
      const updatedMemberState = {
        ...selectedMemberState,
        fieldValues: { ...newFielValues },
      };
      this.setState({
        newMembers: newMembers.map((member, memIndex) => {
          if (member.name === memberName) {
            return {
              ...member,
              ...updatedMemberState,
            };
          }
          return member;
        }),
      });
    }
  }

  renderPlannerInputFields = (name) => {
    const { fieldsConfig, componentName } = this.props;
    const { fieldConstants, commonFields } = fieldsConfig;
    const { fields, label } = fieldConstants.find(
      (member) => member.label === name
    );
    let allFields = [...fields];
    const fieldValues = this.getSelectedMemberFormFields(name, label);
    let allFieldConst = [];
    if (commonFields.length > 0)
      allFieldConst = [...commonFields, ...fields.map((field) => field.name)];
    else if (TABS_WITH_DIFF_BORDER.indexOf(componentName) === -1) {
      const allMemberFields = fieldConstants.reduce((acc, curr) => {
        return acc.concat(curr.fields.map((field) => field.name));
      }, []);
      allFieldConst = [...new Set(allMemberFields)];
    }
    const emptyFieldsLength = allFieldConst.length - allFields.length;
    if (componentName === ASSETGOALS_COMPONENT && name === 'home') {
      allFields.splice(4, 0, {});
    }
    if (componentName === ASSETGOALS_COMPONENT && name === 'vehicles') {
      allFields.splice(3, 0, {});
    }
    // if (componentName === DEBT_COMPONENT && name === 'credit cards') {
    //   allFields.splice(3, 0, {});
    // }
    if (componentName === DEBT_COMPONENT && name === 'student loans') {
      allFields.splice(3, 0, {});
    }
    if (componentName === DEBT_COMPONENT && name === 'other loans') {
      allFields.splice(3, 0, {});
    }
    return (
      <>
        {allFields.map((colField, index) => (
          <Grid
            item
            xs={1}
            className="planner-input-grid"
            // spacing={0}
            key={`col-${index + 1}`}
          >
            {Object.keys(colField).length > 0 && (
              <PlannerInputField
                isLabelrequired={false}
                {...colField}
                onInputChange={(value) =>
                  this.handleInputChange(value, colField, name)
                }
                onSelectChange={(event) =>
                  this.handleSelectChange(event, colField, name)
                }
                onDateChange={(dateValue) =>
                  this.handleDateChange(dateValue, colField, name)
                }
                {...fieldValues}
              />
            )}
          </Grid>
        ))}
        {TABS_WITH_DIFF_BORDER.indexOf(componentName) === -1 && (
          <>
            {[...Array(emptyFieldsLength)].map(() => (
              <Grid item xs={1} className="empty-grid" />
            ))}
          </>
        )}
        {componentName === ASSETGOALS_COMPONENT && name === 'vehicles' && (
          <>
            {[...Array(2)].map(() => (
              <Grid item xs={1} className="planner-input-grid" />
            ))}
          </>
        )}
        {componentName === EXPENSE_COMPONENT && name === 'housing(if rented)' && (
          <>
            {[...Array(3)].map(() => (
              <Grid item xs={1} className="empty-grid" />
            ))}
          </>
        )}
        {componentName === OTHERGOALS_COMPONENT && name === 'vacation' && (
          <>
            {[...Array(2)].map(() => (
              <Grid item xs={1} className="empty-grid" />
            ))}
          </>
        )}
        {componentName === DEBT_COMPONENT && name === 'credit cards' && (
          <>
            {[...Array(3)].map(() => (
              <Grid item xs={1} className="empty-grid" />
            ))}
          </>
        )}
      </>
    );
  };

  getCommonFieldValue = () => {
    const { fieldsConfig, fieldsConstants } = this.props;
    const { commonFields } = this.state;
    const { commonFields: commonFieldConfig, fieldConstants } = fieldsConfig;
    const commonFieldsConfig = fieldsConstants[0].fields.filter((field) =>
      commonFieldConfig.includes(field.name)
    );
    return (
      <>
        {commonFieldsConfig.length > 0 && (
          <Grid
            container
            className="member-list-container common-list-container"
          >
            <Grid item xs={1} className="common-title">
              Common
            </Grid>
            {fieldConstants[0].fields.map((member) => (
              <Grid item xs={1} className="empty-grid" />
            ))}

            {commonFieldsConfig.map((field, index) => (
              <Grid item xs={1} className="planner-input-grid">
                <PlannerInputField
                  isLabelrequired={false}
                  {...field}
                  onInputChange={(value) =>
                    this.handleInputChange(value, field)
                  }
                  onSelectChange={(event) =>
                    this.handleSelectChange(event, field)
                  }
                  onDateChange={(dateValue) =>
                    this.handleDateChange(dateValue, field)
                  }
                  {...commonFields}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </>
    );
  };

  render() {
    const { fieldsConfig, fieldsConstants, componentName } = this.props;
    const { newMembers } = this.state;
    const { fieldConstants: fieldConstantsFromConfig, commonFields } =
      fieldsConfig;
    const memberFields = fieldConstantsFromConfig.reduce((acc, curr) => {
      return acc.concat(curr.fields);
    }, []);
    let labels = [...new Set(memberFields.map((item) => item.label))];
    if (componentName === ASSETGOALS_COMPONENT) {
      const lastItem = labels.pop();
      labels.splice(4, 0, lastItem);
    }
    const commonFieldsObj = fieldsConstants[0].fields.filter((field) =>
      commonFields.includes(field.name)
    );
    return (
      <>
        <div className="form-container">
          <Grid container className="fields-list-container">
            <Grid item xs={1} />
            {labels.map((label) => (
              <Grid item xs={1} className="fields-list-item">
                {label}
              </Grid>
            ))}
            {commonFieldsObj.map((field) => (
              <Grid item xs={1} className="fields-list-item">
                {field.label}
              </Grid>
            ))}
          </Grid>
          {newMembers.map((member, index) => (
            <Grid container className="member-list-container">
              <Grid item xs={1} className="member-name">
                {member.label}
              </Grid>
              {this.renderPlannerInputFields(member.name)}
            </Grid>
          ))}
          {this.getCommonFieldValue()}
        </div>
      </>
    );
  }
}

export default PlannerTabsContent;
