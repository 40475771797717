import {
  SET_INITAL_REPORT_OPTIONS,
  SET_SELECTED_REPORT_OPTIONS_ACTION,
  GET_REPORT_DATA_BY_REPORTID_SUCCESS,
  SET_REPORT_DATA_LOADING_ACTION,
} from '../actions/reportsAction';
import { reportInitialData } from '../../constants/reports';

const initialState = {
  reportData: {},
  isReportDataLoading: false,
};

export default function Plans(state = initialState, action) {
  switch (action.type) {
    case SET_INITAL_REPORT_OPTIONS: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case SET_SELECTED_REPORT_OPTIONS_ACTION: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case GET_REPORT_DATA_BY_REPORTID_SUCCESS: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case SET_REPORT_DATA_LOADING_ACTION: {
      return {
        ...state,
        isReportDataLoading: action.payload,
      };
    }
    default:
      return state;
  }
}
