import {
  SET_SIMULATE_BEFORE_AFTER_VALUES,
  RESET_SIMULATE_BEFORE_AFTER_VALUES,
  TILE_BEFORE_AFTER_DATA,
} from '../actions/simulateBeforeAfterAction';

const listData = [
  {
    id: 1,
    itemid: 'List Item',
    beforeId: '',
    afterId: '',
    chronoItem: '',
    chronoSubItem: '',
    itemChosen: false,
  },
  {
    id: 2,
    itemid: 'List Item',
    beforeId: '',
    afterId: '',
    chronoItem: '',
    chronoSubItem: '',
    itemChosen: false,
  },
  {
    id: 3,
    itemid: 'List Item',
    beforeId: '',
    afterId: '',
    chronoItem: '',
    chronoSubItem: '',
    itemChosen: false,
  },
  {
    id: 4,
    itemid: 'List Item',
    beforeId: '',
    afterId: '',
    chronoItem: '',
    chronoSubItem: '',
    itemChosen: false,
  },
  {
    id: 5,
    itemid: 'Total Premium',
    beforeId: '',
    afterId: '',
    chronoItem: 'Insurance',
    chronoSubItem: 'HORIZON (10Y)',
    itemChosen: true,
  },
  {
    id: 6,
    itemid: 'List Menu',
    beforeId: '',
    afterId: '',
    chronoItem: '',
    chronoSubItem: '',
    itemChosen: false,
  },
];

const initialState = {
  simulateBeforeAfterList: [],
  tileBeforeAfterData: [],
};

export default function Plans(state = initialState, action) {
  switch (action.type) {
    case SET_SIMULATE_BEFORE_AFTER_VALUES: {
      return {
        ...state,
        simulateBeforeAfterList: action.payload,
      };
    }
    case RESET_SIMULATE_BEFORE_AFTER_VALUES: {
      return {
        ...state,
        simulateBeforeAfterList: [],
        tileBeforeAfterData: [],
      };
    }
    case TILE_BEFORE_AFTER_DATA: {
      const { tileBeforeAfterData } = state;
      const { tileName, tileItReq } = action.payload;
      const isNewTile =
        tileBeforeAfterData.map((tile) => tile.tileName).indexOf(tileName) ===
        -1;
      if (!isNewTile) {
        return {
          ...state,
          tileBeforeAfterData: tileBeforeAfterData.map((tile) => {
            if (tile.tileName === tileName) {
              return {
                ...tile,
                beforeAfter: [...tileItReq.beforeAfter],
              };
            }
            return {
              ...tile,
            };
          }),
        };
      }
      const newTileBeforeAfterData = tileBeforeAfterData.map((obj) => ({
        ...obj.tileBeforeAfterData,
      }));
      newTileBeforeAfterData.push(tileItReq);
      return {
        ...state,
        tileBeforeAfterData: [
          ...tileBeforeAfterData,
          ...newTileBeforeAfterData,
        ],
      };
    }
    default:
      return state;
  }
}
