export const getNewClientMailBody = (mailBodyObj) => {
  const { clientName, emailId, advisorName, siteDomain } = mailBodyObj;
  const passwordUrl = ` <a href=${siteDomain}/forgotPassword>Reset password</a> `;
  return (
    'Hi' +
    '\t' +
    clientName +
    ',' +
    '<br><br>' +
    'Your advisor' +
    '\t' +
    advisorName +
    '\t' +
    "created account for you at Green'r" +
    '<br><br>' +
    'Please reset password:' +
    passwordUrl +
    ' to continue<br><br>' +
    '<br>' +
    'Email:' +
    '\t' +
    emailId +
    '<br>' +
    '<br><br>' +
    'Thank You,' +
    '<br>' +
    "Green'R"
  );
};

export const getNewAdvisorMailBody = (mailBodyObj) => {
  const { clientName, emailId, companyName, siteDomain } = mailBodyObj;
  const passwordUrl = ` <a href=${siteDomain}/forgotPassword>Reset password</a> `;
  return (
    'Hi' +
    '\t' +
    clientName +
    ',' +
    '<br><br>' +
    'Your advisor' +
    '\t' +
    "account has been created for you at Green'r" +
    '\t' +
    'by' +
    '\t' +
    companyName +
    '<br><br>' +
    'Please reset password:' +
    passwordUrl +
    ' to continue<br><br>' +
    '<br>' +
    'Email:' +
    '\t' +
    emailId +
    '<br>' +
    '<br><br>' +
    'Thank You,' +
    '<br>' +
    "Green'R"
  );
};
