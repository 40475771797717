import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import Controls from '../components/controls/Controls';
import { PropertyNamesMap } from '../constants/PropertyNames';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isEqual, cloneDeep } from 'lodash';
import edit_pencil from '../images/edit_pencil.svg';
import delete_icon from '../images/delete_icon.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  validateNamesWithoutSpaces,
  validatePercentageFields,
  validateCurrencyFields,
  validateDateFields,
  checkIfYearIsValid,
  isDate1AfterDate2,
} from '../utils/validators';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { FormControlLabel, FormGroup } from '@material-ui/core';

const disabledFields = ['age', 'retirementAge', 'gender', 'name'];

export const renderInfoSection = (type, label, message, coverHalf = false) => {
  return (
    <div className="row align-items-center">
      <div className={`col-lg-${coverHalf ? 6 : 8} insured_content_outer`}>
        <p>{label}</p>
      </div>
      <div className={`col-lg-${coverHalf ? 6 : 3} insured_radio_group`}>
        <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
          <Alert severity={type}>
            <p>{message}</p>
          </Alert>
        </Stack>
      </div>
    </div>
  );
};

export const renderInputRow = (
  type,
  firstInputIdName,
  inputName,
  label,
  valueType,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  coverHalf = false,
  setFormDetailsObj,
  isFieldValid = true,
  validationMessage
) => {
  return (
    <div className="row align-items-center" key={firstInputIdName}>
      <div className={`col-lg-9 col-sm-9 col-8 insured_content_outer`}>
        <p>{label}</p>
      </div>
      <div className={`col-lg-3 col-sm-3 col-4 insured_radio_group`}>
        <input
          type={type}
          id={firstInputIdName}
          name={firstInputIdName}
          onKeyDown={(event) => {
            if (event.key === '.' && valueType === 'number') {
              event.preventDefault();
            }
          }}
          // onInput={(event) => {
          //   let pasteData = event.clipboardData.getData('text');
          //   if (pasteData) {
          //     pasteData.replace(/[^0-9]*/g, '');
          //   }
          // }}
          value={
            modelObj && modelObj[inputName] !== null ? modelObj[inputName] : ''
          }
          className={'custom-input'} // Apply the appropriate class
          onChange={(event) =>
            handleFormFieldChange(
              event,
              inputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              firstInputIdName,
              valueType,
              setFormDetailsObj
            )
          }
          onBlur={(event) =>
            handleBlur(
              event,
              inputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              valueType,
              undefined,
              firstInputIdName,
              undefined,
              undefined,
              setFormDetailsObj
            )
          }
          placeholder=""
        />
      </div>
      {!isFieldValid && <p className="error-text">{validationMessage}</p>}
    </div>
  );
};

export const handleAddMember = (
  tab,
  subTab,
  countVal,
  formDetails,
  formField,
  state,
  setLocalPlanDetailsByPlanId,
  triggerSimulatePlanDetailsByPlanId,
  triggerUpdatePlannerDetailsinRedux,
  handleEdit,
  finalExistingItems
) => {
  const { localPlanDetailsByPlanId, isEditable, isAddNew } = state;
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  // Dynamically fetch the moduleTab object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
  const subTabModule = tabModule[subTab] ? tabModule[subTab] : {};
  const tempFormField = subTab === 'invProps' ? 'props' : formField;
  let objectArray = subTabModule[tempFormField];
  // if (validateNameField(state, finalExistingItems, formField, subTab)) {
  let tempFormDetails = formDetails;
  if (isAddNew === true) {
    if (Array.isArray(objectArray)) {
      objectArray.push(tempFormDetails[formField]);
    } else {
      objectArray = tempFormDetails[formField];
    }
    if (Array.isArray(objectArray)) {
      const newCountVal = subTabModule[countVal] + 1;
      subTabModule[countVal] = newCountVal;
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [countVal]: newCountVal,
            [formField]: [...objectArray],
          },
        },
      };
    } else {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [formField]: objectArray,
          },
        },
      };
    }

    setLocalPlanDetailsByPlanId(
      tempPlanDetailsByPlanId,
      false,
      tempFormDetails,
      formField
    );

    // Trigger the simulation with the updated plan details
    // triggerSimulatePlanDetailsByPlanId({
    //   displayTermChanged: false,
    //   chrono: {},
    //   plannerFormReq: {
    //     ...localPlanDetailsByPlanId,
    //     [tab]: tabModule,
    //   },
    //   version: 'v2',
    // });
    triggerUpdatePlannerDetailsinRedux({
      plannerData: {
        ...tempPlanDetailsByPlanId,
        [tab]: tabModule,
      },
    });
  } else {
    let tempObjDetails = tempFormDetails[formField];
    let existingObjectIndex;
    if (Array.isArray(objectArray)) {
      existingObjectIndex = objectArray.indexOf(
        objectArray.filter(
          (t) =>
            (subTab === 'invProps'
              ? t.prop?.name?.toLowerCase()
              : t.name.toLowerCase()) ===
            (state.selectedObjValue !== ''
              ? state.selectedObjValue?.toLowerCase()
              : subTab === 'invProps'
                ? formDetails['prop'][formField]['name']?.toLowerCase
                : formDetails[formField]['name']?.toLowerCase)
        )[0]
      );
    } else {
      existingObjectIndex =
        objectArray.name.toLowerCase() ===
          (state.selectedObjValue !== ''
            ? state.selectedObjValue?.toLowerCase()
            : formDetails[formField]['name']?.toLowerCase())
          ? 0
          : -1;
    }
    if (existingObjectIndex > -1) {
      let existingObjectInfo;
      if (Array.isArray(objectArray)) {
        existingObjectInfo = objectArray[existingObjectIndex];
      } else {
        existingObjectInfo = objectArray;
      }
      existingObjectInfo = {
        ...existingObjectInfo,
        ...tempObjDetails,
      };
      if (Array.isArray(objectArray)) {
        objectArray[existingObjectIndex] = existingObjectInfo;
      } else {
        objectArray = existingObjectInfo;
      }
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [tempFormField]: Array.isArray(objectArray)
              ? [...objectArray]
              : objectArray,
          },
        },
      };

      setLocalPlanDetailsByPlanId(
        tempPlanDetailsByPlanId,
        false,
        tempFormDetails,
        formField
      );

      // Trigger the simulation with the updated plan details
      // triggerSimulatePlanDetailsByPlanId({
      //   displayTermChanged: false,
      //   chrono: {},
      //   plannerFormReq: {
      //     ...localPlanDetailsByPlanId,
      //     [tab]: tabModule,
      //   },
      //   version: 'v2',
      // });
      triggerUpdatePlannerDetailsinRedux({
        plannerData: {
          ...tempPlanDetailsByPlanId,
          [tab]: tabModule,
        },
      });
    }
    // UpdatePlanDetail(
    //   '',
    //   objectArray,
    //   formDetails,
    //   formField,
    //   'name',
    //   formDetails[formField]['name'],
    //   tempPlanDetailsByPlanId,
    //   tab,
    //   tabModule,
    //   subTab,
    //   setLocalPlanDetailsByPlanId,
    //   state.selectedObjValue,
    //   true
    // );
  }
  const name =
    subTab === 'invProps'
      ? formDetails[formField]['prop']?.name
      : formDetails[formField]['name'];
  handleEdit(false, false, name);
  //}
};

export const renderFooterSubmitCancelButtons = (
  state,
  moduleObject,
  props,
  finalExistingItems,
  isFormValid
) => {
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
  return (
    <>
      {state.isEditable ? (
        <div className="Simulate_button_group">
          <button
            className={
              !(state.isEditable || state.isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            onClick={() => {
              props.handleCancel(moduleObject, 'name');
            }}
            disabled={!(state.isEditable || state.isAddNew)}
          >
            Cancel
          </button>
          <button
            onClick={() =>
              handleAddMember(
                moduleObject.moduleTab,
                moduleObject.mainTab,
                moduleObject.countVal,
                state.formDetails,
                tab,
                state,
                props.setLocalPlanDetailsByPlanId,
                props.triggerSimulatePlanDetailsByPlanId,
                props.triggerUpdatePlannerDetailsinRedux,
                props.handleEdit,
                finalExistingItems
              )
            }
            className={
              !isFormValid || !(state.isEditable || state.isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            disabled={!isFormValid || !(state.isEditable || state.isAddNew)}
          >
            Update
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const renderFormInputRowName = (
  type,
  idName,
  name,
  label,
  valueType,
  state,
  moduleObject,
  props,
  finalExistingItems,
  isMandatory,
  formErrors = [],
  isAddNew = false,
  isEditable = false
) => {
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isMemberFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
  const tabData = (state.formDetails && state.formDetails[tab]) || '';
  return (
    <div className="row align-items-center">
      <div
        className={`col-lg-9 col-sm-9 col-8 insured_content_outer labelStyle 
          }`}
      >
        <p
          className={`${isMandatory && (isAddNew || isEditable) ? 'required' : ''
            }`}
        >
          {label}
        </p>
      </div>
      <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
        <input
          type={type}
          id={idName}
          name={idName}
          required={isMandatory}
          disabled={!isAddNew}
          value={
            tabData
              ? isInvPropsTab
                ? tabData['prop']?.[name]
                : tabData[name]
              : ''
          }
          className={'custom-input'} // Apply the appropriate class
          onChange={(event) =>
            handleFormInputChange(
              event,
              name,
              'input',
              moduleObject.moduleTab,
              moduleObject.mainTab,
              props.localPlanDetailsByPlanId,
              props.setLocalPlanDetailsByPlanId,
              valueType,
              state.formDetails,
              tab,
              state,
              props.setFormDetails,
              isInvPropsTab,
              undefined // for special props in invProps
            )
          }
          onBlur={(event) =>
            handleBlurWithoutUpdate(
              event,
              valueType,
              name,
              moduleObject.mainTab,
              state.formDetails,
              tab,
              props.setFormDetails,
              isInvPropsTab,
              undefined // for special props in invProps
            )
          }
          placeholder=""
        />
      </div>
      {isMemberFieldInValid && (
        <p className="error-text">{isMemberFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

export const renderFormDateField = (
  name,
  label,
  valueType,
  moduleObject,
  state,
  props,
  view,
  isMandatory = false,
  isDisablePast = false,
  formErrors = [],
  isAddNew = false,
  isEditable = false
) => {
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
  const tabData = (state.formDetails && state.formDetails[tab]) || '';
  const value = (() => {
    const val = tabData
      ? isInvPropsTab
        ? tabData['prop']?.[name]
        : tabData[name]
      : '';
    if (val) {
      if (view === 'year') {
        return parseInt(val)?.toString();
      } else {
        return val.toString();
      }
    } else {
      return '';
    }
  })();
  // const today = new Date();
  // const yesterday = new Date(today);
  // yesterday.setDate(today.getDate() - 1);
  // // Calculate the start of the current year
  // const startOfCurrentYear = new Date(today.getFullYear(), 0, 1);

  const handleDateChange = (date) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        view === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'

      // Call the form input change handler with the formatted date

      handleFormInputChange(
        formattedDate,
        name,
        'datepicker',
        moduleObject.moduleTab,
        moduleObject.mainTab,
        props.localPlanDetailsByPlanId,
        props.setLocalPlanDetailsByPlanId,
        valueType,
        state.formDetails,
        tab,
        state,
        props.setFormDetails,
        isInvPropsTab,
        undefined, // for special prop
        view
      );
    }
  };
  const isYearView = view === 'year';

  const formattedValue = (() => {
    if (isYearView) {
      // For 'year' view, parse and return just the year part
      const year = parseInt(value, 10);
      return year ? new Date(year, 0, 1) : null; // January 1 of the given year, in UTC
    } else {
      // For 'yyyy-MM-dd' view, directly parse the value
      return value ? new Date(value) : null; // Ensure the date is treated as UTC
    }
  })();

  const parsedValue =
    formattedValue && !isNaN(formattedValue.getTime()) ? formattedValue : null;

  return (
    <div className="row align-items-center">
      <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
        <p
          className={`${isMandatory && (isAddNew || isEditable) ? 'required' : ''
            }`}
        >
          {label}
        </p>
      </div>
      <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            views={[view]}
            className="planner-input long-text-input"
            format={view === 'year' ? 'yyyy' : 'yyyy-MM-dd'}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: 'start' }}
            value={parsedValue}
            // onChange={handleDateChange}
            onChange={(date) => handleDateChange(date)}
            inputProps={{ style: { height: '39px' } }}
            disabled={!state.isEditable}
            utc
          />
        </MuiPickersUtilsProvider>
      </div>
      {isFieldInValid && (
        <p className="error-text">{isFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

const getValue = (
  state,
  moduleObject,
  isInvPropsTab,
  name,
  valueType,
  isSpecialProp
) => {
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
  const tabData = (state.formDetails && state.formDetails[tab]) || '';
  const value = tabData
    ? isInvPropsTab && !isSpecialProp
      ? tabData['prop']?.[name]
      : tabData[name]
    : '';
  if (value !== undefined && value !== null && value !== '') {
    if (valueType === 'dropdown') {
      // this is only for dropdown which has YES/NO options
      return value ? 'Yes' : 'No';
    }
    return value;
  }
  return '';
};

export const renderFormInputRow = (
  type,
  idName,
  name,
  label,
  valueType,
  moduleObject,
  state,
  props,
  isSpecialProp, // In InvProps these are considered as special props as it is outside 'prop' object - ['annualRent', 'annualRentGrowth', 'priorDeprAccumulated']
  disabled = false, // for umbrella in insurance, this comes as true
  isMandatory,
  formErrors = [],
  isAddNew = false,
  isEditable = false
) => {
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;

  return (
    <div className="row align-items-center">
      <div className="col-lg-9 col-sm-9 col-8 insured_content_outer labelStyle">
        <p
          className={`${isMandatory && (isAddNew || isEditable) ? 'required' : ''
            }`}
        >
          {label}
        </p>
      </div>
      <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
        <input
          type={type}
          id={idName}
          name={idName}
          required={isMandatory}
          value={getValue(
            state,
            moduleObject,
            isInvPropsTab,
            name,
            valueType,
            isSpecialProp
          )}
          onChange={(event) =>
            handleFormInputChange(
              event,
              name,
              'input',
              moduleObject.moduleTab,
              moduleObject.mainTab,
              props.localPlanDetailsByPlanId,
              props.setLocalPlanDetailsByPlanId,
              valueType,
              state.formDetails,
              tab,
              state,
              props.setFormDetails,
              isInvPropsTab,
              isSpecialProp
            )
          }
          onBlur={(event) =>
            handleBlurWithoutUpdate(
              event,
              valueType,
              name,
              moduleObject.mainTab,
              state.formDetails,
              tab,
              props.setFormDetails,
              isInvPropsTab,
              isSpecialProp
            )
          }
          placeholder=""
          className={!state.isEditable ? 'disabled-input' : 'custom-input'} // Apply the appropriate class
          disabled={!state.isEditable || disabled}
        />
      </div>
      {isFieldInValid && (
        <p className="error-text">{isFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

export const handleEditMember = (
  e,
  editValue,
  member,
  setEditStateValues,
  state,
  handleAddMemberModalOpen
) => {
  if (member === 'basicsFirst') {
    let isEditMember = editValue;
    setEditStateValues(isEditMember, member);
  }
  if (member === 'basicsSecond') {
    let isEditMember = editValue;
    setEditStateValues(isEditMember, member);
  }
  if (handleAddMemberModalOpen) {
    handleAddMemberModalOpen(true, member);
  }
};

export const addMember = (handleAddMemberModalOpen) => {
  handleAddMemberModalOpen(true);
};

export const renderRadioGroupDual = (
  firstInputIdName,
  inputName,
  secondInputIdName,
  label,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  firstMemberFieldName,
  secondMemberFieldName,
  state,
  radioOptions
) => (
  <div
    className="row align-items-center radio_button_inline"
    key={firstInputIdName + '_' + secondInputIdName}
  >
    <div className="col-lg-6 insured_content_outer">
      <p>{label}</p>
    </div>

    <div className="col-lg-3 insured_radio_group">
      <Controls.RadioGroup
        name="retirement-first-radio-buttons"
        value={
          (modelObj &&
            modelObj[firstMemberFieldName] &&
            modelObj[firstMemberFieldName][inputName]) ||
          false
        }
        rowType={false}
        onChange={(event) =>
          handleRadioButtonChange(
            event,
            inputName,
            mainTabName,
            subTabName,
            localPlanDetailsByPlanId,
            setLocalPlanDetailsByPlanId,
            firstMemberFieldName
          )
        }
        items={radioOptions}
        disabled={!state.isEditable}
      />
    </div>

    {state.membersCount > 1 && (
      <div className="col-lg-3 insured_radio_group">
        <Controls.RadioGroup
          name="retirement-second-radio-buttons"
          value={
            (modelObj &&
              modelObj[secondMemberFieldName] &&
              modelObj[secondMemberFieldName][inputName]) ||
            false
          }
          rowType={false}
          onChange={(event) =>
            handleRadioButtonChange(
              event,
              inputName,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              secondMemberFieldName
            )
          }
          items={radioOptions}
          disabled={!state.isEditable}
        />
      </div>
    )}
  </div>
);

const handleCheckboxFieldChange = (
  e,
  name,
  type,
  valueType,
  formDetails,
  formField,
  setFormDetails,
  index,
  memberOptions,
  setMemberOptions
) => {
  let { checked } = e && e.target;
  const updatedOptions = memberOptions.map((option, i) =>
    i === index ? { ...option, isSelected: checked } : option
  );
  let insuredValue = '';
  if (updatedOptions.every((option) => option.isSelected)) {
    insuredValue = 'family';
  } else {
    const selectedOptions = updatedOptions.filter(
      (option) => option.isSelected
    );
    insuredValue = selectedOptions.length === 1 ? selectedOptions[0].id : '';
  }
  let tempFormDetails = cloneDeep(formDetails);
  tempFormDetails[formField][name] = insuredValue;
  setFormDetails(tempFormDetails);
  setMemberOptions(updatedOptions);
};

export const renderCheckboxGroup = (
  state,
  props,
  memberOptions,
  moduleObject,
  name,
  label,
  isMandatory,
  formErrors = []
) => {
  const { setFormDetails, setMemberOptions } = props;
  const { formDetails } = state;
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isMemberFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  return (
    <div className="row align-items-center">
      <div className="col-lg-8 insured_content_outer">
        <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
      </div>
      <div className="col-lg-4 insured_radio_group Choose_Risk_Appetite_member">
        <FormGroup aria-label="members" row={true}>
          {memberOptions.map((cb, i) => (
            <FormControlLabel
              key={cb.id}
              control={
                <input
                  type="checkbox"
                  name={`members[${i}]`}
                  onChange={(event) =>
                    handleCheckboxFieldChange(
                      event,
                      name,
                      'input',
                      'checkbox',
                      formDetails,
                      moduleObject.subTab,
                      setFormDetails,
                      i,
                      memberOptions,
                      setMemberOptions
                    )
                  }
                  checked={cb.isSelected}
                />
              }
              label={cb.title}
            />
          ))}
        </FormGroup>
      </div>
      {isMemberFieldInValid && (
        <p className="error-text">{isMemberFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

export const renderRadioGroup = (
  state,
  props,
  memberOptions,
  moduleObject,
  name,
  label,
  isMandatory,
  formErrors = []
) => {
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isMemberFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  return (
    <div className="row align-items-center">
      <div className="col-lg-8 insured_content_outer">
        <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
      </div>
      <div className="col-lg-4 insured_radio_group">
        <Controls.RadioGroup
          name="housing-expense-radio-buttons"
          value={
            (state.formDetails &&
              state.formDetails[moduleObject.subTab] &&
              state.formDetails[moduleObject.subTab][name]) ||
            false
          }
          rowType={true}
          onChange={(event) =>
            handleFormInputChange(
              event,
              name,
              'input',
              moduleObject.moduleTab,
              moduleObject.mainTab,
              props.localPlanDetailsByPlanId,
              props.setLocalPlanDetailsByPlanId,
              'radio',
              state.formDetails,
              moduleObject.subTab,
              state,
              props.setFormDetails
            )
          }
          items={memberOptions}
          disabled={!state.isEditable}
        />
      </div>
      {isMemberFieldInValid && (
        <p className="error-text">{isMemberFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

export const renderSelectAccountDropdown = (
  finalExistingItems,
  defaultName,
  openDeleteConfirmation,
  props,
  state,
  moduleObject,
  specificItemsPresentToInsure, // this flag is sent only from AutoClientContent as few might not have vehicles to insure
  isAddDeleteRequired = true
) => {
  const { moduleTab, mainTab, subTab } = moduleObject;
  const { localPlanDetailsByPlanId = {} } = state;
  const { [moduleTab]: parentTab = {} } = localPlanDetailsByPlanId;
  const { [mainTab]: childTab = {} } = parentTab;
  const { [subTab]: items = [] } = childTab; //Note to Bhargavi - made this change as page was crashing - here items can be an object instead of array for primaryHome
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const selectedItemInfo =
    items && items.length > 0
      ? items?.find((f) => isInvPropsTab ? f['prop']?.name === defaultName : f.name === defaultName)
      : items;
  let lockedInSolution = false;
  if (selectedItemInfo) {
    lockedInSolution = isInvPropsTab ? selectedItemInfo['prop']?.lockedInSolution : selectedItemInfo.lockedInSolution
  }
  return (
    <div className="row align-items-center">
      <div
        className="col-lg-3 col-md-4 col-6"
        style={{ display: !state.isEditable ? 'block' : 'none' }}
      >
        <Controls.Select
          className="js-example-basic-single"
          name="Account"
          id="Account"
          value={defaultName}
          onChange={(e) => {
            props.onAccountChange(
              e,
              finalExistingItems,
              moduleObject.moduleTab,
              moduleObject.mainTab,
              moduleObject.subTab
            );
          }}
          style={{ display: !state.isEditable ? 'block' : 'none' }}
          options={finalExistingItems.map((c) => {
            return {
              value: c.id,
              label: c.title,
            };
          })}
        />
      </div>
      <div
        className="col-lg-8 col-md-8 col-5 button-group"
        style={{
          display: !state.isEditable ? 'block' : 'none',
          width: 'max-content',
        }}
      >
        <>
          {finalExistingItems && finalExistingItems.length > 0 && (
            <>
              {!lockedInSolution && (
                <>
                  <button
                    className="action-button"
                    onClick={(e) => {
                      props.handleEdit(true);
                    }}
                  >
                    <img src={edit_pencil} alt="" title="" />
                  </button>
                  {isAddDeleteRequired && (
                    <button
                      className="action-button"
                      onClick={(e) => openDeleteConfirmation(defaultName)}
                    >
                      <img src={delete_icon} alt="" title="" />
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </>

        {((moduleObject.mainTab === 'primaryHome' &&
          finalExistingItems.length === 0) ||
          (moduleObject.mainTab !== 'primaryHome' &&
            !specificItemsPresentToInsure)) && (
            <>
              {isAddDeleteRequired && (
                <button
                  className="action-button"
                  onClick={(event) => {
                    props.handleEdit(true, true);
                    handleAddNewRecord(
                      state.formDetails,
                      true,
                      props.setFormDetails,
                      moduleObject,
                      state
                    );
                  }}
                >
                  <img src={pdf_page_plus_icon} alt="" title="" />
                </button>
              )}
            </>
          )}
      </div>
      {lockedInSolution && <p className="locked-text">Locked By Advisor</p>}
    </div>
  );
};

export const renderMemberLabel = (
  modelObj,
  name,
  memberOne,
  memberTwo,
  state
) => {
  return (
    <div className="row align-items-center personal_div_first_row" key={name}>
      <div className="col-lg-6 col-12">
        <p>
          <strong>Family Member</strong>
        </p>
      </div>
      {state.memberOne === false &&
        modelObj &&
        modelObj[memberOne] &&
        modelObj[memberOne][name] && (
          <>
            <div className="col-lg-3 col-6">
              <p style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  {modelObj[memberOne][name]}
                </strong>
              </p>
            </div>
          </>
        )}
      {state.membersCount > 1 &&
        state.memberTwo === false &&
        modelObj &&
        modelObj[memberTwo] &&
        modelObj[memberTwo] && (
          <>
            <div className="col-lg-3 col-6">
              <p style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  {modelObj[memberTwo][name]}
                </strong>
              </p>
            </div>
          </>
        )}
    </div>
  );
};

export const renderMemberLabelRow = (
  modelObj,
  name,
  memberOne,
  memberTwo,
  setEditStateValues,
  state,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  openDeleteConfirmation,
  handleAddMemberModalOpen
) => {
  const { lockedInSolution = false } = (modelObj && modelObj[memberOne]) || {};
  const { lockedInSolution: lockedInSolution2 = false } =
    (modelObj && modelObj[memberTwo]) || {};
  return (
    <>
      <div className="row align-items-center personal_div_first_row" key={name}>
        {state.memberOne === false &&
          modelObj &&
          modelObj[memberOne] &&
          modelObj[memberOne][name] && (
            <>
              <div className="col-lg-3 col-6 personal_div_first_content">
                <p style={{ textAlign: 'center', color: '#56A080' }}>
                  <strong style={{ fontWeight: 'bold' }}>
                    {modelObj[memberOne][name]}
                  </strong>
                  {lockedInSolution && (
                    <p className="locked-text">Locked By Advisor</p>
                  )}
                </p>

                {!lockedInSolution && (
                  <div className="button-group">
                    <button
                      className="action-limit-width"
                      onClick={(e) => {
                        handleEditMember(
                          e,
                          true,
                          memberOne,
                          setEditStateValues,
                          state,
                          handleAddMemberModalOpen
                        );
                      }}
                    >
                      <img src={edit_pencil} alt="" title="" />
                    </button>
                    {state.membersCount > 1 && (
                      <button
                        className="action-limit-width"
                        onClick={(e) =>
                          openDeleteConfirmation(
                            modelObj[memberOne][name],
                            memberOne
                          )
                        }
                      >
                        <img src={delete_icon} alt="" title="" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        {state.membersCount > 1 &&
          state.memberTwo === false &&
          modelObj &&
          modelObj[memberTwo] &&
          modelObj[memberTwo] && (
            <>
              <div className="col-lg-3 col-6 personal_div_first_content">
                <p style={{ textAlign: 'center', color: '#56A080' }}>
                  {modelObj[memberTwo][name] ? (
                    <strong style={{ fontWeight: 'bold' }}>
                      {modelObj[memberTwo][name]}
                    </strong>
                  ) : (
                    <strong style={{ fontWeight: 'bold' }}>Name?</strong>
                  )}
                  {lockedInSolution2 && (
                    <p className="locked-text">Locked By Advisor</p>
                  )}
                </p>
                {!lockedInSolution2 && (
                  <div className="button-group">
                    <button
                      className="action-limit-width"
                      onClick={(e) => {
                        handleEditMember(
                          e,
                          true,
                          memberTwo,
                          setEditStateValues,
                          state,
                          handleAddMemberModalOpen
                        );
                      }}
                    >
                      <img src={edit_pencil} alt="" title="" />
                    </button>
                    <button
                      className="action-limit-width"
                      onClick={(e) =>
                        openDeleteConfirmation(
                          modelObj[memberTwo][name],
                          memberTwo
                        )
                      }
                    >
                      <img src={delete_icon} alt="" title="" />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        {state.membersCount === 1 && (
          <div className="col-lg-3 col-6 insured_content_outer">
            <button
              className="action-member-button"
              onClick={(e) => addMember(handleAddMemberModalOpen)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={pdf_page_plus_icon} alt="" title="" />
                <p style={{ color: '#56A080', marginLeft: '5px' }}>
                  Add Member
                </p>
              </div>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export const renderMemberLabelRowWithoutEdits = (
  modelObj,
  name,
  memberOne,
  memberTwo,
  state
) => {
  const { isFirstPersonRetired, isSecondPersonRetired, membersCount } = state;
  return (
    <>
      <div className="row align-items-center personal_div_first_row" key={name}>
        <div className="col-lg-6 col-md-6">
          <p>
            <strong>Family Members</strong>
          </p>
        </div>
        {state.memberOne === false &&
          modelObj &&
          modelObj[memberOne] &&
          modelObj[memberOne][name] && (
            <div className="col-lg-3 col-md-3 col-6 personal_div_first_content">
              <p style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  {modelObj[memberOne][name]}
                </strong>
                <br />
                {isFirstPersonRetired && <p>(Retired)</p>}
              </p>
            </div>
          )}
        {membersCount > 1 &&
          state.memberTwo === false &&
          modelObj &&
          modelObj[memberTwo] &&
          modelObj[memberTwo] && (
            <div className="col-lg-3 col-md-3 col-6 personal_div_first_content">
              <p style={{ textAlign: 'center' }}>
                {modelObj[memberTwo][name] ? (
                  <>
                    <strong style={{ fontWeight: 'bold' }}>
                      {modelObj[memberTwo][name]}
                    </strong>
                    <br />
                    {isSecondPersonRetired && <p>(Retired)</p>}
                  </>
                ) : (
                  <strong style={{ fontWeight: 'bold' }}>Name?</strong>
                )}
              </p>
            </div>
          )}
      </div>

      <hr style={{ marginTop: '0px' }} />
    </>
  );
};

export const renderLabelRow = (
  label,
  labelName,
  colSizeFirstLast = 4,
  colSizeCenter = 4
) => {
  return (
    <div className="row align-items-center label_row" key={labelName}>
      <div className={`col-lg-${colSizeFirstLast}`}>
        <hr /> {/* Horizontal line on the left */}
      </div>
      <div className={`col-lg-${colSizeCenter}`}>
        <p style={{ textAlign: 'center', color: 'black' }}>
          <strong style={{ fontWeight: 'bold' }}>{label}</strong>
        </p>
      </div>
      <div className={`col-lg-${colSizeFirstLast}`}>
        <hr /> {/* Horizontal line on the right */}
      </div>
    </div>
  );
};

export const renderFormSelectOption = (
  type,
  idName,
  name,
  label,
  options,
  moduleObject,
  state,
  props,
  isMandatory,
  formErrors = []
) => {
  const errorsInForm = props?.formErrors?.length > 0 ? props?.formErrors : formErrors
  const isMemberFieldInValid = errorsInForm.filter(
    (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
  )[0];
  const isInvPropsTab = moduleObject.mainTab === 'invProps';
  const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
  const tabData = (state.formDetails && state.formDetails[tab]) || '';
  return (
    <div className="row align-items-center" key={idName}>
      <div className={`col-lg-9 col-sm-9 col-8 insured_content_outer`}>
        <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
      </div>
      <div className={`col-lg-3 col-sm-3 col-4 insured_radio_group`}>
        <Controls.Select
          name={idName}
          id={idName}
          value={
            tabData
              ? isInvPropsTab
                ? tabData['prop']?.[name]?.toLowerCase()
                : tabData[name]?.toLowerCase()
              : ''
          }
          onChange={(event, index) =>
            handleFormInputChange(
              event,
              name,
              'input',
              moduleObject.moduleTab,
              moduleObject.mainTab,
              props.localPlanDetailsByPlanId,
              props.setLocalPlanDetailsByPlanId,
              'dropdown',
              state.formDetails,
              tab,
              state,
              props.setFormDetails,
              isInvPropsTab,
              undefined //for special props
            )
          }
          className={!state.isEditable ? 'disabled-input' : 'custom-input'} // Apply the appropriate class
          disabled={!state.isEditable}
          options={options.map((c) => {
            return {
              value: c.value?.toLowerCase(),
              label: c.label,
            };
          })}
        />
      </div>
      {isMemberFieldInValid && (
        <p className="error-text">{isMemberFieldInValid.validationMessage}</p>
      )}
    </div>
  );
};

export const renderSelectOption = (
  type,
  idName,
  name,
  label,
  options,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  coverHalf = false,
  setFormDetailsObj
) => {
  return (
    <div className="row align-items-center" key={idName}>
      <div className={`col-lg-9 col-sm-9 col-8 insured_content_outer`}>
        <p>{label}</p>
      </div>
      <div className={`col-lg-3 col-sm-3 col-4 insured_radio_group`}>
        {renderSelectDDL(
          idName,
          name,
          modelObj,
          mainTabName,
          subTabName,
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          options,
          setFormDetailsObj
        )}
      </div>
    </div>
  );
};

export const renderMembersSelectOption = (
  type,
  idFirstName,
  name,
  label,
  options,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  firstMemberFieldName,
  secondMemberFieldName,
  idSecondName,
  state
) => {
  const { isFirstPersonRetired, isSecondPersonRetired, membersCount } = state;
  return (
    <div className="row align-items-center" key={idFirstName}>
      <div className="col-lg-6 insured_content_outer">
        <p>{label}</p>
      </div>
      {!isFirstPersonRetired ? (
        <div className="col-lg-3 col-6 insured_radio_group">
          {renderMembersSelectDDL(
            idFirstName,
            name,
            modelObj,
            mainTabName,
            subTabName,
            localPlanDetailsByPlanId,
            setLocalPlanDetailsByPlanId,
            options,
            firstMemberFieldName
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-6 insured_radio_group"></div>
      )}
      {membersCount > 1 && !isSecondPersonRetired ? (
        <div className="col-lg-3 col-6 insured_radio_group">
          {renderMembersSelectDDL(
            idSecondName,
            name,
            modelObj,
            mainTabName,
            subTabName,
            localPlanDetailsByPlanId,
            setLocalPlanDetailsByPlanId,
            options,
            secondMemberFieldName
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-6 insured_radio_group"></div>
      )}
    </div>
  );
};

export const getContributionYears = (obj, basicsTab, defCompTab) => {
  let contributionYears = 0;
  if (
    obj[basicsTab] &&
    Object.keys(obj[basicsTab]).length > 0 &&
    obj[defCompTab] &&
    Object.keys(obj[defCompTab]).length > 0
  ) {
    const { retirementAge = 0, age = 0 } = obj[basicsTab];
    const { pensionContribYrs = 0 } = obj[defCompTab];
    const ageToRetirement = retirementAge - age;
    contributionYears =
      pensionContribYrs <= ageToRetirement
        ? pensionContribYrs
        : ageToRetirement;
  }
  return contributionYears >= 0 ? contributionYears : 0;
};

// const validateField = (
//   obj, //planDetailsByPlanId
//   tab,
//   subTab,
//   valueType,
//   memberFieldType,
//   inputName = '',
//   member
// ) => {
//   let isValid = false;
//   if (
//     obj[tab] &&
//     Object.keys(obj[tab]).length > 0 &&
//     obj[tab][subTab] &&
//     Object.keys(obj[tab][subTab]).length > 0
//   ) {
//     switch (inputName.toLowerCase()) {
//       case 'pensioncontribyrs':
//         const basicsTab = member === 'first' ? 'basicsFirst' : 'basicsSecond';
//         if (obj[tab][subTab][memberFieldType] && obj[tab][subTab][basicsTab]) {
//           const { retirementAge = 0, age = 0 } =
//             obj[tab][subTab][basicsTab] || {};
//           const contributionYears = getContributionYears(
//             obj[tab][subTab],
//             basicsTab,
//             memberFieldType
//           );
//           const expectedDefaultAge = retirementAge - age + 1;
//           if (contributionYears > expectedDefaultAge) {
//             isValid = false;
//           } else {
//             isValid = true;
//           }
//         }
//         return {
//           errorMessage: !isValid ? 'Invalid number of years' : '',
//           isValid,
//         };
//       default:
//         return {
//           errorMessage: '',
//           isValid: true,
//         };
//     }
//   }
//   return {
//     isValid: false,
//     errorMessage: 'Something went wrong, please try again',
//   };
// };

const checkForDuplicates = (
  name,
  existingNames,
  isAddNew,
  isEditable,
  selectedDDNameIndex
) => {
  let isDuplicateName = false;
  if (isEditable && !isAddNew) {
    if (selectedDDNameIndex !== -1) {
      const otherNames = existingNames.filter(
        (name, i) => i !== selectedDDNameIndex
      );
      if (name)
        isDuplicateName = otherNames.some(
          (ins) => ins.title.toLowerCase() === name.toLowerCase()
        );
    }
  }
  if (isAddNew) {
    if (name)
      isDuplicateName = existingNames.some(
        (ins) => ins.title.toLowerCase() === name.toLowerCase()
      );
  }
  return isDuplicateName ? true : false;
};

export const parseCurrency = (val) => {
  if (typeof val === 'string') {
    // Remove any non-numeric characters (like "$" or ",") and convert to float
    return parseFloat(val.replace(/[^0-9.-]+/g, ''));
  }
  return parseFloat(val);
};

const validateLoanAmountField = (formDetails, name) => {
  const value = formDetails[name];
  const purchasePrice = parseCurrency(formDetails['purchasePrice'] || 0)
  const loanAmount = parseCurrency(value || 0);

  if (isNaN(loanAmount) || isNaN(purchasePrice)) {
    return false;
  }
  // Ensure loan amount is not greater than the purchase price
  return loanAmount <= purchasePrice;
}

export const validateFormDetails = (
  formDetails,
  validateFields,
  existingItems,
  isAddNew,
  isEditable,
  selectedDDNameIndex,
  subTab = ''
) => {
  let validationErrors = [];
  let isValid = true;
  let isDup = false;
  validateFields.forEach((field) => {
    let { validationMessage } = field;
    const { name, type } = field;
    switch (name) {
      case 'name':
        isValid = validateNamesWithoutSpaces(formDetails[name]);
        isDup = checkForDuplicates(
          formDetails[name],
          existingItems,
          isAddNew,
          isEditable,
          selectedDDNameIndex
        );
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage:
                formDetails[name] === ''
                  ? 'This is a mandatory field'
                  : validationMessage,
              isValid,
            });
        } else {
          if (isDup) {
            validationErrors.indexOf(
              validationErrors.filter((v) => name === v.apiFieldName)
            ) === -1 &&
              validationErrors.push({
                apiFieldName: name,
                validationMessage: 'Name already exists',
                isValid: false,
              });
          }
        }
        break;
      case 'insured':
        isValid = formDetails[name] ? true : false;
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage:
                formDetails[name] === ''
                  ? 'This is a mandatory field'
                  : validationMessage,
              isValid,
            });
        }
        break;
      case 'premiumGrowth':
        isValid = validatePercentageFields(formDetails[name]);
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage:
                formDetails[name] === ''
                  ? 'This is a mandatory field'
                  : validationMessage,
              isValid,
            });
        }
        break;
      case 'coverageYrs':
      case 'cashValueDistributionYrs':
      case 'collegeYears':
        isValid =
          formDetails[name] !== '' && formDetails[name] > 0 ? true : false;
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'collegeAge':
        isValid =
          formDetails[name] !== '' && formDetails[name] >= 18 ? true : false;
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'deprYrs':
        isValid =
          formDetails[name] !== '' && formDetails[name] >= 0 ? true : false;
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'premium':
      case 'coverage':
      case 'cashValueAnnual':
      case 'vacationExpense':
      case 'purchasePrice':
      case 'maintenanceExp':
      case 'value':
      case 'monthlyPayment':
      case 'balance':
        isValid = validateCurrencyFields(formDetails[name], name, formDetails);
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'loanAmount':
        isValid = formDetails[name] !== '' ? validateLoanAmountField(formDetails, name) : false;
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'vacationYear':
        isValid = checkIfYearIsValid(formDetails[name], type);
        if (!isValid) {
          validationErrors.indexOf(
            validationErrors.filter((v) => name === v.apiFieldName)
          ) === -1 &&
            validationErrors.push({
              apiFieldName: name,
              validationMessage: validationMessage,
              isValid,
            });
        }
        break;
      case 'paymentStartDate': {
        if (subTab === 'vehicles') {
          const { paymentStartDate, purchaseDate, interestStartDate } =
            formDetails;
          const isPaymentDtAfterPurchaseDt = isDate1AfterDate2(
            paymentStartDate,
            purchaseDate
          );
          const isPaymentDtAfterInterestDt = isDate1AfterDate2(
            paymentStartDate,
            interestStartDate
          );
          if (!isPaymentDtAfterPurchaseDt) {
            validationMessage =
              'Payment Start Date should be greater than purchase Date';
            validationErrors.indexOf(
              validationErrors.filter((v) => name === v.apiFieldName)
            ) === -1 &&
              validationErrors.push({
                apiFieldName: name,
                validationMessage: validationMessage,
                isValid: isPaymentDtAfterPurchaseDt,
              });
          } else if (!isPaymentDtAfterInterestDt) {
            validationErrors.indexOf(
              validationErrors.filter((v) => name === v.apiFieldName)
            ) === -1 &&
              validationErrors.push({
                apiFieldName: name,
                validationMessage: validationMessage,
                isValid: isPaymentDtAfterInterestDt,
              });
          }
        }
        if (subTab === 'studentLoans' || subTab === 'otherLoans') {
          const { interestStartDate, paymentStartDate } = formDetails;
          const isValid = isDate1AfterDate2(
            paymentStartDate,
            interestStartDate
          );
          if (!isValid) {
            validationErrors.indexOf(
              validationErrors.filter((v) => name === v.apiFieldName)
            ) === -1 &&
              validationErrors.push({
                apiFieldName: name,
                validationMessage: validationMessage,
                isValid,
              });
          }
        }
        break;
      }
      case 'origOrRefiLoanDate':
        {
          const { purchaseDate, origOrRefiLoanDate } = formDetails;
          isValid = isDate1AfterDate2(origOrRefiLoanDate, purchaseDate);
          if (!isValid) {
            validationErrors.indexOf(
              validationErrors.filter((v) => name === v.apiFieldName)
            ) === -1 &&
              validationErrors.push({
                apiFieldName: name,
                validationMessage: validationMessage,
                isValid,
              });
          }
        }
        break;

      default:
        break;
    }
  });
  return validationErrors;
};

export const getAndUpdatePensionContribYrs = (infoCore, membersCount) => {
  let pensionContribYrsMemberOne = 0;
  let pensionContribYrsMemberTwo = 0;
  pensionContribYrsMemberOne = getContributionYears(
    infoCore,
    'basicsFirst',
    'defCompFirst'
  );
  if (membersCount > 1) {
    pensionContribYrsMemberTwo = getContributionYears(
      infoCore,
      'basicsSecond',
      'defCompSecond'
    );
  }
  const { defCompFirst = {}, defCompSecond = {} } = infoCore;
  let tempInfoCore = {
    ...infoCore,
    defCompFirst: {
      ...defCompFirst,
      pensionContribYrs: pensionContribYrsMemberOne,
    },
    defCompSecond: {
      ...defCompSecond,
      pensionContribYrs: pensionContribYrsMemberTwo,
    },
  };
  return tempInfoCore;
};

export const validateForm = (modelObj, membersCount = 1, validateFields) => {
  const {
    defCompFirst = {},
    defCompSecond = {},
    basicsFirst = {},
    basicsSecond = {},
    retChoicesFirst = {},
    retChoicesSecond = {},
  } = modelObj;
  let validationObj = {};
  let validationObj1 = [];
  let validationObj2 = [];
  let isValid = true;
  validateFields.forEach((field) => {
    const { apiFieldName, errorMessage } = field;
    switch (apiFieldName) {
      case 'pensionContribYrs':
        const { retirementAge = 0, age = 0 } = basicsFirst;
        const { pensionContribYrs = 0 } = defCompFirst;
        const ageToRetirement = retirementAge - age;
        isValid =
          ageToRetirement <= 0 && pensionContribYrs >= ageToRetirement
            ? true
            : pensionContribYrs > ageToRetirement
              ? false
              : true;
        if (!isValid) {
          validationObj1.indexOf(
            validationObj1.filter((v) => apiFieldName === v.apiFieldName)
          ) === -1 &&
            validationObj1.push({
              apiFieldName: apiFieldName,
              errorMessage: errorMessage,
              isValid,
            });
        }
        if (membersCount > 1) {
          const { retirementAge = 0, age = 0 } = basicsSecond;
          const { pensionContribYrs = 0 } = defCompSecond;
          const ageToRetirement = retirementAge - age;
          isValid =
            ageToRetirement <= 0 && pensionContribYrs >= ageToRetirement
              ? true
              : pensionContribYrs > ageToRetirement
                ? false
                : true;
          if (!isValid) {
            validationObj2.indexOf(
              validationObj2.filter((v) => apiFieldName === v.apiFieldName)
            ) === -1 &&
              validationObj2.push({
                apiFieldName: apiFieldName,
                errorMessage: errorMessage,
                isValid,
              });
          }
        }
        validationObj = {
          validationObj1: validationObj1,
          validationObj2: validationObj2,
        };
        break;
      case 'socialSecAge':
        const { socialSecAge } = retChoicesFirst;
        isValid = socialSecAge < 62 ? false : true;
        if (!isValid) {
          validationObj1.indexOf(
            validationObj1.filter((v) => apiFieldName === v.apiFieldName)
          ) === -1 &&
            validationObj1.push({
              apiFieldName: apiFieldName,
              errorMessage: errorMessage,
              isValid,
            });
        }

        if (membersCount > 1) {
          const { socialSecAge } = retChoicesSecond;
          isValid = socialSecAge < 62 ? false : true;
          if (!isValid) {
            validationObj2.indexOf(
              validationObj2.filter((v) => apiFieldName === v.apiFieldName)
            ) === -1 &&
              validationObj2.push({
                apiFieldName: apiFieldName,
                errorMessage: errorMessage,
                isValid,
              });
          }
        }
        validationObj = {
          validationObj1: validationObj1,
          validationObj2: validationObj2,
        };
        break;
      case 'defCompWDYrs':
        const { defCompWDYrs } = retChoicesFirst;
        isValid = defCompWDYrs < 1 ? false : true;
        if (!isValid) {
          validationObj1.indexOf(
            validationObj1.filter((v) => apiFieldName === v.apiFieldName)
          ) === -1 &&
            validationObj1.push({
              apiFieldName: apiFieldName,
              errorMessage: errorMessage,
              isValid,
            });
        }
        if (membersCount > 1) {
          const { defCompWDYrs } = retChoicesSecond;
          isValid = defCompWDYrs < 1 ? false : true;
          if (!isValid) {
            validationObj2.indexOf(
              validationObj2.filter((v) => apiFieldName === v.apiFieldName)
            ) === -1 &&
              validationObj2.push({
                apiFieldName: apiFieldName,
                errorMessage: errorMessage,
                isValid,
              });
          }
        }
        validationObj = {
          validationObj1: validationObj1,
          validationObj2: validationObj2,
        };
        break;
      default:
        break;
    }
  });
  return validationObj;
};

const deriveGender = (value) => {
  return value === 'male'
    ? 'Male'
    : value === 'female'
      ? 'Female'
      : 'Unspecified';
};

export const renderMembersInputRow = (
  type,
  firstInputIdName,
  inputName, //APIfieldName
  secondInputIdName,
  label,
  valueType,
  modelObj, //infoCore obj or infoExpenses obj
  subTabName, //infoCore or infoExpenses
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName, //module family
  firstMemberFieldName, // incSavFirst or defCompFirst
  secondMemberFieldName, // incSavSecond or defCompSecond
  state,
  setFormDetailsObj,
  formErrors,
  isRetirementAgeSpecificField
) => {
  const { isFirstPersonRetired, isSecondPersonRetired, membersCount } = state;
  const { validationObj1 = [], validationObj2 = [] } = formErrors || [];
  const isMember1FieldInValid = validationObj1.filter(
    (v) =>
      v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
  )[0];
  const isMember2FieldInValid = validationObj2.filter(
    (v) =>
      v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
  )[0];
  let firstMemberValue =
    modelObj &&
      modelObj[firstMemberFieldName] &&
      modelObj[firstMemberFieldName][inputName] !== null
      ? modelObj[firstMemberFieldName][inputName]
      : '';
  firstMemberValue =
    inputName === 'gender' && mainTabName === 'moduleFamily'
      ? deriveGender(firstMemberValue)
      : firstMemberValue;
  let secondMemberValue = '';
  if (membersCount > 1) {
    secondMemberValue =
      modelObj &&
        modelObj[secondMemberFieldName] &&
        modelObj[secondMemberFieldName][inputName] !== null
        ? modelObj[secondMemberFieldName][inputName]
        : '';
    secondMemberValue =
      inputName === 'gender' && mainTabName === 'moduleFamily'
        ? deriveGender(secondMemberValue)
        : secondMemberValue;
  }
  return (
    <div
      className="row align-items-center"
      key={firstInputIdName + '_' + secondInputIdName}
    >
      <div className="col-lg-6 insured_content_outer">
        <p>{label}</p>
      </div>
      {!isRetirementAgeSpecificField || !isFirstPersonRetired ? (
        <div className="col-lg-3 col-6 insured_radio_group">
          <input
            type={type}
            onKeyDown={(event) => {
              if (event.key === '.' && valueType === 'number') {
                event.preventDefault();
              }
            }}
            id={firstInputIdName}
            name={firstInputIdName}
            disabled={disabledFields.indexOf(inputName) !== -1}
            value={firstMemberValue}
            className={'custom-input'} // Apply the appropriate class
            onChange={(event) =>
              handleFormFieldChangeForMembers(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                firstMemberFieldName,
                firstInputIdName,
                'first',
                state,
                setFormDetailsObj
              )
            }
            onBlur={(event) =>
              handleBlur(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                firstMemberFieldName,
                firstInputIdName,
                'first',
                state,
                setFormDetailsObj
              )
            }
            placeholder=""
          />
          {isMember1FieldInValid && !isMember1FieldInValid.isValid && (
            <p className="error-text">{isMember1FieldInValid.errorMessage}</p>
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-6 insured_radio_group"></div>
      )}
      {state.membersCount > 1 &&
        (!isRetirementAgeSpecificField || !isSecondPersonRetired) ? (
        <div className="col-lg-3 col-6 insured_radio_group">
          <input
            type={type}
            onKeyDown={(event) => {
              if (event.key === '.' && valueType === 'number') {
                event.preventDefault();
              }
            }}
            id={secondInputIdName}
            name={secondInputIdName}
            value={secondMemberValue}
            disabled={disabledFields.indexOf(inputName) !== -1}
            className={'custom-input'} // Apply the appropriate class
            onChange={(event) =>
              handleFormFieldChangeForMembers(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                secondMemberFieldName,
                secondInputIdName,
                'second',
                state,
                setFormDetailsObj
              )
            }
            onBlur={(event) =>
              handleBlur(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                secondMemberFieldName,
                secondInputIdName,
                'second',
                state,
                setFormDetailsObj
              )
            }
            placeholder=""
          />
          {isMember2FieldInValid && !isMember2FieldInValid.isValid && (
            <p className="error-text">{isMember2FieldInValid.errorMessage}</p>
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-6 insured_radio_group"></div>
      )}
    </div>
  );
};

export const renderCustomMembersInputRow = (
  type,
  firstInputIdName,
  inputName,
  secondInputIdName,
  label,
  valueType,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  firstMemberFieldName,
  secondMemberFieldName,
  state,
  firstPersonslifetimePension = false,
  secondPersonslifetimePension = false,
  setFormDetailsObj,
  formErrors
) => {
  const { validationObj1 = [], validationObj2 = [] } = formErrors || {};
  const isMember1FieldInValid = validationObj1.filter(
    (v) =>
      v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
  )[0];
  const isMember2FieldInValid = validationObj2.filter(
    (v) =>
      v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
  )[0];
  const firstMemberValue =
    modelObj &&
      modelObj[firstMemberFieldName] &&
      modelObj[firstMemberFieldName][inputName] !== null
      ? modelObj[firstMemberFieldName][inputName]
      : '';
  let secondMemberValue = '';
  if (state.membersCount > 1) {
    secondMemberValue =
      modelObj &&
        modelObj[secondMemberFieldName] &&
        modelObj[secondMemberFieldName][inputName] !== null
        ? modelObj[secondMemberFieldName][inputName]
        : '';
  }
  return (
    <div
      className="row align-items-center"
      key={firstInputIdName + '_' + secondInputIdName}
    >
      <div className="col-lg-6 insured_content_outer">
        <p>{label}</p>
      </div>
      {firstPersonslifetimePension ? (
        <div className="col-lg-3 col-6 insured_radio_group">
          <input
            type={type}
            id={firstInputIdName}
            name={firstInputIdName}
            disabled={disabledFields.indexOf(inputName) !== -1}
            value={firstMemberValue}
            className={'custom-input'} // Apply the appropriate class
            onKeyDown={(event) => {
              if (event.key === '.' && valueType === 'number') {
                event.preventDefault();
              }
            }}
            onChange={(event) =>
              handleFormFieldChangeForMembers(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                firstMemberFieldName,
                firstInputIdName,
                'first',
                state,
                setFormDetailsObj
              )
            }
            onBlur={(event) =>
              handleBlur(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                firstMemberFieldName,
                firstInputIdName,
                'first',
                state,
                setFormDetailsObj
              )
            }
            placeholder=""
          />
          {isMember1FieldInValid && !isMember1FieldInValid.isValid && (
            <p className="error-text">{isMember1FieldInValid.errorMessage}</p>
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-6 insured_radio_group"></div>
      )}
      {state.membersCount > 1 && secondPersonslifetimePension && (
        <div className="col-lg-3 col-6 insured_radio_group">
          <input
            type={type}
            id={secondInputIdName}
            name={secondInputIdName}
            value={secondMemberValue}
            onKeyDown={(event) => {
              if (event.key === '.' && valueType === 'number') {
                event.preventDefault();
              }
            }}
            disabled={disabledFields.indexOf(inputName) !== -1}
            className={'custom-input'} // Apply the appropriate class
            onChange={(event) =>
              handleFormFieldChangeForMembers(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                secondMemberFieldName,
                secondInputIdName,
                'second',
                state,
                setFormDetailsObj
              )
            }
            onBlur={(event) =>
              handleBlur(
                event,
                inputName,
                'input',
                modelObj,
                mainTabName,
                subTabName,
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                valueType,
                secondMemberFieldName,
                secondInputIdName,
                'second',
                state,
                setFormDetailsObj
              )
            }
            placeholder=""
          />
          {isMember2FieldInValid && !isMember2FieldInValid.isValid && (
            <p className="error-text">{isMember2FieldInValid.errorMessage}</p>
          )}
        </div>
      )}
    </div>
  );
};

export const renderDualSelectOption = (
  type,
  idName,
  name,
  label,
  options,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  idSecondName,
  secondName,
  secondLabel,
  state
) => {
  return (
    <div
      className="row align-items-center residence_taxFiling_outer_first_row"
      key={idName}
    >
      <>
        <div className="col-lg-3 col-sm-3 col-6 insured_content_outer">
          <p>{label}</p>
        </div>
        <div className="col-lg-3 col-sm-3 col-6 insured_radio_group">
          {renderSelectDDL(
            idName,
            name,
            modelObj,
            mainTabName,
            subTabName,
            localPlanDetailsByPlanId,
            setLocalPlanDetailsByPlanId,
            options
          )}
        </div>
      </>
      <div className="col-lg-3 col-sm-3 col-6 insured_content_outer">
        <p>{secondLabel}</p>
      </div>

      <div className="col-lg-3 col-sm-3 col-6 insured_radio_group">
        {renderSelectDDL(
          idSecondName,
          secondName,
          modelObj,
          mainTabName,
          subTabName,
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          options
        )}
      </div>
    </div>
  );
};

export const renderDoubleInputRow = (
  type,
  firstInputIdName,
  firstInputName,
  secondInputIdName,
  secondInputName,
  label,
  valueType,
  modelObj,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  mainTabName,
  setFormDetailsObj
) => {
  const firstMemberValue =
    modelObj &&
      modelObj[firstInputName] !== null &&
      modelObj[firstInputName] !== ''
      ? modelObj[firstInputName]
      : '';
  const secondMemberValue =
    modelObj &&
      modelObj[secondInputName] !== null &&
      modelObj[secondInputName] !== ''
      ? modelObj[secondInputName]
      : '';
  return (
    <div
      className="row align-items-center"
      key={firstInputIdName + '_' + secondInputIdName}
    >
      <div
        className="col-lg-6 insured_content_outer"
        style={{ marginBottom: 15 }}
      >
        <p>{label}</p>
      </div>

      <div className="col-lg-3 insured_radio_group">
        <input
          type={type}
          id={firstInputIdName}
          name={firstInputIdName}
          value={firstMemberValue}
          className={'custom-input'} // Apply the appropriate class
          onChange={(event) =>
            handleFormFieldChange(
              event,
              firstInputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              firstInputIdName,
              valueType,
              setFormDetailsObj
            )
          }
          onBlur={(event) =>
            handleBlur(
              event,
              firstInputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              valueType,
              undefined,
              firstInputIdName,
              undefined,
              undefined,
              setFormDetailsObj
            )
          }
          placeholder=""
          style={{
            background: 'linear-gradient(0deg, #FEFFEC, #FEFFEC)', // Specify the background style here
          }}
        />
      </div>
      <div className="col-lg-3 insured_radio_group">
        <input
          type={type}
          id={secondInputIdName}
          name={secondInputIdName}
          value={secondMemberValue}
          className={'custom-input'} // Apply the appropriate class
          onChange={(event) =>
            handleFormFieldChange(
              event,
              secondInputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              secondInputIdName,
              valueType,
              setFormDetailsObj
            )
          }
          onBlur={(event) =>
            handleBlur(
              event,
              secondInputName,
              'input',
              modelObj,
              mainTabName,
              subTabName,
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              valueType,
              undefined,
              secondInputIdName,
              undefined,
              undefined,
              setFormDetailsObj
            )
          }
          placeholder=""
          style={{
            background: 'linear-gradient(0deg, #FFE3E3, #FFE3E3)', // Specify the background style here
          }}
        />
      </div>
    </div>
  );
};

export const transformValue = (value, valueType, inputIdName) => {
  const currentlyFocusedInput = document.activeElement;
  const numFormat = Intl.NumberFormat('en-US');
  if (value !== null && value !== undefined && value !== '') {
    if (valueType === 'percentage') {
      let percentFieldValue = '';
      if (isNaN(value)) {
        return '';
      } else {
        if (value.toString().endsWith('.')) {
          // If value is a decimal point without subsequent digits, set it to 0
          if (inputIdName !== currentlyFocusedInput?.id) {
            value = value.toString() + '0';
          } else {
            percentFieldValue = parseFloat(value).toFixed(2);
            return percentFieldValue.toString() + '.';
          }
        }
        let decimalPlaces = (value.toString().split('.')[1] || '').length;
        if (decimalPlaces > 0 && !isNaN(value) && Number(value) < 1) {
          percentFieldValue = parseFloat(parseFloat(value) * 100).toFixed(
            2
            //Math.abs(decimalPlaces - 2)
          );
        } else {
          percentFieldValue = value ? parseFloat(value).toFixed(2) : 0.0;
        }
      }
      if (inputIdName === currentlyFocusedInput?.id) {
        return percentFieldValue.toString();
      }
      return `${percentFieldValue}%`;
    } else if (valueType === 'currency') {
      let numVal = value;
      if (isNaN(numVal) || (isNaN(numVal) && numVal.includes('$'))) {
        return '';
      }
      const newNumformat = numFormat.format(numVal);
      return `$${newNumformat}`;
    }
  }
  if (valueType === 'percentage') {
    return '0.0%';
  } else if (valueType === 'currency') {
    const newNumformat = numFormat.format(0);
    return `$${newNumformat}`;
  }
  return '';
};

const handleBlurWithoutUpdate = (
  event,
  valueType,
  from,
  subTab,
  formDetails,
  formField,
  setFormDetails,
  isInvPropsTab,
  isSpecialProp
) => {
  let { value } = event && event.target;
  if (valueType === 'percentage') {
    let percentFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value)) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
  }
  if (valueType === 'currency') {
    const numFormat = Intl.NumberFormat('en-US');
    let newNumformat = numFormat.format(0);
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
          newNumformat = numFormat.format(currencyFieldValue);
        }
      } else if (!isNaN(value)) {
        newNumformat = numFormat.format(value);
      }
      value = `$${newNumformat}`;
    } else {
      value = `$${0}`;
    }
  }
  if (valueType === 'number') {
    if (value !== '' && value !== null && value !== undefined) {
      if (!isNaN(value)) {
        value = Number(value);
      } else {
        value = 0;
      }
    } else {
      value = 0;
    }
  }
  let tempFormDetails = cloneDeep(formDetails);
  if (from === 'purchaseDate') {
    if (formField === 'vehicles') {
      tempFormDetails[formField]['paymentStartDate'] = value;
      tempFormDetails[formField]['interestStartDate'] = value;
    } else if (['prop', 'props'].includes(formField)) {
      tempFormDetails[formField]['origOrRefiLoanDate'] = value;
    }
    if (formField === 'invProps') {
      tempFormDetails[formField]['prop']['origOrRefiLoanDate'] = value;
    }
  }
  if (from === 'purchasePrice') {
    tempFormDetails[formField]['value'] = value;
    tempFormDetails[formField]['loanAmount'] = value;
    tempFormDetails[formField]['balance'] = value;
  }
  if (from === 'loanAmount') {
    if (parseCurrency(value) === 0) {
      tempFormDetails[formField]['termMonths'] = 0;
      tempFormDetails[formField]['balance'] = value;
    }
    else {
      tempFormDetails[formField]['balance'] = value;
    }
  }
  if (isInvPropsTab && !isSpecialProp) {
    tempFormDetails[formField]['prop'][from] = value;
  } else {
    tempFormDetails[formField][from] = value;
  }
  setFormDetails(tempFormDetails);
};

const handleBlur = (
  event,
  from,
  type,
  modelObj,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  valueType,
  memberFieldType,
  idName,
  member,
  state,
  setFormDetailsObj
) => {
  let { value } = event && event.target;
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  if (valueType === 'percentage') {
    let percentFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (
          percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          Number(percentFieldValue) >= 0
        ) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
  }
  if (valueType === 'currency') {
    const numFormat = Intl.NumberFormat('en-US');
    let newNumformat = numFormat.format(0);
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (
          currencyFieldValue !== '' &&
          !isNaN(currencyFieldValue) &&
          Number(currencyFieldValue) >= 0
        ) {
          newNumformat = numFormat.format(currencyFieldValue);
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        newNumformat = numFormat.format(value);
      }
      value = `$${newNumformat}`;
    } else {
      value = `$${newNumformat}`;
    }
  }
  if (valueType === 'number') {
    if (value !== '' && value !== null && value !== undefined) {
      if (!isNaN(value) && Number(value) >= 0) {
        value = Number(value);
      } else {
        value = 0;
      }
    } else {
      value = 0;
    }
  }
  let valueToApi = '';
  if (type === 'input') {
    if (value !== null && value !== undefined && value !== '') {
      if (isNaN(value) && value.includes('%')) {
        const percentValue = value.split('%')[0];
        if (!isNaN(percentValue)) {
          valueToApi = parseFloat(parseFloat(percentValue) / 100);
        } else {
          valueToApi = 0;
        }
      } else if (isNaN(value) && value.includes('$')) {
        let dollarValue = value.split('$')[1];
        dollarValue = dollarValue.replace(/,/g, '');
        if (!isNaN(dollarValue)) {
          valueToApi = Number(dollarValue);
        } else {
          valueToApi = 0;
        }
      } else if (!isNaN(value)) {
        valueToApi = Number(value);
      }
    } else {
      valueToApi = 0;
    }
  }
  // Dynamically fetch the moduleTab object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
  const memberModule = tabModule[subTab]
    ? tabModule[subTab][memberFieldType] || {}
    : {};

  let tempModelObj = {};
  if (memberFieldType) {
    tempModelObj = {
      ...modelObj,
      [memberFieldType]: { ...memberModule, [from]: value },
    };
  } else {
    tempModelObj = {
      ...modelObj,
      [from]: value,
    };
  }
  setFormDetailsObj(tempModelObj);
  if (memberFieldType) {
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      [tab]: {
        ...tabModule,
        [subTab]: {
          ...tabModule[subTab],
          [memberFieldType]: {
            ...memberModule,
            [from]: valueToApi,
          },
        },
      },
    };
  } else {
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      [tab]: {
        ...tabModule,
        [subTab]: {
          ...tabModule[subTab],
          [from]: valueToApi,
        },
      },
    };
  }
  let ageModified = false;
  if (from === 'age' || from === 'retirementAge') {
    ageModified = true;
  }
  setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, ageModified);
};

export const validateMemberNames = (
  fieldValue,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  otherMemberType
) => {
  const familyModule = localPlanDetailsByPlanId[tab] || {};
  const otherMemberModule = familyModule[subTab]
    ? familyModule[subTab][otherMemberType] || {}
    : {};
  const nameRegex = /^[a-zA-Z]+$/;
  if (
    fieldValue &&
    typeof fieldValue === 'string' &&
    otherMemberModule['name'] &&
    typeof otherMemberModule['name'] === 'string' &&
    fieldValue.toLowerCase() === otherMemberModule['name'].toLowerCase()
  ) {
    return 'Name already exists, please use a different name';
  } else if (
    fieldValue !== undefined &&
    fieldValue !== null &&
    typeof fieldValue === 'string' &&
    fieldValue.trim() === ''
  ) {
    return 'This is a mandatory field';
  } else if (!nameRegex.test(fieldValue)) {
    return 'Name cannot contain numbers or special characters';
  } else {
    return '';
  }
};

export const handleFormFieldChangeForMembers = (
  event,
  from,
  type,
  modelObj,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  valueType,
  memberFieldType,
  idName,
  member,
  state,
  setFormDetailsObj
) => {
  //let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  let fieldValue = event.target.value;
  if (valueType === 'percentage') {
    if (fieldValue.toString().includes('%')) {
      let percentFieldValue = fieldValue.split('%')[0];
      if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
        fieldValue = percentFieldValue;
      }
    }
  } else if (valueType === 'currency') {
    if (fieldValue.toString().includes('$')) {
      let currencyFieldValue = fieldValue.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
        fieldValue = currencyFieldValue;
      }
    }
  } else if (valueType === 'number') {
    if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
      if (isNaN(fieldValue)) {
        fieldValue = 0;
      }
    }
  }
  const selectedObj = { ...modelObj[memberFieldType] };
  let tempModelObj = {
    ...modelObj,
    [memberFieldType]: { ...selectedObj, [from]: fieldValue },
  };
  setFormDetailsObj(tempModelObj);
};

export const handleAddNewRecord = (
  formDetails,
  addNew,
  setFormDetails,
  moduleObject,
  state
) => {
  let tempFormDetails = formDetails;
  if (addNew === true) {
    const isInvPropsTab = moduleObject.mainTab === 'invProps';
    const tab = isInvPropsTab ? moduleObject.mainTab : moduleObject.subTab;
    const propertyMap = PropertyNamesMap[tab];
    let newRecord = {};
    for (const key in propertyMap) {
      let keyVal = propertyMap[key];
      if (propertyMap.hasOwnProperty(key)) {
        const { defaultValue = '' } = keyVal;
        if (isInvPropsTab && key === 'prop') {
          newRecord[key] = {};
          for (const subKey in keyVal) {
            let subKeyVal = keyVal[subKey];
            if (keyVal.hasOwnProperty(subKey)) {
              let newRecordKey = generateProps(
                subKeyVal,
                newRecord,
                subKey,
                isInvPropsTab,
                defaultValue
              );
              newRecord['prop'][subKey] = newRecordKey;
            }
          }
        } else {
          let newRecordKey = generateProps(
            keyVal,
            newRecord,
            key,
            false,
            defaultValue
          );
          newRecord[key] = newRecordKey;
        }
      }
    }
    if (moduleObject.subTab === 'umbrellaIns') {
      newRecord['insured'] = 'family';
    }
    tempFormDetails = {
      ...tempFormDetails,
      [tab]: newRecord,
    };
  }
  setFormDetails(tempFormDetails);

  function generateProps(keyVal, newRecord, key, isSubProperty, defaultValue) {
    let newRecordKey;
    if (keyVal['type'] === 'number') {
      newRecordKey = 0;
    } else if (keyVal['type'] === 'percentage') {
      newRecordKey = '0%';
    } else if (keyVal['type'] === 'currency') {
      newRecordKey = '$0';
    } else if (keyVal['type'] === 'year') {
      newRecordKey = new Date().getFullYear();
    } else if (keyVal['type'] === 'date') {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(now.getDate()).padStart(2, '0');
      newRecordKey = `${year}-${month}-${day}`;
    } else if (keyVal['type'] === 'select') {
      if (moduleObject.subTab === 'prop' && key === 'propDomicile') {
        let residenceState =
          state.localPlanDetailsByPlanId?.moduleFamily?.infoCore
            ?.residenceState;
        newRecordKey = residenceState;
      } else {
        newRecordKey =
          keyVal['option'] === 'BooleanOptions' ? false : defaultValue;
      }
    } else {
      newRecordKey = '';
    }
    return newRecordKey;
  }
};

export const handleFormInputChange = (
  event,
  from,
  type,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  valueType,
  formDetails,
  formField,
  state,
  setFormDetails,
  isInvPropsTab,
  isSpecialProp,
  format = 'year'
) => {
  let fieldValue = '';
  if (type === 'datepicker') {
    const newDate = new Date(event);
    // newDate.setUTCHours(0);
    if (format === 'year') {
      const currentDate = new Date(new Date().setFullYear(event));
      fieldValue = new Date(currentDate).getFullYear();
    } else if (format === 'date') {
      fieldValue = newDate;
    }
  } else {
    fieldValue = event.target.value;
  }
  if (valueType === 'percentage') {
    if (fieldValue.toString().includes('%')) {
      let percentFieldValue = fieldValue.split('%')[0];
      if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
        fieldValue = percentFieldValue;
      }
    }
  } else if (valueType === 'currency') {
    if (fieldValue.toString().includes('$')) {
      let currencyFieldValue = fieldValue.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
        fieldValue = currencyFieldValue;
      }
    }
  } else if (valueType === 'number') {
    if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
      if (isNaN(fieldValue)) {
        fieldValue = 0;
      }
    }
  }
  let tempFormDetails = cloneDeep(formDetails);
  if (from === 'purchaseDate') {
    if (formField === 'vehicles') {
      tempFormDetails[formField]['paymentStartDate'] = fieldValue;
      tempFormDetails[formField]['interestStartDate'] = fieldValue;
    } else if (['prop', 'props'].includes(formField)) {
      tempFormDetails[formField]['origOrRefiLoanDate'] = fieldValue;
    }
    if (formField === 'invProps') {
      tempFormDetails[formField]['prop']['origOrRefiLoanDate'] = fieldValue;
    }
  }
  if (from === 'purchasePrice') {
    tempFormDetails[formField]['value'] = fieldValue;
    tempFormDetails[formField]['loanAmount'] = fieldValue;
    tempFormDetails[formField]['balance'] = fieldValue;
  }
  if (from === 'loanAmount') {
    if (parseCurrency(fieldValue) === 0) {
      tempFormDetails[formField]['termMonths'] = 0;
      tempFormDetails[formField]['balance'] = fieldValue;
    }
    else {
      tempFormDetails[formField]['balance'] = fieldValue;
    }
  }
  if (isInvPropsTab && !isSpecialProp) {
    tempFormDetails[formField]['prop'][from] = fieldValue;
  } else {
    tempFormDetails[formField][from] = fieldValue;
  }
  setFormDetails(tempFormDetails);
};

export const handleFormInputChange1 = (
  event,
  from,
  type,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  valueType,
  formDetails,
  formField,
  state,
  setFormDetails,
  format = 'year'
) => {
  let fieldValue = '';
  if (type === 'datepicker') {
    if (format === 'year') {
      fieldValue = event.getFullYear();
    } else if (format === 'date') {
      fieldValue = event;
    }
  } else {
    fieldValue = event.target.value;
  }
  if (valueType === 'percentage') {
    if (fieldValue.toString().includes('%')) {
      let percentFieldValue = fieldValue.split('%')[0];
      if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
        fieldValue = percentFieldValue;
      }
    }
  } else if (valueType === 'currency') {
    if (fieldValue.toString().includes('$')) {
      let currencyFieldValue = fieldValue.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
        fieldValue = currencyFieldValue;
      }
    }
  } else if (valueType === 'number') {
    if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
      if (isNaN(fieldValue)) {
        fieldValue = 0;
      }
    }
  }

  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  // if (from === 'name') {
  //   setNameValidationError('');
  // }
  if (type === 'input') {
    fieldValue =
      fieldValue !== null && fieldValue !== undefined && fieldValue !== ''
        ? isNaN(fieldValue) && fieldValue.includes('%')
          ? parseFloat(parseFloat(fieldValue.split('%')[0]) / 100)
          : fieldValue.includes('$') &&
            !isNaN(fieldValue.split('$')[1].replace(/,/g, ''))
            ? fieldValue.split('$')[1].replace(/,/g, '')
            : !isNaN(fieldValue)
              ? fieldValue
              : ''
        : fieldValue;
  }

  // Dynamically fetch the moduleTab object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
  const subTabModule = tabModule[subTab] ? tabModule[subTab] : {};
  const objectArray = subTabModule[formField];

  if (state.isAddNew !== true && from !== 'name') {
    tempPlanDetailsByPlanId = UpdatePlanDetail(
      setFormDetails,
      objectArray,
      formDetails,
      formField,
      from,
      fieldValue,
      tempPlanDetailsByPlanId,
      tab,
      tabModule,
      subTab,
      setLocalPlanDetailsByPlanId,
      '',
      false,
      valueType
    );
  } else {
    let tempFormDetails = cloneDeep(formDetails);
    const isInvPropsTab =
      subTab === 'invProps' &&
      !['annualRent', 'annualRentGrowth', 'priorDeprAccumulated'].includes(
        from
      );
    const propertyMap =
      subTab === 'invProps'
        ? PropertyNamesMap[subTab]
        : PropertyNamesMap[formField];
    let formattedNumber;
    let fromPropTab = isInvPropsTab
      ? propertyMap['prop'][from]
      : propertyMap[from];

    if (fromPropTab.type === 'percentage') {
      formattedNumber =
        fieldValue !== ''
          ? fieldValue.toString().endsWith('.')
            ? fieldValue
            : Number(fieldValue).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 20,
            })
          : 0;
    } else if (
      fromPropTab.type === 'number' ||
      fromPropTab.type === 'currency'
    ) {
      formattedNumber = parseFloat(fieldValue);
      formattedNumber = isNaN(formattedNumber)
        ? ''
        : isNaN(formattedNumber)
          ? 0
          : formattedNumber;
    } else {
      // 'text' type
      formattedNumber = fieldValue;
    }

    if (isInvPropsTab) {
      tempFormDetails[formField]['prop'][from] = formattedNumber;
    } else {
      tempFormDetails[formField][from] = formattedNumber;
    }
    const cascadedToArray = fromPropTab['cascadedTo'];
    if (Array.isArray(cascadedToArray) && cascadedToArray.length > 0) {
      cascadedToArray.forEach((cascadedValue, index) => {
        if (isInvPropsTab === true) {
          tempFormDetails[formField]['prop'][cascadedValue] = formattedNumber;
        } else {
          tempFormDetails[formField][cascadedValue] = formattedNumber;
        }
      });
    }
    setFormDetails(tempFormDetails);
  }
};

export const handleRadioButtonChange = (
  event,
  from,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  formField
) => {
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  let fieldValue =
    event.target.checked && event.target.defaultValue === 'true' ? true : false;
  // Dynamically fetch the moduleTab object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
  const subTabModule = tabModule[subTab] ? tabModule[subTab] : {};
  const fieldObj = subTabModule[formField] ? subTabModule[formField] : {};
  tempPlanDetailsByPlanId = {
    ...tempPlanDetailsByPlanId,
    [tab]: {
      ...tabModule,
      [subTab]: {
        ...subTabModule,
        [formField]: {
          ...fieldObj,
          [from]: fieldValue,
        },
      },
    },
  };
  setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId);
};

export const handleFormFieldChange = (
  event,
  from,
  type,
  modelObj,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  idName,
  valueType,
  setFormDetailsObj
) => {
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  let fieldValue = event.target.value;
  let currentlyFocusedInputId = document.activeElement?.id;
  if (valueType === 'percentage') {
    if (fieldValue.toString().includes('%')) {
      let percentFieldValue = fieldValue.split('%')[0];
      if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
        fieldValue = percentFieldValue;
      }
    }
  } else if (valueType === 'currency') {
    if (fieldValue.toString().includes('$')) {
      let currencyFieldValue = fieldValue.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
        fieldValue = currencyFieldValue;
      }
    }
  } else if (valueType === 'number') {
    if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
      if (isNaN(fieldValue)) {
        fieldValue = 0;
      }
    }
  }
  let tempModelObj = {
    ...modelObj,
    [from]: fieldValue,
  };
  setFormDetailsObj(tempModelObj);
};

export const handleSelectChange = (
  event,
  from,
  modelObj,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  setFormDetailsObj
) => {
  const { value } = event.target;
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  // Dynamically fetch the moduleSettings object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {};
  tempPlanDetailsByPlanId = {
    ...tempPlanDetailsByPlanId,
    [tab]: {
      ...tabModule,
      [subTab]: {
        ...tabModule[subTab],
        [from]: typeof value === 'number' ? parseFloat(value) : value,
      },
    },
  };
  if (setFormDetailsObj) {
    let tempModelObj = {
      ...modelObj,
      [from]: typeof value === 'number' ? parseFloat(value) : value,
    };
    setFormDetailsObj(tempModelObj);
  }
  setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId);
};

export const handleMemberSelectChange = (
  event,
  from,
  modelObj,
  tab,
  subTab,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  memberFieldName
) => {
  const { value } = event.target;
  let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
  // Dynamically fetch the moduleSettings object based on tab
  const tabModule = tempPlanDetailsByPlanId[tab] || {};
  const memberModule = tabModule[subTab]
    ? tabModule[subTab][memberFieldName] || {}
    : {};

  tempPlanDetailsByPlanId = {
    ...tempPlanDetailsByPlanId,
    [tab]: {
      ...tabModule,
      [subTab]: {
        ...tabModule[subTab],
        [memberFieldName]: {
          ...memberModule,
          [from]: isNaN(value) ? value : parseFloat(value),
        },
      },
    },
  };
  setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId);
};

export const validateNameForMembers = (
  tempPlanDetailsByPlanId,
  memberName,
  setNameValidationError,
  editValue
) => {
  const tabModule = tempPlanDetailsByPlanId['moduleFamily'] || {}; // Tab will be module Family
  const memberModule = tabModule['infoCore']
    ? tabModule['infoCore'][memberName] || {}
    : {};
  if (editValue === false) {
    if (!memberModule || memberModule['name'] === '') {
      setNameValidationError('Please fill the mandatory fields');
      return false;
    }
    let otherMemberModule = {};
    if (memberName === 'basicsFirst') {
      otherMemberModule = tabModule['infoCore']
        ? tabModule['infoCore']['basicsSecond'] || {}
        : {};
    } else {
      otherMemberModule = tabModule['infoCore']
        ? tabModule['infoCore']['basicsFirst'] || {}
        : {};
    }
    if (otherMemberModule && otherMemberModule['name']) {
      if (memberModule['name'] === otherMemberModule['name']) {
        setNameValidationError(
          'Name already exists, please use a different name'
        );
        return false;
      }
    }
  }
  return true;
};

// Validation function for the Name Your Policy field
export const validateNameField = (
  state,
  finalExistingItems,
  formField,
  subTab
) => {
  const isInvPropsTab = subTab === 'invProps';
  if (
    !state.formDetails[formField] ||
    (isInvPropsTab
      ? !state.formDetails[formField]['prop']?.name
      : !state.formDetails[formField].name)
  ) {
    // setNameValidationError('Please fill the mandatory fields');
    return false;
  }
  const enteredName = isInvPropsTab
    ? state.formDetails[formField]['prop']?.name.toLowerCase()
    : state.formDetails[formField].name.toLowerCase();

  if (state.isAddNew) {
    // Check for duplicate name only when adding a new item
    const isDuplicateName = finalExistingItems.some(
      (ins) => ins.title.toLowerCase() === enteredName
    );

    if (isDuplicateName) {
      // setNameValidationError(
      //   'Name already exists, please use a different name'
      // );
      return false;
    }
  }

  if (state.isEditable && !state.isAddNew) {
    // Check for duplicate name only when editing a new item
    let finalExistingVals = finalExistingItems.filter(
      (item) =>
        item.title &&
        state.selectedObjValue &&
        item.title.toLowerCase() !== state.selectedObjValue.toLowerCase()
    );
    const isDuplicateName = finalExistingVals.some(
      (ins) => ins.title.toLowerCase() === enteredName
    );

    if (isDuplicateName) {
      // setNameValidationError(
      //   'Name already exists, please use a different name'
      // );
      return false;
    }
  }

  // setNameValidationError('');
  return true;
};

function UpdatePlanDetail(
  setFormDetails,
  objectArray,
  formDetails,
  formField,
  from,
  fieldValue,
  tempPlanDetailsByPlanId,
  tab,
  tabModule,
  subTab,
  setLocalPlanDetailsByPlanId,
  selectedObjValue = '',
  updateName = false,
  valueType = ''
) {
  const isInvPropsTab =
    subTab === 'invProps' &&
    !['annualRent', 'annualRentGrowth', 'priorDeprAccumulated'].includes(from);
  let tempFormDetails = cloneDeep(formDetails);

  const propertyMap =
    subTab === 'invProps'
      ? PropertyNamesMap[subTab]
      : PropertyNamesMap[formField];

  let formattedNumber;

  let fromPropTab = isInvPropsTab
    ? propertyMap['prop'][from]
    : propertyMap[from];

  if (fromPropTab.type === 'percentage') {
    formattedNumber =
      fieldValue !== ''
        ? fieldValue.toString().endsWith('.')
          ? fieldValue
          : Number(fieldValue).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 20,
          })
        : 0;
  } else if (fromPropTab.type === 'number' || fromPropTab.type === 'currency') {
    formattedNumber = parseFloat(fieldValue);
    formattedNumber = isNaN(formattedNumber)
      ? ''
      : isNaN(formattedNumber)
        ? 0
        : formattedNumber;
  } else {
    // 'text' type
    formattedNumber = fieldValue;
  }

  isInvPropsTab === true
    ? (tempFormDetails[formField]['prop'][from] = formattedNumber)
    : (tempFormDetails[formField][from] = formattedNumber);
  const cascadedToArray = fromPropTab['cascadedTo'];
  if (Array.isArray(cascadedToArray) && cascadedToArray.length > 0) {
    cascadedToArray.forEach((cascadedValue, index) => {
      if (isInvPropsTab === true) {
        tempFormDetails[formField]['prop'][cascadedValue] = formattedNumber;
      } else {
        tempFormDetails[formField][cascadedValue] = formattedNumber;
      }
    });
  }
  if (
    setFormDetails !== undefined &&
    setFormDetails != null &&
    setFormDetails !== ''
  ) {
    setFormDetails(tempFormDetails);
  } else {
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      [tab]: {
        ...tabModule,
        [subTab]: {
          ...tabModule[subTab],
          [formField]: Array.isArray(objectArray)
            ? [...objectArray]
            : objectArray,
        },
      },
    };
    setLocalPlanDetailsByPlanId(
      tempPlanDetailsByPlanId,
      false,
      tempFormDetails,
      formField
    );
  }
  return tempPlanDetailsByPlanId;
}

function renderSelectDDL(
  idName,
  name,
  modelObj,
  mainTabName,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  options,
  setFormDetailsObj
) {
  return (
    <Controls.Select
      name={idName}
      value={modelObj[name]}
      options={options.map((c) => {
        return {
          value: c.value,
          label: c.label,
        };
      })}
      onChange={(event, index) =>
        handleSelectChange(
          event,
          name,
          modelObj,
          mainTabName,
          subTabName,
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          setFormDetailsObj
        )
      }
    />
  );
}

function renderMembersSelectDDL(
  idName,
  name,
  modelObj,
  mainTabName,
  subTabName,
  localPlanDetailsByPlanId,
  setLocalPlanDetailsByPlanId,
  options,
  memberFieldName
) {
  return (
    <Controls.Select
      name={idName}
      onChange={(event, index) =>
        handleMemberSelectChange(
          event,
          name,
          modelObj,
          mainTabName,
          subTabName,
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          memberFieldName
        )
      }
      disabled={disabledFields.indexOf(name) !== -1}
      value={
        modelObj[memberFieldName]
          ? modelObj[memberFieldName][name]?.toUpperCase()
          : ''
      } // Add null check
      options={options.map((c) => {
        return {
          value: c.value?.toUpperCase(),
          label: c.key,
        };
      })}
    />
  );
}

export const DeleteConfirmation = (
  props,
  isDeleteConfirmationOpen,
  closeDeleteConfirmation,
  handleDeleteConfirmation,
  nameToDelete,
  moduleObject
) => {
  // Function to handle item deletion after confirmation
  const handleDeleteConfirm = () => {
    // Call the handleDeleteConfirmation function from the hook
    handleDeleteConfirmation(props.handleDelete, moduleObject);
  };

  return (
    <Dialog
      open={isDeleteConfirmationOpen}
      onClose={closeDeleteConfirmation}
      className="delete_popup_outer"
    >
      <DialogTitle>
        Are you sure you want to delete "{nameToDelete}"?
      </DialogTitle>
      <DialogActions>
        <button onClick={closeDeleteConfirmation} className="common-button">
          Cancel
        </button>
        <button onClick={handleDeleteConfirm} className="common-button">
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
};

const booleanConversionToUI = (val) => {
  return val ? 'Yes' : 'No';
};

const booleanConversionToAPI = (val) => {
  return val === 'Yes' || val === true ? true : false;
};

const percentageConversionToUI = (val) => {
  let fieldValue = val;
  if (val !== null && val !== undefined && val !== '') {
    if (val.toString().includes('%')) {
      const percentFieldValue = val.split('%')[0];
      fieldValue =
        percentFieldValue !== '' && !isNaN(percentFieldValue)
          ? parseFloat(percentFieldValue) / 100
          : 0;
    }
    fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
    return `${fieldValue}%`;
  }
  return `0.00%`;
};

const percentageConversionToAPI = (value) => {
  let valueToApi = '';
  if (value !== null && value !== undefined && value !== '') {
    if (isNaN(value) && value.includes('%')) {
      const percentValue = value.split('%')[0];
      if (!isNaN(percentValue)) {
        valueToApi = parseFloat(parseFloat(percentValue) / 100);
      } else {
        valueToApi = 0;
      }
    } else if (!isNaN(value)) {
      valueToApi = Number(value);
    }
  } else {
    valueToApi = 0;
  }
  return valueToApi;
};

const getDefCompObj = (defComp, isUI) => {
  const {
    percentDefferedComp,
    percentPensionGrant,
    percentPensionSelfContrib,
  } = defComp;
  let tempDefComp = {
    ...defComp,
    percentDefferedComp: isUI
      ? percentageConversionToUI(percentDefferedComp)
      : percentageConversionToAPI(percentDefferedComp),
    percentPensionGrant: isUI
      ? percentageConversionToUI(percentPensionGrant)
      : percentageConversionToAPI(percentPensionGrant),
    percentPensionSelfContrib: isUI
      ? percentageConversionToUI(percentPensionSelfContrib)
      : percentageConversionToAPI(percentPensionSelfContrib),
  };
  return tempDefComp;
};

const getEqCompObj = (eqComp, isUI) => {
  const { percentRsuGrant } = eqComp;
  let tempEqComp = {
    ...eqComp,
    percentRsuGrant: isUI
      ? percentageConversionToUI(percentRsuGrant)
      : percentageConversionToAPI(percentRsuGrant),
  };
  return tempEqComp;
};

const getRetChoicesObj = (retChoices, isUI) => {
  const {
    lifetimePensionGrowth,
    lifetimePensionAmount,
    socialSecAmount,
    socialSecAge,
    pensionWDYrs,
    defCompWDYrs,
  } = retChoices;
  let tempRetChoices = {
    ...retChoices,
    lifetimePensionGrowth: isUI
      ? percentageConversionToUI(lifetimePensionGrowth)
      : percentageConversionToAPI(lifetimePensionGrowth),
    lifetimePensionAmount: isUI
      ? currencyFormatToUI(lifetimePensionAmount)
      : currencyFormatToAPI(lifetimePensionAmount),
    socialSecAmount: isUI
      ? currencyFormatToUI(socialSecAmount)
      : currencyFormatToAPI(socialSecAmount),
  };
  return tempRetChoices;
};

const currencyFormatToUI = (value) => {
  let fieldValue = value;
  const numFormat = Intl.NumberFormat('en-US');
  let newNumformat = numFormat.format(0);
  let currencyFieldValue = '';
  if (value !== null && value !== undefined && value !== '') {
    if (value.toString().includes('$')) {
      currencyFieldValue = value.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
        newNumformat = numFormat.format(currencyFieldValue);
      }
    } else if (!isNaN(value)) {
      newNumformat = numFormat.format(value);
    }
    fieldValue = `$${newNumformat}`;
  } else {
    fieldValue = `${0}`;
  }
  return fieldValue;
};

const currencyFormatToAPI = (value = 0) => {
  let fieldValue = value;
  if (isNaN(value) && value.includes('$')) {
    let dollarValue = value.split('$')[1];
    dollarValue = dollarValue.replace(/,/g, '');
    if (!isNaN(dollarValue)) {
      fieldValue = Number(dollarValue);
    } else {
      fieldValue = 0;
    }
  } else if (!isNaN(value)) {
    fieldValue = Number(value);
  } else {
    fieldValue = 0;
  }
  return fieldValue || 0;
};

const getIncSavObj = (incSav, isUI) => {
  const {
    percentRoth401k,
    percentRothIra,
    wageGrowth,
    selfContrib401k,
    employerContrib401k,
    iraContrib,
    wages,
  } = incSav;
  let tempIncSav = {
    ...incSav,
    wages: isUI ? currencyFormatToUI(wages) : currencyFormatToAPI(wages),
    percentRoth401k: isUI
      ? percentageConversionToUI(percentRoth401k)
      : percentageConversionToAPI(percentRoth401k),
    percentRothIra: isUI
      ? percentageConversionToUI(percentRothIra)
      : percentageConversionToAPI(percentRothIra),
    wageGrowth: isUI
      ? percentageConversionToUI(wageGrowth)
      : percentageConversionToAPI(wageGrowth),
    selfContrib401k: isUI
      ? percentageConversionToUI(selfContrib401k)
      : percentageConversionToAPI(selfContrib401k),
    employerContrib401k: isUI
      ? percentageConversionToUI(employerContrib401k)
      : percentageConversionToAPI(employerContrib401k),
    iraContrib: isUI
      ? percentageConversionToUI(iraContrib)
      : percentageConversionToAPI(iraContrib),
  };
  return tempIncSav;
};

const transformInfoCoreToAPIType = (infoCore) => {
  const {
    defCompFirst = {},
    defCompSecond = {},
    eqCompFirst = {},
    eqCompSecond = {},
    incSavFirst = {},
    incSavSecond = {},
    retChoicesFirst = {},
    retChoicesSecond = {},
    otherIncGrowth = '',
    otherIncValue = '',
  } = infoCore;

  let tempDefCompFirst = getDefCompObj(defCompFirst);
  let tempDefCompSecond = getDefCompObj(defCompSecond);
  let tempEqCompFirst = getEqCompObj(eqCompFirst);
  let tempEqCompSecond = getEqCompObj(eqCompSecond);
  let tempIncSavFirst = getIncSavObj(incSavFirst);
  let tempIncSavSecond = getIncSavObj(incSavSecond);
  let tempRetChoicesFirst = getRetChoicesObj(retChoicesFirst);
  let tempretChoicesSecond = getRetChoicesObj(retChoicesSecond);
  let tempOtherIncGrowth = percentageConversionToAPI(otherIncGrowth);
  let tempOtherIncValue = currencyFormatToAPI(otherIncValue);

  let tempInfoCore = {
    ...infoCore,
    defCompFirst: tempDefCompFirst,
    defCompSecond: tempDefCompSecond,
    eqCompFirst: tempEqCompFirst,
    eqCompSecond: tempEqCompSecond,
    incSavFirst: tempIncSavFirst,
    incSavSecond: tempIncSavSecond,
    otherIncGrowth: tempOtherIncGrowth,
    retChoicesFirst: tempRetChoicesFirst,
    retChoicesSecond: tempretChoicesSecond,
    otherIncValue: tempOtherIncValue,
  };
  return tempInfoCore;
};

const convertPropertyValue = (value, type) => {
  if (type === 'currency') {
    return currencyFormatToAPI(value);
  } else if (type === 'percentage') {
    return percentageConversionToAPI(value);
  }
  if (type === 'date') return getDateMonthYearInUTC(value);
};

const transformPrimaryHomeToAPIType = (primaryHome) => {
  const { prop = {} } = primaryHome;
  const propertyMap = PropertyNamesMap['prop'];
  for (let key in prop) {
    if (
      propertyMap[key]?.type === 'currency' ||
      propertyMap[key]?.type === 'percentage'
    ) {
      prop[key] = convertPropertyValue(prop[key], propertyMap[key].type);
    }
  }
  primaryHome.prop = { ...prop };
  return primaryHome;
};
const transformSecondHomesToAPIType = (secondHomes) => {
  const { props = [] } = secondHomes;
  if (props.length > 0) {
    const propertyMap = PropertyNamesMap['props'];
    for (let i = 0; i < props.length; i++) {
      let prop = props[i];
      for (let key in prop) {
        if (propertyMap[key]) {
          if (
            propertyMap[key]?.type === 'currency' ||
            propertyMap[key]?.type === 'percentage'
          ) {
            prop[key] = convertPropertyValue(prop[key], propertyMap[key].type);
          }
        }
      }
    }
    secondHomes.props = props;
  }
  return secondHomes;
};

const transformInvPropsToAPIType = (invProps) => {
  const { props = [] } = invProps;
  if (props.length > 0) {
    const propertyMap = PropertyNamesMap['invProps'];
    for (let i = 0; i < props.length; i++) {
      let obj = props[i];
      for (let key in obj) {
        if (key !== 'prop') {
          if (
            propertyMap[key]?.type === 'currency' ||
            propertyMap[key]?.type === 'percentage'
          ) {
            obj[key] = convertPropertyValue(obj[key], propertyMap[key].type);
          }
        } else if (key === 'prop') {
          let nestedObj = obj['prop'];
          if (Object.keys(nestedObj).length > 0) {
            const nestedObjPropertyMap = PropertyNamesMap['invProps']['prop'];
            for (let nestedKey in nestedObj) {
              if (
                nestedObjPropertyMap[nestedKey]?.type === 'currency' ||
                nestedObjPropertyMap[nestedKey]?.type === 'percentage'
              ) {
                nestedObj[nestedKey] = convertPropertyValue(
                  nestedObj[nestedKey],
                  nestedObjPropertyMap[nestedKey].type
                );
              }
            }
          }
        }
      }
    }
    invProps.props = props;
  }
  return invProps;
};

const transformInfoCreditCardsToAPIType = (infoCreditCards) => {
  const { creditCards = [] } = infoCreditCards;
  if (creditCards.length > 0) {
    const propertyMap = PropertyNamesMap['creditCards'];
    for (let i = 0; i < creditCards.length; i++) {
      let creditCard = creditCards[i];
      for (let key in creditCard) {
        if (propertyMap[key]) {
          if (
            propertyMap[key]?.type === 'currency' ||
            propertyMap[key]?.type === 'percentage'
          ) {
            creditCard[key] = convertPropertyValue(
              creditCard[key],
              propertyMap[key].type
            );
          }
        }
      }
    }
    infoCreditCards.creditCards = creditCards.map((card) => ({
      ...card,
      gracePeriod: 30,
    }));
  }
  return infoCreditCards;
};

const transformInfoOtherLoansToAPIType = (infoOtherLoans) => {
  const { otherLoans = [] } = infoOtherLoans;
  if (otherLoans.length > 0) {
    const propertyMap = PropertyNamesMap['otherLoans'];
    for (let i = 0; i < otherLoans.length; i++) {
      let otherLoan = otherLoans[i];
      for (let key in otherLoan) {
        if (propertyMap[key]) {
          if (
            propertyMap[key]?.type === 'currency' ||
            propertyMap[key]?.type === 'percentage'
          ) {
            otherLoan[key] = convertPropertyValue(
              otherLoan[key],
              propertyMap[key].type
            );
          }
        }
      }
    }
    infoOtherLoans.otherLoans = otherLoans;
  }
  return infoOtherLoans;
};

const transformInfoStudentLoansToAPIType = (infoStudentLoans) => {
  const { studentLoans = [] } = infoStudentLoans;
  if (studentLoans.length > 0) {
    const propertyMap = PropertyNamesMap['studentLoans'];
    for (let i = 0; i < studentLoans.length; i++) {
      let studentLoan = studentLoans[i];
      for (let key in studentLoan) {
        if (propertyMap[key]) {
          if (
            propertyMap[key]?.type === 'currency' ||
            propertyMap[key]?.type === 'percentage'
          ) {
            studentLoan[key] = convertPropertyValue(
              studentLoan[key],
              propertyMap[key].type
            );
          }
        }
      }
    }
    infoStudentLoans.studentLoans = studentLoans;
  }
  return infoStudentLoans;
};

export const transformBackToAPIType = (planDetailsByPlanId) => {
  const {
    moduleDebt = {},
    moduleEstate = {},
    moduleFamily = {},
    moduleInsurance = {},
    moduleInvestments = {},
    moduleSettings = {},
  } = planDetailsByPlanId || {};
  const {
    infoCore = {},
    infoExpenses = {},
    infoKids = {},
    infoVacations = {},
    infoVehicles = {},
  } = moduleFamily || {};

  const {
    auto = {},
    longTermCare = {},
    property = {},
    termLife = {},
    wholeLife = {},
    umbrella = {},
    health = {},
  } = moduleInsurance;

  const {
    invProps = {},
    primaryHome = {},
    secondHomes = {},
    markets = {},
  } = moduleInvestments;

  const {
    infoCreditCards = {},
    infoOtherLoans = {},
    infoStudentLoans = {},
  } = moduleDebt;

  let tempInfoCore = transformInfoCoreToAPIType(infoCore);
  let tempInfoKids = transformInfoKidsToAPIType(infoKids);
  let tempInfoVacations = transformInfoVacationsToAPIType(infoVacations);
  let tempInfoVehicles = transformInfoVehiclesToAPIType(infoVehicles);

  let tempAuto = transformAutoInsuranceToAPIType(auto);
  let tempLongTermCare = transformLongTermCareInsuranceToAPIType(longTermCare);
  let tempProperty = transformPropertyInsuranceToAPIType(property);
  let tempTermLife = transformTermLifeInsuranceToAPIType(termLife);
  let tempUmbrella = transformUmbrellaInsuranceToAPIType(umbrella);
  let tempWholeLife = transformWholeLifeInsuranceToAPIType(wholeLife);
  let tempHealthInsurance = transformHealthInsuranceToAPIType(health);

  let tempInvProps = transformInvPropsToAPIType(invProps);
  let tempPrimaryHome = transformPrimaryHomeToAPIType(primaryHome);
  let tempSecondHomes = transformSecondHomesToAPIType(secondHomes);

  let tempInfoCreditCards = transformInfoCreditCardsToAPIType(infoCreditCards);
  let tempInfoOtherLoans = transformInfoOtherLoansToAPIType(infoOtherLoans);
  let tempInfoStudentLoans =
    transformInfoStudentLoansToAPIType(infoStudentLoans);

  let tempModuleFamily = {
    ...moduleFamily,
    infoCore: tempInfoCore,
    infoKids: tempInfoKids,
    infoVacations: tempInfoVacations,
    infoVehicles: tempInfoVehicles,
  };
  let tempModuleInsurance = {
    ...moduleInsurance,
    auto: tempAuto,
    longTermCare: tempLongTermCare,
    property: tempProperty,
    termLife: tempTermLife,
    umbrella: tempUmbrella,
    wholeLife: tempWholeLife,
    health: tempHealthInsurance,
  };
  let tempModuleInvestments = {
    ...moduleInvestments,
    markets: {
      ...markets,
      invValueAsOf: 'now', //as part of v3 changes
    },
    invProps: tempInvProps,
    primaryHome: tempPrimaryHome,
    secondHomes: tempSecondHomes,
  };
  let tempModuleDebt = {
    ...moduleDebt,
    infoCreditCards: tempInfoCreditCards,
    infoOtherLoans: tempInfoOtherLoans,
    infoStudentLoans: tempInfoStudentLoans,
  };
  let tempPlanDetailsByPlanId = {
    ...planDetailsByPlanId,
    moduleFamily: tempModuleFamily,
    moduleInsurance: tempModuleInsurance,
    moduleInvestments: tempModuleInvestments,
    moduleDebt: tempModuleDebt,
  };
  return tempPlanDetailsByPlanId;
};

export const transformInfoCoreBackToUIType = (infoCore) => {
  const {
    defCompFirst = {},
    defCompSecond = {},
    eqCompFirst = {},
    eqCompSecond = {},
    incSavFirst = {},
    incSavSecond = {},
    retChoicesFirst = {},
    retChoicesSecond = {},
    otherIncGrowth = '',
    otherIncValue = '',
  } = infoCore;

  let tempDefCompFirst = getDefCompObj(defCompFirst, true);
  let tempDefCompSecond = getDefCompObj(defCompSecond, true);
  let tempEqCompFirst = getEqCompObj(eqCompFirst, true);
  let tempEqCompSecond = getEqCompObj(eqCompSecond, true);
  let tempIncSavFirst = getIncSavObj(incSavFirst, true);
  let tempIncSavSecond = getIncSavObj(incSavSecond, true);
  let tempRetChoicesFirst = getRetChoicesObj(retChoicesFirst, true);
  let tempretChoicesSecond = getRetChoicesObj(retChoicesSecond, true);
  let tempOtherIncGrowth = percentageConversionToUI(otherIncGrowth);
  let tempOtherIncValue = currencyFormatToUI(otherIncValue);

  let tempInfoCore = {
    ...infoCore,
    defCompFirst: tempDefCompFirst,
    defCompSecond: tempDefCompSecond,
    eqCompFirst: tempEqCompFirst,
    eqCompSecond: tempEqCompSecond,
    incSavFirst: tempIncSavFirst,
    incSavSecond: tempIncSavSecond,
    otherIncGrowth: tempOtherIncGrowth,
    retChoicesFirst: tempRetChoicesFirst,
    retChoicesSecond: tempretChoicesSecond,
    otherIncValue: tempOtherIncValue,
  };
  return tempInfoCore;
};

export const getFormattedUIValue = (
  value = '',
  propertyInfo = {},
  propName = ''
) => {
  if (propName !== 'prop') {
    const { type } = propertyInfo;
    switch (type) {
      case 'percentage':
        return percentageConversionToUI(value);
      case 'currency':
        return currencyFormatToUI(value);
      default:
        return value;
    }
  } else {
    return Object.keys(propertyInfo).reduce(
      (acc, nestedPropName) => ({
        ...acc,
        [nestedPropName]:
          value !== undefined &&
            value.hasOwnProperty(nestedPropName) &&
            value[nestedPropName] !== undefined
            ? getFormattedUIValue(
              value[nestedPropName],
              propertyInfo[nestedPropName],
              nestedPropName
            )
            : '',
      }),
      {}
    );
  }
};

export const transformInfoKidsBackToUIType = (infoKids) => {
  const { kids = [] } = infoKids;
  let tempKids = [];
  if (kids.length > 0) {
    tempKids = kids.map((k) => {
      return {
        ...k,
        annualCollegeExpenseNow: currencyFormatToUI(k.annualCollegeExpenseNow),
        annualExpense: currencyFormatToUI(k.annualExpense),
        collegeExpenseGrowth: percentageConversionToUI(k.collegeExpenseGrowth),
        peakReturn529Plan: percentageConversionToUI(k.peakReturn529Plan),
        useSavingsFor529Deficit: booleanConversionToUI(
          k.useSavingsFor529Deficit
        ),
        useSavingsToBuild529: booleanConversionToUI(k.useSavingsToBuild529),
        value529Plan: currencyFormatToUI(k.value529Plan),
      };
    });
  }
  let tempInfoKids = { ...infoKids, kids: tempKids };
  return tempInfoKids;
};

const getDateMonthYearInUTC = (
  localDate = new Date(),
  returnOnlyYear = false
) => {
  // const currentDate = new Date(
  //   new Date(localDate).setHours(new Date().getHours())
  // );
  const currentDate = new Date(localDate);
  const UTCDate = ('0' + currentDate.getUTCDate()).slice(-2);
  const UTCMonth = ('0' + (currentDate.getUTCMonth() + 1)).slice(-2);
  const UTCYear = currentDate.getUTCFullYear();
  const formattedDate = `${UTCYear}-${UTCMonth}-${UTCDate}`;
  return returnOnlyYear ? UTCYear : formattedDate;
};

export const transformInfoKidsToAPIType = (infoKids) => {
  const { kids = [] } = infoKids;
  let tempKids = [];
  if (kids.length > 0) {
    tempKids = kids.map((k) => {
      const dateObj = new Date().setFullYear(k.yearBorn);
      const UTCYear = getDateMonthYearInUTC(dateObj, true);
      return {
        ...k,
        annualCollegeExpenseNow: currencyFormatToAPI(k.annualCollegeExpenseNow),
        annualExpense: currencyFormatToAPI(k.annualExpense),
        collegeExpenseGrowth: percentageConversionToAPI(k.collegeExpenseGrowth),
        peakReturn529Plan: percentageConversionToAPI(k.peakReturn529Plan),
        useSavingsFor529Deficit: booleanConversionToAPI(
          k.useSavingsFor529Deficit
        ),
        useSavingsToBuild529: booleanConversionToAPI(k.useSavingsToBuild529),
        value529Plan: currencyFormatToAPI(k.value529Plan),
        yearBorn: UTCYear,
      };
    });
  }
  let tempInfoKids = { ...infoKids, kids: tempKids };
  return tempInfoKids;
};

export const transformInfoVacationsToAPIType = (infoVacations) => {
  const { vacations = [] } = infoVacations;
  let tempArray = [];
  if (vacations.length > 0) {
    tempArray = vacations.map((k) => {
      const dateObj = new Date().setFullYear(k.vacationYear);
      const UTCYear = getDateMonthYearInUTC(dateObj, true);
      return {
        ...k,
        vacationExpense: currencyFormatToAPI(k.vacationExpense),
        vacationYear: UTCYear,
      };
    });
  }
  let tempInfoVacations = { ...infoVacations, vacations: tempArray };
  return tempInfoVacations;
};

export const transformInfoVehiclesToAPIType = (infoVehicles) => {
  const { vehicles = [] } = infoVehicles;
  let tempArray = [];
  if (vehicles.length > 0) {
    tempArray = vehicles.map((k) => {
      const { purchaseDate, interestStartDate, paymentStartDate } = k;
      const utcPurchaseDate = getDateMonthYearInUTC(purchaseDate);
      const utcInterestStartDt = getDateMonthYearInUTC(interestStartDate);
      const utcPaymentStartDt = getDateMonthYearInUTC(paymentStartDate);
      return {
        ...k,
        apr: percentageConversionToAPI(k.apr),
        balance: currencyFormatToAPI(k.balance),
        loanAmount: currencyFormatToAPI(k.loanAmount),
        monthlyPayment: currencyFormatToAPI(k.monthlyPayment),
        purchasePrice: currencyFormatToAPI(k.purchasePrice),
        maintenanceExp: currencyFormatToAPI(k.maintenanceExp),
        value: currencyFormatToAPI(k.value),
        purchaseDate: utcPurchaseDate,
        interestStartDate: utcInterestStartDt,
        paymentStartDate: utcPaymentStartDt,
      };
    });
  }
  let tempInfoVehicles = { ...infoVehicles, vehicles: tempArray };
  return tempInfoVehicles;
};

export const transformAutoInsuranceToAPIType = (auto) => {
  const { autoIns = [] } = auto;
  let tempArray = [];
  if (autoIns.length > 0) {
    tempArray = autoIns.map((k) => {
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
      };
    });
  }
  let tempAuto = { ...auto, autoIns: tempArray };
  return tempAuto;
};

export const transformLongTermCareInsuranceToAPIType = (longTermCare) => {
  const { ltcIns = [] } = longTermCare;
  let tempArray = [];
  if (ltcIns.length > 0) {
    tempArray = ltcIns.map((k) => {
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
      };
    });
  }
  let tempLongTermCare = { ...longTermCare, ltcIns: tempArray };
  return tempLongTermCare;
};

export const transformHealthInsuranceToAPIType = (health) => {
  const { healthIns = [] } = health;
  let tempArray = [];
  if (healthIns.length > 0) {
    tempArray = healthIns.map((k) => {
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
        coverageYrs: 1,
        renewAtExpiry: true, // change it later
      };
    });
  }
  let tempHealth = { ...health, healthIns: tempArray };
  return tempHealth;
};

export const transformPropertyInsuranceToAPIType = (property) => {
  const { propIns = [] } = property;
  let tempArray = [];
  if (propIns.length > 0) {
    tempArray = propIns.map((k) => {
      const coverageYrdateObj = new Date().setFullYear(k.coverageStartYr);
      const UtccoverageStartYr = getDateMonthYearInUTC(coverageYrdateObj, true);
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
        coverageStartYr: UtccoverageStartYr,
      };
    });
  }
  let tempProperty = { ...property, propIns: tempArray };
  return tempProperty;
};

export const transformTermLifeInsuranceToAPIType = (termLife) => {
  const { termLifeIns = [] } = termLife;
  let tempArray = [];
  if (termLifeIns.length > 0) {
    tempArray = termLifeIns.map((k) => {
      const coverageYrdateObj = new Date().setFullYear(k.coverageStartYr);
      const UtccoverageStartYr = getDateMonthYearInUTC(coverageYrdateObj, true);
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
        coverageStartYr: UtccoverageStartYr,
      };
    });
  }
  let tempTermLife = { ...termLife, termLifeIns: tempArray };
  return tempTermLife;
};

export const transformUmbrellaInsuranceToAPIType = (umbrella) => {
  const { umbrellaIns = [] } = umbrella;
  let tempArray = [];
  if (umbrellaIns.length > 0) {
    tempArray = umbrellaIns.map((k) => {
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
      };
    });
  }
  let tempUmbrella = { ...umbrella, umbrellaIns: tempArray };
  return tempUmbrella;
};

export const transformWholeLifeInsuranceToAPIType = (wholeLife) => {
  const { wholeLifeIns = [] } = wholeLife;
  let tempArray = [];
  if (wholeLifeIns.length > 0) {
    tempArray = wholeLifeIns.map((k) => {
      const coverageYrdateObj = new Date().setFullYear(k.coverageStartYr);
      const UtccoverageStartYr = getDateMonthYearInUTC(coverageYrdateObj, true);
      return {
        ...k,
        premiumGrowth: percentageConversionToAPI(k.premiumGrowth),
        coverage: currencyFormatToAPI(k.coverage),
        premium: currencyFormatToAPI(k.premium),
        deductible: 0,
        cashValueAnnual: currencyFormatToAPI(k.cashValueAnnual),
        coverageStartYr: UtccoverageStartYr,
      };
    });
  }
  let tempWholeLife = { ...wholeLife, wholeLifeIns: tempArray };
  return tempWholeLife;
};

export const transformHeirsBackToUIType = (heirs) => {
  const { agiAtInheritance = '', agiGrowth = '', taxState = '' } = heirs;
  let tempAgiAtInheritance = currencyFormatToUI(agiAtInheritance);
  let tempAgiGrowth = percentageConversionToUI(agiGrowth);

  let tempHeirs = {
    ...heirs,
    agiAtInheritance: tempAgiAtInheritance,
    agiGrowth: tempAgiGrowth,
  };
  return tempHeirs;
};

const getRothObj = (rothObj, isUI) => {
  const {
    trad401kToRothPercentAtRet,
    trad401kToRothPercentNow,
    tradIraToRothPercent,
  } = rothObj;
  let tempRothObj = {
    ...rothObj,
    trad401kToRothPercentAtRet: isUI
      ? percentageConversionToUI(trad401kToRothPercentAtRet)
      : percentageConversionToAPI(trad401kToRothPercentAtRet),
    trad401kToRothPercentNow: isUI
      ? percentageConversionToUI(trad401kToRothPercentNow)
      : percentageConversionToAPI(trad401kToRothPercentNow),
    tradIraToRothPercent: isUI
      ? percentageConversionToUI(tradIraToRothPercent)
      : percentageConversionToAPI(tradIraToRothPercent),
  };
  return tempRothObj;
};

export const transformRothChoicesBackToUIType = (rothChoices) => {
  const { first = {}, second = {}, withdrawRothFirst } = rothChoices;
  let tempFirst = getRothObj(first, true);
  let tempSecond = getRothObj(second, true);
  //let tempWithdrawRothFirst = booleanConversionToUI(withdrawRothFirst);
  let tempRothChoices = {
    ...rothChoices,
    first: tempFirst,
    second: tempSecond,
    // withdrawRothFirst: tempWithdrawRothFirst,
  };
  return tempRothChoices;
};

export const transformAlertsBackToUIType = (alerts) => {
  const {
    debtRateRedThreshold = '',
    debtRateYellowThreshold = '',
    insuranceRedThreshold = '',
    insuranceYellowThreshold = '',
    invReturnRedHigh = '',
    invReturnRedLow = '',
    invReturnYellowHigh = '',
    invReturnYellowLow = '',
  } = alerts;

  let tempAlerts = {
    debtRateRedThreshold: percentageConversionToUI(debtRateRedThreshold),
    debtRateYellowThreshold: percentageConversionToUI(debtRateYellowThreshold),
    insuranceRedThreshold: percentageConversionToUI(insuranceRedThreshold),
    insuranceYellowThreshold: percentageConversionToUI(
      insuranceYellowThreshold
    ),
    invReturnRedHigh: percentageConversionToUI(invReturnRedHigh),
    invReturnRedLow: percentageConversionToUI(invReturnRedLow),
    invReturnYellowHigh: percentageConversionToUI(invReturnYellowHigh),
    invReturnYellowLow: percentageConversionToUI(invReturnYellowLow),
  };
  return tempAlerts;
};

export const transformCashFlowsBackToUIType = (cashFlows) => {
  const { defaultCashPercentInSavings = '' } = cashFlows;
  let tempCashFlows = {
    defaultCashPercentInSavings: percentageConversionToUI(
      defaultCashPercentInSavings
    ),
  };
  return tempCashFlows;
};

export const transformTaxesBackToUIType = (taxes) => {
  const {
    itemizedDedGrowth = '',
    itemizedDeduction = '',
    qualifiedBusinessIncDedGrowth = '',
    qualifiedBusinessIncDeduction = '',
  } = taxes;
  let tempTaxes = {
    itemizedDedGrowth: percentageConversionToUI(itemizedDedGrowth),
    qualifiedBusinessIncDedGrowth: percentageConversionToUI(
      qualifiedBusinessIncDedGrowth
    ),
    itemizedDeduction: currencyFormatToUI(itemizedDeduction),
    qualifiedBusinessIncDeduction: currencyFormatToUI(
      qualifiedBusinessIncDeduction
    ),
  };
  return tempTaxes;
};

export const transformEmergencyFundsBackToUIType = (emergencyFunds) => {
  const {
    divRate = '',
    emCashPercentForDiv = '',
    deficitAsPercentOfExpForPropLiquidation = '',
  } = emergencyFunds;
  let tempEmergencyFunds = {
    ...emergencyFunds,
    divRate: percentageConversionToUI(divRate),
    emCashPercentForDiv: percentageConversionToUI(emCashPercentForDiv),
    deficitAsPercentOfExpForPropLiquidation: percentageConversionToUI(
      deficitAsPercentOfExpForPropLiquidation
    ),
  };
  return tempEmergencyFunds;
};
