import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { vehiclesFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  createLocalDateFromDateString,
  validateDateFields,
  parseCurrency,
  getDateValue,
} from '../../helpers/SolutionsHelper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const VehiclesContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, compoundingMenuItems = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const conditionalFields = [
    'name',
    'loanAmount',
    'purchaseDate',
    'interestStartDate',
    'paymentStartDate',
  ];

  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [vehiclesFormFieldsInfo, setVehiclesFormFieldsInfo] =
    useState(vehiclesFormFields);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedVehicle;
    },
  }));

  useEffect(() => {
    let tempVehiclesFormFields = [...vehiclesFormFields];
    if (compoundingMenuItems && compoundingMenuItems.length > 0) {
      tempVehiclesFormFields = tempVehiclesFormFields.map((f) => {
        if (f.key === 'compounding') {
          return { ...f, options: compoundingMenuItems };
        }
        return f;
      });
    }
    setVehiclesFormFieldsInfo(tempVehiclesFormFields);
  }, [setVehiclesFormFieldsInfo, compoundingMenuItems]);

  const generatePropsMemoized = useCallback(
    (keyVal, key) =>
      generateProps(keyVal, key, 'vehicles', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const vehicle = formDetails?.vehicles.find((k) => k.name === goal.name);
    if (
      !vehicle &&
      modalType === 'add' &&
      Object.keys(selectedVehicle).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['vehicles']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['vehicles'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedVehicle).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          vehiclesFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !== JSON.stringify(selectedVehicle)
        ) {
          setSelectedVehicle(formattedNewRecord);
        }
      }
    }

    if (vehicle && Object.keys(selectedVehicle).length === 0) {
      const formattedVehicle = formatValuesForUI(vehicle, vehiclesFormFields);
      if (
        JSON.stringify(formattedVehicle) !== JSON.stringify(selectedVehicle)
      ) {
        setSelectedVehicle(formattedVehicle); // Only update if necessary
      }
    }
  }, [
    formDetails.vehicles,
    goal.name,
    modalType,
    selectedVehicle,
    generatePropsMemoized,
  ]);

  const validateVehicleNames = useCallback(
    (value) => {
      const existingVehicle = formDetails?.vehicles.find(
        (vehicle) => vehicle?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingVehicle) {
        return false;
      }
      return true;
    },
    [modalType, formDetails?.vehicles]
  );

  const validateLoanAmountField = useCallback(
    (value) => {
      const loanAmount = parseCurrency(value || 0);
      const purchasePrice = parseCurrency(selectedVehicle?.purchasePrice || 0);

      if (isNaN(loanAmount) || isNaN(purchasePrice)) {
        return false;
      }
      // Ensure loan amount is not greater than the purchase price
      return loanAmount <= purchasePrice;
    },
    [selectedVehicle]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedVehicle[key];
      let isValid = true;
      switch (key) {
        case 'purchasePrice':
        case 'balance':
        case 'monthlyPayment':
        case 'value':
        case 'maintenanceExp':
          isValid = value;
          break;
        case 'loanAmount':
          isValid = value !== '' ? validateLoanAmountField(value) : false;
          break;
        case 'apr':
          isValid = value;
          break;
        case 'termMonths':
        case 'deprYrs':
          isValid = value !== '' && parseInt(value) >= 0;
          break;
        case 'purchaseDate':
          const date =
            value &&
            validateDateFields(value) &&
            createLocalDateFromDateString(value);
          isValid = validateDateFields(value)
            ? modalType === 'add'
              ? date &&
                new Date(date).setHours(0, 0, 0, 0) >=
                  new Date().setHours(0, 0, 0, 0)
              : true
            : false;
          break;
        case 'paymentStartDate':
        case 'interestStartDate':
          isValid = value ? validateDateFields(value) : false;
          break;
        case 'name':
          isValid = value !== '' ? validateVehicleNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedVehicle, validateVehicleNames, modalType, validateLoanAmountField]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = vehiclesFormFields.map((m) => m.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = vehiclesFormFieldsInfo.find((ft) => ft.key === field);
    let formattedValue = selectedVehicle[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedVehicle[field]);
    }
    if (field === 'purchasePrice' && modalType === 'add') {
      setSelectedVehicle({
        ...selectedVehicle,
        [field]: formattedValue,
        loanAmount: formattedValue,
        value: formattedValue,
        balance: formattedValue,
      }); // Update the selected vehicle state
    } else if (field === 'purchaseDate') {
      setSelectedVehicle({
        ...selectedVehicle,
        [field]: formattedValue,
        interestStartDate: formattedValue,
        paymentStartDate: formattedValue,
      }); // Update the selected vehicle state
    } else if (field === 'loanAmount') {
      if (parseCurrency(formattedValue) === 0) {
        setSelectedVehicle({
          ...selectedVehicle,
          [field]: formattedValue,
          balance: formattedValue,
          termMonths: 0,
        }); // Update the selected vehicle state
      } else {
        setSelectedVehicle({
          ...selectedVehicle,
          [field]: formattedValue,
          balance: formattedValue,
        }); // Update the selected vehicle state
      }
    } else {
      setSelectedVehicle({ ...selectedVehicle, [field]: formattedValue }); // Update the selected vehicle state
    }
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = vehiclesFormFieldsInfo.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const vehicle = { ...selectedVehicle, [key]: formattedValue };
    // Update selected vehicle state to reflect changes in UI
    setSelectedVehicle(vehicle);
  };

  useEffect(() => {
    if (selectedVehicle && Object.keys(selectedVehicle).length > 0) {
      checkFormValidity();
    }
  }, [formDetails, checkFormValidity, selectedVehicle]);

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = vehiclesFormFieldsInfo.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        let vehicle = { ...selectedVehicle };
        if (field.key === 'purchaseDate') {
          vehicle = {
            ...vehicle,
            [field.key]: formattedDate,
            interestStartDate: formattedDate,
            paymentStartDate: formattedDate,
          };
        } else {
          vehicle = {
            ...vehicle,
            [field.key]: formattedDate,
          };
        }
        setSelectedVehicle(vehicle);
      }
    }
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Vehicle' : 'Update Vehicle'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedVehicle).length > 0 &&
            vehiclesFormFieldsInfo.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          disabled={modalType === 'edit' && field.isDisabled}
                          value={selectedVehicle[field.key]}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={() => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedVehicle &&
                        Object.keys(selectedVehicle).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {selectedVehicle[field.key] !== '' &&
                            selectedVehicle[field.key] !== undefined
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-8 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={selectedVehicle[field.key]?.toLowerCase()}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          className={'custom-input'}
                          options={field.options.map((c) => {
                            return {
                              value: c.value?.toLowerCase(),
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(
                    selectedVehicle[field.key],
                    field
                  );
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-8 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            onBlur={() => handleBlur(field.key)}
                            inputProps={{ style: { height: '39px' } }}
                            minDate={
                              (modalType === 'add' ||
                                (modalType === 'edit' &&
                                  new Date(dateValue).setHours(0) >=
                                    new Date().setHours(0))) &&
                              (field.key === 'paymentStartDate' ||
                                field.key === 'interestStartDate')
                                ? getDateValue(selectedVehicle['purchaseDate'])
                                : new Date()
                            }
                            disabled={
                              modalType === 'edit' &&
                              new Date(dateValue).setHours(0, 0, 0, 0) <
                                new Date().setHours(0, 0, 0, 0)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    </>
  );
});
export default VehiclesContentGoals;
