import React from 'react'
import { TextareaAutosize } from '@material-ui/core';
export default function Textarea(props) {
    return (
        <TextareaAutosize
            maxRows={4}
            minRows={4}
            aria-label="maximum height"
            placeholder="Place holder to show the instantaneous details on your selection"
            style={{ width: 400 }}
        />
    )
}