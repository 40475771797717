export const GET_USER_DETAILS = 'user:details';
export const GET_USER_DETAILS_ERROR = 'user:details:error';
export const GET_USER_DETAILS_SUCCESS = 'user:details:success';
export const CLEAR_USER_DETAILS = 'user:details:clear';
export const SET_PRIVACY_ACCEPTANCE_VALUE = 'set:privacy:acceptance:value';
export const SET_PREVIOUS_PATH_VALUE = 'set:previous:path:value';
export const SET_IS_PRIVACY_CHECKED = 'set:is:privacy:checked';
export const SET_IS_AUTHENTICATED = 'set:is:authenticated';
export const SET_IS_TERMS_CHECKED = 'set:is:terms:checked';
export const SET_TERMS_ACCEPTANCE_VALUE = 'set:terms:acceptance:value';
export function getUserDetailsAction(payload) {
  return {
    type: GET_USER_DETAILS,
    payload,
  };
}

export function getUserDetailsSuccessAction(payload) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload,
  };
}

export function getUserDetailsErrorAction(payload) {
  return {
    type: GET_USER_DETAILS_ERROR,
    payload,
  };
}

export function clearUserDetailsAction() {
  return {
    type: CLEAR_USER_DETAILS,
  };
}

export function setPrivacyAcceptanceValue(payload) {
  return {
    type: SET_PRIVACY_ACCEPTANCE_VALUE,
    payload,
  };
}

export function setTermsAcceptanceValue(payload) {
  return {
    type: SET_TERMS_ACCEPTANCE_VALUE,
    payload,
  };
}

export function setPreviousPathValue(payload) {
  return {
    type: SET_PREVIOUS_PATH_VALUE,
    payload,
  };
}

export function setIsPrivacyChecked(payload) {
  return {
    type: SET_IS_PRIVACY_CHECKED,
    payload,
  };
}

export function setIsTermsChecked(payload) {
  return {
    type: SET_IS_TERMS_CHECKED,
    payload,
  };
}

export function setIsAuthenticated(payload) {
  return {
    type: SET_IS_AUTHENTICATED,
    payload,
  };
}
