import { DATATYPES } from '../common';
import { stateOptions } from '../retirementPlan';

const taxStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'MFS', value: 'mfs' },
  { label: 'MFJ', value: 'mfj' },
  { label: 'HOH', value: 'hoh' },
];

export const commonFields = ['taxFilingStatus', 'residenceState'];
export const ProfileConstants = [
  {
    label: 'member-1',
    fields: [
      {
        label: 'Age end of last year',
        name: 'age',
        colSize: 4,
        type: 'number',
        errorText: 'Age can take values from 1 to 150',
        validations: [
          {
            methodArgs: ['elderAge'],
            methodKey: 'validateYoungerAgeAgainstElderAge',
            errorText: "Younger age can't be greater than elder age",
          },
        ],
      },
      {
        label: 'Work Tax State',
        name: 'workState',
        colSize: 4,
        type: 'select',
        errorText: '',
        options: [],
      },
      {
        label: 'Tax Filing Status',
        name: 'taxFilingStatus',
        colSize: 3,
        type: 'select',
        errorText: '',
        options: taxStatusOptions,
      },
      {
        label: 'Residence Tax State',
        name: 'residenceState',
        colSize: 4,
        type: 'select',
        errorText: '',
        options: [],
      },
    ],
  },
  {
    label: 'member-2',
    fields: [
      {
        label: 'Age end of last year',
        name: 'age',
        colSize: 4,
        type: 'number',
        errorText: 'Age can take values from 1 to 150',
        validations: [
          {
            methodArgs: ['elderAge'],
            methodKey: 'validateYoungerAgeAgainstElderAge',
            errorText: "Younger age can't be greater than elder age",
          },
        ],
      },
      {
        label: 'Work Tax State',
        name: 'workState',
        colSize: 4,
        type: 'select',
        errorText: '',
        options: [],
      },
      {
        label: 'Tax Filing Status',
        name: 'taxFilingStatus',
        colSize: 3,
        type: 'select',
        errorText: '',
        options: taxStatusOptions,
      },
      {
        label: 'Residence Tax State',
        name: 'residenceState',
        colSize: 4,
        type: 'select',
        errorText: '',
        options: [],
      },
    ],
  },
];
