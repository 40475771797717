import React from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import { orange, yellow, green, red, grey } from '@mui/material/colors';

const renderIconsBasedOnColors = (color) => {
  switch (color && color.toLowerCase()) {
    case 'green':
      return <FlagIcon sx={{ color: green[800], fontSize: '20px' }} />;
    case 'lightgreen':
      return <FlagIcon sx={{ color: '#90EE90', fontSize: '20px' }} />;
    case 'red':
      return <FlagIcon sx={{ color: red[800], fontSize: '20px' }} />;
    case 'orange':
      return <FlagIcon sx={{ color: orange[800], fontSize: '20px' }} />;
    case 'yellow':
      return <FlagIcon sx={{ color: yellow[600], fontSize: '20px' }} />;
    case 'gray':
      return <FlagIcon sx={{ color: grey[600], fontSize: '20px' }} />;
    default:
      return <span>{color}</span>;
  }
};

export const generateTableHTML = (val, parentTabName, snap) => {
  const headingHTML = val.heading.map((p, i) => <th key={i}>{p || ''}</th>);
  const rowsHTML = val.rows.map((row, rowIndex) => (
    <tr key={rowIndex}>
      {row.cells.some((val) => val) &&
        row.cells.map((cell, cellIndex) => {
          if (
            val.heading[cellIndex]?.toLowerCase() === 'plan health' ||
            (row.cells[0] &&
              row.cells[0].toLowerCase() === 'plan health' &&
              cellIndex === 1) ||
            (row.cells[0] &&
              row.cells[0].toLowerCase() === 'overall health' &&
              cellIndex === 1)
          ) {
            return <td key={cellIndex}>{renderIconsBasedOnColors(cell)}</td>;
          } else {
            return <td key={cellIndex}>{cell || '-'}</td>;
          }
        })}
    </tr>
  ));
  return (
    <table
      className={
        val.heading.length > 3 || snap
          ? 'full-width-table'
          : 'small-width-table'
      }
    >
      <thead>
        <tr>{headingHTML}</tr>
      </thead>
      <tbody>{rowsHTML}</tbody>
    </table>
  );
};
