import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ProfileConstants,
  commonFields,
} from '../../../constants/IndividualClient/ProfileConstants';
import { PROFILE_COMPONENT } from '../../../constants/IndividualClient';
import {
  getExpensesByLifestyle,
  getExpenseFactorDetails,
} from '../../../services/dashboardSvc';
import {
  getExpenseDetailsByLifestyleSuccess,
  resetExpenseDetailsByLifeStyle,
} from '../../../redux/actions/plannerDetailsAction';
import PlannerTabsContent from '../PlannerTabsContent';

class ProfileComponent extends Component {
  PlannerTabsRef = React.createRef();

  state = {
    updateLifestyle: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { updateLifestyle } = this.state;
    const { updateLifestyle: prevUpdateLifestyle } = this.state;
    if (updateLifestyle !== prevUpdateLifestyle) {
      this.forceUpdate();
    }
  }

  updateLifestyleInWagesExpenseProfile = () => {
    const { updateLifestyle } = this.state;
    this.setState({
      updateLifestyle: !updateLifestyle,
    });
  };

  newWagesAndExpenseProfileState = () => {
    const { updateLifestyle } = this.state;
    if (updateLifestyle) {
      return {
        lifestyle: 'custom',
      };
    }
    return null;
  };

  getWagesAndExpenseProfileFormState = () => {
    const { current: { getAllFormFieldValues } = {} } = this.PlannerTabsRef;
    return getAllFormFieldValues();
  };

  getInitialFieldsState() {
    const formFields = ProfileConstants.filter((el) => Object.keys(el).length);
    return formFields.reduce((acc, curr) => {
      const { name } = curr;
      return { [name]: '', ...acc };
    }, {});
  }

  getAddBtnStatus = () => {
    const { current } = this.PlannerTabsRef;
    if (current) {
      const { getSelectedMemberFormFields, getAllFormFieldValues } = current;
      const newMembers = getAllFormFieldValues();
      const fieldValues = getSelectedMemberFormFields();
      if (newMembers.length === 1) return fieldValues.taxFilingStatus !== 'MFJ';
      return false;
    }
    return false;
  };

  getNewMemberInitialState = (newMembers) => {
    const { fieldValues } = newMembers[0];
    const dependentFieldsValue = commonFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: fieldValues[curr],
      };
    }, {});
    const initalState = {
      ...this.getInitialFieldsState(),
      ...dependentFieldsValue,
    };
    return initalState;
  };

  saveFormFieldsInStore = () => {};

  getExpenseDetailsByLifestyle = async (lifestyle) => {
    const {
      getExpenseDetailsByLifestyleSuccess,
      resetExpenseDetailsByLifeStyle,
    } = this.props;
    resetExpenseDetailsByLifeStyle();
    let responseObj = {};
    const { status, data: expenseDetails } = await getExpensesByLifestyle(
      lifestyle
    );
    const { status: expenseFactorStatus, data: expenseFactorDetails } =
      await getExpenseFactorDetails();
    if (status === 200 && expenseDetails && expenseDetails.length > 0) {
      responseObj = { ...expenseDetails[0] };
    }
    if (
      expenseFactorStatus === 200 &&
      expenseFactorDetails &&
      expenseFactorDetails.length > 0
    ) {
      responseObj = { ...responseObj, ...expenseFactorDetails[0] };
    }
    if (expenseFactorStatus === 200 && expenseFactorStatus === 200)
      getExpenseDetailsByLifestyleSuccess(responseObj);
  };

  onLifeStyleChangeCallback = (name, value) => {
    if (name === 'lifestyle') {
      this.getExpenseDetailsByLifestyle(value);
    }
  };

  getFieldsConfig = () => {
    let fieldConfig = {
      fieldConstants: {},
      commonFields: {},
    };
    const { lifestyleMenuOptions, taxFilingStatusMenuItems, taxStateOptions } =
      this.props;

    const wagesAndExpensFields = ProfileConstants.map((profileField, index) => {
      const profileFieldwithOpions = profileField.fields.map((field) => {
        if (field.name === 'taxFilingStatus') {
          return {
            ...field,
            options: taxFilingStatusMenuItems,
          };
        }
        if (field.name === 'workState' || field.name === 'residenceState') {
          return {
            ...field,
            options: taxStateOptions,
          };
        }
        return { ...field };
      });

      return {
        ...profileField,
        fields: [...profileFieldwithOpions],
      };
    });
    const formFields = wagesAndExpensFields.filter(
      (el) => Object.keys(el).length
    );
    const fieldConstants = wagesAndExpensFields.map((fieldObj, index) => {
      return {
        ...fieldObj,
        fields: fieldObj.fields.filter(
          (field) => !commonFields.includes(field.name)
        ),
      };
    });
    fieldConfig = {
      fieldConstants: fieldConstants,
      commonFields: commonFields,
    };
    return fieldConfig;
  };

  getProfileCompFormState = () => {
    const { current: { getAllFormFieldValues } = {} } = this.PlannerTabsRef;
    return getAllFormFieldValues();
  };

  render() {
    const { updateLifestyle } = this.state;
    const {
      isComponentVisible,
      storeValues,
      lifestyleMenuOptions,
      taxFilingStatusMenuItems,
      taxStateOptions,
      updatePlannerDataInReduxCb,
    } = this.props;
    const wagesAndExpensFields = ProfileConstants.map((profileField, index) => {
      const profileFieldwithOpions = profileField.fields.map((field) => {
        if (field.name === 'taxFilingStatus') {
          return {
            ...field,
            options: taxFilingStatusMenuItems,
          };
        }
        if (field.name === 'workState' || field.name === 'residenceState') {
          return {
            ...field,
            options: taxStateOptions,
          };
        }
        return { ...field };
      });

      return {
        ...profileField,
        fields: [...profileFieldwithOpions],
      };
    });
    return (
      <>
        <PlannerTabsContent
          ref={this.PlannerTabsRef}
          fieldsConstants={[...wagesAndExpensFields]}
          onSimulateSave={this.saveFormFieldsInStore}
          tabLabelPrefix="Member"
          storeValues={storeValues}
          componentName={PROFILE_COMPONENT}
          newStateToUpdate={this.newWagesAndExpenseProfileState}
          shouldUpdateCompState={updateLifestyle}
          onSelectChangeCallback={this.onLifeStyleChangeCallback}
          fieldsConfig={this.getFieldsConfig()}
          updatePlannerDataInReduxCb={updatePlannerDataInReduxCb}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getExpenseDetailsByLifestyleSuccess: (payload) =>
      dispatch(getExpenseDetailsByLifestyleSuccess(payload)),
    resetExpenseDetailsByLifeStyle: () =>
      dispatch(resetExpenseDetailsByLifeStyle()),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  ProfileComponent
);
