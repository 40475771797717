import { getCurrentDateTime } from '../../../utils/apirequestutil';

export const transformWagesAndExpenseProfileReq = (wagesReq) => {
  const {
    profileCompReq: {
      members: profileMembers,
      commonFields: profileCommonFields,
    },
    incomeCompReq: { members: incomeMembers },
    infoPreferences: { inflationRate: wageGrowth },
  } = wagesReq;
  const profileMember1FieldValues = profileMembers[0].fieldValues;
  const profileMember2FieldValues = profileMembers[1].fieldValues;
  const incomeMember1FieldValues = incomeMembers[0].fieldValues;
  const incomeMember2FieldValues = incomeMembers[1].fieldValues;

  return {
    first: {
      age: profileMember1FieldValues.age,
      wages: incomeMember1FieldValues.wages,
      wageGrowth,
      workState: profileMember1FieldValues.workState,
      name: profileMembers[0].name,
    },
    second: {
      age: profileMember2FieldValues.age,
      wages: incomeMember2FieldValues.wages,
      wageGrowth,
      workState: profileMember2FieldValues.workState,
      name: profileMembers[1].name,
    },
    ...profileCommonFields,
    lifestyle: 'custom',
    otherIncValue: 0,
    otherIncGrowth: 0,
  };
};

export const transformSavingsDetailsReq = (savingsReq) => {
  const {
    savingsCompReq: {
      members: savingCompMembers,
      commonFields: savingsCommonFields,
    },
    incomeCompReq: { members: incomeMembers },
    infoWagesAndExpenseProfile,
  } = savingsReq;
  const savingsMember1FieldValues = savingCompMembers[0].fieldValues;
  const savingsMember2FieldValues = savingCompMembers[1].fieldValues;
  const wagesMember1FieldValues = { ...infoWagesAndExpenseProfile.first };
  const wagesMember2FieldValues = { ...infoWagesAndExpenseProfile.second };
  const incomeMember1FieldValues = incomeMembers[0].fieldValues;
  const incomeMember2FieldValues = incomeMembers[1].fieldValues;
  return {
    first: {
      selfContrib401k: savingsMember1FieldValues.selfContrib401k,
      employerContrib401k: savingsMember1FieldValues.employerContrib401k,
      percentRoth401k: savingsMember1FieldValues.percentRoth401k,
      iraContrib: savingsMember1FieldValues.iraContrib,
      percentRothIra: savingsMember1FieldValues.percentRothIra,
      percentDefferedComp: savingsMember1FieldValues.percentDefferedComp,
      percentPensionGrant: savingsMember1FieldValues.percentPensionGrant,
      percentRsuGrant: incomeMember1FieldValues.percentRsuGrant,
      rsuVestingYrs: incomeMember1FieldValues.rsuVestingYrs,
      ageWhenRsuVestingStarts: wagesMember1FieldValues.age,
    },
    second: {
      selfContrib401k: savingsMember2FieldValues.selfContrib401k,
      employerContrib401k: savingsMember2FieldValues.employerContrib401k,
      percentRoth401k: savingsMember2FieldValues.percentRoth401k,
      iraContrib: savingsMember2FieldValues.iraContrib,
      percentRothIra: savingsMember2FieldValues.percentRothIra,
      percentDefferedComp: savingsMember2FieldValues.percentDefferedComp,
      percentPensionGrant: savingsMember2FieldValues.percentPensionGrant,
      percentRsuGrant: incomeMember2FieldValues.percentRsuGrant,
      rsuVestingYrs: incomeMember2FieldValues.rsuVestingYrs,
      ageWhenRsuVestingStarts: wagesMember2FieldValues.age,
    },
    useSavingsForRetSavings: true,
  };
};

export const getInfoTaxDetailsReq = () => {
  return {
    itemizedDedGrowth: 0,
    itemizedDeduction: 0,
    qualifiedBusinessIncDedGrowth: 0,
    qualifiedBusinessIncDeduction: 0,
  };
};

export const getInfoPreferencesReq = (preferenceObj) => {
  const {
    selectedHorizonTerm,
    selectedDisplayTerm,
    selectedNumberOfRuns,
    action = '',
  } = preferenceObj;
  return {
    emCashReqYrs: 2,
    inflationRate: 0.02,
    youngerAgeAtEstateTransfer: 85,
    // defaultMarketReturn: 0.08,
    // defaultCashReturn: 0.01,
    defaultCashPercentInSavings: 0,
    inflationVol: 0.02,
    // defaultMarketVol: 0.14,
    // defaultCashVol: 0.01,
    runCode: action === 'save' ? 0 : selectedNumberOfRuns,
    horizon: selectedHorizonTerm,
    displayTerm: selectedDisplayTerm,
  };
};

export const getInfoExpenseDetailsReq = (expenseReq) => {
  const {
    expenseCompReq: { members: expenseMembers },
  } = expenseReq;
  const nonHousingFields = expenseMembers[0].fieldValues;
  const RentalFields = expenseMembers[1].fieldValues;
  return {
    expenses: {
      aggNonHousing: nonHousingFields.aggNonHousing,
      rental: RentalFields.rental,
      food: 0,
      restaurants: 0,
      utils: 0,
      phonesAndEnt: 0,
      insurancesNonProp: 0,
      cars: 0,
      travel: 0,
      shoppingAndMisc: 0,
      healthAndFitness: 0,
    },
    disc: {
      rental: false,
      food: false,
      restaurants: true,
      utils: false,
      phonesAndEnt: true,
      insurancesNonProp: false,
      cars: false,
      travel: true,
      shoppingAndMisc: true,
      healthAndFitness: false,
      aggNonHousing: false,
    },
    horFactor: {
      rental: 1,
      food: 1,
      restaurants: 1,
      utils: 1,
      phonesAndEnt: 1,
      insurancesNonProp: 1,
      cars: 1,
      travel: 1,
      shoppingAndMisc: 1,
      healthAndFitness: 1,
      aggNonHousing: 0,
    },
    retFactor: {
      rental: 1,
      food: 1,
      restaurants: 1,
      utils: 1,
      phonesAndEnt: 1,
      insurancesNonProp: 1,
      cars: 1,
      travel: 1,
      shoppingAndMisc: 1,
      healthAndFitness: 1,
      aggNonHousing: 0,
    },
    useAggNonHousingExpense: true,
    useDiscretionaryFlag: true,
    discPercentOfAgg: nonHousingFields.discPercentOfAgg,
    discHorFactor: nonHousingFields.discHorFactor,
    discRetFactor1: nonHousingFields.discRetFactor1,
    discRetFactor2: 1,
    discRetFactor3: 1,
  };
};

export const getKidsDetailsFormReq = (otherGoalsReq) => {
  const {
    otherGoalsCompReq: { members: otherGoalMembers },
    infoPreferences,
  } = otherGoalsReq;
  const kidDetails = otherGoalMembers[1].fieldValues;
  return {
    kids: [
      {
        yearBorn: kidDetails.yearBorn,
        yearBornYCAge: 0,
        annualExpense: kidDetails.annualExpense,
        value529Plan: kidDetails.value529Plan,
        peakReturn529Plan: infoPreferences.defaultMarketReturn,
        useSavingsToBuild529: true,
        collegeAge: 18,
        collegeYears: 4,
        annualCollegeExpenseNow: kidDetails.annualCollegeExpenseNow,
        collegeExpenseGrowth: 0.04,
        useSavingsFor529Deficit: false,
        name: 'kid',
      },
    ],
    numKids: 1,
  };
};

export const getVacationFormState = (otherGoalsReq) => {
  const {
    otherGoalsCompReq: { members: otherGoalsMembers },
  } = otherGoalsReq;
  const vacationDetails = otherGoalsMembers[0].fieldValues;
  return {
    vacations: [
      {
        vacationYear: vacationDetails.vacationYear,
        vacationExpense: vacationDetails.vacationExpense,
        name: 'vacation',
      },
    ],
    numVacations: 1,
  };
};

export const getVehiclesFormState = (assetGoalsReq) => {
  const {
    assetGoalsCompReq: { members: assGoalMembers },
    infoVehicles,
  } = assetGoalsReq;
  const vehicleMember = infoVehicles.vehicles[0];
  const assetVehicleValues = assGoalMembers[1].fieldValues;
  return {
    vehicles: [
      {
        purchasePrice: assetVehicleValues.purchasePrice,
        loanAmount: assetVehicleValues.loanAmount,
        balance: vehicleMember.loanAmount,
        termMonths: assetVehicleValues.termMonths,
        apr: assetVehicleValues.apr,
        interestStartDate: vehicleMember.paymentStartDate,
        paymentStartDate: assetVehicleValues.paymentStartDate,
        monthlyPayment: 0,
        compounding: 'Annually',
        value: vehicleMember.purchasePrice,
        deprYrs: 10,
        name: 'car',
      },
    ],
    numVehicles: 1,
  };
};

export const getCreditCardsFormState = (creditCardsReq) => {
  const {
    debtCompReq: { members: debtCompMembers },
  } = creditCardsReq;
  const creditCardDetails = debtCompMembers[0].fieldValues;
  return {
    creditCards: [
      {
        balance: creditCardDetails.balance,
        apr: creditCardDetails.apr,
        monthlyPayment: creditCardDetails.monthlyPayment,
        monthlySpend: creditCardDetails.monthlySpend,
        limit: 0,
        gracePeriod: 0,
        compounding: 'Annually',
        name: 'cc',
      },
    ],
    numCC: 1,
  };
};

export const getStudentLoansFormState = (creditCardsReq) => {
  const {
    debtCompReq: { members: debtCompMembers },
    infoStudentLoans: { studentLoans },
  } = creditCardsReq;
  const studentLoanReq = debtCompMembers[1].fieldValues;
  return {
    studentLoans: [
      {
        loanAmount: studentLoanReq.loanAmount,
        balance: studentLoanReq.balance,
        termMonths: studentLoanReq.termMonths,
        apr: studentLoanReq.apr,
        interestStartDate: studentLoans[0].paymentStartDate,
        paymentStartDate: studentLoanReq.paymentStartDate,
        monthlyPayment: studentLoanReq.monthlyPayment,
        compounding: 'Monthly',
        name: 'SL',
      },
    ],
    numSL: 1,
  };
};

export const getOtherLoansFormState = (creditCardsReq) => {
  const {
    debtCompReq: { members: debtCompMembers },
    infoOtherLoans: { otherLoans },
  } = creditCardsReq;
  const otherLoansReq = debtCompMembers[2].fieldValues;
  return {
    otherLoans: [
      {
        loanAmount: otherLoansReq.loanAmount,
        balance: otherLoansReq.balance,
        termMonths: otherLoansReq.termMonths,
        apr: otherLoansReq.apr,
        interestStartDate: otherLoans[0].paymentStartDate,
        paymentStartDate: otherLoansReq.paymentStartDate,
        monthlyPayment: otherLoansReq.monthlyPayment,
        compounding: 'Daily',
        name: 'Wells',
      },
    ],
    numOL: 1,
  };
};

export const getInvestmentBalancesFormState = (investReq) => {
  const {
    investmentCompReq: { members: invMembers },
  } = investReq;
  const firstMemberValues = invMembers[0].fieldValues;
  const secondMembervalues = invMembers[1].fieldValues;
  return {
    first: {
      trad401k: firstMemberValues.trad401k,
      roth401k: firstMemberValues.roth401k,
      tradIra: firstMemberValues.tradIra,
      rothIra: firstMemberValues.rothIra,
      brokerage: firstMemberValues.brokerage,
      divFolio: 0,
      disposableCash: 0,
      emCash: firstMemberValues.emCash,
      deferredComp: firstMemberValues.deferredComp,
      pension: firstMemberValues.pension,
      rsu: firstMemberValues.rsu,
    },
    second: {
      trad401k: secondMembervalues.trad401k,
      roth401k: secondMembervalues.roth401k,
      tradIra: secondMembervalues.tradIra,
      rothIra: secondMembervalues.rothIra,
      brokerage: secondMembervalues.brokerage,
      divFolio: 0,
      disposableCash: 0,
      emCash: secondMembervalues.emCash,
      deferredComp: secondMembervalues.deferredComp,
      pension: secondMembervalues.pension,
      rsu: secondMembervalues.rsu,
    },
    getMarketData: false,
    invValueAsOf: 'BoY',
  };
};

export const getInvestmentReturnsFormState = (infoPreferences) => {
  const { defaultMarketReturn } = infoPreferences;
  return {
    first: {
      trad401kReturn: defaultMarketReturn,
      roth401kReturn: defaultMarketReturn,
      tradIraReturn: defaultMarketReturn,
      rothIraReturn: defaultMarketReturn,
      brokerageReturn: defaultMarketReturn,
      divFolioReturn: defaultMarketReturn,
      disposableCashReturn: 0.01,
      emCashReturn: 0.01,
      defCompReturn: 0,
      pensionPlanReturn: 0.04,
      rsuReturn: defaultMarketReturn,
    },
    second: {
      trad401kReturn: defaultMarketReturn,
      roth401kReturn: defaultMarketReturn,
      tradIraReturn: defaultMarketReturn,
      rothIraReturn: defaultMarketReturn,
      brokerageReturn: defaultMarketReturn,
      divFolioReturn: defaultMarketReturn,
      disposableCashReturn: defaultMarketReturn,
      emCashReturn: defaultMarketReturn,
      defCompReturn: defaultMarketReturn,
      pensionPlanReturn: defaultMarketReturn,
      rsuReturn: defaultMarketReturn,
    },
  };
};

export const getInvestmentVolatilityFormState = (infoPreferences) => {
  const { defaultMarketVol } = infoPreferences;
  return {
    first: {
      trad401k: defaultMarketVol,
      roth401k: defaultMarketVol,
      tradIra: defaultMarketVol,
      rothIra: defaultMarketVol,
      brokerage: defaultMarketVol,
      divFolio: defaultMarketVol,
      disposableCash: defaultMarketVol,
      emCash: defaultMarketVol,
      defComp: defaultMarketVol,
      pensionPlan: defaultMarketVol,
      rsu: defaultMarketVol,
    },
    second: {
      trad401k: defaultMarketVol,
      roth401k: defaultMarketVol,
      tradIra: defaultMarketVol,
      rothIra: defaultMarketVol,
      brokerage: defaultMarketVol,
      divFolio: defaultMarketVol,
      disposableCash: defaultMarketVol,
      emCash: defaultMarketVol,
      defComp: defaultMarketVol,
      pensionPlan: defaultMarketVol,
      rsu: defaultMarketVol,
    },
  };
};

export const getInsuranceTermLifeFormState = (termLifeReq) => {
  const {
    insuranceCompReq: { members: insuranceMemebers },
  } = termLifeReq;
  const termLifeMember = insuranceMemebers[0].fieldValues;
  return {
    termLifeIns: [
      {
        insured: 'member-1',
        coverageStartYr: termLifeMember.coverageStartYr,
        coverage: termLifeMember.coverage,
        deductible: 0,
        premium: termLifeMember.premium,
        coverageYrs: termLifeMember.coverageYrs,
        cashValueAnnual: 0,
        cashValueDistributionYrs: 0,
        renewAtExpiry: true,
        premiumGrowth: 0,
        name: 'TLI-1',
      },
    ],
    numTermLifeIns: 1,
  };
};

export const getInsuranceWholeLifeFormState = (termLifeReq) => {
  const {
    insuranceCompReq: { members: insuranceMemebers },
  } = termLifeReq;
  const wholeLifeMember = insuranceMemebers[1].fieldValues;
  return {
    wholeLifeIns: [
      {
        insured: 'member-1',
        coverageStartYr: wholeLifeMember.coverageStartYr,
        coverage: wholeLifeMember.coverage,
        deductible: 0,
        premium: wholeLifeMember.premium,
        coverageYrs: wholeLifeMember.coverageYrs,
        cashValueAnnual: wholeLifeMember.cashValueAnnual,
        cashValueDistributionYrs: wholeLifeMember.cashValueDistributionYrs,
        renewAtExpiry: true,
        premiumGrowth: 0,
        name: 'WLI-1',
      },
    ],
    numWholeLifeIns: 1,
  };
};

export const getLiabilityOrUmbrellaFormState = () => {
  return {
    umbrellaIns: [
      {
        insured: 'umbrella',
        coverageStartYr: 2025,
        coverage: 0,
        deductible: 0,
        premium: 0,
        coverageYrs: 1,
        cashValueAnnual: 0,
        cashValueDistributionYrs: 0,
        renewAtExpiry: true,
        premiumGrowth: 0,
        name: 'LI-1',
      },
    ],
    numUmbrellaIns: 1,
  };
};

export const getPropertyCovergeFormState = () => {
  return {
    propIns: [
      {
        insured: 'kennedy',
        coverageStartYr: 2025,
        coverage: 0,
        deductible: 0,
        premium: 0,
        coverageYrs: 1,
        cashValueAnnual: 0,
        cashValueDistributionYrs: 0,
        renewAtExpiry: true,
        premiumGrowth: 0,
        name: 'PI-1',
      },
    ],
    numPropIns: 1,
  };
};

export const getPrimaryHomeFormState = (assetCompReq) => {
  const {
    assetGoalsCompReq: { members: homeMembers },
    infoPreferences,
    infoWagesAndExpenseProfile: { residenceState },
    infoPrimaryHome: { primaryHome },
  } = assetCompReq;
  const primaryHomeMember = homeMembers[0].fieldValues;
  return {
    primaryHome: {
      propDomicile: residenceState,
      purchaseDate: primaryHomeMember.purchaseDate,
      purchasePrice: primaryHomeMember.purchasePrice,
      appreciationRate: primaryHomeMember.appreciationRate,
      aggMaintenanceExp: primaryHomeMember.aggMaintenanceExp,
      aggMaintenanceExpGrowth: infoPreferences.inflationRate,
      origOrRefiLoanDate: primaryHome.purchaseDate,
      origOrRefiLoanAmount: primaryHomeMember.origOrRefiLoanAmount,
      loanAmountOutstanding: primaryHome.origOrRefiLoanAmount,
      loanTerm: primaryHomeMember.loanTerm,
      fixedIntRate: primaryHomeMember.fixedIntRate,
      taxBasis: primaryHome.purchasePrice,
      taxBasisGrowth: primaryHome.appreciationRate,
      taxRate: infoPreferences.inflationRate,
      value: primaryHome.purchasePrice,
      name: 'home',
    },
  };
};

export const getSecondaryHomeFormState = (assetCompReq) => {
  return {
    secondHomes: [
      {
        propDomicile: '',
        purchaseDate: '2035-12-26',
        purchasePrice: 0,
        appreciationRate: 0,
        aggMaintenanceExp: 0,
        aggMaintenanceExpGrowth: 0,
        origOrRefiLoanDate: '2035-12-26',
        origOrRefiLoanAmount: 0,
        loanAmountOutstanding: 0,
        loanTerm: 30,
        fixedIntRate: 0,
        taxBasis: 0,
        taxBasisGrowth: 0,
        taxRate: 0,
        value: 0,
        name: 'dream',
      },
    ],
    numSecondHomes: 1,
  };
};

export const getInvestmentPropertiesFormState = () => {
  return {
    invProp: [
      {
        annualRent: 0,
        annualRentGrowth: 0,
        priorDeprAccumulated: 0,
        prop: {
          propDomicile: 'CA',
          purchaseDate: '2029-12-04',
          purchasePrice: 0,
          appreciationRate: 0.02,
          aggMaintenanceExp: 0,
          aggMaintenanceExpGrowth: 0.02,
          origOrRefiLoanDate: '2029-12-04',
          origOrRefiLoanAmount: 0,
          loanAmountOutstanding: 0,
          loanTerm: 30,
          fixedIntRate: 0.035,
          taxBasis: 0,
          taxBasisGrowth: 0,
          taxRate: 0,
          value: 0,
          name: 'aspiration',
        },
      },
    ],
    numInvProps: 1,
  };
};

export const getRetirementChoicesFormState = (retirementReq) => {
  const {
    retirementCompReq: { members: retMembers, commonFields },
    infoPreferences,
  } = retirementReq;
  const firstMemberFields = retMembers[0].fieldValues;
  const secMemberFields = retMembers[1].fieldValues;

  return {
    first: {
      retAge: firstMemberFields.retAge,
      socialSecAge: firstMemberFields.socialSecAge,
      socialSecAmount: firstMemberFields.socialSecAmount,
      defCompWDYrs: 1,
      pensionWDYrs: 1,
    },
    second: {
      retAge: secMemberFields.retAge,
      socialSecAge: secMemberFields.socialSecAge,
      socialSecAmount: secMemberFields.socialSecAmount,
      defCompWDYrs: 1,
      pensionWDYrs: 1,
    },
    divRate: infoPreferences.defaultCashReturn,
    emCashPercentForDiv: 0.5,
    withdrawRothFirst: true,
    youngerAgeAtDivWD: 66,
    retirementState: commonFields.retirementState,
  };
};

export const getRothVsTraditionalFormState = () => {
  return {
    first: {
      tradIraToRothPercent: 0,
      trad401kToRothPercentNow: 0,
      trad401kToRothPercentAtRet: 0,
    },
    second: {
      tradIraToRothPercent: 0,
      trad401kToRothPercentNow: 0,
      trad401kToRothPercentAtRet: 0,
    },
  };
};

export const getEstateTransferFormState = (estateReq) => {
  const { infoWagesAndExpenseProfile, infoPreferences } = estateReq;
  return {
    agiAtInheritance: 700000,
    agiGrowth: infoPreferences.inflationRate,
    taxState: infoWagesAndExpenseProfile.residenceState,
  };
};

export const infoInputsCompleted = {
  creditCards: true,
  estate: true,
  expenseDetails: true,
  invBalances: true,
  invProps: true,
  invReturns: true,
  kids: true,
  umbrellaIns: true,
  otherIncome: true,
  otherLoans: true,
  planBasics: true,
  preferences: true,
  primaryHome: true,
  propertyIns: true,
  retChoices: true,
  retSavingsRates: true,
  rothVsTrad: true,
  secondHomes: true,
  studentLoans: true,
  taxDeductions: true,
  termLifeIns: true,
  vacation: true,
  vehicles: true,
  wholeLifeIns: true,
  invVolatility: true,
};

export const infoModulesSubscribed = {
  debt: true,
  estate: true,
  insurance: true,
  investments: true,
  lifeEvents: true,
  lifestyle: true,
  realEstate: true,
  retirement: true,
};

export const getAuditlogs = (userDetails) => {
  const { name, emailId } = userDetails;
  return {
    createdBy: name,
    createdDate: getCurrentDateTime(),
    modifiedBy: name,
    modifiedDate: getCurrentDateTime(),
    userid: emailId,
  };
};
