export const COLUMN_CONFIG_REPORTS = [4, 4, 4];

export const REPORT_OPTIONS = [
  {
    label: 'Charts',
    value: 'charts',
  },
  {
    label: 'Chrono',
    value: 'chrono',
  },
  { label: 'Dashboard', value: 'dashboard' },
  { label: 'Before After Tile', value: 'tiles' },
  { label: 'Pinned Charts', value: 'pinnedCharts' },
  { label: 'Plan Highlights', value: 'planHighlights' },
  { label: 'Notes', value: 'notes' },
];

export const componentToITemMapping = {
  dashboard: 'DashboardComponent',
  chrono: 'DashboardComponent',
  charts: 'ChartComponent',
  tiles: 'BeforeAfterTilesComponent',
  pinnedCharts: 'ChartComponent',
  planHighlights: 'PlanHighlightsComponent',
  notes: 'NotesComponent',
};

// export const reportInitialData = {
//   0: [
//     {
//       id: 1,
//       selectedItemLabel: '',
//       selectedItem: '',
//       selectedSubItem: '',
//       selectedSubItemLabel: '',
//       componentName: '',
//       componentData: '',
//       chronoSubItem: '',
//       itemChosen: false,
//       subItemOptions: [],
//     },
//   ],
// };

export const reportInitialData = [
  {
    advisorId: '',
    templateName: '',
    0: {
      0: [
        {
          id: 0,
          selectedItemLabel: '',
          selectedItem: '',
          selectedSubItem: '',
          selectedSubItemLabel: '',
          componentName: '',
          componentData: '',
          chronoSubItem: '',
          itemChosen: false,
          subItemOptions: [],
        },
      ],
    },
  },
];

export const newColInitialData = {
  id: 0,
  selectedItemLabel: '',
  selectedItem: '',
  selectedSubItem: '',
  selectedSubItemLabel: '',
  componentName: '',
  componentData: '',
  chronoSubItem: '',
  itemChosen: false,
  subItemOptions: [],
};
