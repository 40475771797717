import React, { useEffect, useState } from 'react';
import {
  renderInputRow,
  renderSelectOption,
  transformEmergencyFundsBackToUIType,
} from '../../helpers/ClientHelper';

const EmergencyFundsComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const { moduleSettings: { emergencyFunds = {} } = {} } =
    localPlanDetailsByPlanId;
  const [emergencyFundsDetails, setEmergencyFundsDetails] =
    useState(emergencyFunds);

  useEffect(() => {
    if (emergencyFunds && Object.keys(emergencyFunds).length > 0) {
      let tempEmergencyFunds =
        transformEmergencyFundsBackToUIType(emergencyFunds);
      setEmergencyFundsDetails(tempEmergencyFunds);
    }
  }, [localPlanDetailsByPlanId, emergencyFunds]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlEmCashReqYrs',
          'emCashReqYrs',
          'How many years worth of expenses would you like to keep as emergency funds?',
          'number',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlYoungerAgeAtDivWD',
          'youngerAgeAtDivWD',
          'At what age (younger persons) would you like to start withdrawing dividends?',
          'number',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlEmCashPercentForDiv',
          'emCashPercentForDiv',
          'What % of Emergency Funds would you like to connvert into additional dividend portfolio',
          'percentage',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlDivRate',
          'divRate',
          'What is your projected dividend rate for such connverted funds?',
          'percentage',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderSelectOption(
          'text',
          'ddlIsDivFolioEmFunds',
          'isDivFolioEmFunds',
          'Consider dividend portfolio assets for drawdown after liquidating properties?',
          booleanOptions,
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlDeficitAsPercentOfExpForPropLiquidation',
          'deficitAsPercentOfExpForPropLiquidation',
          'Liquidate property when deficit exceeds this percent of expenses',
          'percentage',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
      </div>
    </>
  );
};

export default EmergencyFundsComponent;
