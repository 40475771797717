import React, { Component } from 'react';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import timeline from 'highcharts/modules/timeline.js';
timeline(Highcharts);

export default class TimelineChartContainer extends Component {
  constructor(props) {
    super(props);
    // Create a ref to store the chart instance
    this.chartRef = React.createRef();
  }

  getChart = (chart) => {
    this.chartRef.current = chart;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.chartRef.current) {
      this.chartRef.current.reflow();
    }
  }

  render() {
    const { options } = this.props;
    return (
      <HighchartsReact
        id="timeline"
        highcharts={Highcharts}
        options={options}
        callback={this.getChart}
      />
    );
  }
}
