import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Controls from '../controls/Controls';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isEqual } from 'lodash';
import '../../styles/advisorhome.scss';
import { selectedClientIdSelector } from '../../selectors/clientsInfoSelector';
import {
  getCompanyAssetUnderManagement,
  getCompanyAsserUnderManagementLoading,
  getCompanyInvestmentAllocationLoading,
  getCompanyInvestmentAllocation,
  getInvMarketCorrelationsByCompanyNmSelector,
  getInvMarketExpectationsByCompanyNameSelector,
} from '../../selectors/businessInfoSelector';
import {
  selectedPlanIdForClientIdSelector,
  planDetailsByPlanIdSelector,
  allPlannerMenuItemsSelector,
  selectedChartSelector,
  getNumberOfRuns,
  allTaxSlabsSelector,
} from '../../selectors/plannerDetailsSelector';
import {
  getPlansDetailsAction,
  simulatePlanDetailsByPlanIdSuccessAction,
  getInvAssetAllocationsByAdvisorIdAction,
} from '../../redux/actions/plannerDetailsAction';
import {
  getClientsByAdvisorIdAction,
  getAdvisorAssetsUnderManagementCompanyAUMAction,
  getClientsNotUpdatedForNumDaysAction,
  setAllocationIdAction,
  getAdvisorAssetsUnderManagementAction,
  clearAdvisorAssetDetailsAction,
} from '../../redux/actions/clientsInfoAction';
import {
  getCompanyAssetsUnderManagementAction,
  getInvAssetAllocationsByCompanyNameAction,
  getInvMarketCorrelationsByCmpnyNmAction,
  getInvMarketExpectationsByCompanyyNameAction,
} from '../../redux/actions/businessInfoAction';
import {
  getPlansByClientIdSuccessAction,
  setClientViewSelected,
  setSelectedDisplayTermAction,
  setSelectedHorizonTermAction,
  updateUserMobileAction,
  getUserInformationByEmailIdAction,
} from '../../redux/actions/plansAction';
import ConfirmModal from '../shared/ConfirmModal';
import { getCurrentDateTime } from '../../utils/apirequestutil';
import { createNewClient, sendEmail } from '../../services/dashboardSvc';
import DashboardSummaryTabsContainer from '../DashboardSummaryTabsContainer';
import {
  getOutputResponseByPlanIdAction,
  clearOutputResponseAction,
} from '../../redux/actions/outputResponseDetailsAction';
import { USERTYPES } from '../../constants/common';
import { getNewAdvisorMailBody } from '../../templates/utils';
import EditIcon from '@mui/icons-material/Edit';
import {
  outputResponseDashboardSummaryDetailsByPlanIdSelector,
  outputResponseLoadingSelector,
  outputResponseFAQsSelector,
  outputResponseGoalsSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponsePlannerDetailsByPlanIdSelector,
  outputResponseChronoDetailsByPlanIdSelector,
  outputResponsePlanHighlightsSelector,
  outputResponseAdvisorHomeSelector,
  isOutputResponseDataAvailable,
} from '../../selectors/outputResponseDetailsSelector';
import { assetMixListValues } from '../../constants/dashboard';
import {
  setVariblePieChartOptions,
  setSpiderChartOptions,
  setRadialCircularChartOptions,
  setTimelineChartOptions,
} from '../shared/ChartData';
import Stack from '@mui/material/Stack';
import history from '../../history';
import { orange, yellow, green, red, grey } from '@mui/material/colors';
import save from '../../images/save.svg';
import { format } from 'date-fns';
import { transformTaxStateOptions } from '../../redux/transformers/apiToClientResponseTransformer';
import '../../styles/controls/dialog.scss';
import {
  validatePhoneNumber,
  validateEmailId,
  formatPhoneNumber,
  validateNamesWithoutSpaces,
} from '../../utils/validators';
import Alert from '@mui/material/Alert';
import {
  percentageFields,
  NewAdvisorFields,
} from '../../constants/CompanyHome';
import FirstCardComponent from './FirstCardComponent';
import SecondCardComponent from './SecondCardComponent';
import AssetsFirstRowSection from './AssetsCardDetailComponent';
import AdvisorReviewsFirstRowSection from './AdvisorCardDetailComponent';
import {
  getAllAdvisorsByCompanyName,
  getACompanyAllAdvisorsByState,
  updateInvMarketCorrelationsByCompanyNm,
  updateInvMarketExpectationsByCompanyName,
  updateInvAssetAllocationsByRiskLevelByCompanyName,
  crecreateAdvisorAndAddToCompany,
} from '../../services/businessDashboardSvc';
import { capitalizeFirstLetter } from './utils';

const AntSwitch = styled(Switch)(({ theme, dual }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : dual
        ? '#225ea8'
        : 'grey',
    boxSizing: 'border-box',
  },
}));

class CompanyHome extends Component {
  marketValueField = React.createRef();
  assetMixField = React.createRef();
  correlationValueField = React.createRef();

  state = {
    advisorOnboardingModalOpen: false,
    planOnboardingModalOpen: false,
    secondPerson: '',
    newPlanName: '',
    clientEmailId: '',
    openSnackbar: false,
    isApiFailed: false,
    openPlanSnackbar: false,
    isLoading: false,
    selectedClientType: '',
    selectedClientState: '',
    advisorStatsClientType: [],
    marketProfileRequest: {},
    isFormValid: true,
    derivedInvMarketExpectations: [],
    isInvMarketSnackbarOpen: false,
    newAdvisorNotification: false,
    activeCard: 'assets',
    timeLineChecked: true,
    isRecommendedRiskLevelChecked: true,
    allClientAgeStats: true,
    allAdvisorAgeStats: true,
    localMeetingDetail: {},
    localClientDetail: {},
    backUpLocalClientDetail: {},
    loading: false,
    isEditable: false,
    isAddMeeting: false,
    isDirectUser: false,
    isClientUser: false,
    isUserTypeSet: false,
    newAdvisorAddition: false,
    newAdvisorMailId: '',
    isSimulateCalled: false,
    recommendedRiskLevels: [],
    selectedValue: '',

    isMarketForecastChecked: true,
    derivedInvMarketCorrelations: [],
    selectedAdvisorId: '',
    allAdvisorsList: [],
    advisorSelectedState: '',
    advsiorStateOptions: [],
    isUpdateInvMarketCorrelationsLoading: false,
    ...this.setNewAdvisorFormFieldsInitialState(),
  };

  setNewAdvisorFormFieldsInitialState() {
    const newAdvisorDetails = NewAdvisorFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.fieldKey]: '',
        [`is${capitalizeFirstLetter(curr.fieldKey)}Valid`]: true,
      };
    }, {});
    this.setState({
      newAdvisorDetails,
    });
  }

  handleChange2 = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  arraymove = (arr) => {
    const fromIndex = arr.map((a) => a[0]).indexOf('inflation');
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(0, 0, element);
    return arr;
  };

  derivedInvMarketExpectationsByCompanyName = () => {
    const { invMarketExpectationsByCompanyName } = this.props;
    var excludedItems = ['advisorId', 'productId', 'companyName'];
    let derivedInvMarketExpectations =
      (
        invMarketExpectationsByCompanyName &&
        Object.entries(invMarketExpectationsByCompanyName)
      ).filter((f) => excludedItems.indexOf(f[0]) === -1) || [];
    derivedInvMarketExpectations = derivedInvMarketExpectations.map((value) => {
      let newInvReturnPercentValue = value[1] && value[1].invReturn;
      let newInvVolatilityPercentValue = value[1] && value[1].invVolatility;
      let newInvDividendPercentValue = value[1] && value[1].invDividend;
      if (newInvReturnPercentValue.toString().includes('%')) {
        newInvReturnPercentValue = parseFloat(newInvReturnPercentValue) / 100;
      }
      newInvReturnPercentValue = parseFloat(
        parseFloat(newInvReturnPercentValue) * 100
      ).toFixed(1);
      if (newInvVolatilityPercentValue.toString().includes('%')) {
        newInvVolatilityPercentValue =
          parseFloat(newInvVolatilityPercentValue) / 100;
      }
      newInvVolatilityPercentValue = parseFloat(
        parseFloat(newInvVolatilityPercentValue) * 100
      ).toFixed(1);
      if (newInvDividendPercentValue.toString().includes('%')) {
        newInvDividendPercentValue =
          parseFloat(newInvDividendPercentValue) / 100;
      }
      newInvDividendPercentValue = parseFloat(
        parseFloat(newInvDividendPercentValue) * 100
      ).toFixed(1);
      const newInvReturnValue = `${newInvReturnPercentValue}`;
      const newInvVolatilityValue = `${newInvVolatilityPercentValue}`;
      const newInvDividendValue = `${newInvDividendPercentValue}`;
      return {
        ...value,
        1: {
          ...value[1],
          invReturn: newInvReturnValue,
          invVolatility: newInvVolatilityValue,
          invDividend: newInvDividendValue,
        },
      };
    });
    return derivedInvMarketExpectations.length > 0
      ? this.arraymove(derivedInvMarketExpectations)
      : [];
  };

  componentDidMount() {
    const {
      userDetails: { companyName },
      companyAssetsUnderManagement,
      companyAUM,
      triggerGetCompanyAssetsUnderManagement,
      invMarketExpectationsByCompanyName,
      triggerGetInvMarketExpectationsByCompanyName,
      triggerGetInvAssetAllocationsByCmpnyNmAction,
      triggerGetInvMarketCorrelationsByCompanyName,
    } = this.props;
    const { selectedAdvisorId } = this.state;
    if (
      invMarketExpectationsByCompanyName &&
      Object.keys(invMarketExpectationsByCompanyName).length === 0
    ) {
      triggerGetInvMarketExpectationsByCompanyName(companyName);
    } else {
      const derivedInvMarketExpectations =
        this.derivedInvMarketExpectationsByCompanyName();
      this.setState({ derivedInvMarketExpectations });
    }
    if (
      companyAssetsUnderManagement &&
      Object.keys(companyAssetsUnderManagement).length === 0 &&
      companyName
    ) {
      triggerGetCompanyAssetsUnderManagement(companyName);
    }
    if (companyAUM && Object.keys(companyAUM).length === 0) {
      triggerGetCompanyAssetsUnderManagement(companyName);
    }

    triggerGetInvAssetAllocationsByCmpnyNmAction(companyName);
    triggerGetInvMarketCorrelationsByCompanyName(companyName);

    if (selectedAdvisorId) {
      this.setState({ activeCard: 'advisor' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      alltaxSlabs,
      invMarketExpectationsByCompanyName,

      companyInvAssetAllocations,
      invMarketCorrelations,
      triggerClearAdvisorAssetDetails,
      triggerGetAdvisorAssetsUnderManagement,
    } = this.props;
    const { recommendedRiskLevels, selectedAdvisorId, advisorSelectedState } =
      this.state;
    const {
      selectedAdvisorId: prevSelectedAdvisorId,
      advisorSelectedState: prevAdvisorSelectedState,
    } = prevState;
    const {
      invMarketExpectationsByCompanyName:
        prevInvMarketExpectationsByCompanyName,
      alltaxSlabs: prevAlltaxSlabs,
      invMarketCorrelations: prevInvMarketCorrelations,
    } = prevProps;
    if (
      companyInvAssetAllocations.length > 0 &&
      recommendedRiskLevels.length === 0
      // &&
      // !isEqual(invAssetAllocations, prevInvAssetAllocations)
    ) {
      const tempRecommendedRiskLevels =
        companyInvAssetAllocations.map((m) => {
          return {
            riskAppetite: m.riskAppetite,
            productId: m.productId,
            riskLevel: m.riskLevel,
          };
        }) || [];
      const selectedRecommendedRiskAppetite =
        tempRecommendedRiskLevels.length > 0
          ? tempRecommendedRiskLevels[0].productId
          : '';
      this.deriveRecommendedRiskLevelValues(selectedRecommendedRiskAppetite);
      this.setState({
        recommendedRiskLevels: tempRecommendedRiskLevels,
        selectedRecommendedRiskAppetite,
      });
    }
    if (selectedAdvisorId && selectedAdvisorId !== prevSelectedAdvisorId) {
      triggerGetAdvisorAssetsUnderManagement(selectedAdvisorId);
    }

    if (
      !isEqual(
        prevInvMarketExpectationsByCompanyName,
        invMarketExpectationsByCompanyName
      )
    ) {
      const derivedInvMarketExpectations =
        this.derivedInvMarketExpectationsByCompanyName();
      this.setState({ derivedInvMarketExpectations });
    }
    if (!isEqual(invMarketCorrelations, prevInvMarketCorrelations)) {
      const derivedInvMarketCorrelations =
        this.deriveInvMarketCorrelationsByAdvisorId();
      if (
        derivedInvMarketCorrelations &&
        derivedInvMarketCorrelations.length > 0
      ) {
        const selectedCorrelation = derivedInvMarketCorrelations[0]?.value;
        const correlationInfo = derivedInvMarketCorrelations.find(
          (m) => m.value === selectedCorrelation
        );
        this.setState({
          derivedInvMarketCorrelations,
          selectedCorrelation,
          correlationInfo,
        });
      }
    }
    if (!isEqual(alltaxSlabs, prevAlltaxSlabs)) {
      const taxStateVals = transformTaxStateOptions(alltaxSlabs);
      this.setAllAdvisorStateOPtions(taxStateVals);
    }
    if (advisorSelectedState !== prevAdvisorSelectedState) {
      this.triggerGetAllByCompanyAdvisorsByState(advisorSelectedState);
      triggerClearAdvisorAssetDetails();
    }
  }

  setAllAdvisorStateOPtions = (taxStateVals) => {
    const newAdvisorStateOptions = [...taxStateVals];
    if (
      newAdvisorStateOptions.filter(
        (stateOption) => stateOption.value === 'All'
      ).length <= 0
    ) {
      newAdvisorStateOptions.splice(0, 0, {
        label: 'All locations',
        value: 'All',
      });
    }

    this.setState({
      advsiorStateOptions: newAdvisorStateOptions,
      advisorSelectedState:
        newAdvisorStateOptions && newAdvisorStateOptions.length > 0
          ? newAdvisorStateOptions[0].value
          : '',
    });
  };

  deriveInvMarketCorrelationsByAdvisorId = () => {
    const { invMarketCorrelations } = this.props;
    var excludedItems = ['advisorId', 'productId', 'companyName'];
    let derivedInvMarketCorrelations =
      (invMarketCorrelations && Object.entries(invMarketCorrelations)).filter(
        (f) => excludedItems.indexOf(f[0]) === -1
      ) || [];
    const dropdownData = derivedInvMarketCorrelations.map(([key, value]) => {
      return {
        label: value.assetClass, // Label for dropdown (e.g., "Stock", "Bond")
        value: key, // Value for the dropdown option (e.g., "stock", "bond")
        details: Object.entries(value)
          .map(([subKey, subValue]) => {
            return {
              subAssetClass: subValue.assetClass,
              corrCoeff: subValue.corrCoeff,
              subKey: subKey,
            };
          })
          .filter((item) => item.subAssetClass), // Filter out any undefined or irrelevant entries
      };
    });
    return dropdownData;
  };

  handleAdvisorOnboardingModalOpen = (val) => {
    const { allMenuItems: { clientTypeMenuItems = [] } = {} } = this.props;
    this.setState({
      advisorOnboardingModalOpen: val,
    });
    this.setNewAdvisorFormFieldsInitialState();
  };

  validateEmailId = (value) => {
    return validateEmailId(value);
  };

  formatPhoneNumber = (value) => {
    return formatPhoneNumber(value);
  };

  validatePhoneNumber = (value) => {
    return validatePhoneNumber(value);
  };

  handleChange = (e, field) => {
    const { value = '', name = '' } = e.target;
    const { newAdvisorDetails } = this.state;
    let updatedAdvisorFields = { ...newAdvisorDetails };
    let newValue = value;
    let isValid = value.length > 0;
    const { validator, formatter } = NewAdvisorFields.find(
      (advsiorField) => advsiorField.fieldKey === name
    );
    if (formatter && typeof this[formatter] === 'function') {
      newValue = this[formatter](value);
    }
    if (validator && typeof this[validator] === 'function') {
      isValid = this[validator](newValue);
    }
    updatedAdvisorFields = {
      ...updatedAdvisorFields,
      [name]: newValue,
      [`is${capitalizeFirstLetter(name)}Valid`]: isValid,
    };
    this.setState({
      newAdvisorDetails: updatedAdvisorFields,
    });
  };

  setClientAlert = (e) => {
    this.setState({
      newAdvisorNotification: e.target.checked,
    });
  };

  renderNewAdvisorFields = (field) => {
    let { errorText } = field;
    const { type, fieldKey, label, placeHolder } = field;
    const { newAdvisorDetails } = this.state;
    const { alltaxSlabs } = this.props;
    const fieldValue = newAdvisorDetails[fieldKey];
    const isFieldValid =
      newAdvisorDetails[`is${capitalizeFirstLetter(fieldKey)}Valid`];
    const taxStateVals = transformTaxStateOptions(alltaxSlabs);
    if (fieldKey === 'advisorId') {
      errorText = !fieldValue ? errorText : 'Invalid Email ID';
    }
    if (fieldKey === 'mobileNumber') {
      errorText = !fieldValue ? errorText : 'Invalid Mobile Number';
    }
    switch (type) {
      case 'text': {
        return (
          <Controls.Input
            name={fieldKey}
            label={label}
            value={fieldValue}
            type="text"
            onChange={(event) => this.handleChange(event, fieldKey)}
            errorText={errorText}
            isValid={isFieldValid}
            required
            placeholder={placeHolder}
          />
        );
      }
      case 'select': {
        let { options } = field;
        if (fieldKey === 'stateCode') options = taxStateVals || [];
        return (
          <Controls.Select
            name={fieldKey}
            label={label}
            value={fieldValue}
            options={options}
            onChange={this.handleChange}
            required
          />
        );
      }
      default:
        return null;
    }
  };

  renderNewAdvisorOnboardingDetails = () => {
    const { newAdvisorNotification } = this.state;

    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">Add New Advisor</span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => this.handleAdvisorOnboardingModalOpen(false)}
          />
        </div>
        <div className="signup-body-container">
          <Grid container className="newplan-form-grid" spacing={2}>
            {NewAdvisorFields.map((field, index) => (
              <Grid item xs={6} md={6} lg={6} key={index}>
                {this.renderNewAdvisorFields(field)}
              </Grid>
            ))}
            <Grid item xs={6} md={6} lg={6} className="plan-dropdown"></Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={newAdvisorNotification}
                    onChange={this.setClientAlert}
                    dual={false}
                  />
                }
                label={`Send client's login information to client through email ?`}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  handleAddNewAdvisor = async () => {
    const {
      newAdvisorDetails: {
        advisorId,
        advisorName,
        mobileNumber,
        stateCode,
        advisorType,
      },
      newAdvisorNotification,
    } = this.state;
    const { userDetails: loggedInUserDetails = {} } = this.props;
    const { emailId, name, companyName } = loggedInUserDetails;
    this.setState({ isLoading: true, advisorOnboardingModalOpen: false });

    let reqObj = {
      advisorId,
      advisorName,
      mobileNumber,
      stateCode,
      advisorType,
      companyName,
    };
    try {
      const [firstResponse] = await Promise.all([
        crecreateAdvisorAndAddToCompany(reqObj),
      ]);
      if (firstResponse && firstResponse.status === 200) {
        this.setState({
          newAdvisorAddition: true,
        });
        if (newAdvisorNotification) {
          const mailData = {
            emailId: advisorId.toLowerCase(),
            clientName: advisorName,
            advisorName: advisorName,
            siteDomain: window.location.origin,
            companyName,
          };
          const reqPayload = {
            createdDate: getCurrentDateTime(),
            message: getNewAdvisorMailBody(mailData),
            notificationId: '',
            subject: 'New account Green`r',
            toAddress: advisorId.toLowerCase(),
          };
          const { status } = await sendEmail(reqPayload);
        }
        this.setState({
          openSnackbar: true,
          advisorOnboardingModalOpen: false,
          isLoading: false,
          newAdvisorMailId: advisorId.toLowerCase(),
        });
      } else {
        this.setState({
          openSnackbar: true,
          isApiFailed: true,
          isLoading: false,
          advisorOnboardingModalOpen: true,
        });
      }
    } catch (e) {
      this.setState({
        openSnackbar: true,
        isApiFailed: true,
        isLoading: false,
        advisorOnboardingModalOpen: true,
      });
    }
  };

  getClients = () => {
    const { userDetails: loggedInUserDetails = {} } = this.props;
    const { emailId } = loggedInUserDetails;
    if (emailId && emailId !== '') {
      this.loadClientsByAdvisorId(emailId);
    }
  };

  loadClientsByAdvisorId = (emailId) => {
    const { triggerGetClientsByAdvisorId } = this.props;
    const payload = {
      advisorId: emailId.toLowerCase(),
    };
    triggerGetClientsByAdvisorId(payload);
  };

  handleClose = () => {
    this.setState({
      openSnackbar: false,
      openPlanSnackbar: false,
      isInvMarketSnackbarOpen: false,
      isAssetMixSnackbarOpen: false,
    });
  };

  renderNewPlanDetails = () => {
    const { newPlanName, isnewPlanNameValid } = this.state;
    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">Add New Plan</span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => this.handleAddPlanModalOpen(false)}
          />
        </div>
        <div className="signup-body-container">
          <Grid container className="newplan-form-grid" spacing={2}>
            <Grid item>
              <Controls.Input
                label="Plan Name"
                name="newPlanName"
                value={newPlanName}
                type="text"
                onChange={(event) => {
                  this.handleChange(event, 'newPlanName');
                }}
                errorText={
                  newPlanName
                    ? 'Invalid Plan Name'
                    : 'This is a mandatory field'
                }
                isValid={isnewPlanNameValid}
                required
                placeholder="Plan Name"
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  renderDashboardSummaryContainer = () => {
    const { dashboard, isOutputResponseLoading } = this.props;
    return (
      <DashboardSummaryTabsContainer
        dashboard={dashboard}
        isOutputResponseLoading={isOutputResponseLoading}
      />
    );
  };
  convertToDecimal = () => {
    const { derivedInvMarketExpectations } = this.state;
    const newDerivedInvMarketExpectations = derivedInvMarketExpectations.map(
      (value) => {
        let newInvReturnPercentValue = value[1] && value[1].invReturn;
        let newInvVolatilityPercentValue = value[1] && value[1].invVolatility;
        let newInvDividendPercentValue = value[1] && value[1].invDividend;
        if (newInvReturnPercentValue !== '') {
          newInvReturnPercentValue = parseFloat(newInvReturnPercentValue) / 100;
        }
        if (newInvVolatilityPercentValue !== '') {
          newInvVolatilityPercentValue =
            parseFloat(newInvVolatilityPercentValue) / 100;
        }
        if (newInvDividendPercentValue !== '') {
          newInvDividendPercentValue =
            parseFloat(newInvDividendPercentValue) / 100;
        }
        return {
          ...value,
          1: {
            ...value[1],
            invReturn: newInvReturnPercentValue,
            invVolatility: newInvVolatilityPercentValue,
            invDividend: newInvDividendPercentValue,
          },
        };
      }
    );
    return newDerivedInvMarketExpectations;
  };

  handleSave = async () => {
    const {
      derivedInvMarketExpectations,
      isMarketForecastChecked,
      derivedInvMarketCorrelations,
    } = this.state;
    const {
      userDetails: { companyName },
      userDetails: loggedInUserDetails = {},
      triggerGetInvMarketExpectationsByCompanyName,
      selectedPlanId,
      selectedClientId,
      triggerGetInvMarketCorrelationsByCompanyName,
    } = this.props;
    if (isMarketForecastChecked) {
      // save market forecast
      const tempDerivedInvMarketExpectations = this.convertToDecimal();
      if (
        !isEqual(tempDerivedInvMarketExpectations, derivedInvMarketExpectations)
      ) {
        this.setState({
          isUpdateInvMarketExpectationsLoading: true,
        });
        let saveRequestObj = tempDerivedInvMarketExpectations.reduce(function (
          acc,
          curr
        ) {
          return { ...acc, [curr[0]]: curr[1] };
        },
        {});
        saveRequestObj = {
          ...saveRequestObj,
          companyName: companyName,
          advisorId: '',
        };
        try {
          const [response] = await Promise.all([
            updateInvMarketExpectationsByCompanyName(saveRequestObj),
          ]);
          if (response && response.status === 200) {
            // if (selectedPlanId && selectedClientId) {
            //   this.getSelectedPlanIdDetails();
            // }
            // triggerGetInvMarketExpectationsByCompanyName(companyName);
            this.setState({
              isUpdateInvMarketExpectationsLoading: false,
              isApiFailed: false,
              isInvMarketSnackbarOpen: true,
            });
          } else {
            // triggerGetInvMarketExpectationsByCompanyName(companyName);
            this.setState({
              isUpdateInvMarketExpectationsLoading: false,
              isApiFailed: true,
              isInvMarketSnackbarOpen: true,
            });
          }
        } catch (e) {
          triggerGetInvMarketExpectationsByCompanyName(companyName);
          this.setState({
            isUpdateInvMarketExpectationsLoading: false,
            isApiFailed: true,
            isInvMarketSnackbarOpen: true,
          });
        }
      }
    } else {
      this.setState({
        isUpdateInvMarketCorrelationsLoading: true,
      });
      const correlations = derivedInvMarketCorrelations.flatMap((item) => {
        return item.details.map((detail) => ({
          asset1: item.label, // The label of the dropdownData
          asset2: detail.subAssetClass, // The subAssetClass from the details
          corr: detail.corrCoeff, // The corrCoeff from the details
        }));
      });

      //save correlations
      const reqObj = {
        advisorId: '',
        companyName,
        productId: '',
        correlations,
      };
      try {
        const [response] = await Promise.all([
          updateInvMarketCorrelationsByCompanyNm(reqObj),
        ]);
        if (response && response.status === 200) {
          if (selectedPlanId && selectedClientId) {
            this.getSelectedPlanIdDetails();
          }
          // triggerGetInvMarketCorrelationsByCompanyName(companyName);
          this.setState({
            isUpdateInvMarketCorrelationsLoading: false,
            isApiFailed: false,
            isInvMarketCorrelationsSnackbarOpen: true,
          });
        } else {
          // triggerGetInvMarketCorrelationsByCompanyName(companyName);
          this.setState({
            isUpdateInvMarketCorrelationsLoading: false,
            isApiFailed: true,
            isInvMarketCorrelationsSnackbarOpen: true,
          });
        }
      } catch (e) {
        triggerGetInvMarketCorrelationsByCompanyName(companyName);
        this.setState({
          isUpdateInvMarketCorrelationsLoading: false,
          isApiFailed: true,
          isInvMarketCorrelationsSnackbarOpen: true,
        });
      }
    }
  };

  handleBlur = (event, pKey, field) => {
    const { value } = event && event.target;
    const { derivedInvMarketExpectations } = this.state;
    let newCustValue = value;
    if (percentageFields.includes(field)) {
      if (!value.toString().includes('%')) {
        const percentCustVal = parseFloat(value).toFixed(1);
        newCustValue = `${percentCustVal}%`;
      }
    }
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    const index = tempDerivedInvMarketExpectations.indexOf(
      tempDerivedInvMarketExpectations.filter((f) => f[0] === pKey)[0]
    );
    let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
    innerObj[field] = newCustValue;
    tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      isFormValid: newCustValue.includes('NaN') ? false : true,
    }));
  };

  handleFormFieldChange = (e, pKey, sKey) => {
    const { derivedInvMarketExpectations } = this.state;
    let { value } = e.target;
    if (value.toString().includes('%')) {
      const percentFieldValue = value.split('%')[0];
      value = parseFloat(percentFieldValue);
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    const index = tempDerivedInvMarketExpectations.indexOf(
      tempDerivedInvMarketExpectations.filter((f) => f[0] === pKey)[0]
    );
    let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
    innerObj[sKey] = value;
    tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      isFormValid: value === 'NaN' ? false : true,
    }));
  };

  transformValue = (value, field) => {
    if (value && value !== '') {
      if (percentageFields.includes(field)) {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(0);
        } else {
          percentFieldValue = parseFloat(value * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US', { notation: 'compact' });
        const newNumformat = numFormat.format(numVal);
        return field === 'assetMix' ? `${newNumformat}` : `$${newNumformat}`;
      }
    }
  };

  transformValue1 = (value, type) => {
    if (value && value !== '') {
      if (type === 'percent') {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(0);
        } else {
          percentFieldValue = parseFloat(value * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US');
        const newNumformat = numFormat.format(numVal);
        return `$${newNumformat}`;
      }
    } else {
      if (type === 'percent') {
        return `${0}%`;
      } else {
        return `$${0}`;
      }
    }
  };

  handleMarketValuesEditClick = (type, i, value) => {
    this.setState(
      {
        selectedBlock: i,
        selectedType: type,
        marketValue: value,
      },
      () => {
        const { current = {} } = this.marketValueField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  handleChangeOnMarket = (event) => {
    this.setState({ marketValue: event.target.value });
  };

  handleBlurOnMarket = (e, d) => {
    const { derivedInvMarketExpectations, marketValue, selectedType } =
      this.state;
    const value = marketValue !== '' ? marketValue : '';
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    if (value !== '' && !isNaN(value)) {
      const type =
        selectedType === 'Ret'
          ? 'invReturn'
          : selectedType === 'Vol'
          ? 'invVolatility'
          : 'invDividend';
      const index = tempDerivedInvMarketExpectations.indexOf(
        tempDerivedInvMarketExpectations.filter((f) => f[0] === d[0])[0]
      );
      let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
      innerObj[type] = parseFloat(parseFloat(value).toFixed(1));
      tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    }
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      selectedBlock: undefined,
      selectedType: undefined,
    }));
  };

  getColor = (color) => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return '#00A36C';
      case 'lightgreen':
        return '#90EE90';
      case 'red':
        return '#C70039';
      case 'orange':
        return '#FFC300';
      case 'yellow':
        return yellow[600];
      default:
        return grey[600];
    }
  };

  generateChartData = (milestones, heading) => {
    let chartOptions = milestones.filter(
      (m) => m.year !== 0 && m.year !== null
    );
    chartOptions = chartOptions.sort((a, b) => a.year - b.year);
    chartOptions = chartOptions.map((m, index) => {
      return {
        name: m.name + '<br/>' + m.year + `${m.desc}`,
        color: this.getColor(m.healthFlag ? m.healthFlag : 'grey'),
        dataLabels: {
          color: '#000000',
          backgroundColor: '#ffffff',
          connectorWidth: 2,
          style: {
            textOutline: 0,
          },
        },
      };
    });
    return setTimelineChartOptions(chartOptions, heading);
  };

  toggleTimelineToNetworth = (e) => {
    const { checked: netWorthChecked } = e.target;
    // const { planDetailsByPlanId, triggerUpdatePlannerDetailsinRedux } =
    //   this.props;
    // const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
    //   planDetailsByPlanId;
    // const tempModel = { ...model, runCode: netWorthChecked ? 100 : 0 }; // setting runcode to 0 for onload for timeline and 100 to networth
    // const tempPlanDetailsByPlanId = {
    //   ...planDetailsByPlanId,
    //   moduleSettings: {
    //     ...moduleSettings,
    //     model: { ...tempModel },
    //   },
    // };
    // triggerUpdatePlannerDetailsinRedux({
    //   plannerData: tempPlanDetailsByPlanId,
    // });
    this.setState({
      timeLineChecked: !netWorthChecked,
    });
  };

  isPlanNameValid = () => {
    const { isnewPlanNameValid } = this.state;
    const { selectedClientId } = this.props;
    const isValid = selectedClientId && isnewPlanNameValid ? true : false;
    return !isValid;
  };

  isAdvisorFormValid = () => {
    const { newAdvisorDetails, clientEmailId } = this.state;
    const isAllFieldsValid = Object.keys(newAdvisorDetails)
      .map((detail) => {
        if (
          NewAdvisorFields.filter((field) => field.fieldKey === detail).length >
          0
        ) {
          return newAdvisorDetails[detail].length > 0;
        } else {
          return newAdvisorDetails[detail];
        }
      })
      .indexOf(false);

    return isAllFieldsValid !== -1;
  };

  takeAction = (e, cta) => {
    let tabName = 'Insurance';
    tabName = cta?.headingName?.toLowerCase().includes('investments')
      ? 'Investments'
      : cta?.headingName?.toLowerCase().includes('net worth')
      ? 'Goals'
      : cta?.headingName?.toLowerCase().includes('debt')
      ? 'Debt'
      : cta?.headingName?.toLowerCase().includes('expenses')
      ? 'Expenses'
      : tabName;
    history.push({
      pathname: '/solutions',
      state: tabName,
    });
  };

  toggleAllClientAgeStats = (e) => {
    const { checked: allClientAgeStatsChecked } = e.target;
    this.setState({ allClientAgeStats: !allClientAgeStatsChecked });
  };

  toggleAllAdvisorAgeStats = (e) => {
    const { checked: allAdvisorAgeStatsChecked } = e.target;
    this.setState({ allAdvisorAgeStats: !allAdvisorAgeStatsChecked });
  };

  toggleRiskLevelToAum = (e) => {
    const { checked: aumChecked } = e.target;
    this.setState({
      isRecommendedRiskLevelChecked: !aumChecked,
    });
  };

  toggleCorrelations = (e) => {
    const { checked: correlationsChecked } = e.target;
    this.setState({
      isMarketForecastChecked: !correlationsChecked,
    });
  };

  transformPercentValueToUI = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '' && val >= 0) {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return fieldValue;
    }
    return 0.0;
  };

  deriveRecommendedRiskLevelValues = (selectedRecommendedRiskAppetite = '') => {
    const { companyInvAssetAllocations } = this.props;
    const transformAssetMixData = (allocation) => {
      return assetMixListValues.map(({ key, label }) => {
        const asset = allocation[key] || {
          assetClass: label,
          assetPercent: 0,
          assetKey: key,
          productId: allocation.productId || '',
        };
        return {
          assetClass: asset.assetClass,
          assetPercent: this.transformPercentValueToUI(asset.assetPercent),
          assetKey: key,
          productId: allocation.productId,
        };
      });
    };

    const selectedRiskLevelObj = companyInvAssetAllocations.find(
      (f) => f.productId === selectedRecommendedRiskAppetite
    );
    let assetMixData = selectedRiskLevelObj
      ? transformAssetMixData(selectedRiskLevelObj)
      : [];

    const allData = companyInvAssetAllocations.map((i) => ({
      productId: i.productId,
      data: transformAssetMixData(i),
    }));

    this.setState({
      assetMixData,
      selectedRecommendedRiskAppetite,
      allData,
      isAssetMixSaveDisabled: false,
    });
  };

  handleChangeOnAssetMixValue = (event) => {
    this.setState({ assetMixValue: event.target.value });
  };

  handleBlurOnAssetMixValue = (e, d) => {
    const {
      assetMixData,
      assetMixValue,
      selectedRecommendedRiskAppetite,
      allData,
    } = this.state;
    const value =
      assetMixValue !== '' && !isNaN(assetMixValue)
        ? Number(assetMixValue).toFixed(2)
        : '';

    // Update assetMixData
    const updatedAssetMixData = assetMixData.map((item) => {
      if (item.assetClass === d.assetClass) {
        return { ...item, assetPercent: value };
      }
      return item;
    });

    const sum = updatedAssetMixData.reduce(
      (acc, item) => acc + parseFloat(item.assetPercent || 0),
      0
    );

    const updatedAllData = allData.map((item) => {
      if (item.productId === selectedRecommendedRiskAppetite) {
        return { ...item, data: updatedAssetMixData };
      }
      return item;
    });

    this.setState((prevState) => ({
      ...prevState,
      assetMixData: updatedAssetMixData,
      selectedAssetMix: undefined,
      isAssetMixSaveDisabled: sum !== 100,
      sumOfAssetMixValue: sum,
      allData: updatedAllData,
    }));
  };

  percentageConversionToAPI = (value) => {
    let valueToApi = '';
    if (value !== null && value !== undefined && value !== '') {
      if (isNaN(value) && value.includes('%')) {
        const percentValue = value.split('%')[0];
        if (!isNaN(percentValue)) {
          valueToApi = parseFloat(parseFloat(percentValue) / 100);
        } else {
          valueToApi = 0;
        }
      } else if (!isNaN(value)) {
        valueToApi = parseFloat(parseFloat(value) / 100);
      }
    } else {
      valueToApi = 0;
    }
    return valueToApi;
  };

  handleSaveAssetMix = async () => {
    const { assetMixData, selectedRecommendedRiskAppetite, allData } =
      this.state;
    const {
      triggerGetInvAssetAllocationsByCmpnyNmAction,
      companyInvAssetAllocations,

      userDetails: { companyName },
    } = this.props;
    if (allData && allData.length > 0) {
      this.setState({
        isUpdateInvAssetAllocationsLoading: true,
      });
      try {
        // Process each item in allData
        for (const f of allData) {
          const selectedRiskLevelObj = companyInvAssetAllocations.find(
            (i) => i.productId === f.productId
          );
          if (
            selectedRiskLevelObj &&
            Object.keys(selectedRiskLevelObj).length > 0
          ) {
            const reqObj = {
              advisorId: '',
              companyName,
              riskAppetite: selectedRiskLevelObj.riskAppetite,
              riskLevel: selectedRiskLevelObj.riskLevel,
            };
            f.data.forEach((m) => {
              reqObj[m.assetKey] = this.percentageConversionToAPI(
                m.assetPercent
              );
            });
            const response =
              await updateInvAssetAllocationsByRiskLevelByCompanyName(reqObj);
            if (response && response.status === 200) {
              continue;
            } else {
              throw new Error('Failed to update asset allocations');
            }
          }
        }
        // if (selectedPlanId && selectedClientId) {
        //   await this.getSelectedPlanIdDetails();
        // }
        // await triggerGetInvAssetAllocationsByCmpnyNmAction(companyName);
        this.setState({
          isUpdateInvAssetAllocationsLoading: false,
          isApiFailed: false,
          isAssetMixSnackbarOpen: true,
        });
      } catch (e) {
        triggerGetInvAssetAllocationsByCmpnyNmAction(companyName);
        this.setState({
          isUpdateInvAssetAllocationsLoading: false,
          isApiFailed: true,
          isAssetMixSnackbarOpen: true,
        });
      }
    }
  };

  handleAssetMixValuesEditClick = (i, value) => {
    this.setState(
      {
        selectedAssetMix: i,
        assetMixValue: value,
      },
      () => {
        const { current = {} } = this.assetMixField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  onRecommendedRiskLevelChange = (event) => {
    let { value: selectedRecommendedRiskAppetite } = event.target;
    this.deriveRecommendedRiskLevelValues(selectedRecommendedRiskAppetite);
  };

  setActiveCardState = (activeCard) => {
    this.setState({
      activeCard,
      selectedMeetingId: '',
    });
  };

  setIsAPILoading = (loading) => {
    this.setState({
      loading,
    });
  };

  setSelectedAdvisorId = (selectedAdvisorId) => {
    this.setState({
      selectedAdvisorId,
      timeLineChecked: true,
    });
  };

  setAllAdvisorList = (allAdvisorsList) => {
    this.setState({
      allAdvisorsList,
    });
  };

  setAdvisorSelectedState = (advisorSelectedState) => {
    this.setState(
      {
        advisorSelectedState,
      },
      () => {
        if (advisorSelectedState) {
          this.triggerGetAllByCompanyAdvisorsByState(advisorSelectedState);
        }
      }
    );
  };

  triggerGetAllByCompanyAdvisorsByState = async (state) => {
    const {
      userDetails: { companyName },
    } = this.props;
    try {
      this.setIsAPILoading(true);
      let status;
      let advisorsListByState;
      if (state === 'All') {
        ({ status, data: advisorsListByState } =
          await getAllAdvisorsByCompanyName(companyName));
      } else {
        ({ status, data: advisorsListByState } =
          await getACompanyAllAdvisorsByState(companyName, state));
      }

      if (status === 200) {
        this.setIsAPILoading(false);
        this.setAllAdvisorList(advisorsListByState);
      }
    } catch (e) {
      this.setIsAPILoading(false);
    }
  };

  handleCorrelationChange = (event) => {
    let { value: selectedCorrelation } = event.target;
    const { derivedInvMarketCorrelations } = this.state;
    const correlationInfo = derivedInvMarketCorrelations.find(
      (c) => c.value === selectedCorrelation
    );
    this.setState({ selectedCorrelation, correlationInfo });
  };

  updateCorrCoeff = (newCorrCoeff, d) => {
    const { subAssetClass, corrCoeff, subKey } = d || {};
    const { derivedInvMarketCorrelations, selectedCorrelation } = this.state;
    // Find the index of the asset class to update
    const correlationIndex = derivedInvMarketCorrelations.findIndex(
      (item) => item.value === selectedCorrelation
    );
    const symmetricalCorrelationIndex = derivedInvMarketCorrelations.findIndex(
      (item) => item.value === subKey
    );
    // Update the dropdownData with the new details array
    const updatedDerivedInvMarketCorrelations = [
      ...derivedInvMarketCorrelations,
    ];
    if (correlationIndex !== -1) {
      // Clone the details array to avoid mutating the original data
      const updatedDetails = derivedInvMarketCorrelations[
        correlationIndex
      ].details.map((detail) => {
        if (detail.subKey === subKey) {
          // Update the corrCoeff value for the matching subAssetClass
          return { ...detail, corrCoeff: newCorrCoeff };
        }
        return detail;
      });
      updatedDerivedInvMarketCorrelations[correlationIndex] = {
        ...updatedDerivedInvMarketCorrelations[correlationIndex],
        details: updatedDetails,
      };
    } else {
      console.error(`Correlation ${selectedCorrelation} not found.`);
    }
    if (symmetricalCorrelationIndex !== -1) {
      // Clone the details array to avoid mutating the original data
      const symmetricalCorrelationUpdatedDetails =
        updatedDerivedInvMarketCorrelations[
          symmetricalCorrelationIndex
        ].details.map((detail) => {
          if (detail.subKey === selectedCorrelation) {
            // Update the corrCoeff value for the matching subAssetClass
            return { ...detail, corrCoeff: newCorrCoeff };
          }
          return detail;
        });
      updatedDerivedInvMarketCorrelations[symmetricalCorrelationIndex] = {
        ...updatedDerivedInvMarketCorrelations[symmetricalCorrelationIndex],
        details: symmetricalCorrelationUpdatedDetails,
      };
    } else {
      console.error(`Correlation ${subKey} not found.`);
    }
    return updatedDerivedInvMarketCorrelations;
  };

  handleChangeOnCorrelationValue = (event) => {
    this.setState({ selectedCorrelationCoeffValue: event.target.value });
  };

  handleBlurOnCorrelationValue = (e, d) => {
    const { selectedCorrelationCoeffValue, selectedCorrelation } = this.state;
    const corrCoeffValue =
      selectedCorrelationCoeffValue !== '' &&
      !isNaN(selectedCorrelationCoeffValue)
        ? Number(selectedCorrelationCoeffValue)
        : '';
    const updatedDerivedInvMarketCorrelations = this.updateCorrCoeff(
      corrCoeffValue,
      d
    );
    const correlationInfo = updatedDerivedInvMarketCorrelations.find(
      (c) => c.value === selectedCorrelation
    );
    this.setState({
      derivedInvMarketCorrelations: updatedDerivedInvMarketCorrelations,
      selectedCorrelationIndex: undefined,
      correlationInfo,
    });
  };

  handleInvMarketCorrelationEditClick = (i, value) => {
    this.setState(
      {
        selectedCorrelationIndex: i,
        selectedCorrelationCoeffValue: value,
      },
      () => {
        const { current = {} } = this.correlationValueField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  renderAdvisorDetails = () => {
    const {
      isAdvisorAssetsLoading,
      advisorAssetsUnderManagement,
      companyAUM: { aum: companyAUM = '' } = {},
    } = this.props;
    const { allClientAgeStats } = this.state;
    let assetMixRows =
      advisorAssetsUnderManagement &&
      Object.keys(advisorAssetsUnderManagement).length > 0 &&
      advisorAssetsUnderManagement.hasOwnProperty('assetMix')
        ? Object.values(advisorAssetsUnderManagement.assetMix)
        : [];
    assetMixRows = assetMixRows.map((m, i) => {
      return {
        id: i,
        assetType: m.assetLabel,
        assetValue: this.transformValue(m.assetValue, 'assetMix'),
        assetActualValue: m.assetValue,
        assetPercent: m.assetPercent,
      };
    });
    const allClientTypeAgeStatsData =
      allClientAgeStats &&
      advisorAssetsUnderManagement &&
      Object.keys(advisorAssetsUnderManagement).length > 0 &&
      advisorAssetsUnderManagement.hasOwnProperty('allClientAgeStats')
        ? Object.values(advisorAssetsUnderManagement.allClientAgeStats)
        : [];
    const clientTypeAgeStatsData =
      advisorAssetsUnderManagement &&
      Object.keys(advisorAssetsUnderManagement).length > 0 &&
      advisorAssetsUnderManagement.hasOwnProperty('clientTypeAgeStats')
        ? Object.values(advisorAssetsUnderManagement.clientTypeAgeStats)
        : [];
    const aumDistributionChartOptions = setVariblePieChartOptions(assetMixRows);
    const clientTypeDistributionData =
      advisorAssetsUnderManagement &&
      Object.keys(advisorAssetsUnderManagement).length > 0 &&
      advisorAssetsUnderManagement.hasOwnProperty('clientTypeStats')
        ? Object.values(advisorAssetsUnderManagement.clientTypeStats)
        : [];
    const clientTypeDistributionOptions = setRadialCircularChartOptions(
      clientTypeDistributionData
    );
    const ageDistributionOptions = allClientAgeStats
      ? setSpiderChartOptions(allClientTypeAgeStatsData, allClientAgeStats)
      : setSpiderChartOptions(clientTypeAgeStatsData, allClientAgeStats);
    return (
      <>
        {Object.keys(advisorAssetsUnderManagement).length <= 0 ? (
          <>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
              <div className="card Insurance_div"></div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
              <div className="card Insurance_div"></div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
              <div className="card Insurance_div"></div>
            </div>
          </>
        ) : (
          <AssetsFirstRowSection
            isAdvisorAssetsLoading={isAdvisorAssetsLoading}
            aumDistributionChartOptions={aumDistributionChartOptions}
            clientTypeDistributionOptions={clientTypeDistributionOptions}
            allClientAgeStats={allClientAgeStats}
            ageDistributionOptions={ageDistributionOptions}
            toggleAllClientAgeStats={this.toggleAllClientAgeStats}
            companyAUM={companyAUM}
            advisorAssetsUnderManagement={advisorAssetsUnderManagement}
            transformValue={this.transformValue}
          />
        )}
      </>
    );
  };
  render() {
    const {
      isLoading,
      advisorOnboardingModalOpen,
      advsiorStateOptions,
      newAdvisorDetails: { advisorName = '' } = {},
      newPlanName,
      isApiFailed,
      openSnackbar,
      openPlanSnackbar,
      isInvMarketSnackbarOpen,
      isAssetMixSnackbarOpen,
      isUpdateInvMarketExpectationsLoading,
      derivedInvMarketExpectations,
      selectedBlock,
      selectedType,
      marketValue,
      activeCard,
      allAdvisorAgeStats,
      loading,
      isRecommendedRiskLevelChecked,
      isMarketForecastChecked,
      recommendedRiskLevels,
      selectedRecommendedRiskAppetite,
      assetMixData,
      assetMixValue,
      selectedAssetMix,
      isUpdateInvAssetAllocationsLoading,
      isAssetMixSaveDisabled,
      sumOfAssetMixValue,
      selectedAdvisorId,
      advisorSelectedState,
      allAdvisorsList,
      derivedInvMarketCorrelations,
      selectedCorrelation,
      selectedCorrelationCoeffValue,
      selectedCorrelationIndex,
      correlationInfo,
      isUpdateInvMarketCorrelationsLoading,
    } = this.state;
    const {
      userDetails: loggedInUserDetails = {},
      isCompanyAssetsLoading,
      companyAssetsUnderManagement,
      advisorHome = {},
      charts = [],
      companyAUM: { aum: companyAUM = '' } = {},
      companyAssetsUnderManagement: { aum = '' },
      isCmpnyInvAssetAllocationsLoading,
      userDetails: { companyName },
      alltaxSlabs,
    } = this.props;

    const { milestonesTab = {}, ctas = [] } = advisorHome || {};
    const { milestones = {} } = milestonesTab;
    let timelineChartData = {};
    // if (milestones != null) {
    const { event = [], heading = '' } = milestones;
    // if (event && event.length > 0) {
    timelineChartData = this.generateChartData(event, heading);
    //}
    //}
    const sortedCTAs = ctas.sort((a, b) => {
      return a.position - b.position;
    });
    let firstRowCTAs = sortedCTAs.slice(0, 1);
    firstRowCTAs = firstRowCTAs.map((f) => {
      return {
        ...f,
        headingName: f.heading?.includes(':')
          ? f.heading?.split(':')[0]
          : f.heading || '',
        headingValue: f.heading?.includes(':')
          ? f.heading?.split(':')[1].trim()
          : '',
      };
    });
    let secondRowCTAs = sortedCTAs.slice(1);
    secondRowCTAs = secondRowCTAs.map((f) => {
      return {
        ...f,
        headingName: f.heading?.includes(':')
          ? f.heading?.split(':')[0]
          : f.heading || '',
        headingValue: f.heading?.includes(':')
          ? f.heading?.split(':')[1].trim()
          : '',
      };
    });
    let assetMixRows =
      companyAssetsUnderManagement &&
      Object.keys(companyAssetsUnderManagement).length > 0 &&
      companyAssetsUnderManagement.hasOwnProperty('assetMix')
        ? Object.values(companyAssetsUnderManagement.assetMix)
        : [];
    assetMixRows = assetMixRows.map((m, i) => {
      return {
        id: i,
        assetType: m.assetLabel,
        assetValue: this.transformValue(m.assetValue, 'assetMix'),
        assetActualValue: m.assetValue,
        assetPercent: m.assetPercent,
      };
    });
    const allClientTypeAgeStatsData =
      allAdvisorAgeStats &&
      companyAssetsUnderManagement &&
      Object.keys(companyAssetsUnderManagement).length > 0 &&
      companyAssetsUnderManagement.hasOwnProperty('allClientAgeStats')
        ? Object.values(companyAssetsUnderManagement.allClientAgeStats)
        : [];
    const clientTypeAgeStatsData =
      companyAssetsUnderManagement &&
      Object.keys(companyAssetsUnderManagement).length > 0 &&
      companyAssetsUnderManagement.hasOwnProperty('clientTypeAgeStats')
        ? Object.values(companyAssetsUnderManagement.clientTypeAgeStats)
        : [];
    const aumDistributionChartOptions = setVariblePieChartOptions(assetMixRows);
    const clientTypeDistributionData =
      companyAssetsUnderManagement &&
      Object.keys(companyAssetsUnderManagement).length > 0 &&
      companyAssetsUnderManagement.hasOwnProperty('clientTypeStats')
        ? Object.values(companyAssetsUnderManagement.clientTypeStats)
        : [];
    const clientTypeDistributionOptions = setRadialCircularChartOptions(
      clientTypeDistributionData
    );
    const ageDistributionOptions = allAdvisorAgeStats
      ? setSpiderChartOptions(allClientTypeAgeStatsData, allAdvisorAgeStats)
      : setSpiderChartOptions(clientTypeAgeStatsData, allAdvisorAgeStats);
    const snackbarMsg = openSnackbar
      ? `${advisorName} advisor creation is successful`
      : isInvMarketSnackbarOpen || isAssetMixSnackbarOpen
      ? 'Saved Successfully'
      : `${newPlanName} plan creation is successful`;
    const taxStateVals = transformTaxStateOptions(alltaxSlabs);
    return (
      <div className="main-content-wrapper">
        <div className="container-fluid main-content-wrapper-inner">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 desktop_card d-flex justify-content-between">
              <FirstCardComponent
                activeCard={activeCard}
                isCompanyAssetsLoading={isCompanyAssetsLoading}
                companyAUM={companyAUM}
                setActiveCardState={(activeCard) =>
                  this.setActiveCardState(activeCard)
                }
                transformValue={(value, field) =>
                  this.transformValue(value, field)
                }
              />
              <SecondCardComponent
                allAdvisorsList={allAdvisorsList}
                advsiorStateOptions={advsiorStateOptions}
                setAdvisorSelectedState={(state) =>
                  this.setAdvisorSelectedState(state)
                }
                advisorSelectedState={advisorSelectedState}
                selectedAdvisorId={selectedAdvisorId}
                companyName={companyName}
                activeCard={activeCard}
                taxStateVals={taxStateVals}
                handleAdvisorOnboardingModalOpen={
                  this.handleAdvisorOnboardingModalOpen
                }
                setActiveCardState={(activeCard) =>
                  this.setActiveCardState(activeCard)
                }
                setSelectedAdvisorId={(advisorId) =>
                  this.setSelectedAdvisorId(advisorId)
                }
              />

              <div
                className={`card home_main_card_outer two mb-4 ${
                  activeCard === 'meetings' ? `active` : ``
                }`}
                onClick={() => {
                  this.setState({
                    activeCard: 'meetings',
                    isEditable: false,
                    isAddMeeting: false,
                    selectedMeetingId: '',
                  });
                }}
              ></div>
            </div>
          </div>
          <div className="row">
            {activeCard === 'assets' && (
              <AssetsFirstRowSection
                setActiveCardState={this.setActiveCardState}
                isAdvisorAssetsLoading={isCompanyAssetsLoading}
                aumDistributionChartOptions={aumDistributionChartOptions}
                clientTypeDistributionOptions={clientTypeDistributionOptions}
                allClientAgeStats={allAdvisorAgeStats}
                ageDistributionOptions={ageDistributionOptions}
                toggleAllClientAgeStats={this.toggleAllAdvisorAgeStats}
                companyAUM={companyAUM}
                advisorAssetsUnderManagement={companyAssetsUnderManagement}
                transformValue={this.transformValue}
              />
            )}

            {activeCard === 'advisor' && (
              <AdvisorReviewsFirstRowSection
                charts={charts}
                advisorSelectedState={advisorSelectedState}
                selectedAdvisorId={selectedAdvisorId}
                companyName={companyName}
                activeCard={activeCard}
                taxStateVals={taxStateVals}
                setActiveCardState={(activeCard) =>
                  this.setActiveCardState(activeCard)
                }
                setSelectedAdvisorId={(advisorId) =>
                  this.setSelectedAdvisorId(advisorId)
                }
                handleAdvisorOnboardingModalOpen={
                  this.handleAdvisorOnboardingModalOpen
                }
                allAdvisorsList={allAdvisorsList}
                advsiorStateOptions={advsiorStateOptions}
              />
            )}
          </div>
          <>
            {activeCard === 'assets' && (
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <h5 className="mb-0">Recommended Risk Level</h5>
                        <AntSwitch
                          inputProps={{ 'aria-label': 'ant design' }}
                          checked={!isRecommendedRiskLevelChecked}
                          onChange={this.toggleRiskLevelToAum}
                          dual={true}
                        />
                        <h5 className="mb-0">AUM Amounts</h5>
                      </Stack>

                      {isCompanyAssetsLoading ||
                      isCmpnyInvAssetAllocationsLoading ||
                      isUpdateInvAssetAllocationsLoading ? (
                        <CircularProgress
                          sx={{
                            color: '#479573',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                          }}
                          size={20}
                        />
                      ) : isRecommendedRiskLevelChecked ? (
                        <>
                          <div className="Choose_Risk_Appetite_dropdown select_client_outer">
                            <Controls.Select
                              className="js-example-basic-single"
                              name="Moderately_Conservative"
                              id="Moderately_Conservative"
                              value={selectedRecommendedRiskAppetite}
                              onChange={this.onRecommendedRiskLevelChange}
                              options={recommendedRiskLevels.map((t) => {
                                return {
                                  label: t.riskAppetite,
                                  value: t.productId,
                                };
                              })}
                            />
                            <div className="amounts_main_outer">
                              {assetMixData &&
                                assetMixData.map((a, i) => (
                                  <div className="amount_box">
                                    <div className="amount_title">
                                      <p>{a.assetClass}</p>
                                      <EditIcon
                                        className="edit"
                                        sx={{
                                          cursor: 'pointer',
                                          fontSize: '10px',
                                        }}
                                        onClick={() => {
                                          this.handleAssetMixValuesEditClick(
                                            i,
                                            a.assetPercent
                                          );
                                        }}
                                        color="action"
                                        fontSize="sm"
                                      />
                                    </div>
                                    {selectedAssetMix === i ? (
                                      <div className="amount_content">
                                        <input
                                          ref={this.assetMixField}
                                          placeholder=""
                                          type="number"
                                          value={assetMixValue}
                                          onChange={(event) =>
                                            this.handleChangeOnAssetMixValue(
                                              event
                                            )
                                          }
                                          onBlur={(event) =>
                                            this.handleBlurOnAssetMixValue(
                                              event,
                                              a
                                            )
                                          }
                                        />
                                        {'  '} %
                                      </div>
                                    ) : (
                                      <div className="amount_content">
                                        <h6>
                                          {a.assetPercent}
                                          <sup>%</sup>
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div
                            style={{
                              paddingTop: '20px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {isAssetMixSaveDisabled && (
                                <Stack sx={{ width: '90%' }}>
                                  <Alert severity="error">
                                    <p>
                                      Asset mix should add upto 100%, currently
                                      it is at {sumOfAssetMixValue}%{' '}
                                    </p>
                                  </Alert>
                                </Stack>
                              )}
                            </div>
                            <div className="save_button">
                              <button
                                type="button"
                                className={
                                  isAssetMixSaveDisabled
                                    ? 'save_btn common-button secondary-button'
                                    : 'save_btn common-button'
                                }
                                variant="contained"
                                onClick={this.handleSaveAssetMix}
                                disabled={isAssetMixSaveDisabled}
                              >
                                <img src={save} alt="" title="" />
                                Save
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="amounts_main_outer">
                          {assetMixRows.map((a) => (
                            <div className="amount_box">
                              <p>{a.assetType}</p>
                              <div className="amount">
                                <span>$</span>
                                {a.assetValue || 0}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="market_forecast">
                        <div className="market_forecast_box">
                          <div
                            className={
                              isMarketForecastChecked
                                ? 'box_title full-width'
                                : 'box_title'
                            }
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <h5 className="mb-0">
                                MARKET Forecast (Returns, Volatilities &
                                Dividends){' '}
                              </h5>
                              <AntSwitch
                                inputProps={{ 'aria-label': 'ant design' }}
                                checked={!isMarketForecastChecked}
                                onChange={this.toggleCorrelations}
                                dual={true}
                              />
                              <h5 className="mb-0">Correlations</h5>
                            </Stack>
                            <p>
                              These will be used for Cash flow projections in
                              Monte Carlo simulations as well as uniform market
                              simulation. You may Change & Save.
                            </p>
                            <div></div>
                          </div>
                          <>
                            {isMarketForecastChecked ? (
                              <>
                                <div className="market_forecast_box_outer">
                                  {derivedInvMarketExpectations.map((d, i) => (
                                    <div
                                      className={
                                        selectedBlock === i
                                          ? 'box_item edit_ret'
                                          : 'box_item'
                                      }
                                    >
                                      <p className="small_title">
                                        {d[1]?.assetClass}
                                      </p>
                                      <div className="value_outer">
                                        <div className="value_outer_box">
                                          <span className="ret">
                                            Ret
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                cursor: 'pointer',
                                                fontSize: '10px',
                                              }}
                                              color="action"
                                              fontSize="sm"
                                              onClick={() => {
                                                this.handleMarketValuesEditClick(
                                                  'Ret',
                                                  i,
                                                  d[1]?.invReturn
                                                );
                                              }}
                                            />
                                          </span>
                                          <h6>
                                            {d[1]?.invReturn} <sup>%</sup>
                                          </h6>
                                        </div>
                                        <div className="value_outer_box">
                                          <span className="vol">
                                            Vol
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                fontSize: 12,
                                                cursor: 'pointer',
                                              }}
                                              color="action"
                                              fontSize="sm"
                                              onClick={() => {
                                                this.handleMarketValuesEditClick(
                                                  'Vol',
                                                  i,
                                                  d[1]?.invVolatility
                                                );
                                              }}
                                            />
                                          </span>
                                          <h6>
                                            {d[1]?.invVolatility} <sup>%</sup>
                                          </h6>
                                        </div>
                                        {d[1]?.assetClass !== 'Inflation' && (
                                          <div className="value_outer_box">
                                            <span className="vol">
                                              Div
                                              <EditIcon
                                                className="edit"
                                                sx={{
                                                  fontSize: 12,
                                                  cursor: 'pointer',
                                                }}
                                                color="action"
                                                fontSize="sm"
                                                onClick={() => {
                                                  this.handleMarketValuesEditClick(
                                                    'Div',
                                                    i,
                                                    d[1]?.invDividend
                                                  );
                                                }}
                                              />
                                            </span>
                                            <h6>
                                              {d[1]?.invDividend} <sup>%</sup>
                                            </h6>
                                          </div>
                                        )}
                                      </div>
                                      {selectedBlock === i && (
                                        <div className="value_ret">
                                          <div className="value_ret_content">
                                            <span>{selectedType}</span>
                                            <div className="edit_div">
                                              <input
                                                ref={this.marketValueField}
                                                placeholder=""
                                                type="number"
                                                value={marketValue}
                                                onChange={(event) =>
                                                  this.handleChangeOnMarket(
                                                    event
                                                  )
                                                }
                                                onBlur={(event) =>
                                                  this.handleBlurOnMarket(
                                                    event,
                                                    d
                                                  )
                                                }
                                              />
                                              {'  '} %
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="Dropdown_main_outer">
                                  <Controls.Select
                                    name="correlationsSelect"
                                    label="Correlations for"
                                    value={selectedCorrelation}
                                    onChange={this.handleCorrelationChange}
                                    options={derivedInvMarketCorrelations.map(
                                      (m) => {
                                        return {
                                          label: m.label,
                                          value: m.value,
                                        };
                                      }
                                    )}
                                  />
                                </div>
                                <div className="Correlations_title">
                                  <h5>Correlations With</h5>
                                </div>

                                <div className="Correlations_main_outer">
                                  {correlationInfo?.details &&
                                    correlationInfo?.details.map((m, i) => (
                                      <div class="Correlations_item">
                                        <h5>{m.subAssetClass}</h5>
                                        <div class="value_outer">
                                          {selectedCorrelationIndex === i ? (
                                            <div className="amount_content">
                                              <input
                                                ref={this.correlationValueField}
                                                placeholder=""
                                                type="number"
                                                value={
                                                  selectedCorrelationCoeffValue
                                                }
                                                onChange={(event) =>
                                                  this.handleChangeOnCorrelationValue(
                                                    event
                                                  )
                                                }
                                                onBlur={(event) =>
                                                  this.handleBlurOnCorrelationValue(
                                                    event,
                                                    m
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <h6 className="value">
                                              {m.corrCoeff}
                                            </h6>
                                          )}
                                          {m.subKey !== selectedCorrelation && (
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                cursor: 'pointer',
                                                fontSize: '10px',
                                              }}
                                              onClick={() => {
                                                this.handleInvMarketCorrelationEditClick(
                                                  i,
                                                  m.corrCoeff
                                                );
                                              }}
                                              color="action"
                                              fontSize="sm"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )}

                            {(isUpdateInvMarketExpectationsLoading ||
                              isUpdateInvMarketCorrelationsLoading) && (
                              <CircularProgress
                                sx={{
                                  color: '#479573',
                                  position: 'relative',
                                  left: '45%',
                                  top: '45%',
                                }}
                                size={20}
                              />
                            )}
                          </>
                        </div>
                      </div>
                      <div style={{ paddingTop: '20px' }}>
                        <div className="save_button">
                          <button
                            type="button"
                            className="save_btn"
                            variant="contained"
                            onClick={this.handleSave}
                          >
                            <img src={save} alt="" title="" />
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {activeCard === 'advisor' && <>{this.renderAdvisorDetails()}</>}
            </div>
          </>
        </div>
        <Snackbar
          open={
            openSnackbar ||
            openPlanSnackbar ||
            isInvMarketSnackbarOpen ||
            isAssetMixSnackbarOpen
          }
          autoHideDuration={4000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {!isApiFailed
              ? snackbarMsg
              : 'Something went wrong, please try again !'}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={
          //   simulatePlanDetailsLoading || plannerDetailsLoading || isLoading
          // }
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {advisorOnboardingModalOpen && (
          <ConfirmModal
            content={this.renderNewAdvisorOnboardingDetails()}
            open={advisorOnboardingModalOpen}
            setOpen={this.handleAdvisorOnboardingModalOpen}
            onConfirm={this.handleAddNewAdvisor}
            okLabel="Add"
            from="horizon"
            size="md"
            isOkButtonDisabled={this.isAdvisorFormValid()}
            dialogId="add_client"
          />
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={
          //   simulatePlanDetailsLoading || plannerDetailsLoading || isLoading
          // }
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  enableMeetingEdit = () => {
    this.setState({
      isEditable: true,
    });
  };

  validateField = (name, value) => {
    let isValid = true;
    switch (name) {
      case 'mobileNumber':
        isValid = validatePhoneNumber(value); // returns true if valid
        break;
      case 'emailId':
        isValid = validateEmailId(value);
        break;
      case 'meetingName':
        isValid = validateNamesWithoutSpaces(value);
        break;
      default:
        isValid = true;
    }
    // Update the corresponding isValid state
    this.setState({
      [`is${name}Valid`]: isValid,
    });
  };

  handleBlurToValidate = (event, fieldName) => {
    const { value } = event.target;
    this.validateField(fieldName, value);
  };

  handleEditMeeting = (event, fieldName, meetingNotes = '') => {
    let meetingAttributes = [
      'firstreminderdays',
      'secondreminderdays',
      'meetingname',
      'commsemail',
      'commssms',
      'commsphone',
      'meetingnotes',
      'meetingstatus',
      'meetingdate',
      'meetingtime',
    ];
    let { localClientDetail, localMeetingDetail } = this.state;
    let tempLocalClientDetail = { ...localClientDetail };
    let tempLocalMeetingDetail = { ...localMeetingDetail };
    if (fieldName.toLowerCase() === 'mobilenumber') {
      const formattedMobileNumber = formatPhoneNumber(event.target.value);
      // localClientDetail[fieldName] = formattedMobileNumber;
      tempLocalClientDetail = {
        ...tempLocalClientDetail,
        [fieldName]: formattedMobileNumber,
      };
      // this.setState({
      //   localClientDetail: {
      //     ...localClientDetail,
      //     [fieldName]: formattedMobileNumber,
      //   },
      // });
    } else if (meetingAttributes.includes(fieldName.toLowerCase())) {
      // let { localMeetingDetail } = this.state;
      if (
        ['commsemail', 'commssms', 'commsphone'].includes(
          fieldName.toLowerCase()
        )
      ) {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.checked,
        };
        //localMeetingDetail[fieldName] = event.target.checked;
      } else if (fieldName.toLowerCase() === 'meetingstatus') {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.checked
            ? 'Concluded'
            : tempLocalMeetingDetail[fieldName],
        };
        // localMeetingDetail[fieldName] =
        //   event.target.checked === true
        //     ? 'Concluded'
        //     : localMeetingDetail[fieldName];
      } else if (fieldName.toLowerCase() === 'meetingnotes') {
        let { meetingNotes: tempMeetingNotes = [] } =
          tempLocalMeetingDetail || {};
        // if (
        //   localMeetingDetail?.meetingNotes === null ||
        //   localMeetingDetail?.meetingNotes?.length <= 0
        // ) {
        //   localMeetingDetail.meetingNotes = [];
        // }
        tempMeetingNotes.push(meetingNotes);
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          meetingNotes: tempMeetingNotes,
        };
        //localMeetingDetail.meetingNotes.push(meetingNotes);
      } else if (fieldName.toLowerCase() === 'meetingdate') {
        const formattedDate =
          format(event, "yyyy-MM-dd'T'HH:mm:ss.SSS") + '+0000';
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: formattedDate,
        };
        //localMeetingDetail[fieldName] = formattedDate;
      } else if (fieldName.toLowerCase() === 'meetingtime') {
        const { meetingDate = '' } = tempLocalMeetingDetail;
        let eventTime = event.$d.toLocaleTimeString('en-US', { hour12: false });
        let datePart = meetingDate.split('T')[0];
        let updatedMeetingDate = datePart + 'T' + eventTime + '.000+0000';
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          meetingDate: updatedMeetingDate,
        };
        //localMeetingDetail['meetingDate'] = updatedMeetingDate;
      } else {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.value,
        };
        this.validateField(fieldName, event.target.value);
        // localMeetingDetail[fieldName] = event.target.value;
      }
    }
    this.setState({
      localMeetingDetail: tempLocalMeetingDetail,
      localClientDetail: tempLocalClientDetail,
      // [`is${fieldName}Valid`]: true,
      // backUpLocalMeetingDetail: backUpLocalMeetingDetail,
      // backUpLocalClientDetail: backUpLocalClientDetail,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    userDetails: (state.loggedInUser && state.loggedInUser.userDetails) || {},
    companyAssetsUnderManagement: getCompanyAssetUnderManagement(state),
    isCompanyAssetsLoading: getCompanyAsserUnderManagementLoading(state),
    companyInvAssetAllocations: getCompanyInvestmentAllocation(state),
    isCmpnyInvAssetAllocationsLoading:
      getCompanyInvestmentAllocationLoading(state),
    invMarketCorrelations:
      getInvMarketCorrelationsByCompanyNmSelector(state) || [],
    invMarketExpectationsByCompanyName:
      getInvMarketExpectationsByCompanyNameSelector(state) || {},
    isAdvisorAssetsLoading: state.clientInfoReducer.isAdvisorAssetsLoading,
    companyAUM: state.clientInfoReducer.companyAUM || {},

    clients: state.clientInfoReducer.clients || [],
    advisorAssetsUnderManagement:
      state.clientInfoReducer.advisorAssetsUnderManagement || {},
    clientsNotUpdated: state.clientInfoReducer.clientsNotUpdated || [],
    meetingDetail: state.plansReducer.meetingDetail || {},
    tenure: state.clientInfoReducer.tenure,
    clientTenure: state.clientInfoReducer.clientTenure,

    plans: state.plansReducer.plans || [],
    selectedClientId: selectedClientIdSelector(state),
    selectedPlanId: selectedPlanIdForClientIdSelector(state),
    dashboard: outputResponseDashboardSummaryDetailsByPlanIdSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    isoutputResponseAvailable: isOutputResponseDataAvailable(state),
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    isAdvisorStatsLoading: state.clientInfoReducer.isAdvisorStatsLoading,

    allMenuItems: allPlannerMenuItemsSelector(state),
    alltaxSlabs: allTaxSlabsSelector(state),
    selectedChart: selectedChartSelector(state) || '',
    planHighlights: outputResponsePlanHighlightsSelector(state) || {},
    advisorHome: outputResponseAdvisorHomeSelector(state) || {},
    faq: outputResponseFAQsSelector(state) || {},
    goals: outputResponseGoalsSelector(state) || {},
    charts: outputResponseChartsByPlanIdSelector(state) || {},
    planner: outputResponsePlannerDetailsByPlanIdSelector(state) || {},
    chrono: outputResponseChronoDetailsByPlanIdSelector(state) || {},
    selectedNumberOfRuns: getNumberOfRuns(state),
    forecast: state.clientInfoReducer.forecast || [],
    allocation: state.clientInfoReducer.allocation || [],
    selectedAllocationId: state.clientInfoReducer.selectedAllocationId || '',
    selectedForecastId: state.clientInfoReducer.selectedForecastId || '',
    clientDetails: state.plansReducer.clientDetails || {},
    isMobileNumberSaved: state.isMobileNumberSaved || false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerGetInvAssetAllocationsByCmpnyNmAction: (payload) =>
      dispatch(getInvAssetAllocationsByCompanyNameAction(payload)),
    triggerGetCompanyAssetsUnderManagement: (payload) =>
      dispatch(getCompanyAssetsUnderManagementAction(payload)),
    triggerGetInvMarketCorrelationsByCompanyName: (payload) =>
      dispatch(getInvMarketCorrelationsByCmpnyNmAction(payload)),
    triggerGetInvMarketExpectationsByCompanyName: (payload) =>
      dispatch(getInvMarketExpectationsByCompanyyNameAction(payload)),
    triggerGetAdvisorAssetsUnderManagement: (payload) =>
      dispatch(getAdvisorAssetsUnderManagementAction(payload)),
    triggerClearAdvisorAssetDetails: (payload) =>
      dispatch(clearAdvisorAssetDetailsAction(payload)),
    triggerUpdateMobileNumberByEmailId: (payload) =>
      dispatch(updateUserMobileAction(payload)),
    triggerGetClientsByAdvisorId: (payload) =>
      dispatch(getClientsByAdvisorIdAction(payload)),

    triggerGetClientsNotUpdatedForNumDays: (payload) =>
      dispatch(getClientsNotUpdatedForNumDaysAction(payload)),

    triggerGetAdvisorAssetsUnderManagementCompanyAUM: (payload) =>
      dispatch(getAdvisorAssetsUnderManagementCompanyAUMAction(payload)),

    triggersetCurrentPlansforClientId: (payload) =>
      dispatch(getPlansByClientIdSuccessAction(payload)),
    triggerSetClientViewSelected: (payload) =>
      dispatch(setClientViewSelected(payload)),
    triggerGetOutputReponseByPlanId: (payload) =>
      dispatch(getOutputResponseByPlanIdAction(payload)),
    triggerClearOutputResponseAction: (payload) =>
      dispatch(clearOutputResponseAction(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
    triggersetSelectedDisplayTermForClientId: (payload) =>
      dispatch(setSelectedDisplayTermAction(payload)),
    triggersetSelectedHorizonTermForClientId: (payload) =>
      dispatch(setSelectedHorizonTermAction(payload)),

    triggerSetAllocationIdAction: (payload) =>
      dispatch(setAllocationIdAction(payload)),

    triggerGetUserDetailsByEmailId: (payload) =>
      dispatch(getUserInformationByEmailIdAction(payload)),
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerGetInvAssetAllocationsByAdvisorId: (payload) =>
      dispatch(getInvAssetAllocationsByAdvisorIdAction(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyHome);
