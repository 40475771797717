import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderRadioGroup,
  renderFormDateField,
  renderSelectAccountDropdown,
  renderFormSelectOption,
  DeleteConfirmation,
} from '../../helpers/ClientHelper';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

export const PropertyClientContent = (props) => {
  const {
    state,
    concatContent,
    propertyOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'property',
    subTab: 'propIns',
    countVal: 'numPropIns',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      {propertyOptions && propertyOptions.length > 0 && (
        <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
          {renderSelectAccountDropdown(
            finalExistingItems,
            defaultName,
            openDeleteConfirmation,
            props,
            state,
            moduleObject
          )}
        </div>
      )}
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of insurance',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {isAddNew ? (
            <>
              {renderFormSelectOption(
                'text',
                'tlinsured',
                'insured',
                'Which property is covered?',
                propertyOptions,
                moduleObject,
                state,
                props,
                true, //isMandatory
                formErrors
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlinsured',
                'insured',
                'Which property is covered?',
                'textDropdown',
                moduleObject,
                state,
                props,
                undefined,
                true, //isDisabled
                true //isMandatory
              )}
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlCoverage',
            'coverage',
            'Liability Coverage Amount',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormDateField(
            'coverageStartYr',
            'Year when coverage started or expected to start',
            'number',
            moduleObject,
            state,
            props,
            'year',
            true
          )}
          {renderFormInputRow(
            'text',
            'tlDeductible',
            'deductible',
            'Deductible',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlPremium',
            'premium',
            'Annual Premium',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlPremiumGrowth',
            'premiumGrowth',
            'Annual growth of premium',
            'percentage',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            false, // isMandatory
            formErrors
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}
      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default PropertyClientContent;
