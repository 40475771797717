export const DashboardPlannerFields = [
  {
    label: 'Annual wages',
    name: 'wages',
    colSize: 4,
    type: 'number',
    errorText: 'Wages should be greater than 0',
    isHidden: false,
    isDollarSignRequired: true,
  },
  {
    label: 'Retirement State',
    name: 'retirementState',
    colSize: 3,
    type: 'select',
    options: [],
  },
];

export const commonFields = ['retirementState'];
