import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import greenrLogo from '../images/Greenr_logo.png';
import HomeIcon from '@mui/icons-material/Home';
import history from '../history';
import { NavLink } from 'react-router-dom';
import Controls from './controls/Controls';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { updateUserPrivacyConsent } from '../services/dashboardSvc';
// import ScrollToTop from 'react-scroll-to-top';
import {
  setPrivacyAcceptanceValue,
  setIsPrivacyChecked,
} from '../redux/actions/userAction';
import { getPrivacyAcceptanceValueSelector } from '../selectors/loginUserSelector';

const Privacy = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const privacyAcceptanceValue = useSelector((state) =>
  //   getPrivacyAcceptanceValueSelector(state)
  // );
  const data = location?.state; // Access the state data from location
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  useEffect(() => {
    if (privacyAccepted) {
      dispatch(setIsPrivacyChecked(true)); // set it to true to track changes on this page, this change is used in website page
    }
  }, [privacyAccepted, dispatch]);

  useEffect(() => {
    dispatch(setIsPrivacyChecked(false)); // set it to false on first load
  }, [dispatch]);

  const backToHome = () => {
    history.push('/');
  };

  const handlePrivacyCheckSubmit = async () => {
    dispatch(setPrivacyAcceptanceValue(privacyAccepted));
    let page = { pathname: '/home' };
    const { state = {} } = location || {};
    const { afterLogin = false } = state;
    const { userType = 'advisor', emailId = '', termsAccepted } = state;
    if (afterLogin) {
      if (privacyAccepted) {
        //TODO: Save the user privacy consent
        const reqObj = {
          emailId: emailId,
          privacyAccepted: privacyAccepted,
          privacyVersion: 1,
        };
        const [userPrivacyResponse] = await Promise.all([
          updateUserPrivacyConsent(reqObj),
        ]);
        if (
          userPrivacyResponse &&
          userPrivacyResponse.status === 200 &&
          termsAccepted
        ) {
          if (userType.toLowerCase() === 'direct') {
            page = {
              pathname: '/home', // not sure what has to be here, changed from /dashboard to /client
              state: { isDirectUser: true },
            };
          } else if (userType.toLowerCase() === 'client') {
            page = {
              pathname: '/home',
              state: { isClientUser: true },
            };
          }
          history.push(page);
        } else if (!termsAccepted) {
          page = {
            pathname: '/termsofuse',
            state: {
              userType: userType,
              afterLogin: true,
              emailId: emailId,
              privacyAccepted: privacyAccepted,
            },
          };
          history.push(page);
        } else {
          history.push('/');
        }
      } else {
        history.push('/');
      }
    } else if (!termsAccepted) {
      page = {
        pathname: '/termsofuse',
        state: {
          userType: userType,
          afterLogin: false,
          emailId: emailId,
          privacyAccepted: privacyAccepted,
        },
      };
      history.push(page);
    } else {
      history.push('/');
    }
  };

  const setPrivacyCheckValue = (event) => {
    let { checked } = event && event.target;
    setPrivacyAccepted(checked);
  };

  return (
    <>
      <div className="master-main-wrapper">
        <div className="main-header">
          <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container-fluid">
              <NavLink to={'/'} className="navbar-brand" key={`home`}>
                <img src={greenrLogo} alt="" title="" />
              </NavLink>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent1"
              >
                <ul className="navbar-nav ms-auto navbar-right-top align-items-sm-center">
                  <li className="log_out">
                    <Controls.Button
                      variant="text"
                      text={<HomeIcon />}
                      onClick={backToHome}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="main-content-wrapper m-0">
        <div className="container-fluid main-content-wrapper-inner">
          <div className="privacy_policy_outer">
            <h1>Privacy Policy</h1>

            <p>
              This Privacy Policy ("Policy") applies to
              https://greenrfinancial.com, and Green'r Financial LLC ("Company")
              and governs data collection and usage. For the purposes of this
              Privacy Policy, unless otherwise noted, all references to the
              Company include https:/greenrfinancial.com. The Company's
              application is a Financial Planning application. By using the
              Company application, you consent to the data practices described
              in this statement.
            </p>

            <h5>Collection of your Personal Information</h5>

            <p>
              In order to better provide you with products and services offered,
              the Company may collect personally identifiable information, such
              as your:
            </p>
            <ul>
              <li>First and last name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>
                Residence state, Work state, Tax filing status, and other
                information pertaining to Financial Planning.
              </li>
            </ul>
            <p>
              If you purchase the Company's products and services, we collect
              billing and credit card information. This information is used to
              complete the purchase transaction.
            </p>

            <p>
              The Company may also collect anonymous demographic information,
              which is not unique to you, such as your:
            </p>

            <ul>
              <li>Age</li>
              <li>Gender</li>
              <li>Household income</li>
            </ul>

            <p>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services. These may include: (a) registering
              for an account; (b) entering a sweepstakes or contest sponsored by
              us or one of our partners; (c) signing up for special offers from
              selected third parties; (d) sending us an email message; (e)
              submitting your credit card or other payment information when
              ordering and purchasing products and services. To wit, we will use
              your information for, but not limited to, communicating with you
              in relation to services and/or products you have requested from
              us. We also may gather additional personal or nonpersonal
              information in the future.
            </p>

            <h5>Use of your Personal Information</h5>
            <p>
              The Company collects and uses your personal information in the
              following ways:
            </p>

            <ul>
              <li>to operate and deliver the services you have requested</li>
              <li>
                - to provide you with information, products, or services that
                you request from us
              </li>
              <li>to provide you with notices about your account</li>
              <li>
                to carry out the Company's obligations and enforce our rights
                arising from any contracts entered between you and us, including
                for billing and collection
              </li>
              <li>
                to notify you about changes to our https://greenrfinancial.com
                or any products or services we offer or provide through it in
                any other way we may describe when you provide the information
                for any other purpose with your consent.
              </li>
            </ul>

            <p>
              The Company may also use your personally identifiable information
              to inform you of other products or services available from the
              Company and its affiliates.
            </p>

            <h5>Sharing Information with Third Parties</h5>

            <p>
              The Company does not sell, rent, or lease its customer lists to
              third parties.
            </p>
            <p>
              The Company may share data with trusted partners to help perform
              statistical analysis, send you email or postal mail, provide
              customer support, or arrange for deliveries. All such third
              parties are prohibited from using your personal information except
              to provide these services tothe Company, and they are required to
              maintain the confidentiality of your information.
            </p>

            <p>
              The Company may disclose your personal information, without
              notice, if required to do so by law or in the good faith belief
              that such action is necessary to: (a) conform to the edicts of the
              law or comply with legal process served on the Company or the
              site; (b) protect and defend the rights or property of the
              Company; and/or (c) act under exigent circumstances to protect the
              personal safety of users of the Company, or the public.
            </p>

            <h5>Right to Deletion</h5>
            <p>
              Subject to certain exceptions set out below, on receipt of a
              verifiable request from you, we will:
            </p>
            <ul>
              <li>Delete your personal information from our records; and</li>
              <li>
                Direct any service providers to delete your personal information
                from their records.
              </li>
            </ul>

            <p>
              Please note that we may not be able to comply with requests to
              delete your personal information if it is necessary to:
            </p>
            <ul>
              <li>
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, and provide a
                good or service requested by you, or reasonably anticipated
                within the context of our ongoing business relationship with
                you, or otherwise perform a contract between you and us;
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </li>
              <li>
                Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li>
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </li>
              <li>
                Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </li>
              <li>Comply with an existing legal obligation; or</li>
              <li>
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </li>
            </ul>
            <h5>Children Under Thirteen</h5>
            <p>
              The Company follows the principles of the Children's Online
              Privacy Protection Act ("COPPA"), the GDPR, and any other local
              laws pertaining to the collection of children's data. Any accounts
              or subscriptions created for users known to be children will
              involve parental notification and/or consent.
            </p>
            <p>
              The Company collects personally identifiable information about
              children under the age of 13.
            </p>
            <h5>Email Communications</h5>
            <p>
              From time to time, the Company may contact you via email for the
              purpose of providing announcements, promotional offers, alerts,
              confirmations, surveys, and/or other general communication.
            </p>
            <h5>External Data Storage Sites</h5>
            <p>
              We may store your data on servers provided by third-party hosting
              vendors with whom we have contracted.
            </p>
            <h5>Changes to This Statement</h5>
            <p>
              The Company reserves the right to change this Policy from time to
              time. For example, when there are changes in our services, changes
              in our data protection practices, or changes in the law. When
              changes to this Policy are significant, we will inform you. You
              may receive a notice by sending an email to the primary email
              address specified in your account, by placing a prominent notice
              on our Green'r Financial LLC, and/or by updating any privacy
              information. Your continued use of the application and/or services
              available after such modifications will constitute your: (a)
              acknowledgment of the modified Policy; and (b) agreement to abide
              and be bound by that Policy.
            </p>
            <h5>Contact Information</h5>
            <p>
              The Company welcomes your questions or comments regarding this
              Policy. If you believe that the Company has not adhered to this
              Policy, please contact the Company at:
            </p>
            <h5>
              Email Address:{' '}
              <a href="mailto:info@greenrfinancial.com">
                info@greenrfinancial.com
              </a>
            </h5>

            {data && (
              <>
                <Grid container className="signup-form-grid" spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="privacyAccepted"
                          checked={privacyAccepted}
                          value={privacyAccepted}
                        />
                      }
                      label={'I agree to accept the Privacy Policy'}
                      onChange={(event) =>
                        setPrivacyCheckValue(event, 'privacyAccepted')
                      }
                    />
                  </Grid>
                </Grid>
                <button
                  type="button"
                  className={
                    privacyAccepted
                      ? 'common-button'
                      : 'common-button secondary-button'
                  }
                  disabled={!privacyAccepted}
                  onClick={() => handlePrivacyCheckSubmit()}
                >
                  Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <ScrollToTop /> */}
    </>
  );
};

export default Privacy;
