import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderMembersInputRow,
  renderLabelRow,
  renderMembersSelectOption,
  renderInputRow,
  renderRadioGroupDual,
  renderCustomMembersInputRow,
  transformInfoCoreBackToUIType,
  validateForm,
  renderMemberLabelRowWithoutEdits,
  renderInfoSection,
  getAndUpdatePensionContribYrs,
} from '../../helpers/BuildHelperComponentV3';
import { infoCoreValidatableFields } from '../../constants/BuildTabComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildIncomeOtherIncomeComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    taxStateOptions,
    setLastStepInParent,
    setValidationError,
  } = props;
  const { retiredState, membersCount } = state;
  const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
  const [formErrors, setFormErrors] = useState([]);
  const [infoCoreDetails, setinfoCoreDetails] = useState(infoCore);

  useEffect(() => {
    if (infoCore && Object.keys(infoCore).length > 0) {
      //let tempInfoCore = getAndUpdatePensionContribYrs(infoCore, membersCount);
      let tempInfoCore = transformInfoCoreBackToUIType(infoCore);
      let validationErrors = {};
      validationErrors = validateForm(
        tempInfoCore,
        membersCount,
        infoCoreValidatableFields
      );
      if (Object.values(validationErrors).length > 0) {
        setFormErrors(validationErrors);
      }
      const { validationObj1 = [], validationObj2 = [] } = validationErrors;
      if (
        Object.values(validationObj1).length > 0 ||
        Object.values(validationObj2).length > 0
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
      setinfoCoreDetails(tempInfoCore);
    }
  }, [localPlanDetailsByPlanId, infoCore, membersCount, setValidationError]);

  const radioOptions = [
    {
      key: 'yes-radio-button',
      id: true,
      title: 'Yes',
    },
    {
      key: 'no-radio-button',
      id: false,
      title: 'No',
    },
  ];

  const [alertMessageOnAgeChange, setAlertMessageOnAgeChange] = useState('');

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  useEffect(() => {
    if (alertMessageOnAgeChange) {
      const timer = setTimeout(() => {
        setAlertMessageOnAgeChange('');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [alertMessageOnAgeChange]);

  return (
    <>
     
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlMemberOtherIncValue',
          'otherIncValue',
          'Other Annual Income (excluding rental and dividend income)',
          'currency',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          true,
          setinfoCoreDetails
        )}
        {renderInputRow(
          'text',
          'tlMemberOtherIncGrowth',
          'otherIncGrowth',
          'Other Income Growth (%)',
          'percentage',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          true,
          setinfoCoreDetails
        )}
      </div>
      <div className='tab_main_nav'>
        <div className='tab_left_nav'>
          <button>
          <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className='tab_right_nav'>
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default BuildIncomeOtherIncomeComponent;
