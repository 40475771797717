import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import '../../styles/controls/dialog.scss';

const DialogBox = (props) => {
  const {
    children,
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    dialogClass = 'dialog-container',
    dialogActionClass = 'dialog-action',
    dialogOpen,
    dialogTitle = '',
    scroll = 'paper',
    size = 'sm',
    alertDialogType = false,
    isConfirmButtonDisabled = false,
    isCancelButtonDisabled = false,
    isFullWidth = false,
    handleCancel,
    handleConfirm,
    isDialogActionRequired = true,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(dialogOpen);

  useEffect(() => {
    setIsOpen(dialogOpen);
  }, [dialogOpen]);
  const defaultHandleClose = () => setIsOpen(false);

  return (
    <Dialog
      open={isOpen}
      className={dialogClass}
      fullWidth={isFullWidth}
      maxWidth={size}
      scroll={scroll}
      {...{ ...rest }}
    >
      {dialogTitle && (
        <DialogTitle className="modal-title">{dialogTitle}</DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {isDialogActionRequired && (
        <DialogActions className={dialogActionClass}>
          <Button
            //className="action-btn secondary-action-btn"
            className="action-btn primary-action-btn"
            onClick={handleCancel || defaultHandleClose}
            variant="contained"
            disabled={isCancelButtonDisabled}
          >
            {!alertDialogType ? cancelLabel : 'OK'}
          </Button>
          {!alertDialogType && (
            <Button
              className={`action-btn ${
                !isConfirmButtonDisabled
                  ? 'primary-action-btn'
                  : 'secondary-action-btn'
              }`}
              onClick={handleConfirm}
              variant="contained"
              disabled={isConfirmButtonDisabled}
            >
              {confirmLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogBox;
