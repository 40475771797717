import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderInputRow,
  renderSelectOption,
  transformEmergencyFundsBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BuildSavingsEmergencyFundsComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const { moduleSettings: { emergencyFunds = {} } = {} } =
    localPlanDetailsByPlanId;
  const [emergencyFundsDetails, setEmergencyFundsDetails] =
    useState(emergencyFunds);

  useEffect(() => {
    if (emergencyFunds && Object.keys(emergencyFunds).length > 0) {
      let tempEmergencyFunds =
        transformEmergencyFundsBackToUIType(emergencyFunds);
      setEmergencyFundsDetails(tempEmergencyFunds);
    }
  }, [localPlanDetailsByPlanId, emergencyFunds]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
        
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlEmCashReqYrs',
          'emCashReqYrs',
          'How many years worth of expenses would you like to keep as emergency funds?',
          'number',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlEmCashPercentForDiv',
          'emCashPercentForDiv',
          'What % of emergency funds would you like to connvert into additional dividend portfolio when you retire?',
          'percentage',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
        {renderInputRow(
          'text',
          'tlDivRate',
          'divRate',
          'What is your projected dividend rate for such connverted funds?',
          'percentage',
          emergencyFundsDetails,
          'emergencyFunds',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setEmergencyFundsDetails
        )}
      </div>

      <hr />

      <div className="insured_main_outer">
        <Alert severity="info">Emerging funds  account(s) will be captured in Investments / Markets</Alert>
      </div>
      
      <div className='tab_main_nav'>
        <div className='tab_left_nav'>
            <button>
                <ArrowBackIcon /> Previous
            </button>
        </div>
        <div className='tab_right_nav'>
            <button>
                Next <ArrowForwardIcon />
            </button>
        </div>
     </div>
    </>
  );
};

export default BuildSavingsEmergencyFundsComponent;
