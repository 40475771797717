import CloseIcon from '@material-ui/icons/Close';
import DialogBox from './DialogBox';

const UserInfoPopup = (props) => {
  const { userInfo = {}, dialogOpen, setDialogState } = props;
  const { name, degree, designation, images = [], experience } = userInfo;
  return (
    <DialogBox
      dialogOpen={dialogOpen}
      handleCancel={() => setDialogState(false)}
      isDialogActionRequired={false}
    >
      <section className="popup">
        <div className="popup__content">
          <CloseIcon className="close" onClick={() => setDialogState(false)} />
          <div className="popup_main_content">
            <div className="who_we_img_outer">
              <div className="who_we_img_outer_inner">
                <img src={images[0].name} alt="" title="" />
              </div>
            </div>
            <div className="Who_we_logo_outer">
              <div className="Who_we_logo_outer_user_details">
                <h6>
                  {name}, {degree}
                </h6>
                <h5>{designation}</h5>
              </div>
              <div className="Who_we_experience_outer">
                <span className="count">{experience}</span>
                <span className="experience_outer_text">
                  Years of experience
                </span>
              </div>
              <div className="Who_we_logos">
                <ul>
                  {images
                    .filter((f, i) => i > 0) //filtering out 0th image as it is same as the persons image
                    .map((m) => (
                      <li>
                        <img src={m.name} alt="" title="" />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DialogBox>
  );
};
export default UserInfoPopup;
