import axios from 'axios';
export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  customGet,
  customPost,
};

function get(url, isPublic = true) {
  const requestOptions = {
    method: 'GET',
  };
  if (isPublic) {
    return axios(url, requestOptions).then(handleResponse).catch(handleError);
  } else {
    const authorizationToken = localStorage.getItem('authorizationToken');
    const axiosInstance = axios.create({
      withCredentials: true,
      headers: {
        Authorization: `${authorizationToken}`,
      },
    });
    return axiosInstance(url, requestOptions)
      .then(handleResponse)
      .catch(handleError);
  }
}

function customGet(url, payload) {
  const requestOptions = {
    method: 'GET',
  };
  const { username, password } = payload;
  const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json', // Default content type
      // Add Authorization header with Basic authentication
      Authorization:
        'Basic ' + window.btoa(username?.toLowerCase() + ':' + password),
    },
  });
  return axiosInstance
    .get(url, requestOptions)
    .then(handleCustomResponse)
    .catch(handleError);
}

function customPost(url, payload) {
  const { username, password } = payload;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${window.btoa(
      username?.toLowerCase() + ':' + password
    )}`,
  };
  return axios
    .post(url, payload, { headers })
    .then(handleResponse)
    .catch(handleError);
}
// const axiosInstance = axios.create({
//   // baseURL: 'http://107.20.29.93:9090',
//   // timeout: 5000, // Timeout in milliseconds
//   method: 'POST',
//   data: JSON.stringify(payload),
//   headers: {
//     'Content-Type': 'application/json', // Default content type
//     // Add Authorization header with Basic authentication
//     Authorization:
//       'Basic ' + window.btoa(username?.toLowerCase() + ':' + password),
//   },
// });

function post(url, body, isOldApi = true) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(body),
  };
  if (isOldApi) {
    return axios(url, requestOptions, { timeOut: 800000 })
      .then(handleResponse)
      .catch(handleError);
  } else {
    const authorizationToken = localStorage.getItem('authorizationToken');
    const axiosInstance = axios.create({
      withCredentials: true,
      headers: {
        Authorization: `${authorizationToken}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    });
    return axiosInstance(url, requestOptions).then(handleResponse);
  }
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return axios(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: 'DELETE',
  };
  return axios(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  const { data, status } = response;
  if (status === 200) {
    return { status, data };
  }
  return response;
}

function handleCustomResponse(response) {
  const { data, status, headers } = response;
  if (status === 200) {
    const authorizationToken = headers.authorization;
    localStorage.setItem('authorizationToken', authorizationToken);
    // const cookies = headers.raw()['set-cookie'];
    // localStorage.setItem('sessionCookie', cookies);
    return { status, data };
  }
  return response;
}

function forceLogout() {
  // Clear any stored authentication tokens
  sessionStorage.clear();
  localStorage.clear();
  // Redirect to the login page or any other appropriate page
  window.location.href = '/';
}

function handleError(error) {
  let errorInfo = {};
  // Check if the error is a network error or a server error
  if (error.response && error.response.data) {
    const {
      loginMsg = '',
      loginSuccess = false,
      message = '',
      error: errorMessage = '',
      status = '',
    } = error.response.data || {};
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorInfo =
      !loginSuccess && loginMsg !== ''
        ? error.response.data
        : {
            loginSuccess: false,
            loginMsg:
              errorMessage !== '' && message !== '' && status !== 200
                ? message
                : 'Something went wrong, please try again',
            statusCode: error.response?.status || status || 500,
          };
  } else if (error.request) {
    // The request was made but no response was received
    errorInfo = {
      loginSuccess: false,
      loginMsg: 'Request Error',
      statusCode: 400,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    errorInfo = {
      loginSuccess: false,
      loginMsg: 'Error: ' + error.message,
      statusCode: 500,
    };
  }
  const {
    loginSuccess = false,
    loginMsg = '',
    statusCode = 500,
  } = errorInfo || {};
  if (
    statusCode !== 200 &&
    !loginSuccess &&
    (loginMsg.toLowerCase().includes('invalid token') ||
      loginMsg.toLowerCase().includes('unauthorized'))
  ) {
    forceLogout();
  }
  // Return a custom error object with the error message and status code
  return Promise.reject({ errorInfo: errorInfo });
}

export const GET = get;

export const POST = post;

export const CUSTOMPOST = customPost;

export const DELETE = _delete;

export const CUSTOMGET = customGet;
