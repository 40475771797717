import { DATATYPES } from '../common';

export const commonFields = [];

export const IncomeConstants = [
  {
    label: 'member-1',
    fields: [
      {
        label: 'Wages & Bonus',
        name: 'wages',
        colSize: 4,
        type: 'number',
        errorText: 'Wages should be greater than 0',
        isDollarSignRequired: true,
      },
      {
        label: 'Wage Growth',
        name: 'wageGrowth',
        colSize: 4,
        type: 'number',
        errorText: '% field should range from 0 to 100',
        dataType: DATATYPES.PERCENTAGE,
      },
      {
        label: 'RSU (% of wages)',
        name: 'percentRsuGrant',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,

        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'RSU vesting years',
        name: 'rsuVestingYrs',
        colSize: 5,
        type: 'number',
        errorText: '% field should range from 0 to 100',
      },
    ],
  },
  {
    label: 'member-2',
    fields: [
      {
        label: 'Wages & Bonus',
        name: 'wages',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,
        errorText: 'Wages should be greater than 0',
      },
      {
        label: 'Wage Growth',
        name: 'wageGrowth',
        colSize: 4,
        type: 'number',
        errorText: '% field should range from 0 to 100',
        dataType: DATATYPES.PERCENTAGE,
      },
      {
        label: 'RSU (% of wages)',
        name: 'percentRsuGrant',
        colSize: 5,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,

        errorText: '% field should range from 0 to 100',
      },
      {
        label: 'RSU vesting years',
        name: 'rsuVestingYrs',
        colSize: 5,
        type: 'number',
        errorText: '% field should range from 0 to 100',
      },
    ],
  },
];
