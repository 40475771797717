/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Button, Grid } from '@mui/material';

const JourneyButtons = (props) => {
  const { currentStep, handlePrevious, handleNext, isLastStep } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="simulate-buttons next_previous_btn_group">
          {currentStep >= 1 && (
            <Button
              variant="contained"
              size="Medium"
              onClick={handlePrevious} 
              className='previous_button'
              style={{ padding: '10px', marginRight: '10px'  }}
            >
              Previous
            </Button>
          )}
          {!isLastStep && (
            <Button
              variant="contained"
              size="Medium"
              onClick={handleNext}
              style={{ padding: '10px'}} 
              className='next_button'
            >
              Next
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default JourneyButtons;
