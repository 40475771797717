import React from 'react';
import { Grid } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DashboardSummaryTabsContainer(props) {
  let fields_per_column = [];
  const COLUMN_CONFIG = [4, 4];
  const { dashboard, isOutputResponseLoading } = props;
  const tempDashboardSummaryKeys = dashboard && [...Object.keys(dashboard)]; // can we make use of this in case they need to replace block 1 with something else at block 1's place
  let tempDashboardSummaryValues = dashboard && [...Object.values(dashboard)];
  tempDashboardSummaryValues =
    (tempDashboardSummaryValues &&
      tempDashboardSummaryValues.filter((f) => !f.length)) ||
    [];
  if (tempDashboardSummaryValues && tempDashboardSummaryValues.length > 0) {
    for (let index = 0; index < COLUMN_CONFIG.length; index += 1) {
      fields_per_column.push(
        tempDashboardSummaryValues.splice(0, COLUMN_CONFIG[index])
      );
    }
  }
  const renderDashboardSummaryItems = (value) => {
    return (
      <ul className="chrono-content-items">
        {value &&
          value.rows &&
          value.rows.length > 0 &&
          value.rows.map((item, idx) => (
            // <React.Fragment key={`${item}-${idx + 1}`}>
            <li
              key={item.label + `${idx}`}
              // style={{
              //   width: `calc(100% / 4})`,
              // }}
              className="chrono-item"
            >
              <div className="chrono-wrapper">
                <span className="chrono-label">{item.label}</span>
                <span className="chrono-value">{item.value}</span>
              </div>
            </li>
            // </React.Fragment>
          ))}
      </ul>
    );
  };
  return (
    <div className="dashboard-summary-container">
      <Accordion sx={{ background: 'rgba(255, 255, 255, 0.3)' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ fontSize: '14px', fontWeight: '600' }}
        >
          Client Profile
        </AccordionSummary>
        <AccordionDetails>
          <div className="chrono-content-container">
            {fields_per_column && fields_per_column.length > 0 ? (
              fields_per_column.map((tab, tabIndex) => (
                <Grid
                  container
                  spacing={2}
                  className="chrono-list-wrapper"
                  key={`row-${tabIndex + 1}`}
                >
                  {tab && tab.length > 0
                    ? tab.map((item, index) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={3}
                          key={item.heading + `${index}`}
                          // style={{ width: `calc(100% / ${tab.length})` }}
                          className="chrono-list"
                        >
                          <div>{item.heading}</div>
                          {renderDashboardSummaryItems(item)}
                        </Grid>
                      ))
                    : tabIndex === 0 && (
                        <Stack
                          sx={{ width: '100%', padding: '5px' }}
                          spacing={2}
                        >
                          <Alert severity="info">
                            Please select client, plan and click on Analyze.{' '}
                          </Alert>
                        </Stack>
                      )}
                </Grid>
              ))
            ) : !isOutputResponseLoading ? (
              <Stack sx={{ width: '100%', padding: '5px' }} spacing={2}>
                <Alert severity="info">
                  Please select client, plan and click on Analyze.{' '}
                </Alert>
              </Stack>
            ) : (
              <></>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
