import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
  renderFooterSubmitCancelButtons,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpenseLoanAccordionNames } from '../../constants/BuildTabComponentV3';

const [CREDITCARDS, STUDENTLOANS, OTHERLOANS] = ExpenseLoanAccordionNames;

export const BuildExpensesLoansComponent = (props) => {
  const {
    state,
    concatContent,
    allMenuItems,
    setLastStepInParent,
    isFormValid,
    formErrors,
    renderStudentLoansComponent,
    expandedPanel,
    handleAccordionChange,
    renderOtherLoansComponent,
    renderCreditCardsComponent,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleDebt',
    mainTab: 'infoStudentLoans',
    subTab: 'studentLoans',
    countVal: 'numSL',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Accordion_outer faq-container-new">
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === CREDITCARDS}
          onChange={handleAccordionChange(CREDITCARDS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Credit Cards</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === CREDITCARDS && renderCreditCardsComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === STUDENTLOANS}
          onChange={handleAccordionChange(STUDENTLOANS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Student Loans</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === STUDENTLOANS && renderStudentLoansComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === OTHERLOANS}
          onChange={handleAccordionChange(OTHERLOANS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Other Loans</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === OTHERLOANS && renderOtherLoansComponent()}
          </AccordionDetails>
        </Accordion>
      </div>

      <Alert severity="info">Mortgages will be captured in Properties</Alert>

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default BuildExpensesLoansComponent;
