import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  CLEAR_USER_DETAILS,
  SET_PRIVACY_ACCEPTANCE_VALUE,
  SET_PREVIOUS_PATH_VALUE,
  SET_IS_PRIVACY_CHECKED,
  SET_IS_AUTHENTICATED,
  SET_IS_TERMS_CHECKED,
  SET_TERMS_ACCEPTANCE_VALUE,
} from '../actions/userAction';

//remove below code and read it from redux instead of browser
const getLoggedInUserDetails = () => {
  const user = sessionStorage.getItem('userDetails');
  if (user) {
    return JSON.parse(user);
  }
  return {};
};

const initialState = {
  userDetails: getLoggedInUserDetails(),
  privacyAcceptanceValue: false,
  outputResponse: {},
  previousPathValue: null,
  isPrivacyChecked: false,
  isAuthenticated: false,
  isTermsChecked: false,
  termsAcceptanceValue: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: {},
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetails: action.payload,
      };
    case CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: {},
        outputResponse: {},
      };
    case SET_PRIVACY_ACCEPTANCE_VALUE:
      return {
        ...state,
        privacyAcceptanceValue: action.payload,
      };
    case SET_TERMS_ACCEPTANCE_VALUE:
      return {
        ...state,
        termsAcceptanceValue: action.payload,
      };
    case SET_PREVIOUS_PATH_VALUE:
      return {
        ...state,
        previousPathValue: action.payload,
      };
    case SET_IS_PRIVACY_CHECKED:
      return {
        ...state,
        isPrivacyChecked: action.payload,
      };
    case SET_IS_TERMS_CHECKED:
      return {
        ...state,
        isTermsChecked: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
}
