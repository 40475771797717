import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
} from '../../helpers/BuildHelperComponentV3';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BuildInsuranceComponentV3 = (props) => {
  const {
    state,
    concatContent,
    allMenuItems,
    autoOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleAccordionChange,
    expandedPanel,
    renderBuildVehiclesComponent,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'auto',
    subTab: 'autoIns',
    countVal: 'numVehicles',
  };
  const { formDetails, isEditable, isAddNew } = state;
  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  return (
    <Typography>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject,
          autoOptions && autoOptions.length === 0 ? true : false
        )}
      </div>
      {autoOptions && autoOptions.length === 0 && (
        <div className="insured_main_outer">
          <Alert severity="warning">
            <p className="select-bank-text">
              Please add your vehicles to proceed further
            </p>
          </Alert>
        </div>
      )}
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of insurance',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew,
            isEditable
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {isAddNew ? (
            <>
              {renderFormSelectOption(
                'text',
                'tlinsured',
                'insured',
                'Which vehicle is covered?',
                autoOptions,
                moduleObject,
                state,
                props,
                true, //isMandatory
                formErrors
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlinsured',
                'insured',
                'Which vehicle is covered?',
                'textDropdown',
                moduleObject,
                state,
                props,
                undefined,
                true, //isDisabled,
                true, // isMandatory
                formErrors,
                isAddNew,
                isEditable
              )}
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlCoverage',
            'coverage',
            'Liability Coverage Amount',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormDateField(
            'coverageStartYr',
            'Year when coverage started or expected to start',
            'number',
            moduleObject,
            state,
            props,
            'year',
            true,
            false,
            [],
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlDeductible',
            'deductible',
            'Deductible',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlPremium',
            'premium',
            'Annual Premium',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlPremiumGrowth',
            'premiumGrowth',
            'Annual growth of premium',
            'percentage',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            false, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </Typography>
  );
};

export default BuildInsuranceComponentV3;
