export const GET_OUTPUT_RESPONSE_BY_PLAN_ID = 'output:response:advisorId';
export const GET_OUTPUT_RESPONSE_BY_PLAN_ID_ERROR =
  'output:response:advisorId:error';
export const GET_OUTPUT_RESPONSE_BY_PLAN_ID_SUCCESS =
  'output:response:advisorId:success';
export const OUTPUT_RESPONSE_LOADING = 'output:response:loading';
export const CLEAR_OUTPUT_RESPONSE = 'output:response:clear';
export function setOutputResponseLoadingAction(payload) {
  return {
    type: OUTPUT_RESPONSE_LOADING,
    payload,
  };
}

export function getOutputResponseByPlanIdAction(payload) {
  return {
    type: GET_OUTPUT_RESPONSE_BY_PLAN_ID,
    payload: payload,
  };
}

export function getOutputResponseByPlanIdSuccessAction(payload) {
  return {
    type: GET_OUTPUT_RESPONSE_BY_PLAN_ID_SUCCESS,
    payload,
  };
}

export function getOutputResponseByPlanIdErrorAction(payload) {
  return {
    type: GET_OUTPUT_RESPONSE_BY_PLAN_ID_ERROR,
    payload,
  };
}

export function clearOutputResponseAction(payload) {
  return {
    type: CLEAR_OUTPUT_RESPONSE,
    payload,
  };
}
