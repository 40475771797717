import React, { Component } from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import dumbbell from 'highcharts/modules/dumbbell.js';
import lollipop from 'highcharts/modules/lollipop.js';
import bullet from 'highcharts/modules/bullet.js';
import variablePie from 'highcharts/modules/variable-pie.js';
import HighchartsReact from 'highcharts-react-official';
highchartsMore(Highcharts);
bullet(Highcharts);
variablePie(Highcharts);
dumbbell(Highcharts);
lollipop(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol;

export default class ChartContainer extends Component {
  constructor(props) {
    super(props);
    // Create a ref to store the chart instance
    this.chartRef = React.createRef();
  }

  getChart = (chart) => {
    this.chartRef.current = chart;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.chartRef.current) {
      this.chartRef.current.reflow();
    }
  }

  render() {
    const { options, selectedChart, index, colSize, chartType, chartTitle } =
      this.props;
    const selectedChartId = index
      ? chartType + '_' + selectedChart + '_' + index
      : chartType + '_' + selectedChart;
    return (
      <>
        {chartTitle ? ( //this one is specific to bullet, as setting title in options is not working as expected
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10px',
            }}
          >
            <label
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {chartTitle}
            </label>
          </div>
        ) : null}
        <HighchartsReact
          id={selectedChartId}
          highcharts={Highcharts}
          options={options}
          callback={this.getChart}
        />
      </>
    );
  }
}
