import { DATATYPES } from '../common';

export const secondHomesConstants = [
  {
    label: 'Purchase Date',
    name: 'purchaseDate',
    colSize: 6,
    type: 'date',
    errorText: 'Invalid Date format',
  },
  {
    label: 'Purchase Price',
    name: 'purchasePrice',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Current value',
    name: 'value',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Appreciation rate',
    name: 'appreciationRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Maintenance expense',
    name: 'aggMaintenanceExp',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Expense growth',
    name: 'aggMaintenanceExpGrowth',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Orig/refi loan dt',
    name: 'origOrRefiLoanDate',
    colSize: 6,
    type: 'date',
    errorText: 'Invalid Date',
  },
  {
    label: 'Loan amount',
    name: 'origOrRefiLoanAmount',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Amount outstanding',
    name: 'loanAmountOutstanding',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Loan term yrs',
    name: 'loanTerm',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
  },
  {
    label: 'Fixed rate',
    name: 'fixedIntRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Domicile state',
    name: 'propDomicile',
    type: 'select',
    options: [],
  },
  {
    label: 'Prop tax rate',
    name: 'taxRate',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Tax basis',
    name: 'taxBasis',
    colSize: 6,
    type: 'number',
    errorText: 'Value should be greater than 0',
    isDollarSignRequired: true,
  },
  {
    label: 'Basis growth',
    name: 'taxBasisGrowth',
    colSize: 6,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {},
  {},
  {},
];
