import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';

export function Input(props) {
  const {
    name,
    label,
    value,
    isValid = true,
    errorText = 'Invalid Field',
    type = 'text',
    onChange,
    helperText = '',
    disabled = false,
    placeholder = '',
    required = false,
    onBlur = undefined,
    max,
    min,
    maxLength = 2048,
    minLength = '1',
    className = '',
  } = props;

  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputError) {
        setInputError(false);
      }
    }, 2000);
    // Cleanup function to clear the timeout if component unmounts or the effect re-runs
    return () => clearTimeout(timer);
  }, [inputError]);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > maxLength) {
      setInputError(true);
    } else {
      setInputError(false);
      onChange(event, name);
    }
  };
  const handleOnBlur = (event) => {
    if (onBlur && typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  return (
    <div className="controls-input">
      <FormControl fullWidth size="small">
        <label className={required ? `${className} required` : `${className}`}>
          {label}
        </label>
        <input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleOnBlur}
          type={type}
          onKeyDown={(e) =>
            type === 'number' &&
            ['e', 'E', '+', '-'].includes(e.key) &&
            e.preventDefault()
          }
          disabled={disabled}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength} // Apply maxLength
        />
      </FormControl>
      {helperText && <p>{helperText}</p>}
      {inputError && (
        <p className="error-text">Input cannot exceed {maxLength} characters</p>
      )}
      {!isValid && <p className="error-text">{errorText}</p>}
    </div>
  );
}

export function InputWithBlur(props) {
  const {
    name,
    label,
    value,
    id = '',
    isValid = true,
    errorText = 'Invalid Field',
    type = 'text',
    onChange,
    onBlur = undefined,
    helperText = '',
    placeholder = '',
    required = false,
    maxLength = 2048,
    minLength = '1',
    className = '',
    disabled = false,
  } = props;

  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputError) {
        setInputError(false);
      }
    }, 2000);
    // Cleanup function to clear the timeout if component unmounts or the effect re-runs
    return () => clearTimeout(timer);
  }, [inputError]);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > maxLength) {
      setInputError(true);
    } else {
      setInputError(false);
      onChange(event, name);
    }
  };

  const handleOnBlur = (event) => {
    if (onBlur && typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  return (
    <div className="controls-input">
      <FormControl fullWidth size="small">
        <label className={required ? `${className} required` : `${className}`}>
          {label}
        </label>
        <input
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(event) => handleChange(event, name)}
          onBlur={(event) => handleOnBlur(event)}
          type={type}
          maxLength={maxLength} // Apply maxLength
          minLength={minLength}
          disabled={disabled}
        />
      </FormControl>
      {helperText && <p>{helperText}</p>}
      {inputError && label?.toLowerCase() === 'password' ? (
        <p className="error-text">
          Password cannot exceed {maxLength} characters
        </p>
      ) : inputError ? (
        <p className="error-text">
          This field cannot exceed {maxLength} characters
        </p>
      ) : (
        <></>
      )}
      {!isValid && <p className="error-text">{errorText}</p>}
    </div>
  );
}
