import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import Footer from '../components/shared/Footer';
import NewHeader from '../components/shared/newUI/NewHeader';
import { USER_TYPES } from '../constants/common';

const NewHome =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/NewHome'))
    : require('../components/NewHome').default;
const CompanyHome =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/CompanyHome'))
    : require('../components/CompanyHome').default;
const Privacy =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/Privacy'))
    : require('../components/Privacy').default;
const Terms =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/Terms'))
    : require('../components/Terms').default;
const IndividualClient =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/IndividualClient/IndividualClient'))
    : require('../components/IndividualClient/IndividualClient').default;
const AdminDashboard =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/AdminDashboard'))
    : require('../components/AdminDashboard').default;
// const NewSolutions =
//   process.env.NODE_ENV === 'production'
//     ? lazy(() => import('../components/NewSolutions'))
//     : require('../components/NewSolutions').default;
const SolutionsV4 =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/SolutionsV4'))
    : require('../components/SolutionsV4').default;
// const BuildComponentV3 =
//   process.env.NODE_ENV === 'production'
//     ? lazy(() => import('../components/BuildComponentV3'))
//     : require('../components/BuildComponentV3').default;
const BuildComponent =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/BuildComponentV4'))
    : require('../components/BuildComponentV4').default;
const Client =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/Client'))
    : require('../components/Client').default;
const Insights =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/Insights'))
    : require('../components/Insights').default;
// const MiboT =
//   process.env.NODE_ENV === 'production'
//     ? lazy(() => import('../components/MiboT'))
//     : require('../components/MiboT').default;
const ForgotPassword =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/ResetPassword'))
    : require('../components/ResetPassword').default;
const ChangePassword =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/ChangePassword'))
    : require('../components/ChangePassword').default;
const ReportTemplate =
  process.env.NODE_ENV === 'production'
    ? lazy(() => import('../components/Reports/ReportTemplate'))
    : require('../components/Reports/ReportTemplate').default;

const noHeaderRoutes = [
  '/individualClient',
  '/forgotPassword',
  '/changePassword',
  '/setPassword',
  '/privacypolicy',
  '/termsofuse',
];

const Router = ({ history }) => {
  const location = useLocation();
  // const userDetails = useSelector((state) => getLoggedInUserDetails(state)); // use this and remove data from browser session
  function isLoggedIn() {
    if (sessionStorage.getItem('userDetails')) {
      // if (userDetails && Object.keys(userDetails).length > 0) {// use this and remove data from browser session
      return true;
    }
    return false;
  }

  const requireAuth = (nextState, replace) => {
    const allowedRoutesWithoutLogin = ['/privacypolicy', '/termsofuse']; // Add more routes as needed

    const pathname = nextState.location.pathname;

    if (!isLoggedIn() && !allowedRoutesWithoutLogin.includes(pathname)) {
      replace({
        pathname: '/',
      });
    }
  };

  const checkIfDirectUser = (nextState, replace) => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails')); // remove this and read from selectors
    return isLoggedIn() && userDetails && userDetails.userType === 'direct';
  };

  const checkIfUserIsBusinessUser = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const { userType } = userDetails || '';
    return userDetails ? userType === USER_TYPES.BUSINESS_ADMIN : false;
  };

  const shouldRenderHeader = noHeaderRoutes.indexOf(location.pathname) === -1;
  const IsUserBusinessUser = checkIfUserIsBusinessUser();

  return (
    <div className="retirement-plan-container">
      {shouldRenderHeader && (
        <Suspense fallback={<div>Loading header...</div>}>
          <NewHeader
            IsUserBusinessUser={IsUserBusinessUser}
            history={history}
          />
        </Suspense>
      )}
      <Suspense fallback={<div>...</div>}>
        <Switch>
          <Route
            exact
            path="/home"
            render={(props) => <NewHome {...props} isV2={true} />}
            onEnter={requireAuth}
          />
          <Route
            exact
            path="/companyHome"
            render={(props) => <CompanyHome {...props} isV2={true} />}
            onEnter={requireAuth}
          />
          <Route
            exact
            path="/individualClient"
            render={(props) => (
              <>
                <IndividualClient {...props} />
                <Footer />
              </>
            )}
            onEnter={checkIfDirectUser}
          />
          <Route
            exact
            path={'/admin'}
            onEnter={requireAuth}
            render={(props) => <AdminDashboard {...props} />}
          />
          {/* <Route
            exact
            path={'/solutions'}
            onEnter={requireAuth}
            render={(props) => <NewSolutions {...props} isV2={true} />}
          /> */}
          <Route
            exact
            path={'/solutions'}
            onEnter={requireAuth}
            render={(props) => {
              const locationKey = props.location.key;
              return <SolutionsV4 {...props} isV2={true} key={locationKey} /> //key is to force rerender solutions component to directly come to home page of solutions from any of the sub tabs
            }
            }
          />
          <Route
            exact
            path={'/build'}
            onEnter={requireAuth}
            render={(props) => {
              const locationKey = props.location.key;
              return <BuildComponent isV2={true} key={locationKey} /> //key is to force rerender Build component to directly come to home page of build from any of the sub tabs
            }
            }
          />
          {/* <Route
            exact
            path={'/buildv3'}
            onEnter={requireAuth}
            render={(props) => <BuildComponentV3 {...props} isV2={true} />}
          /> */}
          <Route
            exact
            path={'/insights'}
            onEnter={requireAuth}
            render={(props) => <Insights {...props} isV2={true} />}
          />
          {/* <Route
            exact
            path="/miboT"
            onEnter={requireAuth}
            render={(props) => <MiboT />}
          /> */}
          <Route
            exact
            path={'/forgotPassword'}
            render={(props) => (
              <>
                <ForgotPassword {...props} />
                <Footer />
              </>
            )}
          />
          <Route
            exact
            path={'/changePassword'}
            render={(props) => (
              <>
                <ChangePassword {...props} />
                <Footer />
              </>
            )}
          />
          <Route
            exact
            path={'/setPassword'}
            render={(props) => (
              <>
                <ChangePassword {...props} />
                <Footer />
              </>
            )}
          />
          <Route
            exact
            path={'/reports'}
            onEnter={requireAuth}
            render={(props) => <ReportTemplate {...props} isV2={true} />}
          />
          <Route
            exact
            path={'/privacypolicy'}
            render={(props) => <Privacy {...props} />}
          />
          <Route exact path={'/termsofuse'} render={(props) => <Terms />} />
        </Switch>
      </Suspense>
    </div>
  );
};
export default Router;
