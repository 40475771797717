import { DATATYPES } from '../common';

export const commonFields = [];

export const AssetGoalsConstants = [
  {
    label: 'home',
    fields: [
      {
        label: 'Purchase Dt',
        name: 'purchaseDate',
        colSize: 3,
        type: 'date',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Purchase Price',
        name: 'purchasePrice',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Loan amount',
        name: 'origOrRefiLoanAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Loan Years',
        name: 'loanTerm',
        colSize: 4,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Interest Rate',
        name: 'fixedIntRate',
        colSize: 4,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Appreciation',
        name: 'appreciationRate',
        colSize: 4,
        dataType: DATATYPES.PERCENTAGE,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Maintenance',
        name: 'aggMaintenanceExp',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
    ],
  },
  {
    label: 'vehicles',
    fields: [
      {
        label: 'Purchase Dt',
        name: 'paymentStartDate',
        colSize: 3,
        type: 'date',
        isDisabled: false,
      },
      {
        label: 'Purchase Price',
        name: 'purchasePrice',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Loan amount',
        name: 'loanAmount',
        colSize: 4,
        type: 'number',
        isDollarSignRequired: true,

        errorText: 'Expenses should be greater than 0',
      },
      {
        label: 'Loan Months',
        name: 'termMonths',
        colSize: 4,
        type: 'number',
        errorText: 'Expenses should be greater than 0',
      },

      {
        label: 'Interest Rate',
        name: 'apr',
        colSize: 4,
        type: 'number',
        dataType: DATATYPES.PERCENTAGE,
        errorText: 'Expenses should be greater than 0',
      },
    ],
  },
];
