import { DATATYPES } from '../common';
export const savingsDetails = [
  {
    label: '401k self (% of wages)',
    name: 'selfContrib401k',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: '401k Employer (% of Self)',
    name: 'employerContrib401k',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Roth (% of 401k)',
    name: 'percentRoth401k',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'IRA (% of Wages)',
    name: 'iraContrib',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Roth (% of IRA)',
    name: 'percentRothIra',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {},
  {
    label: 'Def Comp (% of wages)',
    name: 'percentDefferedComp',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Pension (% of wages)',
    name: 'percentPensionGrant',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'RSU (% of wages)',
    name: 'percentRsuGrant',
    colSize: 5,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,

    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'RSU vesting years',
    name: 'rsuVestingYrs',
    colSize: 5,
    type: 'number',
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Age @ first tranche vesting',
    name: 'ageWhenRsuVestingStarts',
    colSize: 5,
    type: 'number',
    errorText: '% field should range from 0 to 100',
  },
  {},
  {
    label: 'Use savings to build ret',
    name: 'useSavingsForRetSavings',
    colSize: 5,
    type: 'select',
    options: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
    errorText: '% field should range from 0 to 100',
  },
  {},
  {},
  {},
  {},
  {},
];

export const dependentFields = ['useSavingsForRetSavings'];
