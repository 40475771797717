export const WAGES_EXPENSE_PROFILE = 'wagesAndExpenseProfile';
export const SAVINGS_DETAILS = 'savingsDetails';
export const REVIEW_TAX_DETAILS = 'reviewTaxDetails';
export const REVIEW_PREFERENCES = 'reviewpreferences';

// Estate Constants
export const HEIR_DATA = 'heirData';

export const REVIEW_INVESTMENTS = 'reviewinvestments';
export const REVIEW_INVESTMENT_RETURNS = 'reviewinvestmentreturns';
export const REVIEW_MISCINCOME_DEDUCTIONS = 'reviewmiscincomedeductions';
export const REVIEW_KIDS_INFO = 'planforkidsorreviewkidsinfo';
export const PLANTOBUYPRIMARYHOME = 'plantobuyprimaryhome';
export const PLANTOBUYCARORBOAT = 'plantobuycarorboat';
export const REVIEWRETIREMENTPLAN = 'reviewretirementplan';
export const COMPAREBOTHROTHVSTRADITIONAL = 'comparebothrothvstraditional';
export const PLANTOBUYSECONDHOME = 'plantobuysecondhome';
export const REVIEWESTATETRANSFERINFO = 'reviewestatetransferinfo';

export const REVIEWCREDITCARDS = 'reviewcreditcards';
export const REVIEWSTUDENTLOANS = 'reviewstudentloans';
export const REVIEWOTHERLOANS = 'reviewOtherLoans';

export const INVESTMENTBALANCES = 'investmentbalances';
export const INVESTMENTRETURNS = 'investmentReturns';
export const INVESTMENTVOLATILITY = 'investmentVolatility';
//newly added
export const REVIEW_EXPENSE_DETAILS = 'reviewexpensedetails';

export const PLAN_FOR_VACATION = 'planForVacation';
export const PLAN_FOR_VEHICLE = 'planForVehicle';

export const RETIREMENT_CHOICES = 'retirementChoices';
export const RETIREMENT_ROTHVSTRADITIONAL = 'rothvsTraditional';

export const LIFESTYLE = 'LIFESTYLE';
export const LIFEEVENTS = 'LIFE EVENTS';
export const ESTATE = 'ESTATE';
export const DEBT = 'DEBT';
export const RETIREMENT = 'RETIREMENT';
export const REALESTATE = 'REAL ESTATE';
export const INSURANCE = 'INSURANCE';
export const INVESTMENTS = 'INVESTMENTS';

export const TERM_LIFE = 'termLife';
export const WHOLE_LIFE = 'wholeLife';
export const LONG_TERM_CARE = 'longTermCare';
export const LIABILITY_UMBRELLA = 'liabilityOrUmbrella';
export const PROPERTY_COVERAGE = 'propertyCoverage';

export const PRIMARY_HOME = 'primaryHome';
export const SECOND_HOMES = 'secondHomes';
export const INVESTMENT_PROPERTIES = 'investmentProperties';

export const progressIndColorCodes = {
  green: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
  yellow: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
  red: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
};

export const lifeStyleMenuItems = [
  {
    name: 'Wages & Expense Profile',
    // link: '/lifeStyle/reviewWagesAndExpenseProfile',
    key: WAGES_EXPENSE_PROFILE,
    isHighlighted: true,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    value: 'planBasics',
  },
  {
    name: 'Expense Details',
    key: REVIEW_EXPENSE_DETAILS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 60,
    value: 'expenseDetails',
  },
  {
    name: 'Savings Details',
    key: SAVINGS_DETAILS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'retSavings',
  },
  {
    name: 'Tax Details',
    key: REVIEW_TAX_DETAILS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'taxDeductions',
  },

  {
    name: 'Preferences',
    key: REVIEW_PREFERENCES,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'preferences',
  },
];

export const lifeEventMenuItems = [
  {
    name: 'Kids details',
    key: REVIEW_KIDS_INFO,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'kids',
  },
  {
    name: 'Plan for vacation',
    key: PLAN_FOR_VACATION,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'vehicles',
  },
  {
    name: 'Plan for vehicle',
    key: PLAN_FOR_VEHICLE,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'vacation',
  },
];

export const retirementMenuItems = [
  {
    name: 'Retirement Choices',
    key: RETIREMENT_CHOICES,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'retChoices',
  },
  {
    name: 'Roth vs. Traditional',
    key: RETIREMENT_ROTHVSTRADITIONAL,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'rothVsTraditional',
  },
];

export const estateMenuItems = [
  {
    name: 'Heir Data',
    key: HEIR_DATA,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'estate',
  },
];

export const debtMenuItems = [
  {
    name: 'Credit Cards',
    key: REVIEWCREDITCARDS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'creditCards',
  },
  {
    name: 'Student Loans',
    key: REVIEWSTUDENTLOANS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'studentLoans',
  },
  {
    name: 'Other Loans',
    key: REVIEWOTHERLOANS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'otherLoans',
  },
];

export const investmentMenuItems = [
  {
    name: 'Investment Balances',
    key: INVESTMENTBALANCES,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'invBalances',
  },
  {
    name: 'Investment Returns',
    key: INVESTMENTRETURNS,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'invReturns',
  },
  {
    name: 'Investment Volatility',
    key: INVESTMENTVOLATILITY,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'invVolatility',
  },
];
export const realEstateMenuItems = [
  {
    name: 'Primary Home',
    key: PRIMARY_HOME,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'primaryHome',
  },
  {
    name: 'Second Homes',
    key: SECOND_HOMES,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'secondHome',
  },
  {
    name: 'Investment Properties',
    key: INVESTMENT_PROPERTIES,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'invProps',
  },
];

export const insuranceMenuItems = [
  {
    name: 'Term Life',
    key: TERM_LIFE,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'termLife',
  },
  {
    name: 'Whole Life',
    key: WHOLE_LIFE,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'wholeLife',
  },
  {
    name: 'Long Term Care',
    key: LONG_TERM_CARE,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #2FAC66 38.99%, #00A19A 61.8%)',
    completedPercentage: 80,
    value: 'longTermCare',
  },
  {
    name: 'Liability/Umbrella',
    key: LIABILITY_UMBRELLA,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #FFA800 38.99%, #FFB905 61.8%)',
    completedPercentage: 47,
    value: 'liability',
  },
  {
    name: 'Property Coverage',
    key: PROPERTY_COVERAGE,
    isHighlighted: false,
    isDisabled: false,
    progressInd: 'linear-gradient(103deg, #C80154 38.99%, #FF0532 61.8%)',
    completedPercentage: 22,
    value: 'property',
  },
];

export const retirementPlanMenuItems = [
  {
    name: 'Life Style',
    isExpanded: true,
    key: LIFESTYLE,
    items: lifeStyleMenuItems,
    isHighlighted: true,
    isDisabled: false,
    isHidden: false,
  },
  {
    name: 'Life Events',
    isExpanded: false,
    isHighlighted: false,
    isDisabled: false,
    key: LIFEEVENTS,
    items: lifeEventMenuItems,
    isHidden: false,
  },
  {
    name: 'Retirement',
    key: RETIREMENT,
    isHighlighted: false,
    isDisabled: false,
    isExpanded: false,
    items: retirementMenuItems,
    isHidden: true,
  },
  {
    name: 'Estate',
    key: ESTATE,
    isHighlighted: false,
    isDisabled: false,
    isExpanded: false,
    items: estateMenuItems,
    isHidden: false,
  },
  {
    name: 'Debt',
    key: DEBT,
    isHighlighted: false,
    isDisabled: false,
    isExpanded: false,
    items: debtMenuItems,
    isHidden: false,
  },
];

export const COLUMN_CONFIG = [6, 6, 6];

export const COLUMN_CONFIG_REPORTS = [4, 4, 4];

export const MENU_ITEM_WITH_MULTIPLE_KIDS = [
  REVIEW_KIDS_INFO,
  PLAN_FOR_VACATION,
  PLAN_FOR_VEHICLE,
  REVIEWCREDITCARDS,
  REVIEWSTUDENTLOANS,
  REVIEWOTHERLOANS,
  TERM_LIFE,
  WHOLE_LIFE,
  LIABILITY_UMBRELLA,
  LONG_TERM_CARE,
  PROPERTY_COVERAGE,
  PRIMARY_HOME,
  SECOND_HOMES,
  INVESTMENT_PROPERTIES,
];

export const stateOptions = [
  { label: 'CA', value: 'CA' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NY', value: 'NY' },
  { label: 'TX', value: 'TX' },
];

export const booleanValues = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const OUTPUT_FIELD_TYPE = 'outputresponse';
